import { 
    makeStyles 
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    outerBox: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        justifyContent: 'center',
        padding: `${theme.spacer * 4}px 0px`,
        borderBottom: `1px solid ${theme.color.grey40}`
    },
    titleSection: {
        width: '40vw', 
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacer * 0,
        marginBottom: theme.spacer * 2,
        textAlign: 'center'
    },
    blockTitle: {
        maxWidth: '100px'
    },
    inputField: {                                                
        margin: `0px 0px ${theme.spacer * 1}px 0px`, 
        width: '280px',
        backgroundColor: theme.color.white,
        border: 'none',
        borderRadius: theme.spacer * 0.3,
        '& .MuiSelect-select:focus' : {
            border: 'none',
            borderWidth : 0,
            backgroundColor: 'transparent'
        },
        '& .MuiOutlinedInput-root' : {
            height: theme.spacing(5)
        },
        '& ..MuiInputBase-input' : {
            height: theme.spacing(5)
        },
    },
    mainGrid: {
        display: 'flex',
        flexDirection: 'row',
        // alignItems: 'center',
        justifyContent: 'center',
        marginTop: `${theme.spacer * 2}px`,
        width: '90%'
    },
    checkBoxClass: {
        margin: '0% 10% 2% 10%' 
    },
    dialogPaper : {
        maxHeight: '90vh',
    },
    mainBox : {
        margin: '5% 5% 2% 5%'
    },
    tableTitleBox : {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    tableBox : {
        margin: '1% 5%',
    },
    actionButtons: {
        display: 'flex',
        flexDirection: 'row',
    },
    containerBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    indGrid: {
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'center',
        marginTop: `${theme.spacer * 1}px`
    },
    indGrid1: {
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'center',
        marginTop: `${theme.spacer * 1}px`
    },
    labelTitle: {
        color: `${theme.color.black}99`,
        lineHeight: theme.spacer * 0.2,
        marginTop: `${theme.spacer * 1.5}px`
    },
    pending: {
        color: theme.palette.primary.main
    },
    approved: {
        color: theme.color.green
    },
    rejected: {
        color: theme.color.red
    },
    cancelled: {
        color: theme.color.red
    }
}));

export default useStyles;