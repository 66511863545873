import { 
    makeStyles 
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    mainGrid : {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: '01%',
        minHeight: 'calc(30vh)',
        [theme.breakpoints.down('sm')] : {
            minHeight: 'calc(50vh)'
        }
    },
    titleSection: {
        width: '40vw', 
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacer * 3,
        marginBottom: theme.spacer * 3,
        textAlign: 'center'
    },
    leftGrid : {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    orGrid: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: '120px',
        [theme.breakpoints.down('sm')] : {
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    rightGrid : {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        [theme.breakpoints.down('sm')] : {
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    or: {
        width: '100%',
        textAlign: 'center',
    },
    label: {
        backgroundColor: theme.color.grey10,
        lineHeight: theme.spacer * 0.35,
    },
    inputField: {                                               
        height: theme.spacing(5), 
        margin: `0px 0px ${theme.spacer * 1}px 0px`, 
        width: '300px',
        backgroundColor: theme.color.white,
        border: 'none',
        borderRadius: theme.spacer * 0.3,
    },
    phoneInputField : {                                           //
        margin: `0px 0px ${theme.spacer * 1}px 0px`, 
        width: '300px',
        height: theme.spacing(5),
        backgroundColor: theme.color.white,
        border: 'none',
        borderRadius: theme.spacer * 0.5,
        '&::hover' : {
            border: 'none'
        }
    },
    userDetailsBox : {
        display: 'flex',
        flexDirection: 'column',
        margin: '2% 10%'
    },
    notfound : {
        height: '140px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.spacer * 1,
        // boxShadow: '0px 1px 1px 0px rgb(0 0 0 / 14%)',
    },
    accordionShadow : {
        boxShadow: '0px 1px 1px 0px rgb(0 0 0 / 14%)',
        borderRadius: `${theme.spacer * 0.5}px`
    },
    mainBox : {
        margin: '5% 5% 2% 5%'
    },
    tableTitleBox : {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    tableBox : {
        margin: '1% 5%',
    }
}));

export default useStyles;