import React, { useState } from 'react';
import { ModalDialog, Button } from "../../components";
import { ExpandMore } from '@material-ui/icons';
import useStyles from './style';
import {
    Grid, Box,
    Typography,
    FormControl,
    OutlinedInput,
    Select,
    MenuItem
} from '@material-ui/core';

let payl = {
    "order_id": 0,
    "transaction_type": "RTGS",
    "denomination": null
};

let dnm = {
    "2000" : 0,
    "500" : 0,
    "200" : 0,
    "100" : 0,
    "50" : 0,
    "20" : 0,
    "10" : 0,
    "5" : 0,
    "2" : 0,
    "1" : 0
};

let dnmKeys = [
    "2000" , "500" , "200", "100",
    "50", "20", "10", "5", "2", "1" 
]

export default function ConfirmOrder(props) {
    const classes = useStyles();
    const [payload, setPayload] = useState(payl);
    const [denomination, setDenomination] = useState(dnm);
    const {
        status = '',
        details = {},
        showDetails = '',
        setDetails = () => null
    } = props;

    let isDnmRequired = payload.transaction_type === 'CASH';

    const handleChange = (name, value, type = 'text') => {
        setPayload({ ...payload, [name]: value });
    }

    const handleDenominationChange = (name, value) => {
        setDenomination({ ...denomination, [name] : value || 0});
    }

    const handleSubmit = () => {
        let dnmStr = payload.transaction_type === 'CASH' ?
                        JSON.stringify(denomination) : null;
        let pay = {
            denomination: dnmStr,          
            order_id: details.id,
            transaction_type: payload.transaction_type
        };
        props.handleSubmit(pay);
    }

    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        Fill details to Confirm Order
                    </Typography>
                </Box>
                <Grid 
                    container 
                    direction='row' 
                    className = {classes.fundDetailsMainGrid}
                >
                    <Grid 
                        item xs={12} xl={6}
                        sm={6} md={6} lg={6} 
                        className = {classes.actionGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Customer Name
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.customer_name || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Requested Amount
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.requested_amount || 'NA'}
                        </Typography>
                    </Grid>
                    <Grid 
                        item xs={12} xl={6}
                        sm={6} md={6} lg={6} 
                        className = {classes.actionGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Customer Mobile Number
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.customer_mobile_number || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Approved Amount
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.approved_amount || 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid 
                    container 
                    direction='row' 
                    className = {classes.fundDetailsMainGrid}
                    style = {{marginTop: '16px'}}
                >
                    <Grid 
                        item 
                        xs={12} 
                        sm={isDnmRequired ? 6 : 12} 
                        md={isDnmRequired ? 6 : 12} 
                        lg={isDnmRequired ? 6 : 12} 
                        xl={isDnmRequired ? 6 : 12}
                        className = {classes.actionGrid}
                    >
                        <FormControl variant='outlined' className={classes.inputField}>
                            <Typography variant='subtitle2'
                                className={classes.label}
                            >
                                Transaction type
                            </Typography>
                            <Select
                                className={classes.inputField}
                                IconComponent = {ExpandMore}
                                value = {payload['transaction_type']}
                                inputProps={{name: 'transactiontype'}}
                                onChange={(e) => handleChange(
                                    'transaction_type', e.target.value
                                )}
                            >
                                <MenuItem value={'CASH'}>Cash</MenuItem>
                                <MenuItem value={'RTGS'}>RTGS</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {
                    isDnmRequired &&
                    <Grid 
                        item
                        sm={isDnmRequired ? 6 : 12} 
                        md={isDnmRequired ? 6 : 12} 
                        lg={isDnmRequired ? 6 : 12} 
                        xl={isDnmRequired ? 6 : 12}
                        className = {classes.actionGrid}
                    >
                        {
                            dnmKeys.map(dnms => {
                                return (
                                    <FormControl className={classes.inputField}>
                                        <Typography variant='subtitle2' className={classes.label}>
                                            {dnms} 's
                                        </Typography>
                                        <OutlinedInput
                                            onChange={(e) => handleDenominationChange(
                                                dnms, parseInt(e.target.value)
                                            )}
                                            className={classes.inputField}
                                            value = {denomination[dnms]}
                                            type = {'number'}
                                            id={"denom_" + dnms}
                                            inputProps = {{
                                                required: true,
                                                maxLength : 45,
                                                min : 0
                                            }}
                                        />
                                    </FormControl>
                            )})
                        }
                        
                    </Grid>
                    }
                </Grid>
                <Button
                    text = "Confirm Payout"
                    variant = "primary"
                    style = {{
                        padding: '8px 16px',
                        marginTop: '40px', 
                        width: '280px' 
                    }}
                    loading = {status === 'CONFIRM_REQUEST'}
                    onClick = {() => handleSubmit() }
                />
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {showDetails}
            onClose = {() => setDetails('', details)}
            dialogTitle = ''
            dialogContent = {dialogContent()}
            maxWidth = {'md'}
            classes = {{ paper: classes.dialogPaper}}
            dialogFooter = {[]}
        />
    )

}