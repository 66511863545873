import userConstants from '../constants/user';
import { userService } from '../services';
import notyf from '../utils/notification';

export const userActions = {
    add,
    get,
    update,
    activateUser,
    resetPassword,
    deactivateUser,
    getAllEmployees,
    transferBranch,
}


function add(payload){
    return dispatch =>{
        dispatch(request());
        userService.add(payload).then(
            user =>{ dispatch(success(user)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: userConstants.ADD_REQUEST };
    }
    function success(user){
        notyf.open({
            type: 'success',
            message: 'Added Successfully.'
        })
        return {
            type: userConstants.ADD_SUCCESS,
            payload: user
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: userConstants.ADD_ERROR,
            payload : error
        };
    }
}

function update(payload){
    return dispatch =>{
        dispatch(request());
        userService.update(payload).then(
            user =>{ dispatch(success(user)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: userConstants.UPDATE_REQUEST };
    }
    function success(user){
        notyf.open({
            type: 'success',
            message: 'Updated Successfully.'
        })
        return {
            type: userConstants.UPDATE_SUCCESS,
            payload: user
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: userConstants.UPDATE_ERROR,
            payload : error
        };
    }
}

function  get(payload){
    return dispatch =>{
        dispatch(request());
        userService.get(payload).then(
            user =>{ dispatch(success(user)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: userConstants.GET_REQUEST };
    }
    function success(user){
        return {
            type: userConstants.GET_SUCCESS,
            payload: user
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: userConstants.GET_ERROR,
            payload : error
        };
    }
}

function getAllEmployees() {
    return dispatch =>{
        dispatch(request());
        userService.getAllEmployees().then(
            user =>{ dispatch(success(user)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: userConstants.GET_EMP_REQUEST };
    }
    function success(user){
        return {
            type: userConstants.GET_EMP_SUCCESS,
            payload: user
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: userConstants.GET_EMP_ERROR,
            payload : error
        };
    }
}

function resetPassword(payload){
    return dispatch =>{
        dispatch(request());
        userService.resetPassword(payload).then(
            user =>{ dispatch(success(user)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: userConstants.RESET_REQUEST };
    }
    function success(user){
        notyf.open({
            type: 'success',
            message: 'Password Reset Successfull.'
        })
        return {
            type: userConstants.RESET_SUCCESS,
            payload: user
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: userConstants.RESET_ERROR,
            payload : error
        };
    }
}

function activateUser(payload){
    return dispatch =>{
        dispatch(request());
        userService.activateUser(payload).then(
            user =>{ dispatch(success(user)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: userConstants.ACTIVATE_REQUEST };
    }
    function success(user){
        notyf.open({
            type: 'success',
            message: 'Activation Successfull.'
        })
        return {
            type: userConstants.ACTIVATE_SUCCESS,
            payload: user
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: userConstants.ACTIVATE_ERROR,
            payload : error
        };
    }
}

function deactivateUser(payload){
    return dispatch =>{
        dispatch(request());
        userService.deactivateUser(payload).then(
            user =>{ dispatch(success(user)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: userConstants.DEACTIVATE_REQUEST };
    }
    function success(user){
        notyf.open({
            type: 'success',
            message: 'Deactivation Successfull.'
        })
        return {
            type: userConstants.DEACTIVATE_SUCCESS,
            payload: user
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: userConstants.DEACTIVATE_ERROR,
            payload : error
        };
    }
}

function transferBranch(payload){
    return dispatch =>{
        dispatch(request());
        userService.transferBranch(payload).then(
            user =>{ dispatch(success(user)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: userConstants.TRANS_BRANCH_REQUEST };
    }
    function success(user){
        notyf.open({
            type: 'success',
            message: 'Branch transfer successfull'
        })
        return {
            type: userConstants.TRANS_BRANCH_SUCCESS,
            payload: user
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: userConstants.TRANS_BRANCH_ERROR,
            payload : error
        };
    }
}