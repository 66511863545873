import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    DropDown: {
        '& div': {
            willChange: 'unset !important',
        },
        //zIndex:100,
        cursor:'pointer',
    },
    DropDownButton: {
        float: 'right',
        position: 'inherit',
        marginLeft: '-26px',
        marginTop: '6px',
        fontSize: '20px',
        paddingRight: '4px',
        // color: theme.color.gray40,
    },
    moreOptionsIcon: {
        marginTop: '2px !important',
        marginRight: '0 !important',
        paddingRight: '0 !important',
        color: "#CDD4E4"
    }

}));

export default useStyles;