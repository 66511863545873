import React from 'react';
import { ModalDialog } from "../../../components";
import { expenseConstants as ec } from '../../../constants';
import useStyles from '../style';
import {
    Grid, Box, Typography,
} from '@material-ui/core';
import { utils } from '../../../utils';


export default function ExpenseDetails(props) {
    const classes = useStyles();
    const {
        show = false,
        setShow = () => null,
        details = {},
    } = props;

    let isCash = details.expense_payment_mode === 'CASH';
    let cashDenom = isCash && details.cash_denomination ? 
                            JSON.parse(details.cash_denomination) : 
                            {}; 


    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        {ec.updateTitle}
                    </Typography>
                    <Typography variant = 'p' className = {`${classes.subTitle}`}>
                        status : {' '}
                        <span className={classes[details?.status?.toLowerCase()]}>
                            {details.status}
                        </span>
                    </Typography>
                </Box>
                <Grid 
                    container 
                    className = {classes.mainGrid}
                >
                    <Grid 
                        item 
                        xs={12} 
                        sm={isCash ? 5 : 6} 
                        md={isCash ? 5 : 6}
                        lg={isCash ? 5 : 6}
                        xl={isCash ? 5 : 6}
                        className = {classes.indGrid1}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Expense type
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.expense_type || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Expense Amount (INR)
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.expense_amount || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Spent at
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.expense_on || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Expense Payment Mode
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.expense_payment_mode || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Requested from Branch
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.requested_branch_name || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Requested By
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.requested_by || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Request placed on
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.expense_req_placed_on || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Description
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.expense_description || 'NA'}
                        </Typography>

                    </Grid>
                    <Grid 
                        item 
                        xs={12} 
                        sm={isCash ? 5 : 6} 
                        md={isCash ? 5 : 6}
                        lg={isCash ? 5 : 6}
                        xl={isCash ? 5 : 6}
                        className = {classes.indGrid1}
                    >

                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Approved/Rejected by Branch
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.approved_branch_name || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Approved/Rejected By
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.approved_by || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Approved/Rejected On
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {utils.getLocalTime(details.approved_on || "") || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Approval/Rejection Description
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.approval_description || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Expense Request Cancelled By
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.cancelled_by || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Expense Request Cancelled On
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {utils.getLocalTime(details.cancelled_on || "") || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Expense Request Cancelled remarks
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.remarks || 'NA'}
                        </Typography>
                    </Grid>
                    {
                        isCash && Object.keys(cashDenom).length > 0 &&
                        <Grid 
                            item xs={12} sm={2} 
                            md={2} lg={2} xl={2}
                            className = {classes.indGrid1}
                        >
                            <Typography variant='subtitle2' className={classes.labelTitle}>
                                Denominations
                            </Typography>
                            {
                                Object.keys(cashDenom).map(e => {
                                    return (
                                        <Typography variant='subtitle2' className={classes.label}>
                                            <span className={classes.labelTitle}>{e} : </span>
                                            {cashDenom[e] || '0'}
                                        </Typography>
                                    )
                                })
                            }
                        </Grid>
                    }
                </Grid>

                <Grid 
                    container 
                    className = {classes.mainGrid}
                    style = {{textAlign: 'center'}}
                >
                    <Grid 
                        item 
                        className = {classes.indGrid1}
                    >
                        <Typography variant="h5" className={classes.labelTitle}>
                            Expense Bill
                        </Typography>
                        {
                            details.expense_bill &&
                            <img 
                                src={utils.getImageUrl(details.expense_bill)} 
                                width = "100%"
                                height = "100%"
                            />
                        }
                        {
                            !details.expense_bill &&
                            <Typography variant='subtitle2' className={classes.subTitle}>
                                {'NA'}
                            </Typography>
                        }
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {show}
            onClose = {setShow}
            dialogTitle = ''
            dialogContent = {dialogContent()}
            maxWidth = {'md'}
            classes = {{ paper: classes.dialogPaper}}
            dialogFooter = {[]}
        />
    )

}