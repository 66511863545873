import React, { useEffect, useState } from 'react';
import { Add, AutorenewOutlined } from '@material-ui/icons';
import { Box, Paper, Typography } from '@material-ui/core';
import { Button, Navbar, Table } from "../../components";
import UpdateMaterial from './update-material';
import AddMaterial from './add-material';
import { utils } from '../../utils';
import useStyles from './style';

let tableHeaders = [
    {
        id: "name",
        label : "Material Name",
        type: 'text',
        minWidth: '120px',
        style : {}
    },
    {
        id: "material_price",
        label : "Current Price (INR) / g",
        type: 'text_wo_input',
        minWidth: '120px',
        style : {}
    },
    {
        id: "cgst",
        label : "CGST (%)",
        type: 'text_wo_input',
        minWidth: '120px',
        style : {}
    },
    {
        id: "sgst",
        label : "SGST (%)",
        type: 'text_wo_input',
        minWidth: '120px',
        style : {}
    },
    {
        id: "",
        label : "",
        type: 'more',
        minWidth: '50px',
        style : {}
    }
];


let rowMenuOptions = [
    {key: 'edit' , title: 'Edit'}
]

export default function MaterialLayout(props){
    const classes = useStyles();
    const [filterQuery, setFilterQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [clearInputFields, setClearInputFields] = useState(false);
    const {
        status = '',
        details = [],
        error = false,
        showAdd = false,
        addPayload = {},
        updatePayload = {},
        showUpdate = {flag: false, id: ''},
        getDetails = () => null,
        updateState = () => null,
        handleAddSubmit = () => null,
        handleUpdateSubmit = () => null,
    } = props;
    
    useEffect(() => {
        setFilterQuery('');
        setFilteredData([]);
        setClearInputFields(true);
    }, [details]);

    useEffect(() => {
        if(clearInputFields){
            setClearInputFields(false);
        }
    }, [clearInputFields]);

    const handleAddChange = (name, value) => {
        updateState({ 
            error: false,
            addPayload: {
                ...addPayload,
                [name] : value
            }
        });
    }

    const handleUpdateChange = (name, value) => {
        updateState({
            error: false,
            updatePayload: {
                ...updatePayload,
                [name] : parseFloat(value) || 0
            }
        });
    }

    const handleMenuDropdown = (a, b, c, row) => {
        updateState({
            showUpdate: {flag: true, id: row},
            updatePayload : {
                // material_id: row.material_id,
                material_name: row.name,
                cgst: row.cgst || 0,
                sgst: row.sgst || 0,
                material_price: row.material_price || 0,
                // branch_id: row.branch_id
            }
        })
    }

    const onClose = () => {
        updateState({
            addPayload : { 
                name: '', 
                // branch_id: '' 
            },
            showUpdate: {flag: false, id: {}},
            showAdd: false,
            updatePayload : {},
            error : false
        })
    }

    const handleFilterChange = (val, col) => {
        if(val){
            let fd = [ ...details ];
            if(details.length > 0){
                fd = fd.filter((f) => 
                    f.name.toLowerCase().includes(val.toLowerCase())
                );
            }
            setFilterQuery(val);
            setFilteredData(fd);
        }else {
            setFilterQuery('');
            setFilteredData([]);
        }
    }

    const getFormattedData = (data = []) => {
        if(data.length === 0)
            return data;
        return data.map(e => {
            return {
                ...e,
                material_price: e.material_price ? 
                    utils.getFormattedAmount(e.material_price) : "",
            }
        })
    }

    return (
        <Navbar 
            { ...props }
        >
            <Box className={classes.mainBox}>
                <Box className = {classes.tableTitleBox}>
                    <Typography variant = "h5">
                        Materials
                    </Typography>
                    <Box className={classes.actionButtons}>
                        <Button
                            text = "Refresh"
                            variant = "secondary"
                            Icon = {AutorenewOutlined}
                            style = {{
                                padding: '8px 16px', 
                                marginRight: '16px' 
                            }}
                            onClick = {() => getDetails()}
                        />
                        {
                            utils.getRole().includes('ADMIN') &&
                            <Button
                                text = "Add new"
                                variant = "primary"
                                Icon = {Add}
                                style = {{padding: '8px 16px'}}
                                onClick = {() => updateState({ showAdd: true})}
                            />
                        }
                    </Box>
                </Box>
            </Box>
            <Paper className = {classes.tableBox}>
                <Table
                    tableData = {getFormattedData(filterQuery ? filteredData : details)}
                    rowMenuOptions = {
                        utils.getRole().includes('ADMIN') ? rowMenuOptions : []
                    }
                    handleMenuDropdown = {handleMenuDropdown}
                    loading = {status === 'GET_ALL_REQUEST'}
                    clearInputFields = {clearInputFields}
                    handleChange = {handleFilterChange}
                    tableColumns = {tableHeaders}
                />
            </Paper>
            {
                showAdd &&
                <AddMaterial                     
                    handleChange = {handleAddChange}
                    handleSubmit = {handleAddSubmit}
                    payload = {addPayload}
                    setShowAdd = {onClose}
                    showAdd = {showAdd}
                    status = {status}
                    error = {error}
                />
            }
            {
                showUpdate.flag &&
                <UpdateMaterial 
                    handleChange = {handleUpdateChange}
                    handleSubmit = {handleUpdateSubmit}
                    payload = {updatePayload}
                    show = {showUpdate}
                    setShow = {onClose}
                    status = {status}
                    error = {error}
                />
            }
        </Navbar>
    );
}