import attendanceConstants from '../constants/attendance';
import { attendanceService } from '../services';
import notyf from '../utils/notification';

export const attendanceActions = {
    getTotalRecords,
    logAttendance,
    isLoggedForDay,
    getAll,
}

function getAll(offset = 0, limit = 10, filter = {}){
    return dispatch =>{
        dispatch(request());
        attendanceService.getAll(offset, limit, filter)
            .then(
                user =>{ dispatch(success(user)) },
                error =>{ dispatch(failure(error)) }
            );
    };
    function request(){
        return { type: attendanceConstants.GET_ALL_REQUEST };
    }
    function success(logs){
        return {
            type: attendanceConstants.GET_ALL_SUCCESS,
            payload: logs
        };
    }
    function failure(error){
        return {
            type: attendanceConstants.GET_ALL_ERROR,
            payload : error
        };
    }
}

function logAttendance(payload){
    return dispatch =>{
        dispatch(request());
        attendanceService.logAttendance(payload).then(
            logs =>{ dispatch(success(logs)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: attendanceConstants.POST_REQUEST };
    }
    function success(logs){
        notyf.open({
            type: 'success',
            message: logs.message
        });
        return {
            type: attendanceConstants.POST_SUCCESS,
            payload: logs
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: attendanceConstants.POST_ERROR,
            payload : error
        };
    }
}

function isLoggedForDay(userId = 0){
    return dispatch =>{
        dispatch(request());
        attendanceService.isLoggedForDay(userId)
            .then(
                user =>{ dispatch(success(user)) },
                error =>{ dispatch(failure(error)) }
            );
    };
    function request(){
        return { type: attendanceConstants.GET_REQUEST };
    }
    function success(logs){
        return {
            type: attendanceConstants.GET_SUCCESS,
            payload: logs
        };
    }
    function failure(error){
        return {
            type: attendanceConstants.GET_ERROR,
            payload : error
        };
    }
}

function getTotalRecords(filter = {}){
    return dispatch =>{
        dispatch(request());
        attendanceService.getTotalRecords(filter).then(
            value =>{ dispatch(success(value)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: attendanceConstants.RECORDS_REQUEST };
    }
    function success(value){
        return {
            type: attendanceConstants.RECORDS_SUCCESS,
            payload: value
        };
    }
    function failure(error){
        return {
            type: attendanceConstants.RECORDS_ERROR,
            payload : error
        };
    }
}