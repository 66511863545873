import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    radioRoot: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    radioIcon: {
        borderRadius: "50%",
        width: 16,
        height: 16,
        boxShadow: "unset",
        backgroundColor: theme.color.coolgray10,
        backgroundImage: "unset",
        "$root.Mui-focusVisible &": {
            outline: "none",
            outlineOffset: 2
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5"
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)"
        }
    },
    radioCheckedIcon: {
        backgroundColor: theme.color.coolgray10,
        backgroundImage: "unset",
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage: `radial-gradient(${theme.color.blue60},${theme.color.blue60} 28%,transparent 32%)`,
            content: '""'
        },
        "input:hover ~ &": {
            backgroundColor: theme.color.coolgray10
        }
    },
    hideMoreOption: {
        display: 'none'
    },
    moreOptionCursor: {
        cursor: 'default'
    }
}));

export default useStyles