import React from 'react';
import { Button, Loader } from "../../components";
import { Box, Typography } from '@material-ui/core';
import { ExitToAppOutlined, PrintOutlined } from '@material-ui/icons';
import InvoicePage from './invoice';


export default function InvoiceLayout(props){
    const ref = React.createRef();
    const {
        details = {},
        isEstimate = false,
        status = ''
    } = props;

    if(status === 'GET_REQUEST'){
        return (
            <Box>
                <Box style={{height: '200px'}}>
                    <Loader />
                </Box>
                <Typography variant = "h5"
                    style = {{textAlign: 'center'}}
                >
                    Generating Invoice...
                </Typography>
            </Box>
        );
    }else if(status === 'GET_SUCCESS'){
        return (
            <div>
                <InvoicePage 
                    isEstimate = {isEstimate}
                    details = {details}
                />
                <Box 
                    className={'row col-xs-12 center'}
                    style={{margin: '20px 0px'}}
                >
                    <Button
                        text = "Close"
                        variant = "secondary"
                        Icon = {ExitToAppOutlined}
                        style = {{
                            padding: '8px 16px',
                            marginRight: '16px'
                        }}
                        onClick = {() => window.close()}
                    />
                    <Button
                        text = "Print"
                        variant = "primary"
                        Icon = {PrintOutlined}
                        style = {{
                            padding: '8px 16px',
                        }}
                        onClick = {() => window.print()}
                    />
                </Box>
            </div>
        );
    }else {
        return <div></div>;
    }
}
