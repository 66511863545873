import React, { Component } from 'react';
import { ordersConstants as orc } from '../../constants';
import { orderActions } from '../../actions/orders';
import { userActions } from '../../actions/user';
import notyf from '../../utils/notification';
import OrderApprovalLayout from './layout';
import tHeader from './tableHeader.json';
import { connect } from 'react-redux';
import Forbidden from '../forbidden';
import { utils } from '../../utils';

class OrderApproval extends Component{
    constructor(props) {
        super(props);
        this.state = {
            tableHeaders: [ ...tHeader ],
            summary: { },
            pledge_item: {},
            tableData : []
        }
    }

    handleInvalidOrder = () => {
        const { history } = this.props;
        notyf.open({type: 'error' , message: orc.invalidOrder });
        history.push('/orders');
    }

    componentDidMount() {
        const { dispatch, match, orders } = this.props;
        let order_id = parseInt(match.params.id);
        if(order_id){
            dispatch(orderActions.get(order_id));
        }else{
            this.handleInvalidOrder();
        }
    }

    calculateAmount = (g, flag = false) => {
        let net_weight = (g.item_weight - (g.stone_wastage + g.other_wastage));
        let item_actual_price = net_weight * g.material_price * ( g.item_purity / 100 );
        item_actual_price = item_actual_price + ( g.stone_wastage * g.stone_price );
        let is_hallmark = false;
        if(g.is_hallmark === true) is_hallmark = 'Yes';
        else if(g.is_hallmark === false) is_hallmark = 'No';
        else is_hallmark = g.is_hallmark;

        if(!flag)
            item_actual_price =  item_actual_price - ( (g.margin * item_actual_price ) / 100) ;

        item_actual_price = parseFloat(utils.roundFixed(item_actual_price) || 0);
        return { ...g, is_hallmark, item_actual_price };
    }

    calculateEachItemAmount = (data) => {
        return data.map(e => { 
            return {
                ...this.calculateAmount(e),
                item_image: utils.getImageUrl(e.item_image) 
            }
        });
    }

    componentDidUpdate(prevProps){
        const { history, orders, dispatch } = this.props;
        let orderPropsChanged = orders.status !== prevProps.orders.status;


        if(orderPropsChanged){
            if(orders.status === 'APPROVE_SUCCESS'){
                history.push('/orders');
            }
            if(
                orders.status === 'GET_SUCCESS' &&
                !orders.details?.order_items?.length
            ){
                this.handleInvalidOrder();
            }
            if(
                orders.status === 'GET_SUCCESS' &&
                orders.details?.order_items?.length
            ){
                let odi = this.calculateEachItemAmount(orders.details.order_items);
                let summary = { ...orders.details.order };
                summary = { ...summary, approved_amount: summary.requested_amount };
                let pledge_item = { ...orders.details.order_pledge?.[0]}
                this.setState({ tableData: odi, summary, pledge_item });
                dispatch(userActions.get({
                    "filter": [{ 
                        "field": "id", "op": "eq", 
                        "value": summary.customer_id
                    }]
                }));
            }
        }
    }

    handleReset = () => {
        const { orders } = this.props;
        const { summary } = this.state;
        let odi = this.calculateEachItemAmount(orders.details.order_items);
        let s = {
            ...summary,
            description : '',
            approved_amount: summary.requested_amount
        }
        this.setState({ tableData: odi, summary: s });
    }


    handleSubmit = () => {
        const { dispatch, match } = this.props;
        const { summary, tableData } = this.state;
        let orderItems = tableData.map(e => { return {
            "order_item_id": e.id || 0,
            "approved_amount": e.item_actual_price || 0,
            "margin": e.margin || 0
        }});
        let payl = {
             "order_id": parseInt(match.params.id),
             "status": "Approved",
             "approved_amount": parseFloat(summary.approved_amount),
             "description": summary.description || "",
             "order_items": [ ...orderItems ]
        }
        dispatch(orderActions.approve(payl));
    }

    handleReject = () => {
        const { dispatch, match } = this.props;
        const { summary, tableData } = this.state;
        let orderItems = tableData.map(e => { return {
            "order_item_id": e.id || 0,
            "approved_amount": 0,
            "margin": e.margin || 0
        }});
        let payl = {
             "order_id": parseInt(match.params.id),
             "status": "Rejected",
             "approved_amount": 0,
             "description": summary.description || "",
             "order_items": [ ...orderItems ]
        }
        dispatch(orderActions.approve(payl, false));
    }

    render() {
        if(utils.getRole().includes('ADMIN'))
            return (
                <OrderApprovalLayout
                    details = {this.state.summary}
                    tableData = { this.state.tableData}
                    userStatus = {this.props.user.status}
                    pledge_item = {this.state.pledge_item}
                    tableHeaders = { this.state.tableHeaders}
                    userDetails = {this.props.user.userDetails}
                    materialDetails = {this.props.material.details || []}
                    handleReset = {this.handleReset}
                    handleSubmit = {this.handleSubmit}
                    handleReject = {this.handleReject}
                    calculateAmount = {this.calculateAmount}
                    setTableData = {(e) => this.setState({tableData : e })}
                    updateSummary = {(e) => this.setState({ summary: e })}
                    {...this.props}
                />
            );
        else
            return (
                <Forbidden 
                    profile={this.props.profile}
                    dispatch = {this.props.dispatch}
                    notifications = {this.props.notifications}
                />
            );
    }
}

function mapStateToProps(state) {
    const { profile, material, user, orders, notifications } = state;
    
    return {
        profile,
        material,
        user,
        orders,
        notifications
    }
}

export default connect(mapStateToProps)(OrderApproval);