import React, { useEffect, useState } from 'react';
import { Button, Confirmation, Navbar, Table } from "../../components";
import { leaveRequestConstants as lrc } from '../../constants';
import PendingAction from './components/pending-action';
import LeaveDetails from './components/leave-details';
import NewRequest from './components/new-request';
import notyf from '../../utils/notification';
import useStyles from './style';
import { 
    KeyboardArrowDown, 
    AutorenewOutlined, 
    ClearAllOutlined,
    Add
} from '@material-ui/icons';
import {
    Box, Paper,
    Typography,
    TablePagination
} from '@material-ui/core';

let tableHeaders = [
    {
        id: "requested_by",
        label : "Name",
        type: 'text_wo_input',
        minWidth: '120px',
        maxWidth: '120px',
        style : {}
    },
    {
        id: "approved_by",
        label : "Approved By",
        type: 'text_wo_input',
        minWidth: '120px',
        maxWidth: '120px',
        style : {}
    },
    {
        id: 'leave_type',
        label: 'Type',
        type: 'dropdown',
        placeHolder: "Type",
        dropdownItems: [
            {label: "Full Day", value: "Full Day"},
            {label: "First Half", value: "First Half"},
            {label: "Second Half", value: "Second Half"},
        ],
        minWidth: '120px',
        maxWidth: '120px',
    },
    {
        id: "from_date",
        label : "From",
        type: 'text',
        inputType: 'date',
        minWidth: '150px',
        maxWidth: '150px',
        style : {}
    },
    {
        id: "to_date",
        label : "To",
        type: 'text',
        inputType: 'date',
        minWidth: '150px',
        maxWidth: '150px',
        style : {}
    },
    {
        id: 'status',
        label: 'Status',
        type: 'dropdown',
        placeHolder: "Status",
        dropdownItems: [
            {label: "Pending", value: "PENDING"},
            {label: "Approved", value: "APPROVED"},
            {label: "Rejected", value: "REJECTED"},
            {label: "Cancelled", value: "CANCELLED"},
        ],
        minWidth: '120px',
        maxWidth: '120px',
    },
    {
        id: 'status',
        label: '',
        type: 'icon',
        minWidth: '50px',
        maxWidth: '50px'
    },
    {
        id: 'more',
        label: '',
        type: 'dynamic_more_wo_header',
        minWidth: '50px',
        maxWidth: '50px'
    }
];

let paginationDropDownList = [
    10, 20, 40
]


let defaultQuery = {
    status: '',
    from_date : '',
    to_date : '',
    request_by: 0,
    approved_by: 0,
    leave_type: ''
}

export default function LeaveRequestsLayout(props){
    const classes = useStyles();
    const [filterQuery, setFilterQuery] = useState({ ...defaultQuery });
    const [clearInputFields, setClearInputFields] = useState(false);
    const [showPendingAction, setShowPendingAction] = useState(
        {flag: false, details: {}});
    const [showCancelAction, setShowCancelAction] = useState(
        {flag: false, id: ''});
    const [showLeaveDetails, setShowLeaveDetails] = useState(
        {flag: false, value: {}});
    const [showNewRequest, setNewRequest] = useState(false);
    const [tableData, setTableData] = useState([]);
    const {
        handlePendingActionSubmit = () => null,
        handleCancelActionSubmit = () => null,
        handleSubmit = () => null,
        getDetails = () => null,
        setLimit = () => null,
        setPage = () => null,
        leaveDetails = [],
        noOfRecords = 0,
        profile = {},
        status = '',
        limit = 10,
        page = 0
    } = props;

    useEffect(() => {
        if(status === 'GETALL_SUCCESS'){
            let td = [ ...leaveDetails ];
            setTableData([ ...td ]);
        }
        if(status === 'POST_SUCCESS'){
            setNewRequest(false);
        }
        if(status === 'P_ACTION_SUCCESS'){
            setShowPendingAction({flag: false, details: {}});
            setClearInputFields(true);
        }
        if(status === 'CANCEL_LR_SUCCESS'){
            setShowCancelAction({flag: false, id: ''});
            setClearInputFields(true);
        } 
    },[status]);

    useEffect(() => {
        if(clearInputFields){
            setClearInputFields(false);
            setFilterQuery(defaultQuery);
        }
    }, [clearInputFields]);


    const handleMenuDropdown = (e, k, i, r) => {
        if(k.key === 'details'){
            setShowLeaveDetails({flag: true, value: r});
        }else if(k.key === 'app/rej'){
            setShowPendingAction({flag: true, details: r});
        }else if( k.key === 'cancel' ){
            setShowCancelAction({flag: true, id: r.id});
        }else
            notyf.open({ type: 'error', message: lrc.invalidAction });

    }

    const handleIconClick = (row) => {
        if(
            row.request_by === profile?.basic?.id && 
            row.status?.toLowerCase() === 'pending' 
        ) {
            setShowCancelAction({flag: true, id: row.id});
        } else if(row.status?.toLowerCase() === 'pending') {
            setShowPendingAction({flag: true, details: row});
        } else {
            notyf.open({ type: 'error', message: lrc.invalidAction });
        }
    }

    const onRowClick = (e, r, i) => {
        // setShowLeaveDetails({flag: true, value: r});
    }

    const rowMenuOptions = (row) => {
        let arr = [{key: 'details', title: 'Details'}];
        if(
            row.request_by === profile?.basic?.id && 
            row.status?.toLowerCase() === 'pending'
        ) {
            arr.push({key: 'cancel' , title: 'Cancel Request'})
        } else if(row.status?.toLowerCase() === 'pending') {
            arr.push({key: 'app/rej' , title: 'Approve/Reject'})
        }

        return arr;
    };

    const onChangeRowsPerPage = (e) => {
        setLimit(e.target.value);
        setPage(0);
        getDetails(0, e.target.value, filterQuery);
    }
    
    const onChangePage = (e, val) => {
        setPage(val);
        getDetails(val, limit, filterQuery);
    }

    const handleFilterChange = (val, col) => {
        let fq = { ...filterQuery, [col.id] : val }
        setPage(0);
        setFilterQuery(fq);
        getDetails(0, limit, fq);
    }

    const resetFilter = () => {
        setPage(0);
        setClearInputFields(true);
        getDetails(0, limit, defaultQuery);
    }

    return (
        <Navbar 
            { ...props }
        >
            <Box className={classes.mainBox}>
                <Box className = {classes.tableTitleBox}>
                    <Typography variant = "h5">
                        Leave Requests
                    </Typography>
                    <Box className={classes.actionButtons}>
                        <Button
                           text = "Clear Filter"
                            variant = "secondary"
                            Icon = {ClearAllOutlined}
                            style = {{
                                padding: '8px 16px', 
                                marginRight: '16px' 
                            }}
                            onClick = {resetFilter}
                        />
                        <Button
                            text = "Refresh"
                            variant = "secondary"
                            Icon = {AutorenewOutlined}
                            style = {{
                                padding: '8px 16px', 
                                marginRight: '16px' 
                            }}
                            onClick = {() => getDetails(
                                page, limit, filterQuery
                            )}
                        />
                        <Button
                            text = "New request"
                            variant = "primary"
                            Icon = {Add}
                            style = {{padding: '8px 16px'}}
                            onClick = {() => setNewRequest(true) }
                        />
                    </Box>
                </Box>
            </Box>
            <Paper className = {classes.tableBox}>
                <Table
                    handleMenuDropdown = {handleMenuDropdown}
                    loading = {status === 'GETALL_REQUEST'}
                    clearInputFields = {clearInputFields}
                    handleChange = {handleFilterChange}
                    handleIconClick = {handleIconClick}
                    rowMenuOptions = {rowMenuOptions}
                    onTableRowClick = {onRowClick}
                    tableColumns = {tableHeaders}
                    tableData = {tableData}
                />
                 <TablePagination
                    SelectProps={{
                        IconComponent: KeyboardArrowDown,
                        classes: { icon: classes.selectIcon },
                    }}
                    rowsPerPageOptions={paginationDropDownList}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    onChangePage={onChangePage}
                    count={noOfRecords}
                    rowsPerPage={limit}
                    component='div'
                    page={page}                   
                />  
            </Paper>
            {
                showNewRequest &&
                <NewRequest
                    showNewRequest = {showNewRequest}
                    setNewRequest = {setNewRequest}
                    handleSubmit = {handleSubmit}
                    status = {status}
                />
            }
            {
                showLeaveDetails &&
                <LeaveDetails
                    showDetails = {showLeaveDetails.flag}
                    detail = {showLeaveDetails.value}
                    setDetails = {setShowLeaveDetails}
                    status = {status}
                />
            }
            {
                showPendingAction.flag &&
                <PendingAction
                    status = {status}
                    details = {showPendingAction.details}
                    handleSubmit={(p) => handlePendingActionSubmit(p)}
                    setDetails = {() => setShowPendingAction({flag: false, details: {}})}
                />
            }
            {
                showCancelAction.flag &&
                <Confirmation
                    onClose = {() => setShowCancelAction({flag: false, id: ''})}
                    onSubmit = {() => handleCancelActionSubmit({
                        request_id: showCancelAction.id
                    })}
                    submitText = {"Cancel Request"}
                    content = {lrc.cancelText}
                    closeText = {"Close"}
                    type = {'error'}
                />
            }
        </Navbar>
    )
}