import assetConstants from '../constants/assets';

const initialState = {
    status: '',
    details: [],
    requests: [],
    noOfRecords: 0,
    noOfReq: 0
}

function assets(state = initialState, action) {
    switch(action.type) {
        case assetConstants.GET_REQUEST:
            return {
                ...state,
                status: 'GET_REQUEST'
            }
        case assetConstants.GET_SUCCESS : 
            return {
                ...state,   
                status: 'GET_SUCCESS',
                details: action.payload.data
            }
        case assetConstants.GET_ERROR:
            return {
                ...state,
                status: 'GET_ERROR'
            }
        case assetConstants.GET_REQ_REQUEST:
            return {
                ...state,
                status: 'GET_REQ_REQUEST'
            }
        case assetConstants.GET_REQ_SUCCESS : 
            return {
                ...state,   
                status: 'GET_REQ_SUCCESS',
                requests: action.payload.data
            }
        case assetConstants.GET_REQ_ERROR:
            return {
                ...state,
                status: 'GET_REQ_ERROR'
            }
        case assetConstants.RECORDS_SUCCESS : 
            return {
                ...state,   
                status: 'RECORDS_SUCCESS',
                noOfRecords: action.payload.data.total
            }
        case assetConstants.RECORDS_ERROR:
            return {
                ...state,
                status: 'RECORDS_ERROR'
            }
        case assetConstants.RECORDS_REQUEST:
            return {
                ...state,
                status: 'RECORDS_REQUEST'
            }
        case assetConstants.REQ_RECORDS_SUCCESS : 
            return {
                ...state,   
                status: 'REQ_RECORDS_SUCCESS',
                noOfReq: action.payload.data.total
            }
        case assetConstants.REQ_RECORDS_ERROR:
            return {
                ...state,
                status: 'REQ_RECORDS_ERROR'
            }
        case assetConstants.REQ_RECORDS_REQUEST:
            return {
                ...state,
                status: 'REQ_RECORDS_REQUEST'
            }
        case assetConstants.POST_REQUEST:
            return {
                ...state,
                status: 'POST_REQUEST'
            }
        case assetConstants.POST_SUCCESS : 
            return {
                ...state,   
                status: 'POST_SUCCESS',
            }
        case assetConstants.POST_ERROR:
            return {
                ...state,
                status: 'POST_ERROR'
            }
        case assetConstants.P_ACTION_REQUEST:
            return {
                ...state,
                status: 'P_ACTION_REQUEST'
            }
        case assetConstants.P_ACTION_SUCCESS : 
            return {
                ...state,   
                status: 'P_ACTION_SUCCESS',
            }
        case assetConstants.P_ACTION_ERROR:
            return {
                ...state,
                status: 'P_ACTION_ERROR'
            }

        case assetConstants.C_ACTION_REQUEST:
            return {
                ...state,
                status: 'C_ACTION_REQUEST'
            }
        case assetConstants.C_ACTION_SUCCESS : 
            return {
                ...state,   
                status: 'C_ACTION_SUCCESS',
            }
        case assetConstants.C_ACTION_ERROR:
            return {
                ...state,
                status: 'C_ACTION_ERROR'
            }
        case assetConstants.SOLD_REQUEST:
            return {
                ...state,
                status: 'SOLD_REQUEST'
            }
        case assetConstants.SOLD_SUCCESS : 
            return {
                ...state,   
                status: 'SOLD_SUCCESS',
            }
        case assetConstants.SOLD_ERROR:
            return {
                ...state,
                status: 'SOLD_ERROR'
            }
        
        case assetConstants.CLEAR:
            return {
                ...state,
                status: 'CLEAR_SUCCESS',
                details: [],
                requests: []
            }
        default: return state;
    }
}

export default assets;