import React, { useState } from 'react';
import { ModalDialog, Button } from "../../components";
import { ExpandMore } from '@material-ui/icons';
import useStyles from './style';
import {
    Grid, Box,
    Typography,
    FormControl,
    OutlinedInput,
    Select,
    MenuItem
} from '@material-ui/core';

let payl = {
    "order_id": 0,
    "remarks" : null
};


export default function CancelOrder(props) {
    const classes = useStyles();
    const [payload, setPayload] = useState(payl);
    const [error, setError] = useState(false);
    const {
        title = 'CANCEL',
        status = '',
        details = {},
        showDetails = '',
        setDetails = () => null
    } = props;

    const handleChange = (name, value) => {
        setError(false);
        setPayload({ ...payload, [name]: value });
    }

    const handleSubmit = () => {
        if(!payload.remarks){
            setError(true);
        }else {
            let pay = {       
                ...payload,   
                order_id: details.id,
            };
            props.handleSubmit(pay);
        }
    }

    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        Fill details to {title} Order
                    </Typography>
                </Box>
                <Grid 
                    container 
                    direction='row' 
                    className = {classes.fundDetailsMainGrid}
                >
                    <Grid 
                        item xs={12} xl={6}
                        sm={6} md={6} lg={6} 
                        className = {classes.actionGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Customer Name
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.customer_name || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Requested Amount
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.requested_amount || 'NA'}
                        </Typography>
                    </Grid>
                    <Grid 
                        item xs={12} xl={6}
                        sm={6} md={6} lg={6} 
                        className = {classes.actionGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Customer Mobile Number
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.customer_mobile_number || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Approved Amount
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.approved_amount || 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid 
                    container 
                    direction='row' 
                    className = {classes.fundDetailsMainGrid}
                    style = {{marginTop: '16px'}}
                >
                    <Grid 
                        item 
                        xs={12} 
                        sm={12} 
                        md={12} 
                        lg={12} 
                        xl={12}
                        className = {classes.actionGrid}
                    >
                        <FormControl variant='outlined' className={classes.inputField}>
                            <Typography variant='subtitle2'
                                className={classes.label}
                            >
                                Remarks
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleChange(
                                    'remarks',
                                    e.target.value
                                )}
                                className={classes.inputField}
                                value = {details.pledge_amount}
                                id='remarks'
                                type = {'text'}
                                error = {error}
                                inputProps = {{
                                    required: true,
                                    maxLength : 45,
                                }}
                            />
                            <small className="red">
                                {error && 'Remarks Required'}
                            </small>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid 
                    container 
                    direction='row' 
                    className = {classes.fundDetailsMainGrid}
                >
                    <Grid 
                        item xs={12} xl={12}
                        sm={12} md={12} lg={12} 
                        className = {classes.actionGrid}
                    >
                        <Button
                            text = {`${title} ORDER`}
                            variant = "primary"
                            style = {{
                                padding: '8px 16px',
                                marginTop: '40px', 
                                width: '280px',
                                backgroundColor: '#db252e'
                            }}
                            loading = {status === `${title.toUpperCase()}_REQUEST`}
                            onClick = {() => handleSubmit() }
                        />
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {showDetails}
            onClose = {() => setDetails('', details)}
            dialogTitle = ''
            dialogContent = {dialogContent()}
            maxWidth = {'md'}
            classes = {{ paper: classes.dialogPaper}}
            dialogFooter = {[]}
        />
    )

}