import React, { Component } from 'react';
import { connect } from 'react-redux';
import { assetActions } from '../../actions/assets';
import { branchActions } from '../../actions/branch';
import AssetsRequestAllLayout from './layout';


class AssetsRequestAll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clearInputs: false
        }
    }

    componentDidMount(){
        const { profile, dispatch } = this.props;
        dispatch(branchActions.getAll());
        this.getDetails();
    }

    getDetails = (page = 0, limit = 10, filter = {}) => {
        const { dispatch, profile } = this.props;
        let offset = page * limit; 
        dispatch(assetActions.getAssetReq('ALL', offset, limit, filter));
        dispatch(assetActions.getTotalReqRecords('ALL', filter));
    }

    resetPagination = () => {
        this.setState({
            page: 0,
            limit: 10
        })
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(assetActions.clear());
    }

    render() {
        return (
            <AssetsRequestAllLayout 
                {...this.props}
                page = {this.state.page}
                limit = {this.state.limit}
                getDetails = {this.getDetails}
                status = {this.props.assets.status}
                noOfRecords = {this.props.assets.noOfReq}
                requestDetails = {this.props.assets.requests}
                branchDetails ={this.props.branch.branchDetails}
                setPage = {(val) => this.setState({ page: val})}
                setLimit = {(val) => this.setState({ limit: val})}
                setClearInputs = {(e) => this.setState({ clearInputs: e })}
            />
        );
    }
}

function mapStateToProps(state) {
    const { profile, assets, notifications, branch }  = state;

    return {
        branch,
        assets,
        profile,
        notifications
    };
}

export default connect(mapStateToProps)(AssetsRequestAll);