import {httpClient} from "../utils";

export const settingsService ={
    updatePassword,
    enableSafeMode,
    disableSafeMode
}

function updatePassword(payload){
    return  httpClient.callApi("POST",`/user/changePassword`, payload);
}

function enableSafeMode(){
    return  httpClient.callApi("POST",`/user/enableSafeMode`, {});
}

function disableSafeMode(payload){
    return  httpClient.callApi("POST",`/user/disableSafeMode`, payload);
}