import { authActions } from "../actions/authentication";
import Store from "../reducers";

export const httpError ={
    showError
};

export function showError(error){
  if(error.error){
      if(typeof error.error !=="undefined"){
          switch (error.error){
              case 1:
                 alert("ERROR! "+error.message);
                 break;
              case -1:
                  alert("WARNING!! "+error.message);
                  break;
              default:
                  console.log("httperror",error.message);
                  break;
          }
      }
  }  else if (error.code >501 && error.code <=504) {
    alert("Heavy Traffic. Try after sometime.");
  }else {
    if(error.message.indexOf("JSON Parse error") !== -1 ){
      alert("Server Down. Try after some Time");
    }
  }
}

export function handleErrorResponse(res, src = '') {

  if(res.status === 401 && src !== 'login' ){
    Store.dispatch(authActions.logout(false));
  }
}