import verificationConstants from '../constants/verification';
import { verificationService } from '../services';
import notyf from '../utils/notification';

export const verificationActions = {
    sendOtp
}


function sendOtp(payload){
    return dispatch =>{
        dispatch(request());
        verificationService.sendOtp(payload).then(
            user =>{ dispatch(success(user)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { 
            type: verificationConstants.SENT_REQUEST
        };
    }
    function success(otp){
        notyf.open({
            type: 'success',
            message: otp.message
        });
        return {
            type: verificationConstants.SENT_SUCCESS
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: verificationConstants.SENT_ERROR
        };
    }
}