import React, { useState, useEffect } from 'react';
import { notificationActions } from '../../actions/notification';
import LevelTwoMenu from './level-two-menu';
import { Box } from '@material-ui/core';
import Header from './header';
import SideBar from './sidebar';
import useStyles from './style';
import { 
    getCookie, 
    setCookie 
} from '../../utils';

export default function Navbar(props){
    const classes = useStyles();
    const [mainMenuExpanded, setMainMenuExpand] = useState(false);
    const [secondMenu, setSecondMenu] = useState({show: false, key: {}});
    const [badgeValue, setBadgeValue] = useState({ });

    useEffect(() => {
        let value = getCookie('isSidebarExpanded');
        if(typeof value === 'string'){
            setMainMenuExpand(value === "1");
        }

        props.dispatch(notificationActions.get());
        let timer1 = setInterval(() => {
            props.dispatch(notificationActions.get());
        }, 60000);

        return () => {
            clearTimeout(timer1);
        };
    }, []);

    useEffect(() => {
        const { notifications } = props;
        setBadgeValue(notifications.details);
    }, [props.notifications])

    const handleCollapse = (value) => {
        setMainMenuExpand(value);
        setCookie(
            'isSidebarExpanded', 
            value ? 1 : 0, 
            365
        );
    }

    return (
        <Box className = {classes.container}>
            <Header 
                profileDetails = {props.profile.basic || {}}
                badgeValue = {badgeValue}
                {...props}
            />
            <Box className={classes.withSidebar}>
                <SideBar
                    profileDetails = {props.profile.basic || {}}
                    expand = {mainMenuExpanded}
                    setExpand = {handleCollapse} 
                    secondMenu = {secondMenu}
                    setSecondMenu = {setSecondMenu}
                    {...props}
                />
                <LevelTwoMenu
                    profileDetails = {props.profile.basic || {}}
                    mainMenuExpanded = {mainMenuExpanded}
                    setMainMenuExpand = {setMainMenuExpand}
                    show = {secondMenu.show}
                    menuKey = {secondMenu.key}
                    setShow = {setSecondMenu}
                    {...props}
                />
                <div 
                    className = {'dashboardIframe no-scrollbar'}
                    style={{
                        width: `calc(100vw - ${mainMenuExpanded ? 180 : (secondMenu.show ? 280 : 80)}px)`,
                        marginLeft: mainMenuExpanded ? '180px' : secondMenu.show ? '280px' : '80px'
                    }}
                >
                    {props.children}
                </div>
            </Box>
        </Box>
    )
}