import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    containerBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    mainGrid : {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '80%'
    },
    fundDetailsMainGrid : {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        maxWidth: '80%'
    },
    titleSection: {
        width: '40vw', 
        marginTop: theme.spacer * 3,
        marginBottom: theme.spacer * 3,
        textAlign: 'center'
    },
    indGrid : {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    actionGrid: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    label: {
        // backgroundColor: theme.color.grey10,
        lineHeight: theme.spacer * 0.35,
    },
    labelTitle: {
        color: `${theme.color.black}99`,
        lineHeight: theme.spacer * 0.2,
        marginTop: `${theme.spacer * 1.5}px`
    },
    inputField: {                                                
        margin: `0px 0px ${theme.spacer * 0}px 0px`, 
        width: '280px',
        backgroundColor: theme.color.white,
        border: 'none',
        borderRadius: theme.spacer * 0.3,
        '& .MuiSelect-select:focus' : {
            border: 'none',
            borderWidth : 0,
            backgroundColor: 'transparent'
        },
        '& .MuiOutlinedInput-root' : {
            height: theme.spacing(5)
        },
        '& ..MuiInputBase-input' : {
            height: theme.spacing(5)
        }
    },
    dialogPaper : {
        maxHeight: '80vh',
        width: '100vw',
        // maxWidth: '50vw'
    },
    pendingDialogPaper : {
        width: '45vw !important',
        height: '65vh'
    },
    dialogPaperNewRequest: {
        maxHeight: '80vh',
        width: '100vw',
        maxWidth: '600px'
    },
    mainBox : {
        margin: '5% 5% 2% 5%'
    },
    tableTitleBox : {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    footerButtons : {
        display: 'flex',
        flexDirection: 'row',
    },
    tableBox : {
        margin: '1% 5%',
    },
    actionButtons: {
        display: 'flex',
        flexDirection: 'row',
    },
    pending: {
        color: theme.palette.primary.main
    },
    approved: {
        color: theme.color.green
    },
    confirmed: {
        color: theme.color.green
    },
    need_confirmation: {
        color: theme.color.green
    },
    rejected: {
        color: theme.color.red
    },
    cancelled: {
        color: theme.color.red
    }
}));

export default useStyles;