import verificationConstants from '../constants/verification';

const initialState = {
    status: '',
}

function verification(state = initialState, action) {
    switch(action.type) {
        case verificationConstants.SENT_SUCCESS : 
            return {
                ...state,   
                status: 'SEND_OTP_SUCCESS',
            }
        case verificationConstants.SENT_ERROR:
            return {
                ...state,
                status: 'SEND_OTP_ERROR'
            }
        case verificationConstants.SENT_REQUEST:
            return {
                ...state,
                status: 'SEND_OTP_REQUEST'
            }
        default: return state;
    }
}

export default verification;