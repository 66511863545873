import React, { Component } from 'react';
import { connect } from 'react-redux';
import { notificationActions } from '../../actions/notification';
import { authActions } from '../../actions/authentication';
import { settingsActions } from '../../actions/settings';
import notyf from '../../utils/notification';
import SettingsLayout from './layout';

class Settings extends Component{
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    handleChangePassword = (payload) => {
        const { dispatch } = this.props;
        dispatch(settingsActions.updatePassword(payload));
    }

    handleSafeMode = (value, payl = {}) => {
        const { dispatch } = this.props;
        if(value) {
            dispatch(settingsActions.enableSafeMode());
        }else {
            dispatch(settingsActions.disableSafeMode(payl));
        }

    }


    componentDidUpdate(prevProps){
        const { dispatch, settings } = this.props;
        let isPropsChanged = prevProps.settings.status !== settings.status;

        if(isPropsChanged) {
            if(settings.status === 'UPDATE_PASS_SUCCESS'){
                dispatch(authActions.logout());
                notyf.open ({
                    type: 'success',
                    message: 'please Re-login for security'
                })
            }
            if(
                settings.status === 'ENABLE_SM_SUCCESS' || 
                settings.status === 'DISABLE_SM_SUCCESS'
            ) {
                dispatch(notificationActions.get())
            }
        }
    }

    render() {
        return (
            <SettingsLayout
                {...this.props}
                status = {this.props.settings.status}
                handleSafeMode = {this.handleSafeMode}
                handleChangePassword = {this.handleChangePassword}
            />
        );
    }
}

function mapStateToProps(state) {
    const { profile, settings, notifications } = state;
    
    return {
        profile,
        settings,
        notifications
    }
}

export default connect(mapStateToProps)(Settings);