import React, { Component } from 'react';
import { Navbar } from "../../components";

class DashboardLayout extends Component {
    render() {
        return (
            <Navbar 
                { ...this.props }
            >
                {window.location.pathname}
            </Navbar>
        );
    }
}

export default DashboardLayout;