import leaveConstants from '../constants/leave-request';
import { leaveService } from '../services';
import notyf from '../utils/notification';

export const leaveActions = {
    cancelLeaveAction,
    pendingLeaveAction,
    getTotalRecords,
    getAll,
    raise,
}

function getAll(type, offset = 0, limit = 10, filter = {}){
    return dispatch =>{
        dispatch(request());
        leaveService.getAll(type, offset, limit, filter)
            .then(
                user =>{ dispatch(success(user)) },
                error =>{ dispatch(failure(error)) }
            );
    };
    function request(){
        return { type: leaveConstants.GET_ALL_REQUEST };
    }
    function success(leave){
        return {
            type: leaveConstants.GET_ALL_SUCCESS,
            payload: leave
        };
    }
    function failure(error){
        return {
            type: leaveConstants.GET_ALL_ERROR,
            payload : error
        };
    }
}

function raise(payload){
    return dispatch =>{
        dispatch(request());
        leaveService.raise(payload).then(
            leave =>{ dispatch(success(leave)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: leaveConstants.POST_REQUEST };
    }
    function success(leave){
        notyf.open({
            type: 'success',
            message: leave.message
        });
        return {
            type: leaveConstants.POST_SUCCESS,
            payload: leave
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: leaveConstants.POST_ERROR,
            payload : error
        };
    }
}

function getTotalRecords(type, filter = {}){
    return dispatch =>{
        dispatch(request());
        leaveService.getTotalRecords(type, filter).then(
            value =>{ dispatch(success(value)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: leaveConstants.RECORDS_REQUEST };
    }
    function success(value){
        return {
            type: leaveConstants.RECORDS_SUCCESS,
            payload: value
        };
    }
    function failure(error){
        return {
            type: leaveConstants.RECORDS_ERROR,
            payload : error
        };
    }
}


function pendingLeaveAction(payload) {
    return dispatch =>{
        dispatch(request());
        leaveService.pendingLeaveAction(payload).then(
            leave =>{ dispatch(success(leave)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: leaveConstants.P_ACTION_REQUEST };
    }
    function success(leave){
        notyf.open({
            type: 'success',
            message: leave.message
        });
        return {
            type: leaveConstants.P_ACTION_SUCCESS,
            payload: leave
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: leaveConstants.P_ACTION_ERROR,
            payload : error
        };
    }
}

function cancelLeaveAction(payload) {
    return dispatch =>{
        dispatch(request());
        leaveService.cancelLeaveAction(payload).then(
            leave =>{ dispatch(success(leave)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: leaveConstants.CANCEL_LR_REQUEST };
    }
    function success(leave){
        notyf.open({
            type: 'success',
            message: leave.message
        });
        return {
            type: leaveConstants.CANCEL_LR_SUCCESS,
            payload: leave
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: leaveConstants.CANCEL_LR_ERROR,
            payload : error
        };
    }
}