import React, { useEffect } from 'react';
import { Button, ModalDialog, } from "../../../components";
import { expenseConstants as ec } from '../../../constants';
import useStyles from '../style';
import {
    Grid, Box, FormControl, 
    OutlinedInput, Typography,
} from '@material-ui/core';


export default function UpdateExpense(props) {
    const classes = useStyles();
    const {
        show = false,
        setShow = () => null,
        details = {},
        payload = {},
        status = ''
    } = props;

    useEffect(() => {
        props.handleChange( 'request_id', details.id );
    },[]);

    const handleChange = (name, value) => {
        props.handleChange(name, value);
    }

    const handleSubmit = (status = 'APPROVED') => {
        let payl = { ...payload, status };
        props.handleSubmit(payl);
    }


    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        {ec.updateTitle}
                    </Typography>
                </Box>
                <Grid 
                    container 
                    className = {classes.mainGrid}
                >
                    <Grid 
                        item xs={12} sm={6} 
                        md={6} lg={6} xl={6}
                        className = {classes.indGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Requested from Branch
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.requested_branch_name || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Expense Amount
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.expense_amount || 'NA'}
                        </Typography>
                    </Grid>
                    <Grid 
                        item xs={12} sm={6} 
                        md={6} lg={6} xl={6}
                        className = {classes.indGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Expense type
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.expense_type || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Description
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.expense_description || 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
                <FormControl className={classes.inputField} style={{marginTop: 16}}>
                    <Typography variant='subtitle2' className={classes.label}>
                        comment
                    </Typography>
                    <OutlinedInput
                        onChange={(e) => handleChange(
                            'approval_description', e.target.value 
                        )}
                        value = {payload['approval_description']}
                        className={classes.inputField}
                        id='approval_description'
                        placeholder={'comment'}
                        type = {'text'}
                        inputProps = {{
                            required: true,
                            maxLength : 50,
                            min : 0
                        }}
                    />
                </FormControl>
                <Grid 
                    container 
                    className = {classes.mainGrid}
                >
                    <Grid 
                        item xs={12} sm={6} 
                        md={6} lg={6} xl={6}
                        className = {classes.indGrid}
                    >
                        <Button 
                            onClick={() => handleSubmit('REJECTED')} 
                            loading = {status === 'UPDATE_REQUEST'}
                            text='Reject' 
                            style ={{ 
                                backgroundColor: '#db252e',
                                padding: '8px 32px',
                                margin : '40px 0px',
                                width: '160px'
                            }}
                        />
                    </Grid>
                    <Grid 
                        item xs={12} sm={6} 
                        md={6} lg={6} xl={6}
                        className = {classes.indGrid}
                    >
                        <Button 
                            onClick={() => handleSubmit('APPROVED')} 
                            loading = {status === 'UPDATE_REQUEST'}
                            text='Approve' 
                            style ={{ 
                                padding: '8px 32px',
                                margin : '40px 0px',
                                width: '160px'
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {show}
            onClose = {setShow}
            dialogTitle = ''
            dialogContent = {dialogContent()}
            maxWidth = {'md'}
            classes = {{ paper: classes.dialogPaper}}
            dialogFooter = {[]}
        />
    )

}