import React, { useState } from 'react';
import { ModalDialog, Button } from "../../../components";
import useStyles from '../style';
import {
    Grid, Box,
    Typography, 
    FormControl,
    OutlinedInput
} from '@material-ui/core';


export default function CancelRequest(props) {
    const classes = useStyles();
    const [desc, setDesc] = useState('');
    const [error, setError] = useState(false);
    const {
        status = '',
        details = {},
        showDetails = '',
        setDetails = () => null
    } = props;


    const handleSubmit = () => {
        if(!desc)
            setError(true);
        else {
            let pay = { 
                fund_request_id: details.id,
                description: desc || ""
            };
            props.handleSubmit(pay);
        }
    }

    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        Cancel Raised Fund Request
                    </Typography>
                </Box>
                <Grid 
                    container 
                    direction='row' 
                    className = {classes.fundDetailsMainGrid}
                >
                    <Grid 
                        item xs={12} xl={6}
                        sm={6} md={6} lg={6} 
                        className = {classes.actionGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Requested Amount
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.request_amount || 'NA'}
                        </Typography>
                    </Grid>
                    <Grid 
                        item xs={12} xl={6}
                        sm={6} md={6} lg={6} 
                        className = {classes.actionGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Requested type
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.request_transaction_type || 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
                <FormControl variant='outlined' 
                    className={classes.inputField}
                    style= {{ marginTop: '40px'}}
                >
                    <Typography variant='subtitle2'
                        className={classes.label}
                    >
                        Remarks
                    </Typography>
                    <OutlinedInput
                        onChange={(e) => {
                            setError(false);
                            setDesc(e.target.value);
                        }}
                        className={classes.inputField}
                        placeholder={'Description'}
                        type = {'text'}
                        error = {error}
                        id='description'
                        multiline
                        inputProps = {{
                            required: true,
                            maxLength : 200
                        }}
                    />
                    {
                        error &&
                        <small className="red">
                            Description Required
                        </small>
                    }
                </FormControl>
                <Button
                    text = "Cancel Request"
                    variant = "primary"
                    style = {{
                        padding: '8px 16px',
                        marginTop: '40px', 
                        width: '280px' 
                    }}
                    loading = {status === 'CANCEL_FR_REQUEST'}
                    onClick = {() => handleSubmit() }
                />
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {showDetails}
            onClose = {() => setDetails()}
            dialogTitle = ''
            dialogContent = {dialogContent()}
            maxWidth = {'md'}
            classes = {{ paper: classes.pendingDialogPaper}}
            dialogFooter = {[]}
        />
    )

}