import React, { useEffect, useState } from 'react';
import { Button, ModalDialog, } from "../../../components";
import { ExpandMore } from '@material-ui/icons';
import useStyles from '../style';
import { 
    Grid, Typography, Select,
    FormControl, Box, MenuItem 
} from '@material-ui/core';


let payl = {
    "user_id": -1,
    "role_id": [ ]
}

export default function UpdateRole(props) {
    const classes = useStyles();
    const [payload, setPayload] = useState(payl);
    const [error, setError] = useState(false);
    const {
        show = false,
        setShow = () => null,
        details = {},
        status = '',
        roles = []
    } = props;

    useEffect(() => {
        setPayload({ ...payl, user_id: details.user_id });
    },[]);


    const handleChange = (value) => {
        setError(false);
        setPayload({
            ...payload,
            role_id: value
        });
    }

    const handleSubmit = () => {
        if(payload.role_id.length > 0)
            props.handleSubmit(payload);
        else
            setError(true);
    }


    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography 
                        variant = 'h5' 
                        className={classes.title}
                    >
                        Update Role for an Employee
                    </Typography>
                </Box>
                <Grid 
                    container 
                    className = {classes.mainGrid}
                >
                    <Grid 
                        item xs={12} sm={6} 
                        md={6} lg={6} xl={6}
                        className = {classes.indGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            First Name
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.first_name || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Current Role
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.role_name || 'NA'}
                        </Typography>
                    </Grid>
                    <Grid 
                        item xs={12} sm={6} 
                        md={6} lg={6} xl={6}
                        className = {classes.indGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Last Name
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.last_name || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Current Branch
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.branch_name || 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
                <FormControl 
                    className={classes.inputField} 
                    style={{marginTop: '48px'}}
                >
                    <Typography variant='subtitle2'
                        className={classes.label}
                        style = {{ marginBottom: '8px'}}
                    >
                        Select role to update :
                    </Typography>
                    <Select
                        disableUnderline
                        variant = 'outlined'
                        className={classes.inputField}
                        IconComponent = {ExpandMore}
                        error = { error }
                        onChange={(e) => handleChange([e.target.value])}
                        inputProps={{ name: 'role_id' }}
                    >
                        {
                            roles.length > 0 &&
                            roles.map(
                                e => <MenuItem value={e.id}>
                                        {e.role_name}
                                        </MenuItem>
                            )
                        }
                    </Select>
                    <small className={'red'}>
                        { error &&  'Invalid Role. Please select a valid role' }
                    </small>
                </FormControl>               
            </Box>
        );
    }

    const footer = () => {
        return (
            <>
                <Button 
                    style = {{ padding: '8px 32px' }}
                    variant={"secondary"}
                    onClick={setShow} 
                    text={'Cancel'}
                />
                <Button 
                    loading = {status === 'U_ROLE_EMP_REQUEST'}
                    style = {{ padding : '8px 32px' }}
                    onClick={handleSubmit}
                    text = {'Submit'}

                />
            </>
        );
    }

    return (
        <ModalDialog
            open = {show}
            dialogTitle = ''
            maxWidth = {'md'}
            onClose = {setShow}
            dialogFooter = {footer()}
            dialogContent = {dialogContent()}
            classes = {{ paper: classes.dialogPaper}}
        />
    )

}