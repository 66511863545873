//Redux

import { applyMiddleware, combineReducers, createStore } from "redux";
import ReduxThunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistReducer }  from "redux-persist";

/********   Reducer  ********/

import sms from './sms';
import role from './role';
import user from "./user";
import orders from "./orders";
import branch from './branch';
import assets from './assets';
import reports from './report';
import invoice from './invoice';
import profile from "./profile";
import expense from './expense';
import settings from "./settings";
import material from './material';
import funds from './fund-request';
import leave from "./leave-request";
import auth from "./authentication";
import attendance from './attendance';
import fileupload from "./fileupload";
import verification from './verification';
import notifications from './notification';

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ['auth']
};

  const middleware = () => {
    return applyMiddleware(ReduxThunk);
   
  };

  const pReducer =persistReducer(
      persistConfig,
      combineReducers({
        sms,
        auth,
        user,
        role,
        funds,
        leave,
        assets,
        branch,
        orders,
        reports,
        invoice,
        profile,
        expense,
        settings,
        material,
        attendance,
        fileupload,
        verification,
        notifications
      })
  );

  export default createStore(pReducer, middleware());