import React, { useState } from 'react';
import { attendanceConstants as ac } from '../../constants';
import { Button, ModalDialog, } from "../../components";
import { ExpandMore } from '@material-ui/icons';
import { utils } from '../../utils';
import useStyles from './style';
import {
    Grid, Box,
    FormControl,
    OutlinedInput,
    Typography,
    Select,
    MenuItem
} from '@material-ui/core';


export default function ApplyFilter(props) {
    const classes = useStyles();
    const [payload, setPayload] = useState({ ...props.filterQuery });
    const [error, setError] = useState(false);
    const {
        status = '',
        show = false,
        branches = [],
        defaultQuery = {},
        setShow = () => null,
        resetFilter = () => null,
        handleSubmit = () => null,
    } = props;

    const handleChange = (key, value) => {
        let payl = { ...payload, [key]: value };
        setError(false);
        setPayload(payl);
    }

    const submitPayload = () => {
        if(
            (utils.isValidDate(payload['from_date']) &&
            !utils.isValidDate(payload['to_date'])) ||
            (utils.isValidDate(payload['to_date']) &&
            !utils.isValidDate(payload['from_date']))
        ){
            setError(true);
        } else {
            setShow(false);
            handleSubmit(payload);
        }
    }

    const reset = () => {
        resetFilter();
        setShow(false);
        setPayload(defaultQuery);
    }

    const footer = () => {
        return (
            <>
                <Button
                    text = {'Cancel'}
                    variant = { "Secondary" }
                    style = {{ padding: '8px 16px' }}
                    onClick = {() => setShow(false)}
                />
                <Button
                    text = {'Clear Filter'}
                    variant = { "Secondary" }
                    style = {{ padding: '8px 16px' }}
                    onClick = {reset}
                />
                <Button
                    text = {'Apply'}
                    variant = "Primary"
                    onClick={submitPayload} 
                    style = {{ padding: '8px 32px' }}
                    loading = {status === 'GETALL_REQUEST'}
                />
            </>
        )
    }

    const dialogTitle = () => {
        return (
            <Box className={classes.titleSection} >
                <Typography variant = 'h5' >
                    Apply Filter
                </Typography>
            </Box>
        )
    }

    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                <Grid 
                    container 
                    direction='row' 
                    className = {classes.mainGrid}
                >
                    <Grid 
                        item xs={12} sm={12} 
                        md={12} lg={12} xl={12}
                        className = {classes.indGrid}
                    >
                        {utils.getRole()?.includes('ADMIN') &&
                        <FormControl variant='outlined' 
                            className={classes.inputField}
                        >
                            <Typography variant='subtitle2'
                                className={classes.label}
                            >
                                Branch
                            </Typography>
                            <Select
                                className={classes.inputField}
                                IconComponent = {ExpandMore}
                                value = {payload['branch_id']}
                                onChange={(e) => handleChange(
                                    'branch_id', e.target.value
                                )}
                                inputProps={{ name: 'branch_id' }}
                            >
                                <MenuItem value={0}>Select branch</MenuItem>
                                {
                                    branches.map(
                                        f => { return (
                                            <MenuItem 
                                                value={f.id}
                                            >
                                                {f.branch_name}
                                            </MenuItem>
                                        )}
                                    ) 
                                }                          
                            </Select>
                        </FormControl>
                        }
                        <FormControl className={classes.inputField}>
                            <Typography variant='subtitle2' 
                                className={classes.label}>
                                From Date
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleChange(
                                    'from_date', e.target.value
                                )}
                                className={classes.inputField}
                                value = {payload['from_date']}
                                placeholder={'From Date'}
                                type = {'date'}
                                id='from_date'
                                inputProps = {{
                                    required: true,
                                    maxLength : 45,
                                    min : 0
                                }}
                            />
                        </FormControl>
                        <small className="red" style={{marginTop: 4}}>
                            {
                                error && 
                                !payload['from_date'] &&
                                payload['to_date'] &&
                                ac.invalidDate
                            }
                        </small>
                        <FormControl className={classes.inputField}>
                            <Typography variant='subtitle2' 
                                className={classes.label}>
                                To Date
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleChange(
                                    'to_date', e.target.value
                                )}
                                className={classes.inputField}
                                value = {payload['to_date']}
                                placeholder={'To Date'}
                                type = {'date'}
                                id='to_date'
                                inputProps = {{
                                    required: true,
                                    maxLength : 45,
                                    min : 0
                                }}
                            />
                        </FormControl>
                        <small className="red" style={{marginTop: 4}}>
                            {
                                error && 
                                !payload['to_date'] &&
                                payload['from_date'] &&
                                ac.invalidDate
                            }
                        </small>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {show}
            maxWidth = {'md'}
            dialogFooter = {footer()}
            dialogTitle = {dialogTitle()}
            dialogContent = {dialogContent()}
            onClose = {() => setShow(false)}
            classes = {{ paper: classes.dialogPaperNewRequest}}
        />
    )

}