import React, { Component } from 'react';
import { branchActions } from '../../actions/branch';
import { connect } from 'react-redux';
import ReportsLayout from './layout';

class Reports extends Component {

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(branchActions.getAll());
    }

    handleSubmit = (payl = {}) => {
        const { history } = this.props;
        let q = `from_date=${payl.from_date}&to_date=${payl.to_date}`;
        if(payl.branch_id === -1) {
            history.push(`/reports/admin?${q}`);
        }else {
            q = `branch_id=${payl.branch_id}` + `&${q}`;
            history.push(`/reports/branch?${q}`);
        }
    }

    render() {
        return (
            <ReportsLayout 
                {...this.props}
                handleSubmit = {this.handleSubmit}
                branchDetails = {this.props.branch.branchDetails}
            />
        );
    }
}

function mapStateToProps(state) {
    const { profile, branch, notifications }  = state;

    return {
        branch,
        profile,
        notifications
    };
}

export default connect(mapStateToProps)(Reports);