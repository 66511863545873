import React, { useEffect, useState } from 'react';
import { reportConstants as rc } from '../../constants';
import { Button, Navbar } from "../../components";
import { ExpandMore } from '@material-ui/icons';
import { utils } from '../../utils';
import useStyles from './style';
import { 
    Box, Typography, FormControl, 
    Select, MenuItem, OutlinedInput 
} from '@material-ui/core';


export default function ReportsLayout(props){

    let defaultPayload = {
        branch_id: 0,
        from_date : "",
        to_date : ""
    }

    const classes = useStyles();
    const [error, setError] = useState(false);
    const [branches, setBranches] = useState([]);
    const [payload, setPayload] = useState({ ...defaultPayload });
    const {
        branchDetails = [],
        profile = {}
    } = props;

    let role = utils.getRole();

    useEffect(() => {
        if(branchDetails.length){
            let allBr = {label: 'All Branches', value: -1};
            let brs= branchDetails.map(e => {
                return {label: e.branch_name, value: e.id}
            });
            setBranches([allBr, ...brs]);
        }else {
            setBranches([]);
        }
    }, [branchDetails]);

    useEffect(() => {
        if(profile?.basic?.branch_id){
            setPayload({
                ...payload,
                branch_id: profile?.basic?.branch_id
            })
        }
    }, [profile?.basic]);

    const getNextDayDate = () => {
        let nextDay = new Date();
        nextDay.setDate(nextDay.getDate() + 1);
        return nextDay.toJSON().slice(0,10);
    }

    const handleChange = (value, name) => {
        setError(false);
        setPayload((prev) => ({ ...prev, [name] : value }));
    }

    const handleSubmit = () => {
        if( 
            !payload.branch_id ||
            !utils.isValidDate(payload.from_date) ||
            !utils.isValidDate(payload.to_date)
        )
            setError(true);
        else if(payload.from_date > payload.to_date)
            alert(rc.invalidDateLimit);
        else
            props.handleSubmit(payload);
    }

    return (
        <Navbar 
            { ...props }
        >
            <Box>
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        {rc.title}
                    </Typography>
                    <Typography variant = 'p' className={classes.subTitle}>
                        {rc.subTitle}
                    </Typography>
                </Box>
                <Box className={classes.mainBox}>
                    <Box>
                    {
                        role.includes('ADMIN') &&
                        <FormControl className={classes.formControlInput}>
                            <Typography variant='subtitle2'
                                className={classes.label}
                            >
                                Branch
                            </Typography>
                            <Select
                                disableUnderline
                                variant = 'outlined'
                                value = {payload.branch_id}
                                IconComponent = {ExpandMore}
                                className={classes.inputField}
                                inputProps={{ name: 'branch_id' }}
                                error = { error && !parseInt(payload.branch_id) }
                                onChange={(e) => handleChange(e.target.value, 'branch_id')}
                            >
                                {
                                    branches.length > 0 &&
                                    branches.map(
                                        e => <MenuItem value={e.value}> 
                                                {e.label}
                                            </MenuItem>
                                    )
                                }
                            </Select>
                            <small className={'red'}>
                                {
                                    error && 
                                    !parseInt(payload.branch_id) && 
                                    rc.invalidBranch
                                }
                            </small>
                        </FormControl>
                    }
                    </Box>
                    <Box className={classes.dateInputBox}>
                        <FormControl className={`${classes.formControlInput} padded-control`}>
                            <Typography variant='subtitle2'
                                className={classes.label}
                            >
                                From Date
                            </Typography>
                            <OutlinedInput
                                type = 'date'
                                disableUnderline
                                variant = 'outlined'
                                inputProps={{ 
                                    name: 'from_date',
                                    min: '2021-01-01', 
                                    max: getNextDayDate() 
                                }}
                                value = {payload.from_date}
                                className={classes.inputField}
                                error = { error && !utils.isValidDate(payload.from_date) }
                                onChange={(e) => handleChange(e.target.value, 'from_date')}
                            />
                            <small className={'red'}>
                                {
                                    error && 
                                    !utils.isValidDate(payload.from_date) && 
                                    rc.invaildDate
                                }
                            </small>
                        </FormControl>
                        <FormControl className={`${classes.formControlInput} padded-control`}>
                            <Typography variant='subtitle2' className={classes.label}>
                                To Date
                            </Typography>
                            <OutlinedInput
                                type = 'date'
                                disableUnderline
                                variant = 'outlined'
                                inputProps={{ 
                                    name: 'to_date', 
                                    min: '2021-01-01', 
                                    max: getNextDayDate() 
                                }}
                                value = {payload.to_date}
                                className={classes.inputField}
                                error = { error && !utils.isValidDate(payload.to_date) }
                                onChange={(e) => handleChange(e.target.value, 'to_date')}
                            />
                            <small className={'red'}>
                                {
                                    error && 
                                    !utils.isValidDate(payload.to_date) && 
                                    rc.invaildDate
                                }
                            </small>
                        </FormControl>
                    </Box>
                    <Button
                        onClick = {handleSubmit}
                        text = {'Submit'}
                        style = {{
                            width: '280px',
                            margin: '32px 0px',
                            padding: '8px 32px'
                        }}
                    />
                </Box>
            </Box>
        </Navbar>
    );
}