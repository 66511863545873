import React from "react";
import { Box, Typography, Grid, useMediaQuery } from '@material-ui/core';
import { forbiddenConstants as fbc } from "../../constants";
import errorNotFoundImage from '../../assets/404-4.png';
import { useHistory } from "react-router-dom";
import { Button } from '../../components';
import useStyles from './style';


export default function BrokenPage (props) {
    const isMobile = useMediaQuery('(max-width:525px)');
    const classes = useStyles();
    let history = useHistory();

    const canPush = () => {
        return history?.length === 2;
    }

    return(
        <Box className={classes.box}>
            <img
                src={errorNotFoundImage}
                height={isMobile ? 320 : 500}
                width={isMobile ? 320 : 500}
                alt="404"
            />
            <Grid xs = {9} md = {9} className={classes.gridBox}>
                <Typography 
                        variant='h6' 
                        className={classes.gridText}
                >
                    {fbc.brokenPageTitle}
                </Typography>
                <Button 
                    onClick={() => {
                        if(!canPush()) history.goBack();
                        else history.push('/search');
                    }} 
                    text = {fbc.brokenPageBtnText} 
                    style ={{ 
                        margin : '20px 0px',
                        width: '300px'
                    }}
                />
            </Grid>
        </Box>
    );
}