import {httpClient} from "../utils";

export const expenseService ={
    add,
    get,
    update,
    cancel,
    getTotalRecords
}


function getFilter(filter = {}){
    return `expense_branch_id=${filter.requested_branch_name || 0}` +
            `&approved_branch_id=${filter.approved_branch_name || 0}`+
            `&expense_req_placed_on=${filter.expense_req_placed_on || ''}` +
            `&status=${filter.status || '' }`
}


function add(payload){
    return httpClient.callApi("POST",`/expense/raiseExpenseRequest`, payload);
}

function get(offset, limit, filter = {}){
    return httpClient.callApi(
        "GET",
        `/expense/getExpenseRequest?`+
        getFilter(filter) +
        `&offset=${offset}` +
        `&limit=${limit}`
    );
}

function update(payload){
    return httpClient.callApi("POST",`/expense/approveExpenseRequest`, payload);
}

function cancel(payload){
    return httpClient.callApi("POST",`/expense/cancelExpenseRequest`, payload);
}

function getTotalRecords(filter = {}){
    return httpClient.callApi("GET",`/expense/getTotalRecord?` + getFilter(filter));
}