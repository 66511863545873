import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    outerBox: {
       padding: '0.1% 0% 6% 0%',
       backgroundColor: 'white'
    },
    label: {
        // backgroundColor: theme.color.grey10,
        lineHeight: theme.spacer * 0.35,
    },
    labelTitle: {
        color: `${theme.color.black}99`,
        lineHeight: theme.spacer * 0.2,
        marginTop: `${theme.spacer * 1.5}px`
    },
    inputField: {                                                
        margin: `0px 0px ${theme.spacer * 0}px 0px`, 
        width: '280px',
        backgroundColor: theme.color.white,
        border: 'none',
        borderRadius: theme.spacer * 0.3,
        '& .MuiSelect-select:focus' : {
            border: 'none',
            borderWidth : 0,
            backgroundColor: 'transparent'
        },
        '& .MuiOutlinedInput-root' : {
            height: theme.spacing(5)
        },
        '& ..MuiInputBase-input' : {
            height: theme.spacing(5)
        }
    },
    dialogPaper : {
        maxHeight: '80vh',
        width: '100vw',
        // maxWidth: '50vw'
    },
    dialogPaperNewRequest: {
        maxHeight: '80vh',
        width: '100vw',
        maxWidth: '600px'
    },
    mainBox : {
        margin: '3% 0% 1% 0%',
        textAlign: 'right'
    },
    mainBoxLeft: {
        margin: '3% 0% 1% 0%',
    },
    tableTitleBox : {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '3% 5% 2% 5%'
    },
    FooterBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '5% 5% 2% 5%'
    },
    tableBox : {
        display: 'flex',
        flexDirection: 'row',
        margin: '1% 5%',
        boxShadow: 'none',
        backgroundColor: 'transparent',
    },
    splitTable: {
        width: '100%',
        marginRight: '5%'
    },
    orderTable: {
        width: '100%',
    },
    actionButtons: {
        display: 'flex',
        flexDirection: 'row',
    },
    logoStyle: {

    }
}));

export default useStyles;