import { 
    makeStyles 
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    mostOuterBox: {
        width: '85vw',
        display: 'block',
        marginRight: 'auto',
        marginLeft: 'auto',
        borderBottom: `1px solid ${theme.color.grey40}`
    },
    mostOuterFlexBox: {
        width: '85vw',
        display: 'flex',
        flexDirection: 'column',
        marginRight: 'auto',
        marginLeft: 'auto',
        borderBottom: `1px solid ${theme.color.grey40}`
    },
    outerBox: {
        display: 'flex',
        width: '85vw',
        flexDirection: 'row',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: `${theme.spacer * 4}px 0px`,
    },
    inputField: {                                               
        height: `${theme.spacing(5)}px !important`,
        margin: `0px 0px ${theme.spacer * 0}px 0px`, 
        width: '300px',
        backgroundColor: `${theme.color.white} !important`,
        border: 'none',
        borderRadius: theme.spacer * 0.3,
    },
    mainBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: `${theme.spacer * 4}px 0px`,
    },
    mainGrid: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: `${theme.spacer * 4}px`
    },
    mainTitleGrid: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: `${theme.spacer * 4}px ${theme.spacer * 0}px 0px ${theme.spacer * 10}px`,
    },
    formControlInput:{
        marginBottom: theme.spacer,
    },
    blockTitle: {
        fontSize: '16px'
    },
    actionButtons: {
        display: 'flex', 
        flexDirection: 'row', 
        marginBottom: `${theme.spacer * 4}px`,
    },
    label: {
        marginBottom: `${theme.spacer * 0.5}px`
    },
    avatarProfile: {
        width: `${theme.spacer * 30}px`,
        height: `${theme.spacer * 30}px`
    },
    details: {
        margin: `${theme.spacer * 1}px 0px`,
        textAlign: 'left'
    },
    titleLabel: {
        color: `${theme.color.black}99`,
        lineHeight: theme.spacer * 0.2,
        marginTop: `${theme.spacer * 1.5}px`
    },
    subTitleLabel: {
        fontSize: 18
    },
    safeModeGrid : {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: `${theme.spacer * 4}px`
    },

    containerBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    inputField: {                                                
        margin: `0px 0px ${theme.spacer * 0}px 0px`, 
        width: '280px',
        backgroundColor: theme.color.white,
        border: 'none',
        borderRadius: theme.spacer * 0.3,
        height: '36px',
        '& .MuiSelect-select:focus' : {
            border: 'none',
            borderWidth : 0,
            backgroundColor: 'transparent'
        },
        '& .MuiOutlinedInput-root' : {
            height: theme.spacing(5)
        },
        '& .MuiInputBase-input' : {
            height: theme.spacing(5)
        }
    },
    actionGrid: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },

}));

export default useStyles;