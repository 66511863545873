const authConstants = {
    LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    LOGIN_ERROR: 'USER_LOGIN_ERROR',
    LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',

    VERIFICATION_REQUEST : 'USER_VERIFICATION_REQUEST',
    VERIFICATION_SUCCESS : 'USER_VERIFICATION_SUCCESS',
    VERIFICATION_ERROR : 'USER_VERIFICATION_ERROR',

    VALIDATE_REQUEST : 'USER_VALIDATE_REQUEST',
    VALIDATE_SUCCESS : 'USER_VALIDATE_SUCCESS',
    VALIDATE_ERROR : 'USER_VALIDATE_ERROR',

    LOGOUT:  'USER_LOGOUT_SUCCESS'
}

export default authConstants;