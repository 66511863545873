import React from 'react';
import { utils } from '../../utils';
import useStyles from './style';
import { 
    Select, MenuItem,
    Typography, Grid,
    FormControl, Box,
    OutlinedInput,
    InputAdornment,
    CircularProgress,
    Avatar
} from '@material-ui/core';
import { 
    Cake,
    ExpandMore,
    WcOutlined,
    MailOutlined,
    PersonOutlined,
    AttachFileOutlined,
    ErrorOutlineRounded,
    CheckCircleOutlineRounded,
} from '@material-ui/icons';


export default function UserForm(props){

    const classes = useStyles();
    const {
        payload = {},
        fileuploads = {},
        handleChange = () => null,
        error = { show: false, keys: {} }
    } = props;

    return(
        <Box className={classes.userGridBox}>
            <Grid 
                item xs={12} sm={12} 
                md={6} lg={6} xl={6}
                className = {classes.userGrid}
            >
                <FormControl className={classes.userInputField}>
                    <Typography variant='subtitle2' className={classes.label}>
                        First Name *
                    </Typography>
                    <OutlinedInput
                        onChange={(e) => handleChange('first_name', e.target.value )}
                        error= {error.show && error.keys.first_name}
                        startAdornment = {
                            <InputAdornment position='start'>
                                <PersonOutlined edge='start' color='primary'/>
                            </InputAdornment>
                        }
                        className={classes.userInputField}
                        value = {payload['first_name']}
                        placeholder={'First Name'}
                        type = {'text'}
                        id='first_name'
                        inputProps = {{
                            required: true,
                            maxLength : 45
                        }}
                    />
                </FormControl>
                <small className="red" style={{margin: `16px 0px`}}>
                        {
                            error.show &&
                            error.keys.first_name && 
                            'Invalid Value'
                        }
                </small>
                <FormControl className={classes.userInputField}>
                    <Typography variant='subtitle2' className={classes.label}>
                        Last Name
                    </Typography>
                    <OutlinedInput
                        onChange={(e) => handleChange('last_name', e.target.value )}
                        startAdornment = {
                            <InputAdornment position='start'>
                                <PersonOutlined edge='start' color='primary'/>
                            </InputAdornment>
                        }
                        error= {error.show && error.keys.last_name}
                        className={classes.userInputField}
                        value = {payload['last_name']}
                        placeholder={'Last Name'}
                        type = {'text'}
                        id={'last_name'}
                        inputProps = {{
                            required: true,
                            maxLength : 45
                        }}
                    />
                </FormControl>
                <small className="red" style={{margin: `16px 0px`}}>
                        {
                            error.show &&
                            error.keys.last_name && 
                            'Invalid Value'
                        }
                </small>
                <FormControl className={classes.userInputField}>
                    <Typography variant='subtitle2'
                        className={classes.label}
                    >
                        Upload Image
                    </Typography>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Avatar 
                        variant = 'square'
                        src = {utils.getImageUrl(payload?.file_name || "")}
                        style = {{marginRight: '8px'}}
                    />
                    <OutlinedInput
                        onChange={(e) => handleChange('user_image', e.target.files, 'file')}
                        error = { error.show && error.keys.file_name  }
                        className={classes.inputField}
                        id= { 'file_name_0' }
                        placeholder = {''}
                        type = {'file'}
                        startAdornment = {
                            <InputAdornment position='start'>
                                {
                                    fileuploads?.user_image?.["0"]?.status === 'R' &&
                                    <CircularProgress size={16} color="primary"/> 
                                }
                                {
                                    fileuploads?.user_image?.["0"]?.status === 'S' &&
                                    <CheckCircleOutlineRounded size = {24} style={{color: 'green'}}/>
                                }
                                {
                                    fileuploads?.user_image?.["0"]?.status === 'E' &&
                                    <ErrorOutlineRounded size={24} color='error' />
                                }
                                {
                                    !fileuploads?.user_image?.["0"]?.status &&
                                    <AttachFileOutlined edge='start' color='primary'/>
                                }
                            </InputAdornment>
                        }
                        inputProps = {{
                            accept: "image/*",
                            required: true,
                            maxLength : 45
                        }}
                    />
                    </div>
                    <small className={'red'} style={{margin: `16px 0px`}}>
                        {
                            error.show && 
                            error.keys.file_name &&
                            'Invalid File'
                        }
                    </small>
                </FormControl>
            </Grid>
            <Grid 
                item xs={12} sm={12} 
                md={6} lg={6} xl={6}
                className = {classes.userGrid}
            >
                <FormControl className={classes.userInputField}>
                    <Typography variant='subtitle2' className={classes.label}>
                        Email
                    </Typography>
                    <OutlinedInput
                        onChange={(e) => handleChange('email', e.target.value )}
                        error= {error.show && error.keys.email}
                        startAdornment = {
                            <InputAdornment position='start'>
                                <MailOutlined edge='start' color='primary'/>
                            </InputAdornment>
                        }
                        className={classes.userInputField}
                        value = {payload['email']}
                        placeholder={'Email'}
                        type = {'text'}
                        id='email'
                        inputProps = {{
                            required: true,
                            maxLength : 45
                        }}
                    />
                </FormControl>
                <small className="red" style={{margin: `16px 0px`}}>
                        {
                            error.show &&
                            error.keys.email && 
                            'Invalid Value'
                        }
                </small>
                <FormControl className={classes.userInputField}>
                    <Typography variant='subtitle2' className={classes.label}>
                        Gender *
                    </Typography>
                    <Select
                        disableUnderline
                        variant = 'outlined'
                        placeholder = {'Gender'}
                        value = {payload['gender']}
                        IconComponent = {ExpandMore}
                        inputProps={{ name: 'gender' }}
                        className={classes.userInputField}
                        error = { error.show && error.keys.gender }
                        onChange={(e) => handleChange('gender', e.target.value)}
                        startAdornment = {
                            <InputAdornment position='start'>
                                <WcOutlined edge='start' color='primary'/>
                            </InputAdornment>
                        }
                    >
                        <MenuItem value={'MALE'}>Male</MenuItem>
                        <MenuItem value={'FEMALE'}>Female</MenuItem>
                        <MenuItem value={'OTHERS'}>Others</MenuItem>
                    </Select>
                </FormControl>
                <small className={'red'} style={{margin: `16px 0px`}}>
                        {
                            error.show && 
                            error.keys.gender &&
                            'Invalid Selection'
                        }
                </small>
                <FormControl className={classes.userInputField}>
                    <Typography variant='subtitle2'
                        className={classes.label}
                    >
                        Date of Birth *
                    </Typography>
                    <OutlinedInput
                        onChange={(e) => handleChange('dob', e.target.value)}
                        startAdornment = {
                            <InputAdornment position='start'>
                                <Cake edge='start' color='primary'/>
                            </InputAdornment>
                        }
                        error = { error.show && error.keys.dob }
                        className={classes.userInputField}
                        placeholder={'Date of Birth'}
                        value = {payload['dob']}
                        color = {"primary"}
                        type = {'date'}
                        id='dob'
                        inputProps = {{
                            required: true,
                            maxLength : 45
                        }}
                    />
                </FormControl>
                <small className={'red'} style={{margin: `16px 0px`}}>
                        {
                            error.show && 
                            error.keys.dob &&
                            'Invalid Value/Format. Type as YYYY-MM-DD'
                        }
                </small>
            </Grid>
        </Box>
    );
}