import React, { useEffect, useState } from 'react';
import {
    Box, Avatar,
    OutlinedInput,
    FormControl,
    Paper,
    InputAdornment,
    IconButton,
    Typography,
    // Button
} from '@material-ui/core';
import { Button } from '../../../components'; 
import Logo from '../../../assets/agc.png';
import { 
    loginConstants as lc,
    globalRegexp as gr 
} from '../../../constants';
import { 
    Brightness2,
    Lock, Mail, 
    VisibilityOutlined, 
    VisibilityOffOutlined
} from '@material-ui/icons';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import useStyles from '../style';


export default function LoginCredentials(props) {
    let defaultValue = {
        email : '',
        password: '',
        phone: '+91'
    }
    const classes = useStyles();
    const {
        setPage = () => null,
        status = '',
        errorMessage = '',
        history
    } = props;
    const [showPassword, setShowPassword] = useState(false);
    const [values, setValues] = useState(defaultValue);
    const [error, setError] = useState({show: false, keys: []});
    const [loading, setLoading]  = useState(false);

    useEffect(() => {
        if(status === 'LOGIN_SUCCESS') {
            setLoading(false);
            setPage(2);
        }
        else if(status === 'LOGIN_ERROR'){
            setLoading(false);
        }
    }, [status]);

    const errorStyle = (key = '') => {
        return {
            margin: `2px 0px 08px 0px`,
            color: 'red'
        }
    }

    const handleChange = (val, key = '') => {
        setValues(
            (prev) => ({
                ...prev,
                [key] : key === 'phone' ? 
                        '+' + val : val
            })
        )
        let errKey  =   error.keys && 
                        error.keys.filter(e => e !== key);

        setError({
            show:   !(errKey.length === 0),
            keys:   [ ...errKey ]
        })
    }

    const isvalid = (value, key) => {
        if(key === 'email'){
            if(value.match(gr.email))   return true;
            else    return false;
        }

        return true;
    }

    const validateForm = () => {
        let err = [];
        
        // if(
        //     values.email === '' || 
        //     !isvalid(values.email, 'email')
        // )
        //     err.push('email')

        if(
            values.password === '' ||
            values.password.length < 4 
        )
            err.push('password')

        if(
            values.phone === '' ||
            values.phone.length < 4
        )
            err.push('phone')


        if(err.length > 0){
            setError({
                show: true, 
                keys: [ ...err ]
            })
        }else {
            let payload = {
                username : values.phone,
                password : values.password
            } 
            setLoading(!loading);
            props.handleLogin(payload);
        }
    }

    return (
                <Paper 
                    elevation = {1} 
                    component={'div'} 
                    className={classes.paper}
                >
                    <Box className={classes.company}>
                        <Avatar src={Logo}/>
                        <Typography className={classes.companyName}>
                            Abaya Gold Buyers
                        </Typography>
                    </Box>
                    <FormControl 
                        className={classes.phoneInputField}
                    >
                        <PhoneInput
                            country={'in'}
                            placeholder={'Phone Number'}
                            inputStyle = {{
                                height: '40px', 
                                borderColor: error.show && 
                                error.keys.includes('phone') ? 'red' : ''}}
                            countryCodeEditable={false}
                            onChange={ 
                                phone => {
                                    handleChange(phone, 'phone');
                                }
                            }
                            inputProps = {{
                                required: true,
                                autoFocus: true
                            }}
                        />
                        {   
                            error.show && 
                            error.keys.includes('phone') &&
                            <small style = {errorStyle('phone')}>
                                {lc.invalidPhone}
                            </small>
                        }
                    </FormControl>
                    {/* <FormControl>
                        <OutlinedInput 
                            className={classes.inputField}
                            onChange={(e) => 
                                handleChange(e.target.value, 'email')
                            }
                            placeholder={'Email'}
                            type = {'email'}
                            id='email'
                            startAdornment = {
                                <InputAdornment position='start'>
                                    <Mail edge='start' color='primary'/>
                                </InputAdornment>
                            }
                            error = {
                                error.show && 
                                error.keys.includes('email')
                            }
                            inputProps = {{
                                required: true,
                                maxLength : 45
                            }}
                        />
                        {   
                            error.show && 
                            error.keys.includes('email') &&
                            <small style = {errorStyle()}>
                                {lc.invalidEmail}
                            </small>
                        }
                    </FormControl> */}
                    <FormControl>
                        <OutlinedInput 
                            className={classes.inputField}
                            onChange={(e) => 
                                handleChange(e.target.value, 'password')
                            }
                            placeholder={'Password'}
                            type = {
                                showPassword ? 
                                'text' : 
                                'password'
                            }
                            id='password'
                            error = {
                                error.show && 
                                error.keys.includes('password')
                            }
                            inputProps = {{
                                required: true,
                                maxLength : 45
                            }}
                            startAdornment = {
                                <InputAdornment position='start'>
                                    <Lock edge='start' color='primary'/>
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                  >
                                    {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                  </IconButton>
                                </InputAdornment>
                            }
                        />
                        {   
                            error.show &&
                            error.keys.includes('password') &&
                            <small style = {errorStyle()}>
                                {lc.invalidPassword}
                            </small>
                        }
                    </FormControl>
                    <Button 
                        onClick={validateForm} 
                        loading = {loading}
                        text='Login' 
                        style ={{ 
                            margin : '20px 0px',
                            width: '300px'
                        }}
                    />
                    {/* <Typography 
                        className={classes.forgot}
                        variant = 'p'
                        onClick = {() => setPage(3)}
                    >
                        Forgot Password ?
                    </Typography> */}
                    { status === 'LOGIN_ERROR' &&
                        <small style={{
                            color: 'red', 
                            margin: '8px 54px',
                            fontSize: '12px'
                        }}>
                            {  errorMessage }
                        </small>
                    }
                </Paper>

    );
}