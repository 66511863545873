const attendanceConstants = {
    POST_REQUEST: 'POST_LOG_ATTENDANCE_REQUEST',
    POST_ERROR: 'POST_LOG_ATTENDANCE_ERROR',
    POST_SUCCESS: 'POST_LOG_ATTENDANCE_SUCCESS',

    GET_REQUEST : 'ATTENDANCE_GET_USER_DAY_REQUEST',
    GET_SUCCESS : 'ATTENDANCE_GET_USER_DAY_SUCCESS',
    GET_ERROR : 'ATTENDANCE_GET_USER_DAY_ERROR',

    GET_ALL_REQUEST : 'ATTENDANCE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS : 'ATTENDANCE_GET_ALL_SUCCESS',
    GET_ALL_ERROR : 'ATTENDANCE_GET_ALL_ERROR',

    RECORDS_REQUEST : 'ATTENDANCE_TOTAL_RECORDS_REQUEST',
    RECORDS_SUCCESS : 'ATTENDANCE_TOTAL_RECORDS_SUCCESS',
    RECORDS_ERROR : 'ATTENDANCE_TOTAL_RECORDS_ERROR',
}

export default attendanceConstants;