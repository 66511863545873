import React from 'react';
import { 
    Typography, Grid, 
    FormControl, OutlinedInput,
} from '@material-ui/core';
import { utils } from '../../../utils';
import useStyles from '../style';


export default function OrderTotalCalc(props) {
    const classes = useStyles();
    const { 
        details = {} ,
        handleTotalChange = () => null
    } = props;
    return (
        <Grid xs={12} sm={4} md={4}
            className={classes.extraFieldsGrid}
        >
            {/* <FormControl className={classes.formControlText}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Service Charge Type
                </Typography>
                <Typography
                    variant = "p"
                    className = {classes.textField}
                >
                    {details.service_charge_type}
                </Typography>
            </FormControl>
            <FormControl className={classes.formControlText}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Service Charge Percent (%)
                </Typography>
                <Typography
                    variant = "p"
                    className = {classes.textField}
                >
                    {details.service_charge_percent}
                </Typography>
            </FormControl>
            <FormControl className={classes.formControlText}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Service Charge Amount (INR)
                </Typography>
                <Typography
                    variant = "p"
                    className = {classes.textField}
                >
                    {utils.getFormattedAmount(details?.service_charge_amount || 0)}
                </Typography>
            </FormControl> */}
            <FormControl className={classes.formControlText}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Requested Amount (INR)
                </Typography>
                <Typography
                    variant = "p"
                    className = {classes.textField}
                >
                    {
                        details.requested_amount ? 
                        utils.getFormattedAmount(details?.requested_amount || 0) : "NA"
                    }
                </Typography>
            </FormControl>
            <FormControl className={classes.formControlInput}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Amount to Approve (INR)
                </Typography>
                <OutlinedInput
                    className={classes.inputFieldDisabled}
                    value = {details.approved_amount}
                    onChange={(e) => handleTotalChange(
                        e.target.value, 
                        'approved_amount'
                    )}
                    type = {'number'}
                    id='approved_amount'
                    error = {false}
                    disabled
                    inputProps = {{
                        required: true,
                        maxLength : 20,
                        min : 0
                    }}
                />
            </FormControl>
            <FormControl className={classes.formControlInput}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Description
                </Typography>
                <OutlinedInput
                    className={classes.inputField}
                    value = {details.description}
                    onChange={(e) => handleTotalChange(
                        e.target.value, 
                        'description'
                    )}
                    type = {'text'}
                    id='description'
                    error = {false}
                    inputProps = {{
                        required: true,
                        maxLength : 45,
                    }}
                />
            </FormControl>
        </Grid>
    )
}