import KeyboardEventHandler from 'react-keyboard-event-handler';
import React, { useState, useEffect, useRef } from 'react';
import PromptPassword from './prompt-password';
import useStyles from '../style';
import { 
    Typography, Box, Grid,
    FormGroup, Switch
} from '@material-ui/core';

export default function SafeMode(props){

    const classes = useStyles();
    const switchRef = useRef(null);
    const [error, setError] = useState(false);
    const [value, setValue] = useState(false);
    const [askKey, setAskKey] = useState(false);
    const [password, setPassword] = useState('');
    const [showSwitch, setShowSwitch] = useState(false);    
    const { 
        status = '',
        notifications = {},
        handleSubmit = () => null, 
    } = props;

    useEffect(() => {
        if(notifications?.details?.safe_mode) {
            setShowSwitch(false);
            setValue(true);
        } else {
            setShowSwitch(true);
            setValue(false);
        }
    }, [notifications?.details])

    useEffect(() => {
        if(status === 'ENABLE_SM_SUCCESS'){
            setShowSwitch(false);
        } else if(status === 'DISABLE_SM_SUCCESS'){
            setPassword('');
            setAskKey(false);
        }
    }, [status]);

    const onSubmit = () => {
        if(!password)
            setError(true);
        else if(password)
            handleSubmit(false, { password });
    }

    const handleChange = (val, name = 'switch') => {
        if(name === 'switch') {
            if(val) {
                setValue(val);
                handleSubmit(true, {});
            }else {
                setValue(val);
                setAskKey(true);
            }
        }else {
            setPassword(val);
            setError(false);
        }
    }

    const getSwitch = () => {
        if(showSwitch)
            return (
                <Box className={classes.mostOuterBox}>
                    <Box className = {classes.outerBox}>
                        <Box className = {classes.mainBox}>
                            <Typography variant='h6' 
                                className= {classes.blockTitle}
                            >
                                Safe Mode
                            </Typography>
                        </Box>
                        <Grid
                            xs = {12} sm = {6} md = {6} lg = {6} 
                            xl={6} className = {classes.safeModeGrid}
                        >
                            <FormGroup aria-label="position" row>
                                <Switch 
                                    color="primary" 
                                    checked = {value}
                                    onChange = {(e) => handleChange(e.target.checked, 'switch')}
                                />
                            </FormGroup>
                            <Typography style = {{marginLeft: '32px'}}>
                                Current State : 
                                { value ? ' Enabled' : ' Disabled' }
                            </Typography>
                        </Grid>
                    </Box>     
                </Box>
            );
        else return <div></div>;
    }


    return (
        <div 
            ref = {switchRef} 
            style = {{marginBottom: '80px'}}
        >
            {getSwitch()}
            <KeyboardEventHandler 
                handleKeys = {['ctrl+.', 'command+.']}
                onKeyEvent = {(key, e) => {
                    if(value && !showSwitch) {
                        setShowSwitch(true);
                        switchRef.current.scrollIntoView();
                    }
                }}
            />
            {
                askKey &&
                <PromptPassword
                    error = {error}
                    password = {password}
                    onSubmit = {onSubmit}
                    handleChange = {handleChange}
                    setDetails = {() => {
                        setValue(true);
                        setAskKey (false);
                    }}
                />
            }
        </div>
    )
}