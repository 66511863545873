import React, { useEffect, useState } from 'react';
import { Button, Confirmation, Navbar, Table } from "../../components";
import { attendanceConstants as ac } from '../../constants';
import { utils } from '../../utils';
import ApplyFilter from './filter';
import useStyles from './style';
import { 
    FilterListOutlined,
    KeyboardArrowDown, 
    AutorenewOutlined,
    ExitToAppOutlined
} from '@material-ui/icons';
import {
    Box, Paper,
    Typography,
    TablePagination
} from '@material-ui/core';

let tableHeaders = [
    {
        id: "user_name",
        label : "Name",
        type: 'text_wo_input',
        minWidth: '120px',
        maxWidth: '120px',
        style : {}
    },
    {
        id: "branch_name",
        label : "Branch",
        type: 'text_wo_input',
        minWidth: '120px',
        maxWidth: '120px',
        style : {}
    },
    {
        id: "attendance_date",
        label : "Attendance Date",
        type: 'text_wo_input',
        inputType: 'date',
        minWidth: '150px',
        maxWidth: '150px',
        style : {}
    },
    {
        id: "login_time",
        label : "Login Time",
        type: 'text_wo_input',
        inputType: 'date',
        minWidth: '150px',
        maxWidth: '150px',
        style : {}
    }
];

let paginationDropDownList = [
    10, 20, 40
];


let defaultQuery = {
    from_date : '',
    to_date : '',
    user_id: 0,
    branch_id: 0
}

export default function AttendanceLayout(props){
    const classes = useStyles();
    const [filterQuery, setFilterQuery] = useState({ ...defaultQuery });
    const [clearInputFields, setClearInputFields] = useState(false);
    const [showApplyFilter, setShowApplyFilter] = useState(false);
    const [loginToWork, setLoginToWork] = useState(false);
    const [tableData, setTableData] = useState([]);
    const {
        handleSubmit = () => null,
        getDetails = () => null,
        isLoggedToday = false,
        setLimit = () => null,
        setPage = () => null,
        noOfRecords = 0,
        branches = [],
        details = [],
        status = '',
        limit = 10,
        page = 0
    } = props;

    useEffect(() => {
        if(status === 'GETALL_SUCCESS'){
            let td = [ ...details ];
            td = td.map(e => {
                return {
                    ...e,
                    attendance_date: utils.getDate(e.attendance_date || ""),
                    login_time: utils.getDateTime(e.login_time || "")
                }
            })
            setTableData([ ...td ]);
        }
        if(status === 'POST_SUCCESS'){
            setLoginToWork(false);
        }
    },[status]);

    useEffect(() => {
        if(clearInputFields){
            setClearInputFields(false);
            setFilterQuery(defaultQuery);
        }
    }, [clearInputFields]);


    const onRowClick = (e, r, i) => {
        // setShowDetails({flag: true, value: r});
    }

    const onChangeRowsPerPage = (e) => {
        setLimit(e.target.value);
        setPage(0);
        getDetails(0, e.target.value, filterQuery);
    }
    
    const onChangePage = (e, val) => {
        setPage(val);
        getDetails(val, limit, filterQuery);
    }

    const handleFilterChange = (val, col) => {
        let fq = { ...filterQuery, [col.id] : val }
        setPage(0);
        setFilterQuery(fq);
        getDetails(0, limit, fq);
    }

    const applyFilter = (payload) => {
        setPage(0);
        setFilterQuery(payload);
        getDetails(0, limit, payload);
    }

    const resetFilter = () => {
        setPage(0);
        setClearInputFields(true);
        getDetails(0, limit, defaultQuery);
    }

    return (
        <Navbar 
            { ...props }
        >
            <Box className={classes.mainBox}>
                <Box className = {classes.tableTitleBox}>
                    <Typography variant = "h5">
                        Attendance Logs
                    </Typography>
                    <Box className={classes.actionButtons}>
                        <Button
                           text = "Apply Filter"
                            variant = "secondary"
                            Icon = {FilterListOutlined}
                            style = {{
                                padding: '8px 16px', 
                                marginRight: '16px' 
                            }}
                            onClick = {() => setShowApplyFilter(true)}
                        />
                        <Button
                            text = "Refresh"
                            variant = "secondary"
                            Icon = {AutorenewOutlined}
                            style = {{
                                padding: '8px 16px', 
                                marginRight: '16px' 
                            }}
                            onClick = {() => getDetails(
                                page, limit, filterQuery
                            )}
                        />
                        {
                            !isLoggedToday &&
                            <Button
                                text = "LogIn to Work"
                                variant = "primary"
                                Icon = {ExitToAppOutlined}
                                style = {{padding: '8px 16px'}}
                                onClick = {() => setLoginToWork(true) }
                            />
                        }
                    </Box>
                </Box>
                {
                    (filterQuery['from_date'] ||
                    filterQuery['to_date'] ||
                    filterQuery['branch_id']) ?
                    'Note: Filter Applied' : ''
                }
            </Box>
            <Paper className = {classes.tableBox}>
                <Table
                    loading = {status === 'GETALL_REQUEST'}
                    clearInputFields = {clearInputFields}
                    onTableRowDoubleClick = {onRowClick}
                    handleChange = {handleFilterChange}
                    tableColumns = {tableHeaders}
                    tableData = {tableData}
                />
                 <TablePagination
                    SelectProps={{
                        IconComponent: KeyboardArrowDown,
                        classes: { icon: classes.selectIcon },
                    }}
                    rowsPerPageOptions={paginationDropDownList}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    onChangePage={onChangePage}
                    count={noOfRecords}
                    rowsPerPage={limit}
                    component='div'
                    page={page}                   
                />  
            </Paper>
            {
                loginToWork &&
                <Confirmation
                    onClose = {() => setLoginToWork(false)}
                    submitText = {"Login to Work"}
                    onSubmit = {handleSubmit}
                    content = {ac.postLogText}
                    closeText = {"Cancel"}
                />
            }
            {
                showApplyFilter &&
                <ApplyFilter
                    status = {status}
                    branches = {branches}
                    show = {showApplyFilter}
                    filterQuery = {filterQuery}
                    resetFilter = {resetFilter}
                    handleSubmit = {applyFilter}
                    defaultQuery = {defaultQuery}
                    setShow = {setShowApplyFilter}
                />
            }
        </Navbar>
    )
}