import { handleErrorResponse } from './httperror';
import appinfo from "../app.json";
import Store from "../reducers";

export const httpClient = {
    deleteReq,
    get,
    getHeaders,
    callApi,
    handleResponse,
    post,
    postImage,
}

function getHeaders(){

    let token = Store.getState().auth.accessToken;

    let headers={
        "Accept":"application/json",
        "Content-Type":"application/json",
        "Access-Control-Allow-Origin": "*",
    }

    if(token){
      headers['Authorization'] = `Bearer ${token}`
    }

    return {headers};
}

function getRequestOption(credentials){
  let headers = getHeaders(credentials).headers;
  let requestOptions ={
    credentials: "include",
    headers
  };

  if(!credentials){
    delete requestOptions["credentials"];
  }

  return requestOptions;
}
function get(credentials = false){
    let requestOptions = getRequestOption(credentials = true);

    requestOptions["method"] = "GET";
    return requestOptions;
}

function put(payload, credentials=false){
  let requestOptions = getRequestOption(credentials);

  requestOptions["method"] = "PUT";
  requestOptions["body"] = JSON.stringify(payload);
  return requestOptions;
}

function deleteReq(payload, credentials = false) {
    let requestOptions = getRequestOption(credentials);

    requestOptions["method"] ="DELETE";
    requestOptions["body"] =JSON.stringify(payload);
    return requestOptions;

}

function post(payload, credentials = false){
    let requestOptions = getRequestOption(credentials);

    requestOptions["method"] ="POST";
    requestOptions["body"] =JSON.stringify(payload);
    return requestOptions;
}

function postImage(payload){

    let token = Store.getState().auth.accessToken;

    let requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          // "Content-Type": "multipart/form-data",
        },
        body: payload
      };

      if(token){
        requestOptions = {
          ...requestOptions,
          headers : {
            ...requestOptions.headers,
            'Authorization' : `Bearer ${token}`
          }
        }
      }

      return requestOptions;
}


function callApi(
    method,
    endpoint,
    payload = {},
    credentials = false,
    image = false,
    src = ''
  ) {
    let requestOptions = {};
    let url = appinfo.url;
  
    switch (method) {
      case "GET":
        requestOptions = get(credentials, payload);
        break;
      case "POST":
        if (image) {
          requestOptions = postImage(payload);
        } else {
          requestOptions = post(payload, credentials);
        }
        break;
      case "PUT":
        requestOptions=put(payload,credentials);
        break;
      case "DELETE":
        requestOptions = deleteReq(payload,credentials);
        break;
      default:
        console.log("Nothing here");
    }
    return fetch(`${url}${endpoint}`, requestOptions)
              .then((e) => handleResponse(e, src));
  }
  

  function handleResponse(response, src = '') {
    let json = response.json();
    if (!(response.status === 200 || response.status === 201)) {
      handleErrorResponse(response, src);
      return json.then(res => {
        return Promise.reject(res);
      });
    }
  
    return json;
  }
  