import React from 'react';
import useStyles from './style';
import { 
    Address, Button, ModalDialog
} from "../../components";
import { onboardBranchConstants as oc } from '../../constants';
import { 
    Grid, Box,
    Typography, 
    FormControl,
    OutlinedInput,
} from '@material-ui/core';

export default function OnboardBranch(props) {
    const classes = useStyles();
    const {
        errorValue = {show: false, keys: {}},
        setShowOnboard = () => null,
        handleChange = () => null,
        handleSubmit = () => null,
        showOnboard = false,
        details = {},
        status = '',
        task = ''
    } = props;

    const dialogTitle = () => {
        return (
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        {oc.title}
                    </Typography>
                </Box>
        );
    }

    const dialogContent = () => {
        return (
            <Box>
                <Box className = {classes.outerBox}>
                    <Grid
                        xs = {12} sm = {12} md = {12} lg = {12} xl = {12}
                        className = {classes.mainGrid}
                    >
                        <FormControl className={classes.formControlInput}>
                            <Typography variant='subtitle2'
                                className={classes.label}
                            >
                                Branch Name *
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleChange(e.target.value, '', 'branch_name')}
                                value = {details.branch_name || ""}
                                className={classes.inputField}
                                placeholder={'Branch Name'}
                                type = {'text'}
                                id='branch_name'
                                error = {
                                    errorValue.show && 
                                    errorValue.keys['branch_name']
                                }
                                inputProps = {{
                                    required: true,
                                    maxLength : 45
                                }}
                            />
                            <small className={'red'}>
                                {
                                    errorValue.show && 
                                    errorValue.keys['branch_name'] &&
                                    oc.invalid
                                }
                            </small>
                        </FormControl>

                    </Grid>
                </Box>
                <Box className = {classes.outerBox}>
                    <Grid
                        xs = {12} sm = {12} md = {12} lg = {12} xl = {12}
                        className = {classes.mainGrid}
                    >
                        <Address 
                            handleChange = {handleChange}
                            errorValue = {errorValue}
                            title = {'Address'}
                            details = {details}
                            disabled = {false}
                            addressIndex = {0}
                            parentKey = {''}
                        />
                    </Grid>
                </Box>
                <Box style = {{
                    display: 'flex', 
                    flexDirection: 'row', 
                    marginTop: '32px'
                }}>
                    <Button 
                        loading = {status === `${task}_REQUEST`}
                        onClick = {() => handleSubmit(task)}
                        variant = {'primary'}
                        text = { task }
                        style={{
                            width: '300px',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    />
                </Box>
            </Box>
        );
    }

return (
    <ModalDialog
        open = {showOnboard}
        onClose = {() => setShowOnboard(false)}
        dialogTitle = {dialogTitle()}
        dialogContent = {dialogContent()}
        maxWidth = {'md'}
        classes = {{ paper: classes.dialogPaper}}
        dialogFooter = {[]}
    />
)

}