import { fundRequestConstants as frc } from '../constants';
import fundConstants from '../constants/fund-request';
import { fundService } from '../services';
import notyf from '../utils/notification';

export const fundActions = {
    confirmFundsAction,
    cancelFundsAction,
    pendingFundAction,
    getFundsInBranch,
    getTotalRecords,
    addFunds,
    getAll,
    raise,
    // update,
}

function raise(payload){
    return dispatch =>{
        dispatch(request());
        fundService.raise(payload).then(
            user =>{ dispatch(success(user)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: fundConstants.POST_REQUEST };
    }
    function success(funds){
        notyf.open({
            type: 'success',
            message: funds.message || frc.postSuccess
        });
        return {
            type: fundConstants.POST_SUCCESS,
            payload: funds
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: fundConstants.POST_ERROR,
            payload : error
        };
    }
}

function addFunds(payload){
    return dispatch =>{
        dispatch(request());
        fundService.addFunds(payload).then(
            user =>{ dispatch(success(user)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: fundConstants.ADD_REQUEST };
    }
    function success(funds){
        notyf.open({
            type: 'success',
            message: 'Funds Added Successfully'
        });
        return {
            type: fundConstants.ADD_SUCCESS,
            payload: funds
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: fundConstants.ADD_ERROR,
            payload : error
        };
    }
}

function getAll(type, offset = 0, limit = 10, filter = {}){
    return dispatch =>{
        dispatch(request());
        fundService.getAll(type, offset, limit, filter)
            .then(
                user =>{ dispatch(success(user)) },
                error =>{ dispatch(failure(error)) }
            );
    };
    function request(){
        return { type: fundConstants.GET_ALL_REQUEST };
    }
    function success(funds){
        return {
            type: fundConstants.GET_ALL_SUCCESS,
            payload: funds
        };
    }
    function failure(error){
        return {
            type: fundConstants.GET_ALL_ERROR,
            payload : error
        };
    }
}

function getTotalRecords(type, filter = {}){
    return dispatch =>{
        dispatch(request());
        fundService.getTotalRecords(type, filter).then(
            value =>{ dispatch(success(value)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: fundConstants.RECORDS_REQUEST };
    }
    function success(value){
        return {
            type: fundConstants.RECORDS_SUCCESS,
            payload: value
        };
    }
    function failure(error){
        return {
            type: fundConstants.RECORDS_ERROR,
            payload : error
        };
    }
}


function getFundsInBranch(branch_id){
    return dispatch =>{
        dispatch(request());
        fundService.getFundsInBranch(branch_id).then(
            user =>{ dispatch(success(user)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: fundConstants.BRANCH_FUND_REQUEST };
    }
    function success(funds){
        return {
            type: fundConstants.BRANCH_FUND_SUCCESS,
            payload: funds
        };
    }
    function failure(error){
        return {
            type: fundConstants.BRANCH_FUND_ERROR,
            payload : error
        };
    }
}

function pendingFundAction(payload) {
    return dispatch =>{
        dispatch(request());
        fundService.pendingFundAction(payload).then(
            fund =>{ dispatch(success(fund)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: fundConstants.P_ACTION_REQUEST };
    }
    function success(funds){
        notyf.open({
            type: 'success',
            message: funds.message
        });
        return {
            type: fundConstants.P_ACTION_SUCCESS,
            payload: funds
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: fundConstants.P_ACTION_ERROR,
            payload : error
        };
    }
}

function confirmFundsAction(payload) {
    return dispatch =>{
        dispatch(request());
        fundService.confirmFundsAction(payload).then(
            fund =>{ dispatch(success(fund)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: fundConstants.C_ACTION_REQUEST };
    }
    function success(funds){
        notyf.open({
            type: 'success',
            message: funds.message
        });
        return {
            type: fundConstants.C_ACTION_SUCCESS,
            payload: funds
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: fundConstants.C_ACTION_ERROR,
            payload : error
        };
    }
}

function cancelFundsAction(payload) {
    return dispatch =>{
        dispatch(request());
        fundService.cancelFundsAction(payload).then(
            fund =>{ dispatch(success(fund)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: fundConstants.CANCEL_FR_REQUEST };
    }
    function success(funds){
        notyf.open({
            type: 'success',
            message: funds.message
        });
        return {
            type: fundConstants.CANCEL_FR_SUCCESS,
            payload: funds
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: fundConstants.CANCEL_FR_ERROR,
            payload : error
        };
    }
}