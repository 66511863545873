import React, { 
  useState, 
  useLayoutEffect, 
  useRef, 
  useEffect
} from 'react';
import {
  TableContainer, Radio,
  TableBody, TableHead,
  TableRow, Checkbox,
  IconButton, Grid, Table,
  CircularProgress, Box,
  Typography, RadioGroup, 
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  MoreVert, ChevronRight, 
  BorderColor, CheckBox, 
  BorderColorOutlined,
  CheckBoxOutlineBlank,
  Check, CheckOutlined, 
  InfoRounded, 
  CancelOutlined, 
  AccessTimeOutlined,
  InfoOutlined,
  CheckCircleOutlined,
  DoneAllRounded,
  ThumbDownOutlined,
  ThumbUpOutlined,
} from '@material-ui/icons';
import { Button } from '../';
import StyledTableCell from '../TableCell';
import TableInput from '../TableInput';
import Select from '../Select';
import MultiSelect from '../MultiSelect';
import TableRowInput from "../RowInput";
import DropDown from '../DropDown/index.js';
import ToggleSwitch from '../ToggleSwitch/index';
import useStyles  from "./style";

const StyledTableRow = withStyles((theme) => ({
  selected: {
    backgroundColor: 'transparent !important',
  },
  root: {
    cursor: 'pointer',
  },
}))(TableRow);

const CustomizedCircularProgress = withStyles((theme) => ({
  root: {
    color: theme.color.notsocoolgray,
  },
}))(CircularProgress);

const CustomCheckBox = withStyles({
  root: {
    marginTop: '2px',
    color: '#CDD4E4',
    '&$checked': {
      color: '#CDD4E4',
    },
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />);

export default function (props) {
  const {
    clearInputs,
    tableColumns,
    classes,
    tableData,
    onTableRowClick = () => {},
    onInfoPress,
    emptyText,
    tableHeight,
    rowKeySuffix,
    rowKey,
    loading,
  } = props;

  const [selectedRowIndex, setSelectedRowIndex] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorMenuEl, setAnchorMenuEl] = React.useState(null);
  const [rowCheckList, setrowCheckList] = React.useState([]);
  const [columnCheck, setcolumnCheck] = React.useState(false);
  const [allSelect, setallSelect] = React.useState([]);
  const tableRef= useRef();
  const openMenu = Boolean(anchorMenuEl);
  const ITEM_HEIGHT = 48;
  const styledClasses = useStyles();


  useLayoutEffect(() => {
    if (props.checkDisable != undefined && props.checkDisable == true) {
      setrowCheckList([]);
      setcolumnCheck(false);
      props.handleTableCheckBox();
    }
  });

  useEffect(() => {
    if(clearInputs){
      setrowCheckList([]);
      setcolumnCheck(false);
    }
  }, [clearInputs]);

  const handleChange = (event, column) => {
    const value =
      column.type == 'tag_text' ? event.target.value : event.target.value;
    props.handleChange(value, column);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const selectColumnCheck = (event) => {
    const { handleCheckBox = () => null } = props;
    var array = [];
    if (!columnCheck) {
      {
        tableData.map((row, index) => array.push(index));
      }
      setrowCheckList(array);
    } else {
      setrowCheckList([]);
    }
    handleCheckBox({target: {checked: !columnCheck}}, {}, -1, true);
    setcolumnCheck(!columnCheck);
  };
  const selectRowCheck = (e, index) => {
    e.stopPropagation();
    if (rowCheckList.includes(index)) {
      var array = rowCheckList;
      var currentIndex = array.indexOf(index);
      array.splice(currentIndex, 1);
      setrowCheckList(array);
      setcolumnCheck(false);
      setallSelect(!allSelect);
    } else {
      var array = rowCheckList;
      array.push(index);
      setrowCheckList(array);
      setallSelect(!allSelect);
    }
  };
  const dateFormat = (dateTime) => {
    if (typeof dateTime !== 'undefined') {
      var d = new Date(dateTime.toString());
      var formattedDate =
        d.getFullYear() + '/' + (d.getMonth() + 1) + '/' + d.getDate();
      var hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();
      var minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
      var formattedTime = hours + ':' + minutes;
      return formattedDate + ' ' + formattedTime;
    } else {
      return;
    }
  };
  const handleToggleSwitch = (checkedValue, name) => {
    props.handleToggleSwitch(checkedValue, name);
  };
  const renderColCheckboxWithMenu = (column) => {
    return (
      <Grid
        container
        direction='row'
        justify='space-between'
        alignItems='center'
        style={column.style}
      >
        <Box style={column.menuStyle}>
          <DropDown
            data={rowCheckList.length > 0 ? props.roleNameSelected ? props.roleHeaderDropdownData : props.headerMenuOptions : []}
            clickEvent={(event, key) => {
              if (rowCheckList.length > 0) {
                props.handleMenuDropdown(event, key, rowCheckList, 'column');
              }
            }}
            icon='moreOptionsIcon'
            className={props.screenType === 'UserList' && rowCheckList.length < 1 ? styledClasses.moreOptionCursor : ''} 
          />
        </Box>
          <Checkbox
            checked={columnCheck}
            icon={
              <CheckBoxOutlineBlank
                style={{
                  fontSize: 12,
                  width: 18,
                  height: 18,
                  color: 'white',
                  border: '1px solid #CDD4E4',
                }}
              />
            }
            checkedIcon={
              <CheckOutlined
                style={{
                  fontSize: 12,
                  width: 18,
                  height: 18,
                  color: 'black',
                  fontColor: 'black',
                  border: '1px solid #CDD4E4',
                }}
              />
            }
            onChange={(e) => selectColumnCheck(e)}
          />
      </Grid>
    );
  };

  const renderColCheckbox = () => {
    return (
      <Grid
        container
        direction='row'
        justify='flex-end'
        alignItems='center'
      >
        <Box> 
          <DropDown
            data={rowCheckList.length > 0 ? props.headerMenuOptions : []}
            clickEvent={(event, key) => {
              if (rowCheckList.length > 0) {
                props.handleMenuDropdown(event, key, rowCheckList, 'column');
              }
            }}
            icon='moreOptionsIcon'
            className={rowCheckList.length < 1 ? styledClasses.moreOptionCursor : ''} 
          />
        </Box>
        {/* <IconButton edge='end' onClick={handleClick}>
          <MoreVert style={{ fontSize: 16, color: '#CDD4E4' }} />
        </IconButton> */}
        <Checkbox
          checked={columnCheck}
          icon={
            <CheckBoxOutlineBlank style={{ fontSize: 16, color: '#CDD4E4' }} />
          }
          checkedIcon={<CheckBox style={{ fontSize: 16, color: '#CDD4E4' }} />}
          onChange={(e) => selectColumnCheck(e)}
        />
      </Grid>
    );
  };

  const renderColNoIconCheckbox = () => {
    return (
      <Grid
        container
        direction='row'
        justify='space-around'
        alignItems='center'
      >
        {/* <Checkbox
          checked={columnCheck}
          icon={
            <CheckBoxOutlineBlank style={{ fontSize: 16, color: '#CDD4E4' }} />
          }
          checkedIcon={<CheckBox style={{ fontSize: 16, color: '#CDD4E4' }} />}
          onChange={(e) => selectColumnCheck(e)}
        /> */}
      </Grid>
    );
  };

  const renderColPlainText = (column) => {
    return (
      <Grid
        container
        direction='row'
        justify={ column.columnStyle?.align || 'space-between' }
        alignItems='center'
      >
        <span
          style={{
            maxWidth: column.style?.maxWidth,
            color: '#8C8C8C',
            fontSize: 13,
            fontWeight: 400,
          }}
        >
          {column.label || ''}
        </span>
      </Grid>
    );
  };

  const renderColMore = () => {
    return (
      <Grid
        container
        direction='row'
        justify='space-between'
        alignItems='center'
      >
        <IconButton 
          edge='end' 
          onClick={handleClick}
        >
          <MoreVert 
            style={
              { fontSize: 16, 
                color: '#CDD4E4',
                visibility: 'visible'
              }
            } 
          />
        </IconButton>
      </Grid>
    );
  };

  const renderColMultiSelectDropdown = (column) => {
    return (
      <MultiSelect
        dataValue={column.value}
        handleChange={(event) => handleChange(event, column)}
        options={column.dropdownItems}
        labelKey={column?.labelKey}
        valueKey={column?.valueKey}
        placeHolder={column?.placeHolder}
        disabled={column?.disabled}
        value={[]}
        clearInputFields={props.clearInputFields ? props.clearInputFields : ''}
      ></MultiSelect>
    );
  };

  const renderColSingleSelectDropdown = (column) => {
    return (
      <Select
        handleChange={handleChange}
        options={column.dropdownItems}
        labelKey={column?.labelKey}
        valueKey={column?.valueKey}
        placeHolder={column?.placeHolder}
        disabled={column?.disabled}
        column={column}
        value={[]}
        clearInputFields={props.clearInputFields ? props.clearInputFields : ''}
        screenType={props.screenType}
      ></Select>
    );
  };

  const renderColText = (column) => {
    return (
      <TableInput
        value={column.value}
        placeholder={column.label}
        disabled={column?.disabled}
        inputType = {column.inputType || 'text'}
        onChange={(event) => handleChange(event, column)}
        screenType={props.screenType}
        disabled={column?.disabled}
        clearInputFields={props.clearInputFields ? props.clearInputFields : ''}
      />
    );
  };

  const handleClickMenu = (event) => {
    setAnchorMenuEl(event.currentTarget);
  };

  const handleCloseMenu = (event) => {
    setAnchorMenuEl(null);
  };

  const tableHeader = () => {
    return (
      <TableRow>
        {tableColumns.map((column) => {
          let tableHeadCellInput;
          const style = column.style ? column.style : {};
          if (column.type === 'text') {
            tableHeadCellInput = renderColText(column);
          } else if (column.type === 'text_wo_input') {
            tableHeadCellInput = renderColPlainText(column);
          }else if (column.type === 'date') {
            tableHeadCellInput = renderColText(column);
          } else if (column.type === 'tag_text') {
            tableHeadCellInput = renderColText(column);
          } else if (
            column.type === 'dropdown' ||
            column.type === 'dropdown_with_tag'
          ) {
            if (column.isMultiSelect === true)
              tableHeadCellInput = renderColMultiSelectDropdown(column);
            else tableHeadCellInput = renderColSingleSelectDropdown(column);
          } else if (column.type === 'dropdown_with_toggle') {
            if (column.isMultiSelect === true)
              tableHeadCellInput = renderColMultiSelectDropdown(column);
            else tableHeadCellInput = renderColSingleSelectDropdown(column);
          } else if (column.type === 'checkbox') {
            tableHeadCellInput = renderColCheckbox();
          } else if (column.type === 'checkbox-only') {
            tableHeadCellInput = renderColText(column);
          } else if (column.type === 'checkbox_with_menu') {
            tableHeadCellInput = renderColCheckboxWithMenu(column);
          } else if (column.type === 'more') {
            tableHeadCellInput = renderColMore();
          } else if(column.type === 'dynamic_more_wo_header') {
            tableHeadCellInput = renderColPlainText(column);
          } else if (column.type === 'hyperlink') {
            tableHeadCellInput = renderColText(column);
          } else if (column.type === 'popup_action') {
            tableHeadCellInput = renderColPlainText(column);
          } else if (column.type === 'text_input') {
            tableHeadCellInput = renderColPlainText(column);
          } else if (column.type === 'checkbox_without_icon') {
            tableHeadCellInput = renderColNoIconCheckbox(column);
          } else if (column.type === 'radio') {
            tableHeadCellInput = renderColNoIconCheckbox(column);
          } else if (column.type === 'row_dropdown') {
            tableHeadCellInput = renderColPlainText(column);
          }

          return (
            <StyledTableCell
              key={column.id}
              align={column.align}
              style={{
                minWidth: column.minWidth,
                maxWidth: column.maxWidth,
                ...style,
              }}
            >
              {tableHeadCellInput}
            </StyledTableCell>
          );
        })}
      </TableRow>
    );
  };

  const tableBody = (row, index) => {
    const {
      handlePopupAction = () => null,
      handleCheckBox = () => null,
      handleRowInputChange = () => null,
      handleIconClick = () => null,
      handleCallToAction = () => null,
      handleRowDropdown = () => null,
      backgroundColorValue = '',
      backgroundColorKey = '',
      canUpdate = true
    } = props;
    return (
      <StyledTableRow
        hover
        style = {
          backgroundColorKey && 
          row[backgroundColorKey] === backgroundColorValue ? 
          {backgroundColor: '#B1740F22'} : {}
        }
        key={
          rowKeySuffix && rowKey
            ? `tablerow_${rowKeySuffix}_${row[rowKey]}`
            : `tablerow_${Date.now()}${index}`
        }
        onClick={(event) => {
          onTableRowClick(event, row, index);
          setSelectedRowIndex(index);
        }}
        selected={rowCheckList.includes(index) || columnCheck ? true : false}
      >
        {tableColumns.map((column) => {
          const type = column.valueType ? column.valueType : column.type;
          const icon = column.icon;
          const url = row[column.id];
          const value = row[column.id];
          const placeholder = column.placeholder || '';
          const label = column.label || '';
          const inputStyle = column.inputStyle || {};
          let style =
            column.rowStyle != undefined
              ? column.rowStyle
              : column.style
              ? column.style
              : {};
          let textStyle = {};
          if (column.maxWidth) {
            textStyle = {
              ...style,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: column.maxWidth,
              display: 'inline-block',
            };
          }
          if ((type === 'text' || type === 'text_wo_input') && value !== undefined) {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  display: 'block !important',
                  minWidth: column.minWidth,
                  paddingLeft: 10,
                  ...style,
                }}
              >
                <span style={{ ...textStyle }} title={value}>{value}</span>
              </StyledTableCell>
            );          
          } else if (type === 'date' && value !== undefined) {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  display: 'block !important',
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  paddingLeft: 8,
                  ...style,
                }}
              >
                {dateFormat(value)}
              </StyledTableCell>
            );
          } else if (type === 'tag_text' && value !== undefined) {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  display: 'block !important',
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  paddingLeft: 8,
                  ...style,
                }}
              >
                <Box
                  variant='div'
                  style={{
                    padding: 10,
                    borderRadius: 4,
                    margin: '6px 0px',
                    width: 'inherit',
                  }}
                >
                  {' '}
                  {value}{' '}
                </Box>
              </StyledTableCell>
            );
          } else if (type === 'dropdown_with_tag' && value !== undefined) {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  display: 'block !important',
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  paddingLeft: 8,
                  ...style,
                }}
              >
                <Grid container>
                  {value.map((val) => {
                    return (
                      <Box
                        variant='div'
                        style={{
                          padding: 8,
                          borderRadius: 4,
                          margin: '6px 4px',
                          width: 'fit-content',
                        }}
                        item
                      >
                        {' '}
                        {val}{' '}
                      </Box>
                    );
                  })}
                </Grid>
              </StyledTableCell>
            );
          } else if (
            type === 'hyperlink' &&
            value !== undefined &&
            url !== undefined
          ) {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  display: 'block !important',
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  paddingLeft: 8,
                  ...style,
                }}
              >
                <Box
                  variant='div'
                  style={{
                    // backgroundColor: 'rgb(240 242 247 / 50%)',
                    padding: 10,
                    borderRadius: 4,
                    margin: '6px 0px',
                    width: 'fit-content',
                  }}
                >
                  {' '}
                  {url != '' ? (
                    <a
                      href={url}
                      target={"_blank"}
                      style={{
                        ...textStyle,
                        textDecoration: 'none',
                        color: '#0062FF',
                        fontWeight: '500',
                      }}
                    >
                      {value}
                    </a>
                  ) : (
                    <label
                      style={{
                        textDecoration: 'none',
                        color: '#0062FF',
                        fontWeight: '500',
                      }}
                    >
                      {value}
                    </label>
                  )}{' '}
                </Box>
              </StyledTableCell>
            );
          } else if (type === 'popup_action') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  display: 'block !important',
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  paddingLeft: 8,
                  ...style,
                }}
              >
              {
                value != '' ? (
                  <Box
                    variant='div'
                    style={{
                      // backgroundColor: 'rgb(240 242 247 / 50%)',
                      padding: 10,
                      borderRadius: 4,
                      margin: '6px 0px',
                      width: 'fit-content',
                    }}
                    onClick={() => handlePopupAction(row, column, index)}
                  >
                        <label
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            color: '#0062FF',
                            fontWeight: '500',
                          }}
                        >
                          View
                        </label>
                  </Box> ) :''
                }
              </StyledTableCell>
            );
          }else if (type === 'dropdown' && value !== undefined) {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  paddingLeft: 8,
                  ...style,
                }}
              >
                {typeof value === 'string' ? (
                  column.isRowMultiTags ? (
                    <Grid
                      style={{
                        display: '-webkit-inline-box',
                        flexWrap: 'wrap',
                        marginLeft: '-8px',
                      }}
                    >
                      {value.split(',').map((val, index) => {
                        return (
                          <Box
                            variant='div'
                            style={{
                              padding: 8,
                              borderRadius: 4,
                              margin: '6px 0px',
                              width: 'fit-content',
                              marginLeft: '8px',
                            }}
                          >
                            {val}
                          </Box>
                        );
                      })}
                    </Grid>
                  ) : (
                    <Box
                      variant='div'
                      style={{
                        backgroundColor: 'transparent',
                        padding: 8,
                        borderRadius: 4,
                        margin: '6px 0px',
                        width: 'fit-content',
                      }}
                      title={value}
                    >
                      {value}
                    </Box>
                  )
                ) : (
                  <div>
                    {
                      value ? (
                        <div
                          style={{
                            height: 16,
                            width: 16,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 8,
                          }}
                        >
                          <Check style={{ fontSize: 12, color: '#0062FF' }} />
                        </div>
                      ) : (
                        ''
                      )
                      // <Close style={{ fontSize: 12, color: '#0062FF' }} />
                    }
                  </div>
                )}
              </StyledTableCell>
            );
          } else if (type === 'dropdown_with_toggle' && value !== undefined) {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  paddingLeft: 8,
                  ...style,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {/* <img
                    src={schedulerEditIcon}
                    onClick={onTableRowClick}
                    style={{
                      fontSize: ' 0.90em',
                      marginRight: '45px',
                      marginLeft: '10px',
                    }}
                  /> */}
                  {(row['eventId'] !== undefined || 
                    row['nextExecution'] !== undefined ) ? (
                    canUpdate ?
                    <ToggleSwitch
                      id={row['name']}
                      status={value}
                      toggle={handleToggleSwitch}
                    /> : ''
                  ) : (
                    <Typography
                      variant='h5'
                      style={{
                        position: 'relative',
                        left: '-10px',
                        color: '#A4A4A4',
                      }}
                    >
                      Draft
                    </Typography>
                  )}
                </div>
              </StyledTableCell>
            );
          } else if (type === 'menu') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={column.style}
              >
                <Grid
                  container
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                  style={column.style}
                >
                  <Box style={column.menuStyle}>
                    <DropDown
                      data={props.rowMenuOptions}
                      clickEvent={(event, key) => {
                        props.handleMenuDropdown(event, key, index, 'row');
                      }}
                      icon='moreOptionsIcon'
                    />
                  </Box>
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'checkbox_with_menu') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='flex-end'
                  alignItems='center'
                >
                  {/* <IconButton edge='end'>
                    <ChevronRight style={{ fontSize: 16, color: '#CDD4E4' }} />
                  </IconButton> */}
                  <Box style={column?.menuStyle}>
                    <DropDown
                      data={row.roleName && props.roleNameSelected ? props.roleRowDropdownData : props.rowMenuOptions}
                      clickEvent={(event, key) => {
                        props.handleMenuDropdown(event, key, index, row);
                      }}
                      icon='moreOptionsIcon'
                    />
                  </Box>

                  <Checkbox
                    disableRipple
                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={
                      rowCheckList.includes(index) || columnCheck ? true : false
                    }
                    icon={
                      <CheckBoxOutlineBlank
                        style={{ fontSize: 16, color: '#CDD4E4' }}
                      />
                    }
                    checkedIcon={
                      <CheckBox style={{ fontSize: 16, color: '#CDD4E4' }} />
                    }
                    onClick={(e) => {
                      selectRowCheck(e, index);
                      handleCheckBox(e, row, index);
                    }}
                  />
                </Grid>
              </StyledTableCell>
            );
          } 
          else if (type === 'checkbox_with_menu') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={column.style}
              >
                <Grid
                  container
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                  style={column.style}
                >
                  <Box style={column.menuStyle}>
                    <DropDown
                      data={props.rowMenuOptions}
                      clickEvent={(event, key) => {
                        props.handleMenuDropdown(event, key, index, 'row');
                      }}
                      icon='moreOptionsIcon'
                    />
                  </Box>

                  <Checkbox
                    checked={
                      rowCheckList.includes(index) || columnCheck ? true : false
                    }
                    icon={
                      <CheckBoxOutlineBlank
                        style={{
                          fontSize: 12,
                          width: 18,
                          height: 18,
                          color: 'white',
                          border: '1px solid #CDD4E4',
                        }}
                      />
                    }
                    checkedIcon={
                      <CheckOutlined
                        style={{
                          fontSize: 12,
                          width: 18,
                          height: 18,
                          color: 'black',
                          fontColor: 'black',
                          border: '1px solid #CDD4E4',
                        }}
                      />
                    }
                    onChange={(e) => selectRowCheck(e, index)}
                  />
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'checkbox') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                >
                  <IconButton edge='end'>
                    <ChevronRight style={{ fontSize: 16, color: '#CDD4E4' }} />
                  </IconButton>

                  <Checkbox
                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={
                      rowCheckList.includes(index) || columnCheck ? true : false
                    }
                    icon={
                      <CheckBoxOutlineBlank
                        style={{ fontSize: 16, color: '#CDD4E4' }}
                      />
                    }
                    checkedIcon={
                      <CheckBox style={{ fontSize: 16, color: '#CDD4E4' }} />
                    }
                    onClick={(e) => {
                      selectRowCheck(e, index);
                      handleCheckBox(e, row, index, false);
                    }}
                  />
                </Grid>
              </StyledTableCell>
            );
          } else if ( type === 'radio') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='space-around'
                  alignItems='center'
                >
                  <RadioGroup  
                      name={'tableRadioButton-'+index}
                      value={selectedRowIndex.toString()}
                      onChange={(e) => {
                        onTableRowClick(e, row, index);
                        setSelectedRowIndex(index.toString())
                      }}
                  >
                    <Radio
                      onChange={(e) => {
                        onTableRowClick(e, row, index);
                        setSelectedRowIndex(index.toString())
                      }}
                      checkedIcon={<span className={styledClasses.radioCheckedIcon} />}
                      icon={<span className={styledClasses.radioIcon} />}
                      value={index.toString()}
                      id={"radio-"+index}
                      key={"radio-"+index}
                      name={"radio-button-"+index}
                    />
                  </RadioGroup>
                </Grid>
              </StyledTableCell>
            )
          } else if (type === 'action') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='space-around'
                  alignItems='center'
                >
                  <IconButton>
                    <Check style={{ fontSize: 16, color: '#CDD4E4' }} />
                  </IconButton>

                  <IconButton onClick={() => {}}>
                    <BorderColor style={{ fontSize: 16, color: '#CDD4E4' }} />
                  </IconButton>
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'checkbox_without_icon') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='flex-end'
                  alignItems='center'
                >
                  <Checkbox
                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={
                      rowCheckList.includes(index) || columnCheck ? true : false
                    }
                    icon={
                      <CheckBoxOutlineBlank
                        style={{
                          fontSize: 8,
                          width: 16,
                          height: 16,
                          color: '#fff',
                          border: '1px solid #CDD4E4',
                        }}
                      />
                    }
                    checkedIcon={
                      <CheckOutlined
                        style={{
                          fontSize: 8,
                          width: 16,
                          height: 16,
                          color: '#222',
                          fontColor: '#222',
                          border: '1px solid #CDD4E4',
                        }}
                      />
                    }
                    onClick={(e) => {
                      selectRowCheck(e, index);
                      handleCheckBox(e, row, index);
                    }}
                    checked={value}
                  />
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'text_with_checkbox' && value !== undefined) {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  paddingLeft: 8,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='flex-start'
                  alignItems='center'
                >
                  <Checkbox
                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={true}
                    icon={
                      <CheckBoxOutlineBlank
                        style={{ fontSize: 20, color: '#CDD4E4' }}
                      />
                    }
                    checkedIcon={
                      <CheckBox style={{ fontSize: 20, color: '#CDD4E4' }} />
                    }
                    // onChange={onSelectAllClick}
                  />
                  {value}
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'more') {
            let options = [];
            if (props.rowMenuOptions) {
              options = [...props.rowMenuOptions];
            }
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='center'
                  alignItems='center'
                >
                  <Box style={column.menuStyle}>
                    <DropDown
                      data={options}
                      clickEvent={(event, key) => {
                        props.handleMenuDropdown(event, key, index, row);
                      }}
                      icon='moreOptionsIcon'
                    />
                  </Box>
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'dynamic_more_wo_header') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='center'
                  alignItems='center'
                >
                  <Box style={column.menuStyle}>
                    <DropDown
                      data={props.rowMenuOptions(row)}
                      clickEvent={(event, key) => {
                        props.handleMenuDropdown(event, key, index, row);
                      }}
                      icon='moreOptionsIcon'
                    />
                  </Box>
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'checkbox-only') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                justify={column.justify}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                >
                  <Checkbox
                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={row[column.id]}
                    disabled={column.disabled}
                    icon={
                      <CheckBoxOutlineBlank
                        style={{ fontSize: 16, color: '#CDD4E4' }}
                      />
                    }
                    checkedIcon={
                      <CheckBox style={{ fontSize: 16, color: '#CDD4E4' }} />
                    }
                    onClick={(e) => selectRowCheck(e, index)}
                  />
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'icon') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                >
                  <IconButton edge='end' onClick={() => handleIconClick(row, index)}>
                    { ['confirmed', 'completed'].includes(value?.toLowerCase()) && (
                      <CheckCircleOutlined style={{ fontSize: 24, color: '#3bab52' }} />
                    )}
                    {value?.toLowerCase() === 'need_confirmation' && (
                      <InfoOutlined style={{ fontSize: 24, color: '#3bab52' }} />
                    )}
                    { ['cancelled', 'dropped'].includes(value?.toLowerCase()) && (
                      <CancelOutlined style={{ fontSize: 24, color: '#db252e' }} />
                    )}
                    { ['approved'].includes(value?.toLowerCase()) && (
                      <ThumbUpOutlined style={{ fontSize: 24, color: '#3bab52' }} />
                    )}
                    { ['rejected'].includes(value?.toLowerCase()) && (
                      <ThumbDownOutlined style={{ fontSize: 24, color: '#db252e' }} />
                    )}
                    {['pledge_received'].includes(value?.toLowerCase()) && (
                      <DoneAllRounded style={{ fontSize: 24, color: '#3bab52' }} />
                    )}
                    {value?.toLowerCase() === 'pending' && 
                      <AccessTimeOutlined color="primary" style = {{ fontSize: 24 }} />                      
                    }
                    {value?.toLowerCase() === 'loading' && (
                      <CustomizedCircularProgress size={24} />
                    )}
                    {value?.toLowerCase() === 'edit' && (
                      <BorderColorOutlined
                        style={{ fontSize: 18, color: '#0062ff' }}
                      />
                    )}
                  </IconButton>
                </Grid>
              </StyledTableCell>
            );
          } else if (type === 'text_input') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.style.minWidth,
                  maxWidth: column.style.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                >
                  <TableRowInput 
                    inputType = {column.inputType ? column.inputType : 'text'}
                    value={value}
                    onChange={(e) => handleRowInputChange(e, row, index, column)}
                    placeholder={placeholder}
                    style={inputStyle}
                    disabled = { column.disabled ? true : false }
                    inputProps={{ maxLength: column.maxLength || 200 }}
                  />
                </Grid>
              </StyledTableCell>
            );
          }else if (type === 'button') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.style.minWidth,
                  maxWidth: column.style.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='space-around'
                  alignItems='center'
                >
                  <Button
                    variant="secondary" 
                    // color="secondary" 
                    onClick={() => handleCallToAction(row, index) }
                    style = {{
                      minWidth: '120px', 
                      justifyContent: 'center'
                    }}
                    text = {value}
                  />
                </Grid>
              </StyledTableCell>
            );
          }else if (type === 'row_dropdown') {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.style.minWidth,
                  maxWidth: column.style.maxWidth,
                  ...style,
                }}
              >
                <Grid
                  container
                  direction='row'
                  justify='space-around'
                  alignItems='center'
                >
                  <Select
                    handleChange={(e,row) => handleRowDropdown(e, row, index)}
                    options={column?.options}
                    isRowSelect = {true}
                    labelKey={''}
                    valueKey={''}
                    placeHolder={''}
                    disabled={column?.disabled}
                    column={column}
                    value={value}
                  ></Select>
                </Grid>
              </StyledTableCell>
            );
          } else {
            return (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  display: 'block !important',
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  paddingLeft: 8,
                  ...style,
                }}
              />
            );
          }
        })}
      </StyledTableRow>
    );
  };

  return (
    <Box>
      <TableContainer
        className={`${classes?.container} no-scrollbar`}
        style={{ overflowY: 'auto', borderRadius: '8px' }}
        ref={tableRef}
      >
        <Grid
          component='div'
          style={tableHeight == 'auto' ? {} : { height: tableHeight }}
        >
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>{tableHeader()}</TableHead>
            <TableBody style={{ overflowY: 'scroll' }}>
              {tableData &&
                tableData.map((row, index) => {
                  return tableBody(row, index);
                })}
            </TableBody>
          </Table>

          {loading && (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '16px 0',
              }}
            >
              <CustomizedCircularProgress />
            </Box>
          )}

          {!loading && tableData && tableData.length === 0 && (
            <Box
              className={classes?.emptyListTxtMsg}
              style={{ textAlign: 'center', margin: '24px 0px' }}
            >
              <label>{emptyText ? emptyText : 'No Records Found'}</label>
            </Box>
          )}
        </Grid>
      </TableContainer>
    </Box>
  );
}