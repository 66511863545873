import React from 'react';
import { ErrorOutline } from '@material-ui/icons';
import { Box, Typography } from '@material-ui/core';
import { ModalDialog, Button } from "../../components";
import useStyles from './style';

export default function Confirmation(props) {
    const classes = useStyles();
    const { 
        type = 'warning',
        closeText = "No",
        submitText = "Yes",
        titleText = "Confirm",
        content = "Are you sure to Logout ?",
        onSubmit = () => null,
        onClose = () => null,
    } = props;


    const footer = () => {
        return (
            <>
                <Button
                    text = {closeText}
                    variant = "Secondary"
                    style = {{ padding: '8px 32px' }}
                    onClick = {onClose}
                />
                <Button
                    text = {submitText}
                    variant = {
                        type === "error" ?
                            type : "primary"
                    }
                    style = {{ padding: '8px 32px' }}
                    onClick = {onSubmit}
                />
            </>
        )
    }

    const dialogTitle = () => {
        return (
            <Box className={classes.modalTitleContent} >
                <ErrorOutline 
                    className={`${classes.icon} ${classes[type]}`}
                />
                <Typography variant = 'h5' >
                    {titleText}
                </Typography>
            </Box>
        )
    }

    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' 
                        className={classes.modalContent}
                    >
                        {content}
                    </Typography>
                </Box>
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {true}
            maxWidth = {'sm'}
            onClose = {onClose}
            dialogFooter = {footer()}
            dialogTitle = {dialogTitle()}
            dialogContent = {dialogContent()}
            classes = {{ paper: classes.dialogPaper }}
        />
    )

}