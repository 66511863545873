import expenseConstants from '../constants/expense';
import { expenseService } from '../services';
import notyf from '../utils/notification';

export const expenseActions = {
    get,
    add,
    update,
    cancel,
    getTotalRecords
}


function  add(payload){
    return dispatch =>{
        dispatch(request());
        expenseService.add(payload).then(
            value =>{ dispatch(success(value)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: expenseConstants.ADD_REQUEST };
    }
    function success(value){
        notyf.open({
            type: 'success',
            message: 'Expense successfully sent to Admin for Approval.'
        })
        return {
            type: expenseConstants.ADD_SUCCESS,
            payload: value
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: expenseConstants.ADD_ERROR,
            payload : error
        };
    }
}

function  get(offset = 0, limit = 10, filter = {}){
    return dispatch =>{
        dispatch(request());
        expenseService.get(offset, limit, filter).then(
            value =>{ dispatch(success(value)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: expenseConstants.GET_ALL_REQUEST };
    }
    function success(value){
        return {
            type: expenseConstants.GET_ALL_SUCCESS,
            payload: value
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: expenseConstants.GET_ALL_ERROR,
            payload : error
        };
    }
}

function update(payload){
    return dispatch =>{
        dispatch(request());
        expenseService.update(payload).then(
            value =>{ dispatch(success(value)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: expenseConstants.UPDATE_REQUEST };
    }
    function success(value){
        notyf.open({
            type: 'success',
            message: 'Updated Successfully.'
        })
        return {
            type: expenseConstants.UPDATE_SUCCESS,
            payload: value
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: expenseConstants.UPDATE_ERROR,
            payload : error
        };
    }
}

function cancel(payload){
    return dispatch =>{
        dispatch(request());
        expenseService.cancel(payload).then(
            value =>{ dispatch(success(value)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: expenseConstants.CANCEL_REQUEST };
    }
    function success(value){
        notyf.open({
            type: 'success',
            message: 'Cancelled Successfully.'
        })
        return {
            type: expenseConstants.CANCEL_SUCCESS,
            payload: value
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: expenseConstants.CANCEL_ERROR,
            payload : error
        };
    }
}


function getTotalRecords(filter = {}){
    return dispatch =>{
        dispatch(request());
        expenseService.getTotalRecords(filter).then(
            value =>{ dispatch(success(value)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: expenseConstants.RECORDS_REQUEST };
    }
    function success(value){
        return {
            type: expenseConstants.RECORDS_SUCCESS,
            payload: value
        };
    }
    function failure(error){
        return {
            type: expenseConstants.RECORDS_ERROR,
            payload : error
        };
    }
}