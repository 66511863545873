import React, { useEffect, useState } from 'react';
import { Button, Navbar, Table } from "../../components";
import ConfirmFunds from './components/confirm-funds';
import CancelRequest from './components/cancel-funds';
import FundDetails from './components/fund-details';
import NewRequest from './components/new-request';
import notyf from '../../utils/notification';
import { utils } from '../../utils';
import useStyles from './style';
import { 
    Add, KeyboardArrowDown, 
    AutorenewOutlined,
    ClearAllOutlined
} from '@material-ui/icons';
import {
    Box, Paper, Typography,
    TablePagination
} from '@material-ui/core';
import { 
    fundRequestConstants as frc 
} from '../../constants';

let tableHeaders = [
    {
        id: "approved_branch_name",
        placeHolder: "To Branch",
        label : "To Branch",
        type: 'dropdown',
        minWidth: '120px',
        maxWidth: '120px',
        dropdownItems: [],
        style : {}
    },
    {
        id: "request_amount",
        label : "Request Amount",
        type: 'text_wo_input',
        minWidth: '120px',
        maxWidth: '120px',
        style : {}
    },
    {
        id: "approved_amount",
        label : "Approved Amount",
        type: 'text_wo_input',
        minWidth: '120px',
        maxWidth: '120px',
        style : {}
    },
    {
        id: "request_transaction_type",
        placeHolder: "Requested Type",
        label : "Requested Type",
        type: 'dropdown',
        minWidth: '100px',
        maxWidth: '100px',
        dropdownItems: [
            {label: "CASH", value: "CASH"},
            {label: "RTGS", value: "RTGS"},
        ],
        style : {}
    },
    {
        id: "approved_transaction_type",
        placeHolder: "Approved Type",
        label : "Approved Type",
        type: 'dropdown',
        minWidth: '100px',
        maxWidth: '100px',
        dropdownItems: [
            {label: "CASH", value: "CASH"},
            {label: "RTGS", value: "RTGS"},
        ],
        style : {}
    },
    {
        id: "request_on",
        label : "Raised at",
        type: 'text',
        inputType: 'date',
        minWidth: '150px',
        maxWidth: '150px',
        style : {}
    },
    {
        id: 'status',
        label: 'Status',
        type: 'dropdown',
        placeHolder: "Status",
        dropdownItems: [
            {label: "Pending", value: "PENDING"},
            {label: "Approved", value: "APPROVED"},
            {label: "Rejected", value: "REJECTED"},
            {label: "Need Confirmation", value: "NEED_CONFIRMATION"},
            {label: "Confirmed", value: "CONFIRMED"},
            {label: "Cancelled", value: "CANCELLED"},
        ],
        minWidth: '120px',
        maxWidth: '120px',
    },
    {
        id: 'status',
        label: '',
        type: 'icon',
        minWidth: '50px',
        maxWidth: '50px'
    },
    {
        id: 'more',
        label: '',
        type: 'more',
        minWidth: '50px',
        maxWidth: '50px'
    }
];

let paginationDropDownList = [
    10, 20, 40
]

let rowMenuOptions = [
    {key: 'details', title: 'Details'},
    {key: 'action' , title: 'Action'}
];

let defaultQuery = {
    approved_transaction_type: '',
    request_transaction_type: '',
    approved_branch_id : '',
    request_on: '',
    status: ''
}

export default function FundRequestLayout(props){

    let payl = {
        "requested_amount": 0,
        "branch_id" : 0,
        "transaction_type": "CASH",
        "description": ""
    }

    const classes = useStyles();
    const [filterQuery, setFilterQuery] = useState({ ...defaultQuery });
    const [clearInputFields, setClearInputFields] = useState(false);
    const [tableColumns, setTableColumns] = useState(tableHeaders);
    const [showNewRequest, setNewRequest] = useState(false);
    const [showFundDetails, setShowFundDetails] = useState(
        {flag: false, value: {}});
    const [showConfirmAction, setShowConfirmAction] = useState(
        {flag: false, details: {}});
    const [showCancelAction, setShowCancelAction] = useState(
        {flag: false, details: {}});
        const [payload, setPayload] = useState({ ...payl });
    const [tableData, setTableData] = useState([]);
    const [error, setError] = useState(false);
    const {
        handleCancelFundsAction = () => null,
        handlePendingActionSubmit = () => null,
        handleConfirmFundsAction = () => null,
        getDetails = () => null,
        setLimit = () => null,
        setPage = () => null,
        fundDetails = [],
        branches = [],
        noOfRecords = 0,
        basic = {},
        status = '',
        limit = 10,
        page = 0
    } = props;

    useEffect(() => {
        if(status === 'GETALL_SUCCESS'){
            let td = [];
            if(fundDetails.length > 0){
                td = fundDetails.map(e => {
                    return { ...e, 
                        request_on: utils.getLocalTime(e.request_on || ""),
                        approved_on: utils.getLocalTime(e.approved_on || "")
                    }
                });
            }
            setTableData([ ...td ]);
        }
        if(status === 'POST_FUND_SUCCESS'){
            setNewRequest(false);
            setPayload({ ...payl });
        }
        if(status === 'C_ACTION_SUCCESS'){
            setClearInputFields(true);
            setShowConfirmAction({flag: false, details: {}});
        }
        if(status === 'CANCEL_FR_SUCCESS'){
            setClearInputFields(true);
            setShowCancelAction({flag: false, details: {}});
        }
    },[status]);

    useEffect(() => {
        let thc = [ ...tableHeaders ];
        if(branches.length > 0){
            thc = tableHeaders.map(e => {
                if(e.id === 'approved_branch_name') {
                    return {
                        ...e,
                        dropdownItems: branches.map(
                            f => { return { 
                                label: f.branch_name, 
                                value: f.id 
                            } }
                        )
                    }
                }else return e;
            });
        }
        setTableColumns(thc);
    }, [branches]);

    useEffect(() => {
        if(clearInputFields){
            setClearInputFields(false);
            setFilterQuery(defaultQuery);
        }
    }, [clearInputFields]);

    const handleSubmit = () => {
        if(!payload.requested_amount){
            setError(true);
        }else if(!payload.branch_id){
            setError(true);
        }else {
            setError(false);
            props.handleSubmit(payload);
        }
    }

    const handleChange = (key, value) => {
        setError(false)
        setPayload((prev) => ({
                ...prev,
                [key] : value
        }))
    }


    const handleMenuDropdown = (e, k, i, r) => {
        if(k.key === 'details'){
            setShowFundDetails({flag: true, value: r});
        }else if(k.key === 'action'){
            if(r.status?.toLowerCase() === 'pending'){
                setShowCancelAction({flag: true, details: r});
            }else if(r.status?.toLowerCase() === 'need_confirmation'){
                setShowConfirmAction({flag: true, details: r});
            }else
                notyf.open({ type: 'error', message: frc.invalidAction });
        }
    }

    const onRowClick = (e, r, i) => {
        // setShowFundDetails({flag: true, value: r});
    }

    const onChangeRowsPerPage = (e) => {
        setLimit(e.target.value);
        setPage(0);
        getDetails(0, e.target.value, filterQuery);
    }
    
    const onChangePage = (e, val) => {
        setPage(val);
        getDetails(val, limit, filterQuery);
    }

    const handleFilterChange = (val, col) => {
        let fq = { ...filterQuery }
        if(col.id === 'approved_branch_name')
            fq = { ...fq, approved_branch_id : val || 0 };
        else
            fq = { ...fq, [col.id] : val };

        setPage(0);
        setFilterQuery(fq);
        getDetails(0, limit, fq);
    }

    const resetFilter = () => {
        setPage(0);
        setClearInputFields(true);
        getDetails(0, limit, defaultQuery);
    }

    const getFormattedData = (data = []) => {
        if(data.length === 0)
            return data;
        return data.map(e => {
            return {
                ...e,
                approved_amount: e.approved_amount ? 
                    utils.getFormattedAmount(e.approved_amount) : "",
                request_amount : e.request_amount ? 
                    utils.getFormattedAmount(e.request_amount) : ""
            }
        })
    }

    return (
        <Navbar 
            { ...props }
        >
            <Box className={classes.mainBox}>
                <Box className = {classes.tableTitleBox}>
                    <Typography variant = "h5">
                        Fund Request - Out
                    </Typography>
                    <Box className={classes.actionButtons}>
                        <Button
                           text = "Clear Filter"
                            variant = "secondary"
                            Icon = {ClearAllOutlined}
                            style = {{
                                padding: '8px 16px', 
                                marginRight: '16px' 
                            }}
                            onClick = {resetFilter}
                        />
                        <Button
                            text = "Refresh"
                            variant = "secondary"
                            Icon = {AutorenewOutlined}
                            style = {{
                                padding: '8px 16px', 
                                marginRight: '16px' 
                            }}
                            onClick = {() => getDetails(page, limit, filterQuery)}
                        />
                        <Button
                            text = "New request"
                            variant = "primary"
                            Icon = {Add}
                            style = {{padding: '8px 16px'}}
                            onClick = {() => setNewRequest(true) }
                        />
                    </Box>
                </Box>
            </Box>
            <Paper className = {classes.tableBox}>
                <Table
                    handleIconClick = {(r, i) => 
                        handleMenuDropdown({}, {key: 'action'}, i, r )}
                    tableData = {getFormattedData(tableData)}
                    handleMenuDropdown = {handleMenuDropdown}
                    loading = {status === 'GETALL_REQUEST'}
                    handleChange = {handleFilterChange}
                    rowMenuOptions = {rowMenuOptions}
                    onTableRowClick = {onRowClick}
                    tableColumns = {tableColumns}
                />
                 <TablePagination
                    rowsPerPageOptions={paginationDropDownList}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    onChangePage={onChangePage}
                    count={noOfRecords}
                    rowsPerPage={limit}
                    component='div'
                    page={page}
                    SelectProps={{
                        IconComponent: KeyboardArrowDown,
                        classes: { icon: classes.selectIcon },
                    }}  
                />  
            </Paper>
            {
                showNewRequest &&
                <NewRequest
                    current_branch = {basic?.branch_id}
                    showNewRequest = {showNewRequest}
                    setNewRequest = {setNewRequest}
                    handleChange = {handleChange}
                    handleSubmit = {handleSubmit}
                    setPayload = {setPayload}
                    setError = {setError}
                    branches = {branches}
                    payload = {payload}
                    error = {error}
                    status = {status}
                />
            }
            {
                showFundDetails &&
                <FundDetails
                    showDetails = {showFundDetails.flag}
                    detail = {showFundDetails.value}
                    setDetails = {setShowFundDetails}
                    status = {status}
                />
            }
            {
                showConfirmAction.flag &&
                <ConfirmFunds
                    status = {status}
                    showDetails = {showConfirmAction.flag}
                    details = {showConfirmAction.details}
                    handleSubmit={(p) => handleConfirmFundsAction(p)}
                    setDetails = {() => setShowConfirmAction({flag: false, details: {}})}
                />
            }
            {
                showCancelAction.flag &&
                <CancelRequest
                    status = {status}
                    showDetails = {showCancelAction.flag}
                    details = {showCancelAction.details}
                    handleSubmit={(p) => handleCancelFundsAction(p)}
                    setDetails = {() => setShowCancelAction({flag: false, details: {}})}
                />
            }
        </Navbar>
    );
}