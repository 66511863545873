import React, { useState } from 'react';
import { ModalDialog, Button } from "../../../components";
import useStyles from '../style';
import {
    Box, Typography,
    FormControl, Grid,
    OutlinedInput,
} from '@material-ui/core';

let payl = {
    "asset_id": [],
};


export default function ConfirmSold(props) {
    const classes = useStyles();
    const [payload, setPayload] = useState(payl);
    const {
        status = '',
        details = [],
        show = false,
        setShow = () => null
    } = props;



    const handleSubmit = () => {
        props.handleSubmit({
            asset_id: [ ...details ]
        });
    }

    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        Are you sure ?
                    </Typography>
                    <Typography variant = 'p' className = {`${classes.subTitle}`}>
                        Assets selected as sold : { " " + details.length } 
                    </Typography>
                </Box>
                {
                    <Button
                        text = "Mark as Sold"
                        variant = "primary"
                        style = {{
                            padding: '8px 16px',
                            margin: '40px 0px', 
                            width: '280px' 
                        }}
                        loading = {status === 'POST_REQUEST'}
                        onClick = {() => handleSubmit() }
                    />
                }
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {show}
            onClose = {() => setShow(false)}
            dialogTitle = ''
            dialogContent = {dialogContent()}
            maxWidth = {'sm'}
            classes = {{ paper: classes.dialogPaper}}
            dialogFooter = {[]}
        />
    )

}