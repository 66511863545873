import React, { useState, useEffect } from 'react';
import { Add, AutorenewOutlined, ClearAllOutlined, KeyboardArrowDown } from '@material-ui/icons';
import { Box, Paper, Typography, TablePagination } from '@material-ui/core';
import ExpenseDetails from './components/details-expense';
import { Button, Navbar, Table } from "../../components";
import UpdateExpense from './components/update-expense';
import CancelExpense from './components/cancel-expense';
import AddExpense from './components/add-expense';
import notyf from '../../utils/notification';
import payload from './payload.json';
import { utils } from '../../utils';
import useStyles from './style';

let tableHeaders = [
    {
        "id": "expense_type",
        "label": "Expense Type",
        "type": "text_wo_input",
        "minWidth":  "200px",
        "maxWidth":  "200px",
        "style" : {}
    },
    {
        "id": "expense_amount",
        "label": "Amount (INR)",
        "type": "text_wo_input",
        "minWidth":  "120px",
        "minWidth":  "120px",
        "style" : {}
    },
    {
        "id": "requested_branch_name",
        "label": "Requested Branch",
        "type": "text_wo_input",
        "minWidth":  "100px",
        "maxWidth":  "100px",
        "style" : {}
    },
    {
        "placeHolder": "Approved Branch",
        "id": "approved_branch_name",
        "label": "Approved Branch",
        "type": "dropdown",
        "dropdownItems": [],
        "minWidth":  "100px",
        "maxWidth":  "100px",
        "style" : {}
    },
    {
        "id": "expense_req_placed_on",
        "label": "Requested on",
        "type": "text",
        "inputType" : "date",
        "minWidth":  "150px",
        "maxWidth":  "150px",
        "style" : {}
    },
    {
        id: 'status',
        label: 'Status',
        type: 'dropdown',
        placeHolder: "Status",
        dropdownItems: [
            {label: "Pending", value: "PENDING"},
            {label: "Approved", value: "APPROVED"},
            {label: "Rejected", value: "REJECTED"},
            {label: "Cancelled", value: "CANCELLED"},
        ],
        "minWidth":  "150px",
        "style" : {}
    },
    {
        "id": "status",
        "label": "",
        "type": "icon",
        "minWidth":  "50px",
        "maxWidth":  "50px",
        "style" : {}
    },
    {
        "id": "",
        "label": "",
        "type": "more",
        "minWidth":  "50px",
        "style" : {}
    }
];


let paginationDropDownList = [ 10, 20, 40 ];

let rowMenuOptions = [
    {key: 'details' , title: 'Details'},
    {key: 'action', title: 'Action'}
];

let defaultQuery = {
    requested_branch_name : 0,
    approved_branch_name : 0,
    expense_req_placed_on: '',
    status: ''
}

export default function ExpenseLayout(props){
    const classes = useStyles();
    const [showDetails, setShowDetails] = useState({flag: false, details: {}});
    const [filterQuery, setFilterQuery] = useState({ ...defaultQuery });
    const [tableColumns, setTableColumns] = useState(tableHeaders);
    const {
        page = 0,
        limit = 10,
        status = '',
        details = [],
        branches = [],
        showAdd = false,
        addPayload = {},
        noOfRecords = 0,
        cancelPayload = {},
        actionPayload = {},
        setPage = () => null,
        setLimit = () => null,
        getDetails = () => null,
        updateState = () => null,
        clearInputFields = false,
        handleAddSubmit = () => null,
        handleFileUpload = () => null,
        handleUpdateSubmit = () => null,
        handleCancelSubmit = () => null,
        setClearInputFields = () => null,
        showUpdate = {flag: false, id: ''},
        showCancel = {flag: false, id: ''},
    } = props;

    let role = utils.getRole();

    useEffect(() => {
        if(clearInputFields){
            setClearInputFields(false);
            setFilterQuery(defaultQuery);
        }
    }, [clearInputFields]);

    const getBranchDropdown = (branches = []) => {
        return branches.map(
            f => { return { 
                label: f.branch_name, 
                value: f.id 
            } }
        )
    }

    useEffect(() => {
        let thc = tableColumns;
        thc = thc.map(e => {
            if(e.id === 'requested_branch_name'){
                if(role.includes('ADMIN')){
                    let d = { 
                        ...e, type: 'dropdown',
                        placeHolder: "From Branch", 
                        dropdownItems: [],
                    };
                    if(branches.length > 0)
                        d = { ...d, dropdownItems: getBranchDropdown(branches) }
                    
                    return d;
                }else return e;
            } else if( e.id === 'approved_branch_name' && branches.length > 0 )
                    return { ...e, dropdownItems: getBranchDropdown(branches) }
            else return e;
        })
        setTableColumns(thc);
    }, [role, branches]);

    const handleAddChange = (name, value) => {
        updateState({ 
            addPayload: {
                ...addPayload,
                [name] : value
            }
        });
    }

    const handleFileChange = (file, name) => {
        handleFileUpload(file, name);
        updateState({
            addPayload: {
                ...addPayload,
                [name]: 'uploading'
            }
        })
    }

    const handleUpdateChange = (name, value) => {
        updateState({
            actionPayload: {
                ...actionPayload,
                [name] : value
            }
        });
    }

    const handleCancelChange = (name, value) => {
        updateState({
            cancelPayload: {
                ...cancelPayload,
                [name] : value
            }
        })
    }

    const handleMenuDropdown = (e, k, i, row) => {
        if(k.key === 'action' && row.status === 'PENDING' && role.includes('ADMIN'))
            updateState({ showUpdate: { flag: true, id: row } });
        else if(k.key === 'action' && row.status === 'PENDING' && role.includes('BRANCH'))
            updateState({ showCancel: { flag: true, id: row } });
        else if(k.key === 'details')
            setShowDetails({flag: true, details: row });
        else
            notyf.open({type: 'error', message: 'Action not allowed !'})
    }

    const onClose = () => {
        updateState({
            addPayload : { ...payload.expensePayload },
            actionPayload : { ...payload.updPayload },
            showUpdate: {flag: false, id: {}},
            showCancel: {flag: false, id: {}},
            showAdd: false,
            error : false
        })
    }

    const getFormattedData = (details = []) => {
        return details.map(e => {
            return {
                ...e,
                expense_req_placed_on: utils.getLocalTime(
                    e.expense_req_placed_on ) || "",
                expense_amount: e.expense_amount ? 
                    utils.getFormattedAmount(e.expense_amount) : "",
                
            }
        })
    }

    const onChangeRowsPerPage = (e) => {
        setPage(0);
        setLimit(e.target.value);
        getDetails(0, e.target.value, filterQuery);
    }
    
    const onChangePage = (e, val) => {
        setPage(val);
        getDetails(val, limit, filterQuery);
    }

    const handleFilterChange = (val, col) => {
        let fq = { ...filterQuery }
        fq = { ...fq, [col.id] : val };
        setPage(0);
        setFilterQuery(fq);
        getDetails(0, limit, fq);
    }

    const resetFilter = () => {
        setPage(0);
        setClearInputFields(true);
        getDetails(0, limit, defaultQuery);
    }

    return (
        <Navbar 
            { ...props }
        >
            <Box className={classes.mainBox}>
                <Box className = {classes.tableTitleBox}>
                    <Typography variant = "h5">
                        Expenses
                    </Typography>
                    <Box className={classes.actionButtons}>
                        <Button
                           text = "Clear Filter"
                            variant = "secondary"
                            Icon = {ClearAllOutlined}
                            style = {{
                                padding: '8px 16px', 
                                marginRight: '16px' 
                            }}
                            onClick = {resetFilter}
                        />
                        <Button
                           text = "Refresh"
                            variant = "secondary"
                            Icon = {AutorenewOutlined}
                            style = {{
                                padding: '8px 16px', 
                                marginRight: '16px' 
                            }}
                            onClick = {() => getDetails(page, limit, filterQuery)}
                        />
                        <Button
                            text = "Add new"
                            variant = "primary"
                            Icon = {Add}
                            style = {{padding: '8px 16px'}}
                            onClick = {() => updateState({ showAdd: true})}
                        />
                    </Box>
                </Box>
            </Box>
            <Paper className = {classes.tableBox}>
                <Table
                    handleIconClick = {(r, i) => 
                        handleMenuDropdown({}, {key: 'action'}, i, r )}
                    handleMenuDropdown = { handleMenuDropdown }
                    loading = {status === 'GET_ALL_REQUEST'}
                    tableData = {getFormattedData(details)}
                    clearInputFields = { clearInputFields }
                    rowMenuOptions = { rowMenuOptions }
                    handleChange = {handleFilterChange}
                    tableColumns = {tableColumns}
                />
                <TablePagination
                    rowsPerPageOptions={paginationDropDownList}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    onChangePage={onChangePage}
                    count={noOfRecords}
                    rowsPerPage={limit}
                    component='div'
                    page={page}
                    SelectProps={{
                        IconComponent: KeyboardArrowDown,
                        classes: { icon: classes.selectIcon },
                    }}                    
                /> 
            </Paper>
            {
                showAdd &&
                <AddExpense 
                    status = {status}
                    showAdd = {showAdd}
                    setShowAdd = {onClose}
                    payload = {addPayload}
                    handleChange = {handleAddChange}
                    handleSubmit = {handleAddSubmit}
                    handleFileChange = {handleFileChange}
                />
            }
            {
                showUpdate.flag &&
                <UpdateExpense 
                    status = {status}
                    setShow = {onClose}
                    show = {showUpdate.flag}
                    details = {showUpdate.id}
                    payload = {actionPayload}
                    handleChange = {handleUpdateChange}
                    handleSubmit = {handleUpdateSubmit}
                />
            }
            {
                showCancel.flag &&
                <CancelExpense 
                    status = {status}
                    setShow = {onClose}
                    show = {showCancel.flag}
                    details = {showCancel.id}
                    payload = {cancelPayload}
                    handleChange = {handleCancelChange}
                    handleSubmit = {handleCancelSubmit} 
                />
            }
            {
                showDetails.flag &&
                <ExpenseDetails
                    show = {showDetails.flag}
                    details = {showDetails.details}
                    setShow = {() => setShowDetails({flag: false, details: {}})}
                />
            }
        </Navbar>
    );
}