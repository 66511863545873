import React, { useEffect, useState } from 'react';
import { Button, Navbar, Table } from "../../components";
import notyf from '../../utils/notification';
import PendingAction from './pending-action';
import InvoiceAction from './invoice-action';
import ReleaseAction from './release-action';
import ConfirmOrder from './confirm-order';
import CancelOrder from './cancel-order';
import { utils } from '../../utils';
import useStyles from './style';
import { 
    Typography, Paper,
    Box, TablePagination
} from '@material-ui/core';
import { 
    Add, AutorenewOutlined, 
    KeyboardArrowDown, 
    ClearAllOutlined 
} from '@material-ui/icons';

let tableHeaders = [
    {
        id: "customer_name",
        label : "Name",
        type: 'text_wo_input',
        minWidth: '100px',
        maxWidth: '100px',
        style : {}
    },
    {
        id: "customer_mobile_number",
        label : "Mobile",
        type: 'text_wo_input',
        minWidth: '100px',
        maxWidth: '100px',
        style : {}
    },
    {
        id: "total_weight",
        label : "Net Weight (g)",
        type: 'text_wo_input',
        minWidth: '60px',
        maxWidth: '60px',
        style : {}
    },
    {
        id: "requested_amount",
        label : "Request Amt (INR)",
        type: 'text_wo_input',
        minWidth: '80px',
        maxWidth: '80px',
        style : {}
    },
    {
        id: "approved_amount",
        label : "Approved Amt (INR)",
        type: 'text_wo_input',
        minWidth: '80px',
        maxWidth: '80px',
        style : {}
    },
    {
        id: "order_type",
        label : "Type",
        type: 'text_wo_input',
        minWidth: '80px',
        maxWidth: '80px',
        style : {}
    },
    {
        id: "requested_branch",
        placeHolder: "Branch",
        label : "Branch",
        type: 'dropdown',
        minWidth: '100px',
        maxWidth: '100px',
        dropdownItems: [],
        style : {}
    },
    {
        id: "order_date",
        label : "Raised at",
        type: 'text',
        inputType: 'date',
        minWidth: '150px',
        maxWidth: '150px',
        style : {}
    },
    {
        id: 'order_status',
        label: 'Status',
        type: 'dropdown',
        placeHolder: "Status",
        dropdownItems: [
            {"label": "Pending", "value": "PENDING"},
            {"label": "Approved", "value": "APPROVED"},
            {"label": "Rejected", "value": "REJECTED"},
            {"label": "Completed", "value": "COMPLETED"},
            {"label": "Dropped", "value": "DROPPED"},
            {"label": "Cancelled", "value": "CANCELLED"},
            {"label": "Pledge Received", "value": "PLEDGE_RECEIVED"}

        ],
        minWidth: '120px',
        maxWidth: '120px',
    },
    {
        id: 'order_status',
        label: '',
        type: 'icon',
        minWidth: '50px',
        maxWidth: '50px'
    },
    {
        id: 'more',
        label: '',
        type: 'more',
        minWidth: '30px',
        maxWidth: '30px'
    }
];

let paginationDropDownList = [
    10, 20, 40
]

let rowMenuOptions = [
    {key: 'details', title: 'Details'},
    {key: 'action' , title: 'Action'}
];

let defaultQuery = {
    order_date: '',
    order_status: '',
    requested_branch: '',
}

export default function OrdersHistoryLayout(props){

    const classes = useStyles();
    const [filterQuery, setFilterQuery] = useState({ ...defaultQuery });
    const [clearInputFields, setClearInputFields] = useState(false);
    const [tableColumns, setTableColumns] = useState(tableHeaders);
    const [showInvoiceAction, setShowInvoiceAction] = useState(
        {flag: false, obj: {} });
    const [showConfirmOrder, setShowConfirmOrder] = useState(
        {flag: false, details: {}});
    const [showRelease, setShowRelease] = useState(
        {flag: false, details: {}});
    const [showDropOrder, setShowDropOrder] = useState(
        {flag: false, details: {}});
    const [showCancel, setShowCancel] = useState(
        { flag: false, details: {}});
    const [showPending, setShowPending] = useState(
            { flag: false, details: {}});
    const [tableData, setTableData] = useState([]);
    const {
        handleReleaseActionSubmit = () => null,
        handleCancelOrderSubmit = () => null,
        handleConfirmOrderSubmit = () => null,
        handleDropOrderSubmit = () => null,
        getDetails = () => null,
        setLimit = () => null,
        setPage = () => null,
        orderDetails = [],
        noOfRecords = 0,
        branches = [],
        status = '',
        limit = 10,
        page = 0,
        history
    } = props;

    let role = utils.getRole() || "";

    useEffect(() => {
        if(status === 'GETALL_SUCCESS'){
            let od = []
            if(orderDetails.length > 0){
                od = orderDetails.map(e => {
                    return { ...e,
                        order_date: utils.getLocalTime(e.order_date)
                }});
            }
            setTableData(od);
        }
        if(status === 'CONFIRM_SUCCESS'){
            setShowConfirmOrder({flag: false, details: {}});
            setShowPending({flag: false, details: {}});
            setClearInputFields(true);
        }
        if(status === 'CANCEL_SUCCESS'){
            setShowCancel({flag: false, details: {}});
            setClearInputFields(true);
        }
        if(status === 'DROP_SUCCESS'){
            setShowDropOrder({flag: false, details: {}});
            setShowPending({flag: false, details: {}});
            setClearInputFields(true);
        }
        if(status === 'RELEASE_SUCCESS'){
            setShowRelease({flag: false, details: {}});
            setClearInputFields(true);
        }
    },[status]);

    useEffect(() => {
        let thc = [ ...tableHeaders ];
        if(branches.length > 0){
            thc = tableHeaders.map(e => {
                if(e.id === 'requested_branch') {
                    return {
                        ...e,
                        dropdownItems: branches.map(
                            f => { return { 
                                label: f.branch_name, 
                                value: f.id 
                            } }
                        )
                    }
                }else return e;
            });
        }
        setTableColumns(thc);
    }, [branches]);

    useEffect(() => {
        if(clearInputFields){
            setClearInputFields(false);
            setFilterQuery(defaultQuery);
        }
    }, [clearInputFields]);

    const onRowClick = (e, r, i) => {
        // history.push(`/order/${r.id}`);
    }

    const handlePendingAction = (action, detail) => {
        if(action === 'CONFIRM') 
            setShowConfirmOrder({flag: true, details: detail});
        else if(action === 'DROP')
            setShowDropOrder({flag: true, details: detail});
        else
            setShowPending({flag: false, details: {}})
    }

    const handleMenuDropdown = (e, k, i, r) => {
        if(k.key === 'details'){
            history.push(`/order/${r.id}`);
        }else if(k.key === 'action'){
            if(r.order_status === 'PENDING' && role.includes('ADMIN'))
                history.push(`/order/approve/${r.id}`);
            else if(r.order_status === 'PENDING' && role.includes('BRANCH'))
                setShowCancel({ flag: true, details: r });
            else if(r.order_status === 'APPROVED')
                setShowPending({ flag: true, details: r });
            else if(
                r.order_status === 'COMPLETED' ||
                r.order_status === 'PLEDGE_RECEIVED' 
            )
                setShowInvoiceAction({flag: true, obj: r});
            else
                notyf.open({
                    type: 'error', 
                    message: 'Requested Action not allowed !'
                });
        }
    }

    const handleConfirmAction = (details = {}) => {
        setShowInvoiceAction({ flag: false, obj: {} });
        setShowRelease({ flag: true, details });
    }
    
    const onChangeRowsPerPage = (e) => {
        setPage(0);
        setLimit(e.target.value);
        getDetails(0, e.target.value, filterQuery);
    }

    const onChangePage = (e, val) => {
        setPage(val);
        getDetails(val, limit, filterQuery);
    }

    const handleFilterChange = (val, col) => {
        let fq = { ...filterQuery, [col.id] : val };
        setPage(0);
        setFilterQuery(fq);
        getDetails(0, limit, fq);
    }

    const resetFilter = () => {
        setPage(0);
        setClearInputFields(true);
        getDetails(0, limit, defaultQuery);
    }

    const handleInvoiceAction = (isEstimate = 0) => {
        let oId = showInvoiceAction.obj.id;
        window.open(`/invoice/${oId}?est=${isEstimate}`, '_blank');
    }

    const getFormattedData = (data = []) => {
        if(data.length === 0)
            return data;
        return data.map(e => {
            return {
                ...e,
                actual_amount: e.actual_amount ? 
                    utils.getFormattedAmount(e.actual_amount) : "",
                approved_amount: e.approved_amount ? 
                    utils.getFormattedAmount(e.approved_amount) : "",
                requested_amount : e.requested_amount ? 
                    utils.getFormattedAmount(e.requested_amount) : ""
            }
        })
    }
    
    return (
        <Navbar 
            { ...props }
        >
            <Box className={classes.mainBox}>
                <Box className = {classes.tableTitleBox}>
                    <Typography variant = "h5">
                        Order History
                    </Typography>
                    <Box className={classes.actionButtons}>
                        <Button
                            text = "Clear Filter"
                            variant = "secondary"
                            Icon = {ClearAllOutlined}
                            style = {{
                                padding: '8px 16px', 
                                marginRight: '16px' 
                            }}
                            onClick = {resetFilter}
                        />
                        <Button
                            text = "Refresh"
                            variant = "secondary"
                            Icon = {AutorenewOutlined}
                            style = {{
                                padding: '8px 16px', 
                                marginRight: '16px' 
                            }}
                            onClick = {() => getDetails(page, limit, filterQuery)}
                        />
                        <Button
                            text = "New Order"
                            variant = "primary"
                            Icon = {Add}
                            style = {{padding: '8px 16px'}}
                            onClick = {() => history.push('/search')}
                        />
                    </Box>
                </Box>
            </Box>
            <Paper className = {classes.tableBox}>
                <Table
                    handleIconClick = {(r, i) => 
                        handleMenuDropdown({}, {key: 'action'}, i, r )}
                    tableData = {getFormattedData(tableData)}
                    handleMenuDropdown = {handleMenuDropdown}
                    loading = {status === 'GETALL_REQUEST'}
                    clearInputFields = {clearInputFields}
                    handleChange = {handleFilterChange}
                    rowMenuOptions = {rowMenuOptions}
                    onTableRowClick = {onRowClick}
                    tableColumns = {tableColumns}
                />
                 <TablePagination
                    SelectProps={{
                        IconComponent: KeyboardArrowDown,
                        classes: { icon: classes.selectIcon },
                    }}  
                    rowsPerPageOptions={paginationDropDownList}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    onChangePage={onChangePage}
                    count={noOfRecords}
                    rowsPerPage={limit}
                    component='div'
                    page={page}                  
                />  
            </Paper>
            {
                <PendingAction
                    details = {showPending.details}
                    showDetails = {showPending.flag}
                    setDetails = {handlePendingAction}
                />
            }
            {
                showConfirmOrder.flag &&
                <ConfirmOrder
                    status = {status}
                    details = {showConfirmOrder.details}
                    showDetails = {showConfirmOrder.flag}
                    setDetails = {() => setShowConfirmOrder(
                        {flag: false, details: {}})}
                    handleSubmit = {handleConfirmOrderSubmit}
                />
            }
            {
                showCancel.flag &&
                <CancelOrder
                    status = {status}
                    details = {showCancel.details}
                    showDetails = {showCancel.flag}
                    setDetails = {() => setShowCancel(
                        {flag: false, details: {}})}
                    handleSubmit = {handleCancelOrderSubmit}
                />
            }

            {
                showDropOrder.flag &&
                <CancelOrder
                    title = {'DROP'}
                    status = {status}
                    details = {showDropOrder.details}
                    showDetails = {showDropOrder.flag}
                    setDetails = {() => setShowDropOrder(
                        {flag: false, details: {}})}
                    handleSubmit = {handleDropOrderSubmit}
                />
            }
            {
                showRelease.flag &&
                <ReleaseAction
                    status = {status}
                    details = {showRelease.details}
                    showDetails = {showRelease.flag}
                    setDetails = {() => setShowRelease(
                        {flag: false, details: {}})}
                    handleSubmit = {handleReleaseActionSubmit}
                />
            }
            {
                showInvoiceAction.flag &&
                <InvoiceAction
                    enableRelease = {true}
                    setDetails = {() => {
                        setShowInvoiceAction({
                            flag: false, obj: {}
                        })
                    }}
                    details = {showInvoiceAction.obj}
                    handleClick = {handleInvoiceAction}
                    handleConfirmAction = {handleConfirmAction}
                />
            }
        </Navbar>
    );
}