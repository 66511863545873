import Store from "../reducers";
import appinfo from "../app.json";

export const utils = {
    getDate,
    getRole,
    getRoles,
    roundOff,
    roundValue,
    roundFixed,
    hasParamKey,
    getImageUrl,
    isValidDate,
    getDateTime,
    getLocalTime,
    getDataFromUrl,
    getValueFromUrl,
    getDateDifference,
    getFormattedAmount,
    getCurrentDateTime
};

function getRole() {
    let role = Store.getState().profile.basic?.roles?.[0] || '';
    return role;
}

function getRoles() {
    let roles = Store.getState().profile.basic?.roles || '';
    return roles;
}

function roundOff(value) {
    return Math.round(value * 100) / 100;
}

function roundValue(amount) {
    return Math.round(amount || 0).toFixed(2);
}

function roundFixed(amount) {
    return (amount || 0).toFixed(2);
}

function getFormattedAmount(amt = 0, digit = 1) {
    return (amt).toLocaleString('en-IN', { minimumIntegerDigits: digit });
}

function getImageUrl(name) {
    if(name)
        return `${appinfo.url}/third-party/downloadFile?file_name=${name}`;
    else
        return '';
}

function getLocalTime(datetime){
    if(datetime){
        let dateUTC = new Date(datetime);
        let dateIST = new Date(dateUTC.getTime());
        dateUTC.setHours(dateIST.getHours() + 5); 
        dateUTC.setMinutes(dateIST.getMinutes() + 30);
        return dateUTC.toLocaleString();
    }else
        return datetime;
}

function getDateTime(datetime) {
    if(datetime) {
        let dt = new Date(datetime)
        return dt.toLocaleString();
    } else 
        return datetime;
}

function getDate(datetime){
    if(datetime){
        let dt = new Date(datetime);
        return `${dt.getDate()}/${dt.getMonth() + 1}/${dt.getFullYear()}`;
    }else
        return datetime;
}

function formatDate(date) {
    let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

function checkTime(i) {
    return (i < 10) ? "0" + i : i;
}


function formatTime(date) {
    let d = new Date(date);
    let h = checkTime(d.getHours());
    let m = checkTime(d.getMinutes());
    let s = checkTime(d.getSeconds());

    return [h, m, s].join(':');
}

function getCurrentDateTime() {
    let today = new Date();
    let date = formatDate(today);
    let time = formatTime(today);
    return date+' '+time;
}

function isValidDate(date){
    return !isNaN(new Date(date));
}

function getDateDifference(dateA = '', dateB = '') {
    let d1 = new Date(dateA);   
    let d2 = new Date(dateB);   
    let diff = d2.getTime() - d1.getTime();   
    let daydiff = diff / (1000 * 60 * 60 * 24);   

    return daydiff;
}

function getDataFromUrl(paramString) {
    let searchParams = new URLSearchParams(paramString);
    let data = {};
  
    for (let p of searchParams) {
      let key = p[0];
      let value = p[1];
      data = {
          ...data,
          [key] : value
      }
    }
  
    return data;
  }
  
  function hasParamKey(searchParams, key) {
    return new URLSearchParams(searchParams).has(key);
  }
  
  function getValueFromUrl(searchParams, key) {
    return new URLSearchParams(searchParams).get(key);
  }
  