import reportConstants from '../constants/report';
import { reportService } from '../services';
import notyf from '../utils/notification';

export const reportActions = {
    getByBranch,
    getAll,
    clear
}

function getByBranch(payload = {}){
    return dispatch =>{
        dispatch(request());
        reportService.getByBranch(payload).then(
            report =>{ dispatch(success(report)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: reportConstants.GET_BRANCH_REQUEST };
    }
    function success(report){
        return {
            type: reportConstants.GET_BRANCH_SUCCESS,
            payload: report
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: reportConstants.GET_BRANCH_ERROR,
            payload : error
        };
    }
}

function getAll(payload = {}){
    return dispatch =>{
        dispatch(request());
        reportService.getAll(payload).then(
            report =>{ dispatch(success(report)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: reportConstants.GET_ALL_REQUEST };
    }
    function success(report){
        return {
            type: reportConstants.GET_ALL_SUCCESS,
            payload: report
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: reportConstants.GET_ALL_ERROR,
            payload : error
        };
    }
}


function clear(){
    return dispatch =>{
        dispatch(requestClear());
    };

    function requestClear(){
        return { type: reportConstants.CLEAR };
    }
}