import React, { useState } from 'react';
import { Button } from '../../../components';
import useStyles from '../style';
import { 
    Typography, IconButton,
    OutlinedInput, FormControl,
    InputAdornment, Box, Grid
} from '@material-ui/core';
import { 
    Lock, HighlightOffRounded,
    VisibilityOutlined,
    VisibilityOffOutlined,
    CheckCircleOutlineRounded,
} from '@material-ui/icons';

export default function ChangePassword(props){

    const classes = useStyles();
    const [error, setError] = useState({show: false, keys: []});
    const [showConfirm, setShowConfirm] = useState(false);
    const [showOld, setShowOld] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [payload, setPayload] = useState(
        {old: "", new: "", confirm: ""});
    const { handleSubmit = () => null, status = '' } = props;

    const handleError = (payl = {}) => {
        let k = [];
        if(!payl.old)
            k.push('old')
        if(!payl.new)
            k.push('new')
        if(
            !payl.confirm ||
            payl.confirm !== payl.new
        )
            k.push('confirm');
        
        
        return { 
            show: k.length > 0, 
            keys: k 
        };
            
    }

    const onSubmit = () => {
        let err = handleError(payload);
        if(err.show){
            setError(err);
        }else {
            let payl = {
                "old_password" : payload.old,
                "new_password" : payload.new
            }
            handleSubmit(payl);
        }
    }

    const handleReset = () => {
        setPayload({old: "", new: "", confirm: ""});
        setError({show: false, keys: []});
    }

    const handleChange = (value, name) => {
        let payl = { ...payload, [name] : value };
        setPayload(payl);
        if(error.show){
            let errors = handleError(payl);
            setError(errors);
        }
    }

    return (
        <Box className={classes.mostOuterBox}>
            <Box className = {classes.outerBox}>
                <Box className = {classes.mainBox}>
                    <Typography variant='h6' 
                        className= {classes.blockTitle}
                    >
                        Change Password
                    </Typography>
                </Box>
                <Grid
                    xs = {12} sm = {6} md = {6} lg = {6} 
                    xl={6} className = {classes.mainGrid}
                >
                    <FormControl className={classes.formControlInput}>
                        <Typography variant='subtitle2'
                            className={classes.label}
                        >
                            Old Password *
                        </Typography>
                        <OutlinedInput
                            onChange={(e) => handleChange(e.target.value, 'old')}
                            error = { error.show && error.keys?.['old']  }
                            type = {showOld ? 'text' : 'password'}
                            placeholder = {'Old password'}
                            className={classes.inputField}
                            value = {payload['old']}
                            id= { 'old_0' }
                            startAdornment = {
                                <InputAdornment position='start'>
                                    <Lock edge='start' color='primary'/>
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowOld(!showOld)}
                                        edge="end"
                                    >
                                        { 
                                            showOld ? 
                                                <VisibilityOutlined /> : 
                                                <VisibilityOffOutlined />
                                        }
                                    </IconButton>
                                </InputAdornment>
                            }
                            inputProps = {{
                                required: true,
                                maxLength : 20
                            }}
                        />
                        <small className={'red'}>
                            {
                                error.show &&
                                error.keys?.includes('old') &&
                                'Invalid password'
                            }
                        </small>
                    </FormControl>
                    <FormControl className={classes.formControlInput}>
                        <Typography variant='subtitle2'
                            className={classes.label}
                        >
                            New Password *
                        </Typography>
                        <OutlinedInput
                            onChange={(e) => handleChange(e.target.value, 'new')}
                            error = { error.show && error.keys?.['new']  }
                            type = {showNew ? 'text' : 'password'}
                            className={classes.inputField}
                            placeholder = {'New password'}
                            value = {payload['new']}
                            id= { 'new_0' }
                            startAdornment = {
                                <InputAdornment position='start'>
                                    <Lock edge='start' color='primary'/>
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowNew(!showNew)}
                                        edge="end"
                                    >
                                        {
                                            showNew ? 
                                                <VisibilityOutlined /> : 
                                                <VisibilityOffOutlined />
                                        }
                                    </IconButton>
                                </InputAdornment>
                            }
                            inputProps = {{
                                required: true,
                                maxLength : 20
                            }}
                        />
                        <small className={'red'}>
                            {
                                error.show &&
                                error.keys?.includes('new') &&
                                'Invalid password'
                            }
                        </small>
                    </FormControl>
                    <FormControl style={{marginBottom: 16}} 
                        className={classes.formControlInput}
                    >
                        <Typography variant='subtitle2'
                            className={classes.label}
                        >
                            Confirm New Password *
                        </Typography>
                        <OutlinedInput
                            onChange={(e) => handleChange(e.target.value, 'confirm')}
                            error = { error.show && error.keys?.['confirm']  }
                            type = {showConfirm ? 'text' : 'password'}
                            placeholder = {'Confirm New password'}
                            className={classes.inputField}
                            value = {payload['confirm']}
                            id= { 'confirm_0' }
                            startAdornment = {
                                <InputAdornment position='start'>
                                    {
                                        payload['confirm'] === "" &&
                                        <Lock edge='start' color='primary'/>
                                    }
                                    {
                                        payload['confirm'] &&
                                        payload['confirm'] === payload["new"] &&
                                        <CheckCircleOutlineRounded 
                                            size = {24} 
                                            style={{color: 'green'}} 
                                            edge='start' 
                                        />
                                    }
                                    {
                                        payload['confirm'] &&
                                        payload['confirm'] !== payload["new"] &&
                                        <HighlightOffRounded 
                                            size={24} edge='start'
                                            color='error'  
                                        />
                                    }
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowConfirm(!showConfirm)}
                                        edge="end"
                                    >
                                        {
                                            showConfirm ? 
                                                <VisibilityOutlined /> : 
                                                <VisibilityOffOutlined />
                                        }
                                    </IconButton>
                                </InputAdornment>
                            }
                            inputProps = {{
                                required: true,
                                maxLength : 20
                            }}
                        />
                        <small className={'red'}>
                            {
                                error.show &&
                                error.keys?.includes('confirm') &&
                                'Password not matching with New password'
                            }
                        </small>
                    </FormControl>
                </Grid>
            </Box>
            <Box className={classes.actionButtons}>
                <Button 
                    loading = {status === 'UPDATE_PASS_REQUEST'}
                    variant='primary'
                    onClick = {onSubmit}
                    style={{
                        width: '200px',
                        maxHeight: '32px',
                        marginLeft: 'auto',
                        marginRight: '16px' 
                    }}
                    text = 'Submit'
                />
                <Button 
                    variant='secondary'
                    onClick = {handleReset} 
                    style={{
                        width: '200px',
                        maxHeight: '32px',
                        display: 'flex',
                        marginRight: 'auto' 
                    }}
                    text = 'Reset'
                />
            </Box>        
        </Box>
    );
}