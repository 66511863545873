import { 
    makeStyles 
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    outerBox: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        justifyContent: 'center',
        padding: `${theme.spacer * 4}px 0px`,
        borderBottom: `1px solid ${theme.color.grey40}`
    },
    titleSection: {
        width: '40vw', 
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacer * 0,
        marginBottom: theme.spacer * 2,
        textAlign: 'center'
    },
    blockTitle: {
        maxWidth: '100px'
    },
    addressTitle: {
        paddingBottom: theme.spacer * 2
    },
    inputField: {                                               
        height: theme.spacing(5),
        margin: `0px 0px ${theme.spacer * 0}px 0px`, 
        width: '300px',
        backgroundColor: theme.color.white,
        border: 'none',
        borderRadius: theme.spacer * 0.3,
    },
    mainGrid: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: `${theme.spacer * 2}px`
    },
    mainKycGrid: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    phoneInputField: {
        marginBottom: theme.spacer * 1
    },
    formControlInput:{
        marginBottom: theme.spacer
    },
    checkBoxClass: {
        margin: '0% 10% 2% 10%' 
    },
    dialogPaper : {
        maxHeight: '90vh',
    },
    mainBox : {
        margin: '5% 5% 2% 5%'
    },
    tableTitleBox : {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    tableBox : {
        margin: '1% 5%',
    },
    actionButtons: {
        display: 'flex',
        flexDirection: 'row',
    },
    containerBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    indGrid: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: `${theme.spacer * 1}px`
    }
}));

export default useStyles;