import React, { useState } from 'react';
import { Button, ModalDialog, } from "../../../components";
import { expenseConstants as ec } from '../../../constants';
import useStyles from '../style';
import { 
    ExpandMore, CheckCircleOutlineRounded,
    ErrorOutlineRounded, AttachFileOutlined 
} from '@material-ui/icons';
import {
    Grid, Box, MenuItem,
    FormControl, CircularProgress,
    OutlinedInput, Select,
    Typography, InputAdornment
} from '@material-ui/core';

let dnm = {
    "2000" : 0,
    "500" : 0,
    "200" : 0,
    "100" : 0,
    "50" : 0,
    "20" : 0,
    "10" : 0,
    "5" : 0,
    "2" : 0,
    "1" : 0
};

let dnmKeys = [
    "2000" , "500" , "200", "100",
    "50", "20", "10", "5", "2", "1" 
]

export default function AddExpense(props) {
    const classes = useStyles();
    const [error, setError] = useState(false);
    const [denomination, setDenomination] = useState(dnm);
    const {
        showAdd = false,
        setShowAdd = () => null,
        handleFileChange = () => null,
        payload = {},
        status = ''
    } = props;

    const handleDenominationChange = (name, value) => {
        setDenomination({ ...denomination, [name] : value || 0});
    }

    const handleChange = (name, value) => {
        setError(false);
        props.handleChange(name, value);
    }

    const checkIfImageError = () => {
        return payload.expense_bill === '' ||
                payload.expense_bill === 'uploading' ||
                payload.expense_bill === 'failed';
    }

    const handleSubmit = () => {
        let err = false;
        if(!payload.expense_on) err = true;
        else if(!payload.expense_amount) err = true;
        else if(!payload.expense_type) err = true;
        else if(checkIfImageError()) err = true;

        if(err){
            setError(true);
        }else {
            let payl = {
                ...payload,
                cash_denomination : payload.expense_payment_mode === 'CASH' ? 
                                        JSON.stringify(denomination) : null
            }
            props.handleSubmit(payl);
        }
    }


    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        {ec.addTitle}
                    </Typography>
                </Box>
                <Grid 
                    container 
                    className = {classes.mainGrid}
                >
                    <Grid 
                        item xs={12} sm={6} 
                        md={6} lg={6} xl={6}
                        className = {classes.indGrid}
                    >
                        <FormControl className={classes.inputField}>
                            <Typography variant='subtitle2' className={classes.label}>
                                Expense Type
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleChange('expense_type', e.target.value )}
                                className={classes.inputField}
                                value = {payload['expense_type']}
                                error= {error && !payload.expense_type}
                                placeholder={'BEVERAGES, ELECTRICITY, TRANSPORT'}
                                type = {'text'}
                                id='expense_type'
                                inputProps = {{
                                    required: true,
                                    maxLength : 45,
                                    min : 0
                                }}
                            />
                            <small className="red" >
                                {error && !payload.expense_type && ec.invalidValue}
                            </small>
                        </FormControl>
                        <FormControl className={classes.inputField}>
                            <Typography variant='subtitle2' className={classes.label}>
                                Expense Amount
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleChange('expense_amount', e.target.value )}
                                className={classes.inputField}
                                value = {payload['expense_amount']}
                                error= {error && !payload.expense_amount}
                                placeholder={'0'}
                                type = {'number'}
                                id='expense_amount'
                                inputProps = {{
                                    required: true,
                                    maxLength : 14,
                                    min : 0
                                }}
                            />
                            <small className="red"  >
                                {error && !payload.expense_amount && ec.invalidValue}
                            </small>
                        </FormControl>
                        <FormControl variant='outlined' className={classes.inputField}>
                            <Typography variant='subtitle2'
                                className={classes.label}
                            >
                                Expense Payment Mode
                            </Typography>
                            <Select
                                className={classes.inputField}
                                IconComponent = {ExpandMore}
                                value = {payload['expense_payment_mode']}
                                onChange={(e) => handleChange('expense_payment_mode', e.target.value)}
                                inputProps={{
                                    name: 'expense_payment_mode',
                                }}
                            >
                                <MenuItem value={'CASH'}>Cash</MenuItem>
                                <MenuItem value={'RTGS'}>RTGS</MenuItem>
                                <MenuItem value={'IMPS'}>IMPS</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.inputField}>
                            <Typography variant='subtitle2' className={classes.label}>
                                Expense Date
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleChange('expense_on', e.target.value )}
                                className={classes.inputField}
                                value = {payload['expense_on']}
                                error = {error && !payload.expense_on}
                                placeholder={'0'}
                                type = {'date'}
                                id='expense_on'
                                inputProps = {{
                                    required: true,
                                    maxLength : 14,
                                    min : 0
                                }}
                            />
                            <small className="red"  >
                                {error && !payload.expense_on && ec.invalidValue }
                            </small>
                        </FormControl>
                        <FormControl className={classes.inputField}>
                            <Typography variant='subtitle2' className={classes.label}>
                                Expense Description
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleChange('expense_description', e.target.value )}
                                className={classes.inputField}
                                value = {payload['expense_description']}
                                placeholder={'comment'}
                                type = {'text'}
                                id='expense_description'
                                inputProps = {{
                                    required: true,
                                    maxLength : 50,
                                    min : 0
                                }}
                            />
                        </FormControl>
                        <FormControl className={classes.inputField} >
                            <Typography variant='subtitle2'
                                className={classes.label}
                            >
                                Expense Bill
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleFileChange(
                                    e.target.files,
                                    'expense_bill',
                                    'file'
                                )}
                                className={classes.inputField}
                                id= { 'expense_bill' }
                                error = { error && checkIfImageError()}
                                type = {'file'}
                                startAdornment = {
                                    <InputAdornment position='start'>
                                        {
                                            payload?.expense_bill === 'uploading' &&
                                            <CircularProgress size={16} color="primary"/> 
                                        }
                                        {
                                            !['', 'uploading', 'failed'].includes(payload?.expense_bill) &&
                                            <CheckCircleOutlineRounded size = {24} style={{color: 'green'}}/>
                                        }
                                        {
                                            payload?.expense_bill === 'failed' &&
                                            <ErrorOutlineRounded size={24} color='error' />
                                        }
                                        {
                                            payload?.expense_bill === '' &&
                                            <AttachFileOutlined edge='start' color='primary'/>
                                        }                              
                                    </InputAdornment>
                                }
                                inputProps = {{
                                    required: true,
                                    maxLength : 45
                                }}
                            />
                            <small className="red">
                                { error && checkIfImageError() && ec.invaildImage }
                            </small>
                        </FormControl>
                    </Grid>
                    {
                        payload.expense_payment_mode === 'CASH' &&
                    <Grid 
                        item xs={12} sm={6} 
                        md={6} lg={6} xl={6}
                        className = {classes.indGrid}
                    >
                        {
                            dnmKeys.map(dnms => {
                                return (
                                    <FormControl className={classes.inputField}>
                                        <Typography variant='subtitle2' className={classes.label}>
                                            {dnms} 's
                                        </Typography>
                                        <OutlinedInput
                                            onChange={(e) => handleDenominationChange(
                                                dnms, parseInt(e.target.value)
                                            )}
                                            className={classes.inputField}
                                            value = {denomination[dnms]}
                                            type = {'number'}
                                            id={"denom_" + dnms}
                                            inputProps = {{
                                                required: true,
                                                maxLength : 45,
                                                min : 0
                                            }}
                                        />
                                    </FormControl>
                            )})
                        }
                    </Grid>
                    }
                </Grid>
                <Button 
                    onClick={handleSubmit} 
                    loading = {status === 'ADD_REQUEST'}
                    text='Submit' 
                    style ={{ 
                        margin : '40px 0px',
                        width: '280px'
                    }}
                />
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {showAdd}
            onClose = {() => setShowAdd(false)}
            dialogTitle = ''
            dialogContent = {dialogContent()}
            maxWidth = {'md'}
            classes = {{ paper: classes.dialogPaper}}
            dialogFooter = {[]}
        />
    )

}