const fundConstants = {
    POST_REQUEST: 'FUND_POST_REQUEST',
    POST_ERROR: 'FUND_POST_ERROR',
    POST_SUCCESS: 'FUND_POST_SUCCESS',

    ADD_REQUEST: 'FUNDS_ADD_REQUEST',
    ADD_ERROR: 'FUNDS_ADD_ERROR',
    ADD_SUCCESS: 'FUNDS_ADD_SUCCESS',

    GET_ALL_REQUEST : 'FUND_GET_ALL_REQUEST',
    GET_ALL_SUCCESS : 'FUND_GET_ALL_SUCCESS',
    GET_ALL_ERROR : 'FUND_GET_ALL_ERROR',

    RECORDS_REQUEST : 'FUNDS_TOTAL_RECORDS_REQUEST',
    RECORDS_SUCCESS : 'FUNDS_TOTAL_RECORDS_SUCCESS',
    RECORDS_ERROR : 'FUNDS_TOTAL_RECORDS_ERROR',

    BRANCH_FUND_REQUEST: 'GET_FUNDS_IN_BRANCH_REQUEST',
    BRANCH_FUND_SUCCESS: 'GET_FUNDS_IN_BRANCH_SUCCESS',
    BRANCH_FUND_ERROR: 'GET_FUNDS_IN_BRANCH_ERROR',

    P_ACTION_REQUEST: 'PENDING_FUNDS_ACTION_REQUEST',
    P_ACTION_SUCCESS: 'PENDING_FUNDS_ACTION_SUCCESS',
    P_ACTION_ERROR: 'PENDING_FUNDS_ACTION_ERROR',

    C_ACTION_REQUEST: 'CONFIRM_FUNDS_ACTION_REQUEST',
    C_ACTION_SUCCESS: 'CONFIRM_FUNDS_ACTION_SUCCESS',
    C_ACTION_ERROR: 'CONFIRM_FUNDS_ACTION_ERROR',

    CANCEL_FR_REQUEST: 'CANCEL_FUNDS_ACTION_REQUEST',
    CANCEL_FR_SUCCESS: 'CANCEL_FUNDS_ACTION_SUCCESS',
    CANCEL_FR_ERROR: 'CANCEL_FUNDS_ACTION_ERROR'
}

export default fundConstants;