import React, { useState } from 'react';
import { Button, ModalDialog, } from "../../components";
import useStyles from './style';
import {
    fundRequestConstants as frc
} from '../../constants';
import {
    Grid, Box,
    FormControl,
    OutlinedInput,
    Typography,
} from '@material-ui/core';

export default function AddFunds(props) {
    const classes = useStyles();
    const [payload, setPayload] = useState({amount: 0, branch_id: 0});
    const [error, setError] = useState(false);
    const {
        show = false,
        setShow = () => null,
        handleSubmit = () => null,
        basic = {},
        status = '',
    } = props;


    const handleChange = (name, value) => {
        setError(false);
        setPayload({ 
            ...payload, 
            [name] : value 
        });
    }

    const onSubmit = () => {
        if(!payload.amount){
            setError(true);
        }else {
            setError(false);
            handleSubmit({
                amount: payload.amount,
                branch_id: basic.branch_id
            });
        }
    }

    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        Add Funds
                    </Typography>
                    <Typography variant = 'p' className={classes.subTitle}>
                        You can add funds to current branch
                    </Typography>
                </Box>
                <Grid 
                    container 
                    direction='row' 
                    className = {classes.mainGrid}
                >
                    <Grid 
                        item xs={12} sm={12} 
                        md={12} lg={12} xl={12}
                        className = {classes.indGrid}
                    >
                        <FormControl className={classes.inputField}>
                            <Typography variant='subtitle2' className={classes.label}>
                                Amount
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleChange(
                                    'amount', parseFloat(e.target.value)
                                )}
                                className={classes.inputField}
                                value = {payload['amount']}
                                error= {error}
                                placeholder={'Amount'}
                                type = {'number'}
                                id='amount'
                                inputProps = {{
                                    required: true,
                                    maxLength : 45,
                                    min : 0
                                }}
                            />
                        </FormControl>
                        <small className="red" style={{marginTop: 4}}>
                                {error && frc.addInvalidamount}
                        </small>
                    </Grid>
                </Grid>
                <Button 
                    onClick={onSubmit} 
                    loading = {status === 'ADD_FUND_REQUEST'}
                    text='Submit' 
                    style ={{ 
                        margin : '40px 0px',
                        width: '280px'
                    }}
                />
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {show}
            onClose = {() => setShow(false)}
            dialogTitle = ''
            dialogContent = {dialogContent()}
            maxWidth = {'sm'}
            dialogFooter = {[]}
        />
    )

}