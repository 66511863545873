import smsConstants from '../constants/sms';

const initialState = {
    status: '',
    logs: []
}

function sms(state = initialState, action) {
    switch(action.type) {
        case smsConstants.GET_SUCCESS : 
            return {
                ...state,   
                status: 'GET_SUCCESS',
                logs: action.payload.data
            }
        case smsConstants.GET_ERROR:
            return {
                ...state,
                status: 'GET_ERROR'
            }
        case smsConstants.GET_REQUEST:
            return {
                ...state,
                status: 'GET_REQUEST'
            }
        default: return state;
    }
}

export default sms;