import {httpClient} from "../utils";

export const authService ={
    login,
    logout,
    validateToken,
    verifyOtp
}


function verifyOtp(payload){
    return httpClient.callApi("POST",`/authenticate/login/mfa`,payload, false, false, 'login');
}

function validateToken(){
    return httpClient.callApi("GET",`/authenticate/validate-token`);
}

function login(payload){
    return httpClient.callApi("POST",`/authenticate/login`, payload, false, false, 'login');
}

function logout() {
    return httpClient.callApi("GET",`/authenticate/logout`, {}, false, false, 'login');
}