import uploadConstants from '../constants/fileupload';
import { uploadService } from '../services';

export const uploadActions = {
    upload,
    clearState
}


function upload(payload , type, index){
    return dispatch =>{
        dispatch(request());
        uploadService.upload(payload).then(
            user =>{ dispatch(success(user)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { 
            type: uploadConstants.UPLOAD_REQUEST ,
            payload : {
                index,
                type
            }
        };
    }
    function success(resp){
        return {
            type: uploadConstants.UPLOAD_SUCCESS,
            payload: {
                index,
                type,
                data : resp.data
            }
        };
    }
    function failure(error){
        return {
            type: uploadConstants.UPLOAD_ERROR,
            payload : {
                index,
                type,
                error
            }
        };
    }
}

function clearState() {
    return dispatch => {
        dispatch(clearUploads());
    }

    function clearUploads() {
        return { type: uploadConstants.CLEAR_UPLOADS }
    }
}