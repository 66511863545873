const orderConstants = {
    ADD_REQUEST: 'ORDER_ADD_REQUEST',
    ADD_ERROR: 'ORDER_ADD_ERROR',
    ADD_SUCCESS: 'ORDER_ADD_SUCCESS',

    CANCEL_REQUEST: 'ORDER_CANCEL_REQUEST',
    CANCEL_ERROR: 'ORDER_CANCEL_ERROR',
    CANCEL_SUCCESS: 'ORDER_CANCEL_SUCCESS',

    DROP_REQUEST: 'ORDER_DROP_REQUEST',
    DROP_ERROR: 'ORDER_DROP_ERROR',
    DROP_SUCCESS: 'ORDER_DROP_SUCCESS',

    RELEASE_REQUEST: 'PLEDGE_RELEASE_REQUEST',
    RELEASE_ERROR: 'PLEDGE_RELEASE_ERROR',
    RELEASE_SUCCESS: 'PLEDGE_RELEASE_SUCCESS',

    APPROVE_REQUEST: 'ORDER_APPROVE_REQUEST',
    APPROVE_ERROR: 'ORDER_APPROVE_ERROR',
    APPROVE_SUCCESS: 'ORDER_APPROVE_SUCCESS',

    GETALL_REQUEST : 'ORDER_GET_ALL_REQUEST',
    GETALL_SUCCESS : 'ORDER_GET_ALL_SUCCESS',
    GETALL_ERROR : 'ORDER_GET_ALL_ERROR',

    GET_REQUEST : 'ORDER_GET_REQUEST',
    GET_SUCCESS : 'ORDER_GET_SUCCESS',
    GET_ERROR : 'ORDER_GET_ERROR',

    GET_ITEM_REQUEST : 'ORDER_GET_ITEM_DETAILS_REQUEST',
    GET_ITEM_SUCCESS : 'ORDER_GET_ITEM_DETAILS_SUCCESS',
    GET_ITEM_ERROR : 'ORDER_GET_ITEM_DETAILS_ERROR',

    TRANSACTIONS_REQUEST : 'ORDER_GET_TRANSACTIONS_REQUEST',
    TRANSACTIONS_SUCCESS : 'ORDER_GET_TRANSACTIONS_SUCCESS',
    TRANSACTIONS_ERROR : 'ORDER_GET_TRANSACTIONS_ERROR',

    CONFIRM_REQUEST: 'ORDER_CONFIRM_REQUEST',
    CONFIRM_ERROR: 'ORDER_CONFIRM_ERROR',
    CONFIRM_SUCCESS: 'ORDER_CONFIRM_SUCCESS',

    RECORDS_REQUEST : 'ORDERS_TOTAL_RECORDS_REQUEST',
    RECORDS_SUCCESS : 'ORDERS_TOTAL_RECORDS_SUCCESS',
    RECORDS_ERROR : 'ORDERS_TOTAL_RECORDS_ERROR',
}

export default orderConstants;