import React, { useEffect, useState } from 'react';
import { Grid, Box, FormControl, Typography, Paper } from '@material-ui/core';
import { Button, Navbar, UserDetailsCard, Table } from "../../components";
import { searchCustomerConstants as scc } from '../../constants';
import UserUpdateModal from '../../components/userUpdateModal';
import InvoiceAction from '../orders-history/invoice-action';
import { ShoppingCartOutlined } from '@material-ui/icons';
import tableHeaders from './tableHeaders.json';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { utils } from '../../utils';
import useStyles from './style';

export default function SearchCustomerLayout(props){

    const classes = useStyles();
    const [showInvoiceAction, setShowInvoiceAction] = useState(
        {flag: false, obj: {} });
    const [phone, setPhone] = useState('');
    const [error, setError] = useState('');
    const [kyc, setKyc] = useState('');
    const {
        handleUserUpdateSubmit = () => null,
        handleFileChange = () => null,
        setShowUpdate = () => null,
        clearUploads = () => null,
        showUpdate = {
            flag: false,
            payload: {}
        },
        fileuploads = {},
        transactions = [],
        userDetails = [],
        status = '',
        history
    } = props;

    useEffect(() => {
        if(status === 'UPDATE_SUCCESS'){
            props.handleSearch({ 
                mobile_number :showUpdate.payload.mobile_number
            });
            clearUploads();
            setShowUpdate(false, {});
        }
    }, [status])

    const handleChange = (value, source) => {
        if(source === 'phone') setPhone("+"+value);
        else if(source === 'kyc') setKyc(value);

        setError('');
    }

    const redirectNewCustomer = () => {
        let path = `/onboard/customer`
        if(phone){
            let mob = phone?.substr(1);
            path = path + `?phone=${mob}`
        }
        history.push(path);
    }

    const redirectPlaceOrder = () => {
        let mob = userDetails?.[0]?.users?.mobile_number;
        if(mob){
            history.push(`/placeorder/${mob.substr(1)}`);
        }
    }



    const handleSearch = () => {
        let payload = {};
        if( phone.length < 5 ){
            setError('Need atleast one field to search');
        }
        else {
            payload = { mobile_number : phone };
            props.handleSearch(payload);
        }
    }

    const getFormattedData = () => {
        if(transactions.length > 0){
            return transactions.map(e => {
                return { ...e,
                    order_date: utils.getLocalTime(e.order_date || ''),
                    actual_amount : utils.getFormattedAmount(e.actual_amount) || 0,
                    approved_amount: utils.getFormattedAmount(e.approved_amount) || 0,
                    requested_amount : utils.getFormattedAmount(e.requested_amount) || 0
                }
            });
        }else 
            return [];
    }

    const handleShowUpdate = (obj = {}) => {
        setShowUpdate(true, obj);
    }

    const handleInvoiceAction = (isEstimate = 0) => {
        let oId = showInvoiceAction.obj.id;
        window.open(`/invoice/${oId}?est=${isEstimate}`, '_blank');
    }


    const onTableRowClick = (e, row, index) => {
        if(
            [ 'APPROVED', 'COMPLETED', 
              'PLEDGE_RECEIVED', 
              'NEED_CONFIRMATION'
            ].includes(row?.order_status)
        ){
            setShowInvoiceAction({flag: true, obj: row});
        }
    }

    return (
        <Navbar 
            { ...props }
        >
            <Box>
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        {scc.title}
                    </Typography>
                    <Typography variant = 'p' className={classes.subTitle}>
                        {scc.subTitle}
                    </Typography>
                </Box>
                    <Grid 
                        container 
                        direction='row' 
                        className = {classes.mainGrid}
                    >
                        <Grid 
                            item xs={12} sm={12} 
                            md={12} lg={12} xl={12}
                            className = {classes.leftGrid}
                        >
                            <FormControl className={classes.phoneInputField}>
                                <Typography variant='subtitle2' className={classes.label}>
                                    Phone Number
                                </Typography>
                                <PhoneInput
                                    country={'in'}
                                    placeholder={'Phone Number'}
                                    inputStyle = {{
                                        height: '40px',
                                        borderColor: error ? 'red' : ''
                                    }}
                                    countryCodeEditable={false}
                                    onChange={ phone => handleChange(phone, 'phone') }
                                    inputProps = {{
                                        required: true,
                                        autoFocus: true
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    <Grid 
                        item xs={12} sm={12} 
                        md={5} lg={5} xl={5}
                        className = {classes.orGrid}
                    >
                        <small className='red'>
                            {error !== '' && error}
                        </small>
                        <Button 
                            onClick={handleSearch} 
                            loading = {status === 'GET_USER_REQUEST'}
                            text='Search' 
                            style ={{ 
                                margin : '10px 0px',
                                width: '300px'
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box className = {classes.userDetailsBox}>
                {
                    status === 'GET_USER_SUCCESS' &&
                    userDetails.length >= 1 && 
                    userDetails?.[0]?.users &&
                    <Box>
                        <UserDetailsCard
                            userDetails = {userDetails[0]}
                            onClickEdit = {(obj) => handleShowUpdate(obj)}
                            isEditable = { userDetails[0]?.roles?.length === 0 }
                        />
                    </Box>
                }
                {
                    status === 'GET_USER_SUCCESS' &&
                    userDetails.length === 1 &&
                    !userDetails?.[0]?.users &&
                    <Box className = {classes.notfound} elevation={1}>
                        <Typography variant = 'h5'>
                                Customer details not found !
                        </Typography>
                        <Button
                            text = {'Click here to Onboard New Customer'}
                            variant = 'secondary'
                            onClick = {redirectNewCustomer}
                            style ={{padding: '0px 32px', marginTop:16}} 
                        />
                    </Box>
                }
            </Box>
            {
                status === 'GET_USER_SUCCESS' &&
                userDetails.length === 1 &&
                userDetails?.[0]?.users &&
                <Box style={{marginBottom: '5%'}}>
                    <Box className={classes.mainBox}>
                        <Box className = {classes.tableTitleBox}>
                            <Typography variant = "h5">
                                Transactions
                            </Typography>
                            <Button
                                text = {'Place order'}
                                variant = 'primary'
                                Icon = {ShoppingCartOutlined}
                                onClick = {redirectPlaceOrder}
                                style ={{
                                    padding: '8px 32px',
                                    maxWidth: `200px`
                                }} 
                            />
                        </Box>
                    </Box>
                    <Paper className = {classes.tableBox}>
                        <Table
                            loading = {status === 'TRANSACTIONS_REQUEST'}
                            tableData = {getFormattedData() || []}
                            onTableRowClick = {onTableRowClick}
                            handleMenuDropdown = {() => null}  
                            tableColumns = {tableHeaders}                       
                            handleChange = { () => null }
                            rowMenuOptions = {[]}
                        />
                    </Paper>
                </Box>
            }
            {
                showUpdate.flag &&
                <UserUpdateModal
                    status = {status}
                    show = {showUpdate.flag}
                    fileuploads = {fileuploads}
                    payload = {showUpdate.payload}
                    setShow = {() => {
                        setShowUpdate();
                        clearUploads();
                    }}
                    handleSubmit = {handleUserUpdateSubmit}
                    handleFileChange = {handleFileChange}
                />
            }
            {
                showInvoiceAction.flag &&
                <InvoiceAction
                    handleClick = {handleInvoiceAction}
                    details = {showInvoiceAction.obj}
                    setDetails = {() => {
                        setShowInvoiceAction({
                            flag: false, obj: {}
                        })
                    }}
                />
            }
        </Navbar>
    );
}