const reportConstants = {
    GET_BRANCH_REQUEST : 'REPORT_GET_BY_BRANCH_REQUEST',
    GET_BRANCH_SUCCESS : 'REPORT_GET_BY_BRANCH_SUCCESS',
    GET_BRANCH_ERROR : 'REPORT_GET_BY_BRANCH_ERROR',

    GET_ALL_REQUEST : 'REPORT_GET_ALL_REQUEST',
    GET_ALL_SUCCESS : 'REPORT_GET_ALL_SUCCESS',
    GET_ALL_ERROR : 'REPORT_GET_ALL_ERROR',

    CLEAR : 'CLEAR_REPORTS_DATA'
}

export default reportConstants;