import React ,{useEffect} from 'react';
import useStyles from './style';
import { MenuItem, Select } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function (props) {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = React.useState(props.value);
  const {
    isRowSelect = false,
    disabled = false,
  } = props;

  useEffect(()=>{
    if(props.clearInputFields){
      setSelectedValue(props.value);
    }
  },[props.clearInputFields])

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    let rest = [props.column];
    props.handleChange( e, ...rest );
  };

  return (
    <Select
      disableUnderline
      displayEmpty
      disabled = {disabled}
      IconComponent={ExpandMoreIcon} //ArrowDropDownIcon
      value={props.clearInputFields ? '' : selectedValue}
      onChange={handleChange}
      className={`${classes.selectSearch} 
        ${isRowSelect ? classes.rowSelect : classes.columnSelect}  
        ${props.classes}`
      }
      MenuProps = {{
        getContentAnchorEl : null,
        anchorOrigin : {
          vertical: "bottom",
          horizontal: "left"
        }
      }}
      inputProps={{  
        id:props.id,
        name:props.name,
        'aria-label': 'Without label' 
      }}
    >
      {
        !isRowSelect &&
        <MenuItem value=''>
          <span 
            className={classes.placeHolder}
          >
              {props.placeHolder}
          </span>
        </MenuItem>
      }
      {
        props.options.map((option) => (
          <MenuItem
            key={option[props.labelKey ? props.labelKey : 'label']}
            value={option[props.labelKey ? props.labelKey : 'value']}
          >
            {option[props.labelKey ? props.labelKey : 'label']}
          </MenuItem>
        ))
      }
    </Select>
  );
}
