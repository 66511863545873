import React from 'react';
import FilledInput from '@material-ui/core/FilledInput';
import Box from '@material-ui/core/Box';
import useStyles from './style';

export default function TableInput(props) {
  const classes = useStyles();
  const { 
    style = {}, 
    onChange = () => {}, 
    placeholder = '' ,
    inputType = 'text',
    disabled = false
  } = props;
  
  const styles = {
    overflow: 'hidden',
    backgroundColor: "#FFFFFF !important",
    borderRadius: '4px !important',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04)',
  };

  return (
    <Box style={styles}>
        <FilledInput
          value={props.clearInputFields ? '' : props.value}
          disableUnderline
          onChange={onChange}
          type={inputType}
          placeholder={placeholder}
          className = {classes.inputStyle}
          style={style}
          disabled={ disabled }
        />
    </Box>
  );
}
