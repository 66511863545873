import {httpClient} from "../utils";

export const reportService ={
    getByBranch,
    getAll,
}


function getAll(payload){
    return httpClient.callApi("POST",`/reports/getReportForAllBranch`, payload);
}

function getByBranch(payload){
    return httpClient.callApi("POST",`/reports/getReportForBranch`, payload);
}