import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100vw',
        height: '100vh',
        backgroundColor: theme.palette.grey,
    },
    headerNav: {
        height: theme.spacer * 8,
        width: '100vw',
        zIndex: 99,
        backgroundColor: theme.color.white,
        color: theme.palette.primary.main,
        // boxShadow: `0 1px 3px 0 rgb(60 64 67 / 30%), 0px 0px 4px 1px rgb(60 64 67 / 15%)`,
        filter: 'drop-shadow(rgba(0,0,0,0.04) 0px 4px 8px)',
        boxShadow: 'rgb(0 0 0 / 5%) 0px 01px 0px',
        borderRadius: 0,
        position: 'fixed',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `${theme.spacer * 1}px ${theme.spacer * 2}px`
    },
    sideBar:{
        zIndex: 99,
        marginTop: theme.spacer * 8,
        height: `calc(100vh - ${theme.spacer * 8}px)`,
        backgroundColor: theme.color.white,
        color: theme.palette.primary.main,
        boxShadow: '0px 1px 1px 0px rgb(0 0 0 / 14%)',
        position: 'fixed',
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `${theme.spacer * 1}px ${theme.spacer * 0}px`,
        transition: 'width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflowY: 'auto',
        '& ::-webkit-scrollbar' : {
            display: 'none'
        }
    },
    sideBarTwo: {
        boxShadow: '0px 1px 1px 0px rgb(0 0 0 / 14%)',
        justifyContent: 'space-between',
        marginTop: theme.spacer * 8,
        backgroundColor: '#fff',
        flexDirection: 'column',
        position: 'fixed',
        height: 'calc(100vh - 64px)',
        width: 200,
        zIndex: 99,
    },
    withSidebar: {
        display: 'flex',
        flexDirection: 'row',
    },
    companyDetails: {
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer'
    },
    companyName: {
        padding: `0px ${theme.spacer * 1}px`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent : 'center'
    },
    avatarProfile: {
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: theme.color.grey10,
        padding: '0px'
    },
    inputField :{
        width: theme.spacer * 40,
        height: theme.spacer * 4.5,
        backgroundColor: theme.color.grey10,
        borderRadius : '40px',
    },
    searchIcon: {
        paddingRight: '12px',
        fontSize: '32px'
    },
    dynamicMenu: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    staticMenu: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%'
    },
    staticMenuTwo: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginBottom: `${theme.spacer * 1}px`
    },
    iconMenus : {
        margin: `0px ${theme.spacer * 0}px !important`,
        padding: `${theme.spacer* 1}px ${theme.spacer * 2.25}px !important`,
    },
    selectedIconMenus : {
        backgroundColor: '#F1F1F1',
        borderRadius: '0px !important'
    },
    typographyMenu: {
        margin: `0px 0px 0px ${theme.spacer * 1.5}px`,
        padding: `${theme.spacer* 1}px ${theme.spacer * 1}px`,
        transition: 'width 0.3s ease-in-out',
        width: '90%',
        justifyContent: 'flex-start',
        '&:hover' : {
            borderRadius: '0px'
        }
    },
    typographyMenuTwo : {
        margin: `0px 0px 0px ${theme.spacer * 1.5}px`,
        padding: `${theme.spacer* 1}px ${theme.spacer * 1}px`,
        transition: 'width 0.3s ease-in-out',
        width: '90%',
        justifyContent: 'flex-start',
        '&:hover' : {
            borderRadius: '0px'
        }
    },
    HeaderMenus : {
        margin: `0px 4px`,
        padding: `${theme.spacer* 1}px ${theme.spacer * 1}px`,
        '&:hover' : {
            borderRadius: '0px'
        },
    },
    noDisabledColor: {
        color: `${theme.palette.primary.main} !important`,
    },
    menuDesc : {
        fontSize: `14px !important`,
        paddingLeft: theme.spacer * 1.5 ,
        textAlign: 'left'
    },
    secondMenuTitle: {
        padding: `${theme.spacer * 2}px 0px`,
        width : 200,
        textAlign: 'center'
    },
    secondMenuDesc : {
        color: theme.palette.primary.main,
        fontSize: theme.spacer * 2.5,
        textAlign: 'center'
    },
    role: {
        fontSize: `12px !important`,
        paddingLeft: theme.spacer * 1 ,
        textAlign: 'left'
    }
}));

export default useStyles;