import invoiceConstants from '../constants/invoice';
import { invoiceService } from '../services';
import notyf from '../utils/notification';

export const invoiceActions = {
    generate
}

function generate(payload){
    return dispatch =>{
        dispatch(request());
        invoiceService.generate(payload).then(
            value =>{ dispatch(success(value)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: invoiceConstants.GET_REQUEST };
    }
    function success(value){
        return {
            type: invoiceConstants.GET_SUCCESS,
            payload: value
        };
    }
    function failure(error){
        // notyf.open({
        //     type: 'error',
        //     message: error.message
        // })
        return {
            type: invoiceConstants.GET_ERROR,
            payload : error
        };
    }
}
