import {httpClient} from "../utils";

export const userService ={
    add,
    get,
    update,
    activateUser,
    resetPassword,
    deactivateUser,
    getAllEmployees,
    transferBranch,
}


function add(payload){
    return httpClient.callApi("POST",`/user/addUser`, payload);
}

function update(payload){
    return httpClient.callApi("PUT",`/user/updateUser`, payload);
}

function get(payload){
    return httpClient.callApi("POST",`/user/getUser`, payload);
}

function getAllEmployees(){
    return httpClient.callApi("GET", "/user/getEmployees");
}

function resetPassword(payload){
    return httpClient.callApi("POST",`/user/resetPassword`, payload);
}

function activateUser(payload){
    return httpClient.callApi("POST",`/user/activateUser`, payload);
}

function deactivateUser(payload){
    return httpClient.callApi("POST",`/user/deactivateUser`, payload);
}

function transferBranch(payload){
    return httpClient.callApi("PUT",`/branch/moveEmployee`, payload);
}