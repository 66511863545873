import React from 'react';
import { ModalDialog, Button } from "../../components";
import useStyles from './style';
import {
    Grid, Box,
    Typography
} from '@material-ui/core';

export default function PendingAction(props) {
    const classes = useStyles();
    const {
        details = {},
        showDetails = false,
        setDetails = () => null
    } = props;

    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        Choose Action
                    </Typography>
                </Box>
                <Grid 
                    container 
                    direction='row' 
                    className = {classes.fundDetailsMainGrid}
                >
                    <Grid 
                        item xs={12} sm={6} 
                        md={6} lg={6} xl={6}
                        className = {classes.actionGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Customer Name
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.customer_name || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Requested Amount
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.requested_amount || 'NA'}
                        </Typography>

                        <Button
                            text = "Drop"
                            variant = "primary"
                            style = {{
                                padding: '8px 16px',
                                margin: '60px 0px', 
                                backgroundColor: '#db252e',
                                width: '160px' 
                            }}
                            onClick = {() => setDetails('DROP', details) }
                        />

                    </Grid>
                    <Grid 
                        item xs={12} sm={6} 
                        md={6} lg={6} xl={6}
                        className = {classes.actionGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Customer Mobile Number
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.customer_mobile_number || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Approved Amount
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.approved_amount || 'NA'}
                        </Typography>

                        <Button
                            text = "Confirm"
                            variant = "primary"
                            style = {{
                                padding: '8px 16px',
                                margin: '60px 0px', 
                                width: '160px' 
                            }}
                            onClick = {() => setDetails('CONFIRM', details) }
                        />
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {showDetails}
            onClose = {() => setDetails('', details)}
            dialogTitle = ''
            dialogContent = {dialogContent()}
            maxWidth = {'md'}
            classes = {{ paper: classes.pendingDialogPaper }}
            dialogFooter = {[]}
        />
    )

}