import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    titleSection: {
        width: '40vw', 
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacer * 3,
        marginBottom: theme.spacer * 3,
        textAlign: 'center'
    },
    label: {
        lineHeight: theme.spacer * 0.35,
    },
    formControlInput:{
        marginBottom: theme.spacer
    },
    inputField: {                                                
        margin: `0px 0px ${theme.spacer * 0}px 0px`, 
        backgroundColor: theme.color.white,
        borderRadius: theme.spacer * 0.3,
        height: `${theme.spacer * 5}px`,
        width: `${theme.spacer * 35}px`,
        border: 'none',
        '& .MuiSelect-select:focus' : {
            border: 'none',
            borderWidth : 0,
            backgroundColor: 'transparent'
        },
        '& .MuiOutlinedInput-root' : {
            height: theme.spacing(5)
        },
        '& ..MuiInputBase-input' : {
            height: theme.spacing(5)
        }
    },
    mainBox : {
        margin: '2% 5% 2% 5%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    actionButtons: {
        display: 'flex',
        flexDirection: 'row',
    },
    dateInputBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        '& .padded-control': {
            margin: '16px 32px'
        },
        '& .red': {
            marginTop: `${theme.spacer * 1}px`,
        }
    },
}));

export default useStyles;