import React from 'react';
import {
    Box, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import useStyles from './style';

function ModalDialog(props) {
  const classes = useStyles();
  const {
      isCustomFooter = false,
      isFormContainer = false,
      handleSubmit
  } = props;

  return (
    <Dialog
        open={props.open}
        disableBackdropClick={true}
        onClose={props.onClose}
        maxWidth={props.maxWidth}
        fullWidth={props.fullWidth}
        className={classes.dialog}
        classes={{
            paper: props.width ? classes.dialogPaper : 
                        ( props.classes ? props.classes.paper : {} )
        }}
    >
        <DialogTitle id="dialog-title">
            <span>
                <Close
                    icon="close" 
                    className={`btn-close ${classes.span}`} 
                    onClick={props.onClose}
                />
            </span>
        {props.dialogTitle}
        </DialogTitle>
      {
        isFormContainer ?
          (
            <form 
                className={classes.formContainer} 
                onSubmit={handleSubmit} 
            >
                {   
                    props.dialogContent && 
                    <DialogContent 
                        className={`${classes.formInnerContainer} no-scrollbar`}
                        style={props.contentStyle ? props.contentStyle : {}}
                    >
                        {props.dialogContent}
                    </DialogContent>
                }
                {
                    isCustomFooter ? 
                        props.dialogFooter : 
                        ( props.dialogFooter === undefined ? 
                            <Box /> : 
                            <DialogActions>
                                {props.dialogFooter}
                            </DialogActions>
                        )
                }
            </form>
        )
          : 
        (
            <>
                {
                    props.dialogContent && 
                        <DialogContent 
                            className = {`no-scrollbar`}
                            style={ props.contentStyle ? props.contentStyle : {}}
                        >
                            {props.dialogContent}
                        </DialogContent>
                }
                {
                    isCustomFooter ? 
                        props.dialogFooter : 
                        (
                            props.dialogFooter === undefined ? 
                                <Box /> : 
                                <DialogActions>
                                    {props.dialogFooter}
                                </DialogActions>
                        )
                }
            </>
        )
      }

    </Dialog>
  );
}
export default ModalDialog;