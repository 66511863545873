import {httpClient} from "../utils";

export const branchService ={
    add,
    // get,
    getAll,
    update
}


function add(payload){
    return httpClient.callApi("POST",`/branch/addBranch`, payload);
}

function update(payload){
    return httpClient.callApi("PUT",`/branch/updateBranch`, payload);
}

function getAll(){
    return httpClient.callApi("GET",`/branch/getAllBranches`);
}