import React, { useState } from 'react';
import { verificationActions } from '../../actions/verification';
import { authActions } from '../../actions/authentication';
import { Grid } from '@material-ui/core';
import useStyles from './style';
import { 
    LoginCredentials, 
    LoginVerification,
    ForgotPassword 
} from './components';

export default function LoginLayout(props) {
    const classes = useStyles();
    const [mobileNumber, setMobileNumber] = useState('');
    const [resendLimit, setResendLimit] = useState(0);
    const [page, setPage] = useState(1);

    const handleLogin = (payload = {}) => {
        const { dispatch } = props;
        setMobileNumber(payload.username);
        dispatch(authActions.login(payload));
    }

    const handleVerification = (payload = {}) => {
        const { dispatch } = props;
        dispatch(authActions.verifyOtp(payload));
    }

    const handleResendOtp = () => {
        const { dispatch } = props;
        let payl = { mobile_number : mobileNumber };
        setResendLimit((prev) => prev + 1);
        dispatch(verificationActions.sendOtp(payl));
    }

    return(
        <Grid 
            container 
            direction='row' 
            className={classes.rootPage}
        >
            <Grid item xs={12} sm={12} md = {6} lg={6} xl={6}
                className = {classes.leftContent}
            >
                {
                    page === 1 && 
                    <LoginCredentials 
                        setPage = {setPage}
                        handleLogin = {handleLogin}
                        history = {props.history}
                        status = {props.auth.status || ''}
                        errorMessage = {props.auth.error || ''}
                    />
                }
                {
                    page === 2 && 
                    <LoginVerification 
                        setPage = {setPage}
                        history = {props.history}
                        resendLimit = {resendLimit}
                        status = {props.auth.status || ''}
                        errorMessage = {props.auth.error || ''}
                        handleResendOtp = {handleResendOtp}
                        handleVerification = {handleVerification}
                    />
                }
                {
                    page === 3 &&
                    <ForgotPassword
                        setPage = {setPage}
                        history = {props.history}
                        handleForgot = {() => null}
                        errorMessage = {props.auth.error || ''}
                        status = {props.auth.status || ''}
                    />
                }
            </Grid>                    
        </Grid>
    );
}