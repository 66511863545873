import React, { useEffect, useState } from 'react';
import { Button, Navbar, Table } from "../../components";
import PendingAction from './components/pending-action';
import ApproveReject from './components/approve-reject';
import FundDetails from './components/fund-details';
import notyf from '../../utils/notification';
import { utils } from '../../utils';
import useStyles from './style';
import { 
    KeyboardArrowDown, 
    AutorenewOutlined, 
    ClearAllOutlined
} from '@material-ui/icons';
import {
    Box, Paper,
    Typography,
    TablePagination
} from '@material-ui/core';
import { 
    fundRequestConstants as frc 
} from '../../constants';

let tableHeaders = [
    {
        id: "requested_branch_name",
        placeHolder: "From Branch",
        label : "From Branch",
        type: 'dropdown',
        minWidth: '120px',
        maxWidth: '120px',
        dropdownItems: [],
        style : {}
    },
    {
        id: "request_amount",
        label : "Request Amount",
        type: 'text_wo_input',
        minWidth: '120px',
        maxWidth: '120px',
        style : {}
    },
    {
        id: "approved_amount",
        label : "Approved Amount",
        type: 'text_wo_input',
        minWidth: '120px',
        maxWidth: '120px',
        style : {}
    },
    {
        id: "request_transaction_type",
        placeHolder: "Requested Type",
        label : "Requested Type",
        type: 'dropdown',
        minWidth: '100px',
        maxWidth: '100px',
        dropdownItems: [
            {label: "CASH", value: "CASH"},
            {label: "RTGS", value: "RTGS"},
        ],
        style : {}
    },
    {
        id: "approved_transaction_type",
        placeHolder: "Approved Type",
        label : "Approved Type",
        type: 'dropdown',
        minWidth: '100px',
        maxWidth: '100px',
        dropdownItems: [
            {label: "CASH", value: "CASH"},
            {label: "RTGS", value: "RTGS"},
        ],
        style : {}
    },
    {
        id: "request_on",
        label : "Raised at",
        type: 'text',
        inputType: 'date',
        minWidth: '150px',
        maxWidth: '150px',
        style : {}
    },
    {
        id: 'status',
        label: 'Status',
        type: 'dropdown',
        placeHolder: "Status",
        dropdownItems: [
            {label: "Pending", value: "PENDING"},
            {label: "Approved", value: "APPROVED"},
            {label: "Rejected", value: "REJECTED"},
            {label: "Need Confirmation", value: "NEED_CONFIRMATION"},
            {label: "Confirmed", value: "CONFIRMED"},
            {label: "Cancelled", value: "CANCELLED"},
        ],
        minWidth: '120px',
        maxWidth: '120px',
    },
    {
        id: 'status',
        label: '',
        type: 'icon',
        minWidth: '50px',
        maxWidth: '50px'
    },
    {
        id: 'more',
        label: '',
        type: 'more',
        minWidth: '50px',
        maxWidth: '50px'
    }
];

let paginationDropDownList = [
    10, 20, 40
]

let rowMenuOptions = [
    {key: 'details', title: 'Details'},
    {key: 'action' , title: 'Action'}
];

let defaultQuery = {
    approved_transaction_type: '',
    request_transaction_type: '',
    approved_branch_id : '',
    request_on: '',
    status: ''
}

export default function FundRequestLayout(props){
    const classes = useStyles();
    const [pendingAction, setPendingAction] = useState({action: '', details: {}})
    const [filterQuery, setFilterQuery] = useState({ ...defaultQuery });
    const [clearInputFields, setClearInputFields] = useState(false);
    const [tableColumns, setTableColumns] = useState(tableHeaders);
    const [showPendingAction, setShowPendingAction] = useState(
        {flag: false, details: {}});
    const [showFundDetails, setShowFundDetails] = useState(
        {flag: false, value: {}});
    const [tableData, setTableData] = useState([]);
    const {
        handlePendingActionSubmit = () => null,
        getDetails = () => null,
        setLimit = () => null,
        setPage = () => null,
        fundDetails = [],
        noOfRecords = 0,
        branches = [],
        status = '',
        limit = 10,
        page = 0
    } = props;

    useEffect(() => {
        if(status === 'GETALL_SUCCESS'){
            let td = [];
            if(fundDetails.length > 0){
                td = fundDetails.map(e => {
                    return { ...e, 
                        request_on: utils.getLocalTime(e.request_on || ""),
                        approved_on: utils.getLocalTime(e.approved_on || "")
                    }
                });
            }
            setTableData([ ...td ]);
        }
        if(status === 'P_ACTION_SUCCESS'){
            setPendingAction({action : '', details: {}});
            setClearInputFields(true);
        }
    },[status]);

    useEffect(() => {
        let thc = [ ...tableHeaders ];
        if(branches.length > 0){
            thc = tableHeaders.map(e => {
                if(e.id === 'requested_branch_name') {
                    return {
                        ...e,
                        dropdownItems: branches.map(
                            f => { return { 
                                label: f.branch_name, 
                                value: f.id 
                            } }
                        )
                    }
                }else return e;
            });
        }
        setTableColumns(thc);
    }, [branches]);

    useEffect(() => {
        if(clearInputFields){
            setClearInputFields(false);
            setFilterQuery(defaultQuery);
        }
    }, [clearInputFields]);


    const handleMenuDropdown = (e, k, i, r) => {
        if(k.key === 'details'){
            setShowFundDetails({flag: true, value: r});
        }else if(k.key === 'action'){
            if(
                r.status?.toLowerCase() === 'pending' &&
                !['BRANCH_EMPLOYEE'].includes(utils.getRole())
            ){
                setShowPendingAction({flag: true, details: r});
            }else
                notyf.open({ type: 'error', message: frc.invalidAction });
        }
    }

    const onRowClick = (e, r, i) => {
        // setShowFundDetails({flag: true, value: r});
    }

    const onChangeRowsPerPage = (e) => {
        setLimit(e.target.value);
        setPage(0);
        getDetails(0, e.target.value, filterQuery);
    }
    
    const onChangePage = (e, val) => {
        setPage(val);
        getDetails(val, limit, filterQuery);
    }

    const handleFilterChange = (val, col) => {
        let fq = { ...filterQuery }
        if(col.id === 'requested_branch_name')
            fq = { ...fq, approved_branch_id : val || 0 };
        else
            fq = { ...fq, [col.id] : val };

        setPage(0);
        setFilterQuery(fq);
        getDetails(0, limit, fq);
    }

    const resetFilter = () => {
        setPage(0);
        setClearInputFields(true);
        getDetails(0, limit, defaultQuery);
    }

    const getFormattedData = (data = []) => {
        if(data.length === 0)
            return data;
        return data.map(e => {
            return {
                ...e,
                approved_amount: e.approved_amount ? 
                    utils.getFormattedAmount(e.approved_amount) : "",
                request_amount : e.request_amount ? 
                    utils.getFormattedAmount(e.request_amount) : ""
            }
        })
    }

    return (
        <Navbar 
            { ...props }
        >
            <Box className={classes.mainBox}>
                <Box className = {classes.tableTitleBox}>
                    <Typography variant = "h5">
                        Fund Request - in
                    </Typography>
                    <Box className={classes.actionButtons}>
                        <Button
                           text = "Clear Filter"
                            variant = "secondary"
                            Icon = {ClearAllOutlined}
                            style = {{
                                padding: '8px 16px', 
                                marginRight: '16px' 
                            }}
                            onClick = {resetFilter}
                        />
                        <Button
                            text = "Refresh"
                            variant = "secondary"
                            Icon = {AutorenewOutlined}
                            style = {{
                                padding: '8px 16px', 
                                marginRight: '16px' 
                            }}
                            onClick = {() => getDetails(page, limit, filterQuery)}
                        />
                    </Box>
                </Box>
            </Box>
            <Paper className = {classes.tableBox}>
                <Table
                    handleIconClick = {(r, i) => 
                        handleMenuDropdown({}, {key: 'action'}, i, r )}
                    tableData = {getFormattedData(tableData)}
                    handleMenuDropdown = {handleMenuDropdown}
                    loading = {status === 'GETALL_REQUEST'}
                    clearInputFields = {clearInputFields}
                    handleChange = {handleFilterChange}
                    rowMenuOptions = {rowMenuOptions}
                    onTableRowClick = {onRowClick}
                    tableColumns = {tableColumns}
                />
                 <TablePagination
                    rowsPerPageOptions={paginationDropDownList}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    onChangePage={onChangePage}
                    count={noOfRecords}
                    rowsPerPage={limit}
                    component='div'
                    page={page}
                    SelectProps={{
                        IconComponent: KeyboardArrowDown,
                        classes: { icon: classes.selectIcon },
                    }}                    
                />  
            </Paper>
            {
                showFundDetails &&
                <FundDetails
                    showDetails = {showFundDetails.flag}
                    detail = {showFundDetails.value}
                    setDetails = {setShowFundDetails}
                    status = {status}
                />
            }
            {
                showPendingAction.flag &&
                <PendingAction
                    showDetails = {showPendingAction.flag}
                    details = {showPendingAction.details}
                    setDetails = {(e, row) => {
                        setPendingAction({action: e, details: row})
                        setShowPendingAction({flag: false, details: {}});
                    }}
                />
            }
            {
                pendingAction.action &&
                <ApproveReject
                    status = {status}
                    showDetails = {pendingAction.action}
                    details = {pendingAction.details}
                    handleSubmit={(p) => handlePendingActionSubmit(p)}
                    setDetails = {() => setPendingAction({action: '', details: {}})}
                />
            }
        </Navbar>
    );
}