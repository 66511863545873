import React, { useState } from 'react';
import { ModalDialog, Button } from "../../../components";
import { utils } from '../../../utils';
import useStyles from '../style';
import {
    Grid, Box,
    Typography
} from '@material-ui/core';

export default function LeaveDetails(props) {
    const classes = useStyles();
    const {
        detail = {},
        showDetails = false,
        setDetails = () => null
    } = props;

    const onClose = () => {
        setDetails({flag: false, value: {}});
    }   

    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        Leave Request Details
                    </Typography>
                    <Typography variant = 'p' className = {`${classes.subTitle}`}>
                        Status : {' '}
                        <span className={classes[detail?.status?.toLowerCase()]}>
                            {detail.status}
                        </span>
                    </Typography>
                </Box>
                <Grid 
                    container 
                    direction='row' 
                    className = {classes.fundDetailsMainGrid}
                >
                    <Grid 
                        item xs={12} sm={6} 
                        md={6} lg={6} xl={6}
                        className = {classes.indGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Requested By
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {detail.requested_by || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Requested On :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {utils.getLocalTime(detail.request_on || "") || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Approved/Rejected By :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {detail.approved_by || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Approved/Rejected On :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {utils.getLocalTime(detail.approved_on || "") || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            cancelled On :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {detail.cancelled_on || 'NA'}
                        </Typography>
                    </Grid>
                    <Grid 
                        item xs={12} sm={6} 
                        md={6} lg={6} xl={6}
                        className = {classes.indGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            From Date :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {detail.from_date || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            To Date :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {detail.to_date || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Leave type :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {detail.leave_type || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            No of Days :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {detail.no_of_days || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Reason :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {detail.reason || 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    const footer = () => {
        return (
            <>
                <Button
                    text = {'Cancel'}
                    variant = { "Secondary" }
                    style = {{ padding: '8px 32px' }}
                    onClick = {onClose}
                />
            </>
        )
    }

    return (
        <ModalDialog
            maxWidth = {'md'}
            dialogTitle = {''}
            onClose = {onClose}
            open = {showDetails}
            dialogFooter = {footer()}
            dialogContent = {dialogContent()}
            classes = {{ paper: classes.dialogPaper}}
        />
    )

}