import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper } from '@material-ui/core';
import { Add, ClearAllOutlined } from '@material-ui/icons';
import { Button, Navbar, Table } from "../../components";
import OnboardBranch from './onboard';
import useStyles from './style';

let tableHeaders = [
    {
        id: "branch_name",
        label : "Name",
        type: 'text',
        minWidth: '200px',
        maxWidth: '200px',
        style : {}
    },
    {
        id: "area",
        label : "Area",
        type: 'text',
        minWidth: '200px',
        maxWidth: '200px',
        style : {}
    },
    {
        id: "city",
        label : "City",
        type: 'text',
        minWidth: '200px',
        maxWidth: '200px',
        style : {}
    },
    {
        id: "state",
        label : "state",
        type: 'text',
        minWidth: '200px',
        maxWidth: '200px',
        style : {}
    },
    {
        id: "",
        label : "",
        type: 'more',
        minWidth: '50px',
        style : {}
    }
];

let rowMenuOptions = [
    // {key: 'details' , title: 'Details'},
    {key: 'update' , title: 'Update'}
];

let defaultQuery = {
    city: '',
    state : '',
    area: '',
    branch_name: ''
}

export default function BranchLayout(props){

    const classes = useStyles();
    const [filteredData, setFilteredData] = useState([]);
    const [clearInputFields, setClearInputFields] = useState(false);
    const [filterQuery, setFilterQuery] = useState({ ...defaultQuery });
    const {
        errorValue = {show: false, keys: {}},
        setShowOnboard = () => null,
        setShowUpdate = () => null,
        handleChange = () => null,
        handleSubmit = () => null,
        showOnboard = false,
        branchDetails = [],
        showUpdate = false,
        details = {},
        status = '',
    } = props;

    useEffect(() => {
        setFilterQuery({ ...defaultQuery });
        setFilteredData([]);
        setClearInputFields(true);
    }, [branchDetails]);

    useEffect(() => {
        if(clearInputFields){
            setClearInputFields(false);
            setFilterQuery(defaultQuery);
        }
    }, [clearInputFields]);

    const checkIfExist = (source, query) => {
        return source?.toLowerCase().includes(query?.toLowerCase());
    }

    const getFilteredData = (fq = {}) => {
        let fd = [ ...branchDetails ];
        let fql = Object.keys(fq);
        if(fql.length > 0){
            fql.map(j => {
                if(fq[j]){
                    fd = fd.filter(e => 
                        checkIfExist(e[j], fq[j])
                    );
                }
            })
        }
        setFilteredData(fd);
    }

    const handleFilterChange = (val, col) => {
        let fq = { 
            ...filterQuery,
            [col.id] : val
        }
        setFilterQuery(fq);
        getFilteredData(fq);
    }

    const isFilterQueryExist = () => {
        let f = filterQuery;
        return ( f.state || f.area || f.city || f.branch_name );
    }

    const handleMenuDropdown = (e, key = {}, index = 0, row = {}) => {
        if(key.key === 'details')
            console.log('details');
        else if(key.key === 'update')
            setShowUpdate(true, row);
    }


    return (
        <Navbar 
            { ...props }
        >
            <Box className={classes.mainBox}>
                <Box className = {classes.tableTitleBox}>
                    <Typography variant = "h5">
                        Branch
                    </Typography>
                    <Box className={classes.actionButtons}>
                        <Button
                            text = "Clear Filter"
                            variant = "secondary"
                            Icon = {ClearAllOutlined}
                            style = {{
                                padding: '8px 16px', 
                                marginRight: '16px' 
                            }}
                            onClick = {() => setClearInputFields(true)}
                        />
                        <Button
                            text = "Add new"
                            variant = "primary"
                            Icon = {Add}
                            style = {{padding: '8px 16px'}}
                            onClick = {() => setShowOnboard(true) }
                        />
                    </Box>
                </Box>
            </Box>
            <Paper className = {classes.tableBox}>
                <Table
                    tableData = {isFilterQueryExist() ? filteredData : branchDetails}
                    handleMenuDropdown = {handleMenuDropdown}
                    loading = {status === 'GET_ALL_REQUEST'}
                    clearInputFields = {clearInputFields}
                    handleChange = { handleFilterChange }
                    rowMenuOptions = { rowMenuOptions }
                    tableColumns = {tableHeaders}
                    onTableRowClick = {() => null}
                />
            </Paper>
            {
                showOnboard &&
                <OnboardBranch
                    setShowOnboard = { setShowOnboard }
                    handleChange = {handleChange}
                    handleSubmit = {handleSubmit}
                    showOnboard = {showOnboard}
                    errorValue = {errorValue}
                    details = {details}
                    status = {status}
                    task = 'ADD'
                />
            }
            {
                showUpdate &&
                <OnboardBranch
                    setShowOnboard = { setShowUpdate }
                    handleChange = {handleChange}
                    handleSubmit = {handleSubmit}
                    showOnboard = {showUpdate}
                    errorValue = {errorValue}
                    details = {details}
                    status = {status}
                    task = 'UPDATE'
                />
            }
        </Navbar>
    );
}