import orderConstants from '../constants/orders';

const initialState = {
    status: '',
    transactions: [],
    orderDetails: [],
    orderItems: [],
    details: {},
    noOfRecords: 0
}

function orders(state = initialState, action) {
    switch(action.type) {
        case orderConstants.ADD_SUCCESS : 
            return {
                ...state,   
                status: 'ADD_SUCCESS',
            }
        case orderConstants.ADD_ERROR:
            return {
                ...state,
                status: 'ADD_ERROR'
            }
        case orderConstants.ADD_REQUEST:
            return {
                ...state,
                status: 'ADD_REQUEST'
            }
        case orderConstants.APPROVE_SUCCESS : 
            return {
                ...state,   
                status: 'APPROVE_SUCCESS',
            }
        case orderConstants.APPROVE_ERROR:
            return {
                ...state,
                status: 'APPROVE_ERROR'
            }
        case orderConstants.APPROVE_REQUEST:
            return {
                ...state,
                status: 'APPROVE_REQUEST'
            }
        case orderConstants.CONFIRM_SUCCESS : 
            return {
                ...state,   
                status: 'CONFIRM_SUCCESS',
            }
        case orderConstants.CONFIRM_ERROR:
            return {
                ...state,
                status: 'CONFIRM_ERROR'
            }
        case orderConstants.CONFIRM_REQUEST:
            return {
                ...state,
                status: 'CONFIRM_REQUEST'
            }
        case orderConstants.GETALL_SUCCESS : 
            return {
                ...state,   
                status: 'GETALL_SUCCESS',
                orderDetails: action.payload.data
            }
        case orderConstants.GETALL_ERROR:
            return {
                ...state,
                status: 'GETALL_ERROR'
            }
        case orderConstants.GETALL_REQUEST:
            return {
                ...state,
                status: 'GETALL_REQUEST'
            }
        case orderConstants.GET_SUCCESS : 
            return {
                ...state,   
                status: 'GET_SUCCESS',
                details: action.payload.data
            }
        case orderConstants.GET_ERROR:
            return {
                ...state,
                status: 'GET_ERROR'
            }
        case orderConstants.GET_REQUEST:
            return {
                ...state,
                status: 'GET_REQUEST'
            }
        case orderConstants.GET_ITEM_SUCCESS : 
            return {
                ...state,   
                status: 'GET_ITEM_SUCCESS',
                orderItems: action.payload.data
            }
        case orderConstants.GET_ITEM_ERROR:
            return {
                ...state,
                status: 'GET_ITEM_ERROR'
            }
        case orderConstants.GET_ITEM_REQUEST:
            return {
                ...state,
                status: 'GET_ITEM_REQUEST',
                orderItems: []
            }
        case orderConstants.TRANSACTIONS_SUCCESS : 
            return {
                ...state,   
                status: 'TRANSACTIONS_SUCCESS',
                transactions: action.payload.data
            }
        case orderConstants.TRANSACTIONS_ERROR:
            return {
                ...state,
                status: 'TRANSACTIONS_ERROR'
            }
        case orderConstants.TRANSACTIONS_REQUEST:
            return {
                ...state,
                status: 'TRANSACTIONS_REQUEST',
                transactions: []
            }
        case orderConstants.DROP_SUCCESS : 
            return {
                ...state,   
                status: 'DROP_SUCCESS',
            }
        case orderConstants.DROP_ERROR:
            return {
                ...state,
                status: 'DROP_ERROR'
            }
        case orderConstants.DROP_REQUEST:
            return {
                ...state,
                status: 'DROP_REQUEST'
            }
        case orderConstants.CANCEL_SUCCESS : 
            return {
                ...state,   
                status: 'CANCEL_SUCCESS',
            }
        case orderConstants.CANCEL_ERROR:
            return {
                ...state,
                status: 'CANCEL_ERROR'
            }
        case orderConstants.CANCEL_REQUEST:
            return {
                ...state,
                status: 'CANCEL_REQUEST'
            }
        case orderConstants.RELEASE_SUCCESS : 
            return {
                ...state,   
                status: 'RELEASE_SUCCESS',
            }
        case orderConstants.RELEASE_ERROR:
            return {
                ...state,
                status: 'RELEASE_ERROR'
            }
        case orderConstants.RELEASE_REQUEST:
            return {
                ...state,
                status: 'RELEASE_REQUEST'
            }
        case orderConstants.RECORDS_SUCCESS : 
            return {
                ...state,   
                status: 'RECORDS_SUCCESS',
                noOfRecords: action.payload.data.total
            }
        case orderConstants.RECORDS_ERROR:
            return {
                ...state,
                status: 'RECORDS_ERROR'
            }
        case orderConstants.RECORDS_REQUEST:
            return {
                ...state,
                status: 'RECORDS_REQUEST'
            }
        default: return state;
    }
}

export default orders;