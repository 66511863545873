import React, { useEffect, useState } from 'react';
import { Button } from '../../../components'; 
import Logo from '../../../assets/agc.png';
import {
    Box, Avatar,
    OutlinedInput,
    InputAdornment,
    FormControl,
    Typography,
    Paper, IconButton
} from '@material-ui/core';
import { 
    loginConstants as lc ,
    globalRegexp as gr
} from '../../../constants';
import { 
    Brightness2, VpnKey ,
    VisibilityOutlined,
    VisibilityOffOutlined,
} from '@material-ui/icons';
import useStyles from '../style';


export default function LoginVerification(props) {
    const classes = useStyles();
    const {
        handleResendOtp = () => null,
        setPage = () => null,
        errorMessage = '',
        resendLimit = 0,
        status = '',
        history
    } = props;
    const [showPassword,setShowPassword] = useState(false);
    const [error, setError] = useState({ show: false });
    const [values, setValues] = useState({pin: ''});
    const [timer, setEnableTimer] = useState(null);
    const [loading, setLoading] = useState(false);
    const [seconds, setSeconds] = useState(120);

    useEffect(() => {
        if(status === 'VERIFICATION_SUCCESS'){
            setLoading(false);
            history.push('/search');
        }else if(status === 'VERIFICATION_ERROR'){
            setLoading(false);
        }
    }, [status]);

    const enableCountDown = () => {
        let tmr = setInterval(() => {
            setSeconds((prev) => prev - 1);
        }, 1000);
        setEnableTimer(tmr);
    }

    useEffect(() => {
        if(resendLimit > 0 && resendLimit < 3){
            // enableCountDown();
        }
    }, [resendLimit]);

    // useEffect(() => {
    //     enableCountDown();

    //     return () => {
    //         clearInterval(timer);
    //         setEnableTimer(null);
    //     }
    // }, []);

    useEffect(() => {
        if(seconds === 0)
            clearInterval(timer);
    }, [seconds]);

    let errorStyle = {
        margin: `-2px 0px 08px 0px`,
        color: 'red'
    }

    const nonNumberReplace = (value) => {
        return value.replace(gr.nonNumeric, '');
    }

    const handleChange = (value, key) => {
        setValues((prev) => ({
            ...prev, 
            [key] : nonNumberReplace(value)
        }));
        setError({show: false});
    }

    const validateValues = () => {
        if(
            values.pin === '' || 
            values.pin.length < 4
        ){
            setError({show: true});
        }else {
            setLoading(true);
            props.handleVerification({ otp : values.pin });
        }
    }

    return (
        <Paper 
            elevation = {1} 
            component={'div'} 
            className={classes.paper}
        >
            <Box className={classes.company}>
                <Avatar src={Logo}/>
                {/* <Brightness2 
                    className = {classes.companyIcon}
                    color = 'primary'
                    fontSize = 'large'
                /> */}
                <Typography className={classes.companyName}>
                    Abaya Gold Buyers
                </Typography>
            </Box>
            <FormControl>
                <OutlinedInput 
                    value = {values.pin}
                    className={classes.inputField}
                    onChange={(e) => 
                        handleChange(e.target.value, 'pin')
                    }
                    startAdornment = {
                        <InputAdornment position='start'>
                            <VpnKey edge='start' color='primary'/>
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {
                                showPassword ? 
                                <VisibilityOutlined /> : 
                                <VisibilityOffOutlined />
                            }
                          </IconButton>
                        </InputAdornment>
                    }
                    placeholder={'Pin'}
                    type = {showPassword ? 'text' : 'password'}
                    id='pin'
                    error = {error.show}
                    inputProps = {{
                        required: true,
                        maxLength : 10
                    }}
                />
                {   
                    error.show &&
                    <small style = {errorStyle}>
                        {lc.invalidPin}
                    </small>
                }
            </FormControl>
            {/* {
                seconds < 1 && resendLimit < 3 &&
                <Typography 
                    className={classes.forgot}
                    loading = {loading}
                    variant = 'p'
                    onClick = {handleResendOtp}
                >
                    Resend OTP 
                </Typography>
            }
            {
                seconds > 0 &&
                <Typography 
                    className={classes.forgotDefault}
                    loading = {loading}
                    variant = 'p'
                >
                    Resend OTP in {seconds} seconds
                </Typography>
            } */}
            <Button 
                onClick={validateValues} 
                loading = {status === 'VERIFICATION_REQUEST'}
                text='Submit' 
                style ={{ 
                    margin : '20px 0px',
                    width: '300px'
                }}
            />
            { status === 'VERIFICATION_ERROR' &&
                <small style={{
                    color: 'red', 
                    margin: '10px 0px',
                    fontSize: '12px'
                }}>
                    {  errorMessage }
                </small>
            }
        </Paper>

    );
}