import branchConstants from '../constants/branch';

const initialState = {
    status: '',
    branchDetails: []
}

function branch(state = initialState, action) {
    switch(action.type) {
        case branchConstants.ADD_SUCCESS : 
            return {
                ...state,   
                status: 'ADD_SUCCESS',
            }
        case branchConstants.ADD_ERROR:
            return {
                ...state,
                status: 'ADD_ERROR'
            }
        case branchConstants.ADD_REQUEST:
            return {
                ...state,
                status: 'ADD_REQUEST'
            }
        case branchConstants.UPDATE_SUCCESS : 
            return {
                ...state,   
                status: 'UPDATE_SUCCESS',
            }
        case branchConstants.UPDATE_ERROR:
            return {
                ...state,
                status: 'UPDATE_ERROR'
            }
        case branchConstants.UPDATE_REQUEST:
            return {
                ...state,
                status: 'UPDATE_REQUEST'
            }
        case branchConstants.GET_ALL_SUCCESS : 
            return {
                ...state,   
                status: 'GET_ALL_SUCCESS',
                branchDetails: action.payload.data
            }
        case branchConstants.GET_ALL_ERROR:
            return {
                ...state,
                status: 'GET_ALL_ERROR'
            }
        case branchConstants.GET_ALL_REQUEST:
            return {
                ...state,
                status: 'GET_ALL_REQUEST'
            }
        default: return state;
    }
}

export default branch;