import React, { useState } from 'react';
import { 
    Button, Navbar, Table, UserDetailsCard, ViewImage
} from "../../components";
import { Box, Paper, Typography } from '@material-ui/core';
import OrderServiceCalc from './components/servicecalc';
import OrderTotalCalc from './components/totalcalc';
import MaterialRelease from './components/release';
import useStyles from './style';
import { utils } from '../../utils';


export default function OrderApprovalLayout(props){
    const classes = useStyles();
    const [imageShow, setImageShow] = useState({flag: false, details: {}});
    const {
        calculateAmount = () => null,
        setTableData = () => null,
        updateSummary = () => null,
        handleSubmit = () => null,
        handleReject = () => null,
        handleReset = () => null,
        tableHeaders = [],
        userDetails = {},
        pledge_item = {},
        tableData = [],
        details = {},
        status = '',
    } = props;


    const calculateMargin = (g) => {
        let grossAmount = calculateAmount(g , true)?.item_actual_price;
        let margin_amount = grossAmount - g.item_actual_price;
        let margin = (margin_amount / (margin_amount + g.item_actual_price) ) * 100;
        margin = utils.roundFixed(margin);
        return { ...g, margin_amount, margin };
    }

    const handleTotalChange = (value, name, detail = details) => {
            updateSummary({ ...detail, [name] : value });
    }

    const calculateNetAmount = (t) => {
        let amt = 0;
        t.map(e => amt = amt + ( e.item_actual_price || 0) );
        let total_amount = ( amt - details.service_charge_amount ) || 0;
        handleTotalChange(utils.roundValue(total_amount), 'approved_amount');
    }

    const handleRowInputChange = (v, row, index, column) => {
        let t = [ ...tableData ];
        let val = parseFloat(v.target.value) ?
                        ( parseFloat(v.target.value) >= 0 ? 
                            parseFloat(v.target.value) : 0 ) : 0
        t = t.map((e, i) => {
            if(i === index) {
                let g = { ...e, [column.id] : val };
                let final = column.id === 'item_actual_price' ? 
                                calculateMargin(g) : 
                                calculateAmount(g);
                return final;
            }else 
                return { ...e };
        });
        calculateNetAmount(t);
        setTableData(t);
    }

    const handleImageShow = (r,c,i) => {
        setImageShow({flag: true, details: r});
    }


    return (
        <Navbar 
            { ...props }
        >
            <Box style={{ margin: '2% 10%'}}>
                {
                    userDetails?.[0] &&
                    userDetails?.[0].users &&
                    <UserDetailsCard
                        overRideKey = {'Branch'}
                        overRideKeyValue = { userDetails[0]?.branch?.branch_name || "" }
                        userDetails = { userDetails[0] || {}}
                    />
                }
            </Box>
            <Box className={classes.mainBox}>
                <Box className = {classes.tableTitleBox}>
                    <Typography variant = "h5">
                        Order Details
                    </Typography>
                </Box>
            </Box>
            <Paper className = {classes.tableBox}>
                <Table
                    tableColumns = {tableHeaders}
                    rowMenuOptions = {[]}
                    handleMenuDropdown = {() => null}
                    tableData = {tableData}
                    handleChange = { () => null }
                    handleRowInputChange = {handleRowInputChange}
                    handlePopupAction = {handleImageShow}
                />
            </Paper>
            <Box className={classes.extraFields}>
                <MaterialRelease
                    {...props}
                    pledge_item = {pledge_item}
                    handleImageView = {handleImageShow}
                    handleTotalChange = {handleTotalChange}
                />
                <OrderServiceCalc 
                    {...props}
                    handleTotalChange = {handleTotalChange}
                />
                <OrderTotalCalc
                    {...props}
                    handleTotalChange = {handleTotalChange}
                />
            </Box>
            <Box className={classes.placeOrderBtn}>
                <Button
                    text = "Reset Order"
                    variant = "secondary"
                    style = {{
                        padding: '8px 16px',
                        marginRight: '16px', 
                        width: '200px'
                    }}
                    onClick = {handleReset}
                />
                <Button
                    text = "Reject"
                    variant = "primary"
                    style = {{
                        backgroundColor: '#db252e',
                        padding: '8px 16px', 
                        marginRight: '16px', 
                        width: '200px'
                    }}
                    onClick = {handleReject}
                /> 
                <Button
                    text = "Approve"
                    variant = "primary"
                    style = {{
                        padding: '8px 16px', 
                        width: '200px'
                    }}
                    onClick = {handleSubmit}
                />        
            </Box>
            {
                imageShow.flag &&
                <ViewImage
                    show = {imageShow.flag}
                    setShow = {setImageShow}
                    image = {imageShow.details?.item_image}
                    imageTitle = {imageShow.details?.name + " Image "}
                />
            }
        </Navbar>
    );
}
