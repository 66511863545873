import React from 'react';
import { Typography, Grid, FormControl } from '@material-ui/core';
import { utils } from '../../../utils';
import useStyles from '../style';

export default function ActionDetails (props) {
    const classes = useStyles();
    const {
        details = {},
    } = props;

// estimation_approved_branch_id: null
// estimation_raised_branch_id: 2
// payout_denomination: null

    return (
        <Grid xs={12} sm={4} md={4}
            className={classes.extraFieldsGrid}
        >
            {/* <FormControl className={classes.formControlText}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Requested By
                </Typography>
                <Typography
                    variant = "p"
                    className = {classes.textField}
                >
                    { details.order_request_placed_by || 'NA' }
                </Typography>
            </FormControl> */}
            <FormControl className={classes.formControlText}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Requested At
                </Typography>
                <Typography
                    variant = "p"
                    className = {classes.textField}
                >
                    { utils.getLocalTime(details.order_date || "" ) || 'NA' }
                </Typography>
            </FormControl>
            {/* <FormControl className={classes.formControlText}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Approved By
                </Typography>
                <Typography
                    variant = "p"
                    className = {classes.textField}
                >
                    { details.order_approved_by || 'NA' }
                </Typography>
            </FormControl> */}
            <FormControl className={classes.formControlText}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Approved At
                </Typography>
                <Typography
                    variant = "p"
                    className = {classes.textField}
                >
                    { utils.getLocalTime(details.order_approved_date || "" ) || 'NA' }
                </Typography>
            </FormControl>
            {/* <FormControl className={classes.formControlText}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Payment Done By
                </Typography>
                <Typography
                    variant = "p"
                    className = {classes.textField}
                >
                    { details.payout_done_by || 'NA' }
                </Typography>
            </FormControl> */}
            <FormControl className={classes.formControlText}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Payment Done At
                </Typography>
                <Typography
                    variant = "p"
                    className = {classes.textField}
                >
                    { utils.getLocalTime(details.payout_date || "" ) || 'NA' }
                </Typography>
            </FormControl>
            {/* <FormControl className={classes.formControlText}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Dropped By
                </Typography>
                <Typography
                    variant = "p"
                    className = {classes.textField}
                >
                    { details.order_dropped_by || 'NA' }
                </Typography>
            </FormControl> */}
            <FormControl className={classes.formControlText}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Dropped At
                </Typography>
                <Typography
                    variant = "p"
                    className = {classes.textField}
                >
                    { utils.getLocalTime(details.order_dropped_date || "" ) || 'NA' }
                </Typography>
            </FormControl>
            <FormControl className={classes.formControlText}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Request / Approval comment
                </Typography>
                <Typography
                    variant = "p"
                    className = {classes.textField}
                >
                    {   
                        details.approval_description ||
                        details.request_description || 
                        'NA'
                    }
                </Typography>
            </FormControl>
            <FormControl className={classes.formControlText}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Payout Remarks
                </Typography>
                <Typography
                    variant = "p"
                    className = {classes.textField}
                >
                    { details.remarks || 'NA' }
                </Typography>
            </FormControl>
        </Grid>
    )
}