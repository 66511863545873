import {httpClient} from "../utils";

export const attendanceService = {
    getTotalRecords,
    logAttendance,
    isLoggedForDay,
    getAll,
}

function getFilter(filter = {}){
    return `from_date=${filter.from_date || ''}` +
            `&to_date=${filter.to_date || ''}` +
            `&user_id=${filter.user_id || 0 }`+
            `&branch_id=${filter.branch_id || 0}`
}

function getAll(offset, limit, filter){
    return httpClient.callApi(
        "GET",
        `/attendance/getAttendanceDetails?`+ 
            getFilter(filter) +
            `&offset=${offset}`+ 
            `&limit=${limit}`
    );
}

function getTotalRecords(filter){
    return httpClient.callApi(
        "GET", `/attendance/getTotalAttendanceDetails?`+ getFilter(filter)
    );
}

function isLoggedForDay(userId = 0){
    return httpClient.callApi(
        "GET", `/attendance/isLoggedForDay?user_id=${userId}`
    );
}

function logAttendance(payload){
    return httpClient.callApi("POST",`/attendance/logAttendance`, payload);
}
