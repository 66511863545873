const expenseConstants = {
    ADD_REQUEST: 'EXPENSE_ADD_REQUEST',
    ADD_ERROR: 'EXPENSE_ADD_ERROR',
    ADD_SUCCESS: 'EXPENSE_ADD_SUCCESS',

    UPDATE_REQUEST : 'EXPENSE_UPDATE_REQUEST',
    UPDATE_SUCCESS : 'EXPENSE_UPDATE_SUCCESS',
    UPDATE_ERROR : 'EXPENSE_UPDATE_ERROR',

    RECORDS_REQUEST : 'EXPENSE_TOTAL_RECORDS_REQUEST',
    RECORDS_SUCCESS : 'EXPENSE_TOTAL_RECORDS_SUCCESS',
    RECORDS_ERROR : 'EXPENSE_TOTAL_RECORDS_ERROR',

    CANCEL_REQUEST : 'EXPENSE_CANCEL_REQUEST',
    CANCEL_SUCCESS : 'EXPENSE_CANCEL_SUCCESS',
    CANCEL_ERROR : 'EXPENSE_CANCEL_ERROR',

    GET_ALL_REQUEST : 'EXPENSE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS : 'EXPENSE_GET_ALL_SUCCESS',
    GET_ALL_ERROR : 'EXPENSE_GET_ALL_ERROR'
}

export default expenseConstants;