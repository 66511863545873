import { 
    makeStyles 
} from '@material-ui/core/styles';
import loginBg from '../../assets/loginbg1.png';

const useStyles = makeStyles((theme) => ({
    rootPage: {                 
        // backgroundColor: theme.palette.primary.light,
        backgroundImage: `url(${loginBg})`,
        backgroundSize: `100vw 100vh`,
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')] : {
            backgroundSize: 'auto'
        }
    },
    leftContent: {                   
        maxHeight: '70vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.color.white,
        width: 420,
        maxHeight: 500,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: `${theme.spacing(4)}px 0px`,
        borderRadius: theme.spacer * 1,
        [theme.breakpoints.down('xs')] : {
            width: '100vw',
            minHeight: '80vh' 
        }
    },
    avatarBox: {                    
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    avatarName: {                     
        display: 'flex',
        fontSize: '16px',
        color: '#fff',
        paddingLeft: '20px',
        alignItems: 'center',
        lineHeight: '16px'
    },
    inputField: {                                               
        height: theme.spacing(5), 
        margin: `0px 0px ${theme.spacer * 1}px 0px`, 
        width: '300px',
        backgroundColor: theme.color.white,
        border: 'none',
        borderRadius: theme.spacer * 0.3,
    },
    phoneInputField : {                                           //
        margin: `0px 0px ${theme.spacer * 3}px 0px`, 
        width: '300px',
        height: theme.spacing(5),
        backgroundColor: theme.color.white,
        border: 'none',
        borderRadius: theme.spacer * 0.5,
        '&::hover' : {
            border: 'none'
        }
    },
    forgot : {
        fontSize: theme.spacer * 1.5,
        color: theme.palette.primary.main,
        margin: theme.spacer * 1,
        cursor: 'pointer'
    },
    forgotDefault: {
        fontSize: theme.spacer * 1.5,
        color: theme.palette.primary.main,
        margin: theme.spacer * 1,
    },
    company : {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center',
        margin: '10px 0px 30px'
    },
    companyName: {
        padding: `0px ${theme.spacer}px`,
        fontSize: `${theme.spacer * 2}px`,
        color: theme.palette.primary.main
    },
    companyIcon: {
        transform: 'rotate(15deg)',
        fontSize: theme.spacing(5)
    },
}));

export default useStyles;