import React from 'react';
import { ModalDialog, Button } from "../../components";
import useStyles from './style';
import {
    Grid, Box,
    Typography,
} from '@material-ui/core';


export default function InvoiceAction(props) {
    const classes = useStyles();
    const {
        handleConfirmAction = () => null,
        handleClick = () => null,
        setDetails = () => null,
        enableRelease = false,
        details = {},
    } = props;

    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' 
                        style={{textAlign: 'center'}}
                    >
                        Which one you wish to choose ?
                    </Typography>
                </Box>
                <Grid 
                    container 
                    direction='row' 
                    className = {classes.fundDetailsMainGrid}
                >
                    <Grid 
                        item xs={12} xl={12}
                        sm={12} md={12} lg={12} 
                        className = {classes.actionGrid}
                    >
                        <Button
                            text = {`Generate Estimate`}
                            variant = "primary"
                            style = {{
                                padding: '8px 16px',
                                margin: '40px 0px 0px', 
                                width: '220px',
                            }}
                            onClick = {() => handleClick(1) }
                        />
                        <Button
                            text = {`Generate Invoice`}
                            variant = "primary"
                            style = {{
                                padding: '8px 16px',
                                margin: '40px 0px', 
                                width: '220px',
                            }}
                            onClick = {() => handleClick(0) }
                        />
                        {
                            enableRelease &&
                            details.order_status === "COMPLETED" &&
                            details.order_type === "RELEASE" &&
                            <Button
                                text = {`Confirm Pledge receival`}
                                variant = "primary"
                                style = {{
                                    padding: '8px 16px',
                                    margin: '0px 0px 40px', 
                                    width: '220px',
                                }}
                                onClick = {() => handleConfirmAction(details) }
                            />
                        }
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {true}
            onClose = {setDetails}
            dialogTitle = ''
            dialogContent = {dialogContent()}
            maxWidth = {'sm'}
            classes = {{ paper: classes.dialogPaper}}
            dialogFooter = {[]}
        />
    )

}