import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { authActions } from "../../actions/authentication";
import store from '../../reducers';

const ProtectedRoute =({component: Component, ...rest}) => {

    const renderRoute = (props) => {
        let auth = store.getState().auth;
        if(auth?.accessToken){
            return <Component {...props} />;
        }else {
            store.dispatch(authActions.logout(false));
            return <Redirect to='/' />;
        }
    }

    return(
        <Route
            {...rest}
            render = { 
                (props) => renderRoute(props)
            }
        />
    );

}

export default ProtectedRoute;