import React from 'react';
import { Loader } from './components';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import  AgcTheme  from './agc-theme';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/es/integration/react';
import RootLoader from "./screens/rootloader";


import './App.css';

const App = ({store}) => {

  return(
      <Provider store={store}>
          <PersistGate 
            loading={<Loader style={{ color: '#B1740F' }}/>} 
            persistor={persistStore(store)} 
          >
            <ThemeProvider theme={AgcTheme}>
              <CssBaseline />
              <RootLoader />
            </ThemeProvider>
          </PersistGate>
      </Provider>
  )
}

export default App;