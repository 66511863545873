import React, { useEffect, useState } from 'react';
import FilledInput from '@material-ui/core/FilledInput';
import { CloudDoneOutlined, CloudUploadOutlined, ErrorOutline } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';

export default function TableRowInput(props) {
    const hiddenFileInput = React.useRef(null);
    const { 
        inputType = 'text',
        style = {}, 
        onChange = () => null, 
        placeholder = '' ,
        maxLength = 200,
        disabled = false
    } = props;

    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(props.value);
    },[props.value]);

    useEffect(() => {
        if(props.clearInputFields)
            setValue('');
    },[props.clearInputFields]);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };


    if(inputType !== 'file'){
        return (
            <FilledInput
                value={props.clearInputFields ? '' : value}
                disableUnderline
                onChange={(e) => setValue(e.target.value)}
                onBlur={onChange}
                type={ inputType ? inputType : 'text' }
                placeholder={placeholder}
                style={disabled ? { ...style, color: '#000' } : style}
                inputProps={{ maxLength }}
                disabled = { disabled }
            />
        );
    }
    else {
        return (
            <div style ={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
            }}>
                <FilledInput
                    disableUnderline
                    onChange={(e) => {
                        setValue(e.target.value);
                        onChange(e);
                    }}
                    style={
                        disabled ? 
                            { ...style, color: '#000', display: 'none' } : 
                            { ...style, display: 'none'} 
                    }
                    type={ inputType ? inputType : 'text' }
                    inputRef = {hiddenFileInput}
                    placeholder={placeholder}
                    inputProps={{ maxLength }}
                    disabled = { disabled }
                />
                {
                    value === "" &&
                    <CloudUploadOutlined 
                        color = "primary"
                        onClick = {handleClick}
                    />
                }
                {
                    !["", "uploading", "failed"].includes(value) &&
                    <CloudDoneOutlined
                        style = {{color: '#3bab52'}}
                        onClick = {handleClick}
                    />
                }
                {
                    value === "uploading" &&
                    <CircularProgress 
                        color = "primary"
                        size = {20}
                    />
                }
                {
                    value === "failed" &&
                    <ErrorOutline 
                        style={{color: '#db252e'}} 
                        onClick = {handleClick}
                    />
                }
            </div>
        );
    }
}
