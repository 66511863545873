import settingsConstants from '../constants/settings';

const initialState = {
    status: '',
}

function settings(state = initialState, action) {
    switch(action.type) {
        case settingsConstants.UPDATE_PASS_SUCCESS : 
            return {
                ...state,   
                status: 'UPDATE_PASS_SUCCESS',
            }
        case settingsConstants.UPDATE_PASS_ERROR:
            return {
                ...state,
                status: 'UPDATE_PASS_ERROR'
            }
        case settingsConstants.UPDATE_PASS_REQUEST:
            return {
                ...state,
                status: 'UPDATE_PASS_REQUEST'
            }
        case settingsConstants.ENABLE_SM_REQUEST : 
            return {
                ...state,   
                status: 'ENABLE_SM_REQUEST',
            }
        case settingsConstants.ENABLE_SM_SUCCESS:
            return {
                ...state,
                status: 'ENABLE_SM_SUCCESS'
            }
        case settingsConstants.ENABLE_SM_ERROR:
            return {
                ...state,
                status: 'ENABLE_SM_ERROR'
            }
        case settingsConstants.DISABLE_SM_REQUEST : 
            return {
                ...state,   
                status: 'DISABLE_SM_REQUEST',
            }
        case settingsConstants.DISABLE_SM_SUCCESS:
            return {
                ...state,
                status: 'DISABLE_SM_SUCCESS'
            }
        case settingsConstants.DISABLE_SM_ERROR:
            return {
                ...state,
                status: 'DISABLE_SM_ERROR'
            }
        default: return state;
    }
}

export default settings;