import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoginLayout from './layout';

class Login extends Component {
    componentDidMount() {
        const { auth = {}, history } = this.props;

        if(auth.loggedIn){
            history.push('/search');
        }
    }

    render() {
        return (
            <LoginLayout 
                { ...this.props} 
            />
        );
    }
}

function mapStateToProps(state) {
    const { auth, profile } = state;

    return {
        auth,
        profile
    }
}

export default connect(mapStateToProps)(Login);