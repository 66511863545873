import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    buttonPrimary: {
        minHeight: theme.spacing(4),
        maxwidth: '100px',
        padding: `${theme.spacer * 0}px ${theme.spacing(2)}px`,
        boxShadow: '0px 2px 4px rgb(0 0 0 / 8%)',
        borderRadius: theme.spacer * 0.5,
        backgroundColor : theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontSize: `${theme.spacing(2)}px`,
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        cursor: 'pointer',
        '&:hover' : {
            backgroundColor: theme.palette.primary.light, //+ 'DD'
        }
    },
    buttonSecondary: {
        minHeight: theme.spacing(4),
        maxwidth: '100px',
        padding: `${theme.spacer * 0}px ${theme.spacing(2)}px`,
        boxShadow: '0px 2px 4px rgb(0 0 0 / 8%)',
        borderRadius: theme.spacer * 0.5,
        backgroundColor : theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        fontSize: `${theme.spacing(2)}px`,
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        cursor: 'pointer',
        '&:hover' : {
            backgroundColor: theme.palette.primary.light, //+ 'DD'
            color: theme.palette.secondary.main
        }
    },
    buttonError: {
        minHeight: theme.spacing(4),
        maxwidth: '100px',
        padding: `${theme.spacer * 0}px ${theme.spacing(2)}px`,
        boxShadow: '0px 2px 4px rgb(0 0 0 / 8%)',
        borderRadius: theme.spacer * 0.5,
        backgroundColor : theme.palette.secondary.main,
        color: theme.color.red,
        fontSize: `${theme.spacing(2)}px`,
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        cursor: 'pointer',
        '&:hover' : {
            backgroundColor: theme.color.red, //+ 'DD'
            color: theme.palette.secondary.main
        }
    },
    icon: {
        fontSize : `${theme.spacer * 2.5}px`,
        marginRight: `${theme.spacer * 1}px`
    }
}));

export default function Button(props) {

    const classes = useStyles();

    const { 
        onClick = () => null,
        text = '',
        Icon = null,
        style = {},
        loading = false,
        variant = 'primary'
    } = props;

    return (
        <Box 
            className={
                variant.toLowerCase() === 'primary' ? 
                        classes.buttonPrimary :
                        (variant?.toLowerCase() === 'error' ?
                        classes.buttonError :
                        classes.buttonSecondary)
            }
            onClick = {onClick}
            style = {style}
        >
            { 
                loading 
                    ? <CircularProgress 
                        color = {
                            variant === 'primary' ? 
                                'secondary' :
                                'primary'
                        }
                        size = { 20 }
                        thickness = {1.8}
                    /> 
                    : 
                    <> 
                        { Icon && <Icon className={classes.icon} />} 
                        { text }
                    </>
            }
        </Box>
    );
}