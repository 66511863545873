import React, { useState } from 'react';
import { smsLogsConstants as slc } from '../../constants';
import { Button, Navbar, Table } from "../../components";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { utils } from '../../utils';
import useStyles from './style';
import {
    Box, FormControl, Paper,
    Grid, Typography,
} from '@material-ui/core';


let tableHeaders = [
    {
        id: "mobile_number",
        label : "Mobile Number",
        type: 'text_wo_input',
        minWidth: '200px',
        maxWidth: '200px',
        style : {}
    },
    {
        id: "secret_key",
        label : "One time password",
        type: 'text_wo_input',
        minWidth: '200px',
        maxWidth: '200px',
        style : {}
    },
    {
        id: "sent_time",
        label : "Sent At",
        type: 'text_wo_input',
        minWidth: '200px',
        maxWidth: '200px',
        style : {}
    }
];


export default function SmsLogsLayout(props){

    const classes = useStyles();

    const [phone, setPhone] = useState('');
    const [error, setError] = useState('');
    const {
        status = '',
        logs = [],
        history
    } = props;

    const handleChange = (value, source) => {
        if(source === 'phone') 
            setPhone("+"+value);

        setError('');
    }

    const handleSearch = () => {
        let payload = {};
        if( phone.length < 5 ){
            setError('Mobile Number Required to search');
        }
        else {
            payload = { mobile_number : phone };
            props.handleSearch(payload);
        }
    }

    const getLogsWithTime = () => {
        if(logs.length > 0){
            return logs.map(e => {
                return { 
                    ...e,
                    sent_time: utils.getLocalTime(e.sent_time)
                }
            })
        }else
            return [];
    }

    return (
        <Navbar 
            { ...props }
        >
            <Box>
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' 
                        className={classes.title}
                    >
                        {slc.title}
                    </Typography>
                    <Typography variant = 'p' 
                        className={classes.subTitle}
                    >
                        {slc.subTitle}
                    </Typography>
                </Box>
                <Grid 
                    container 
                    direction='row' 
                    className = {classes.mainGrid}
                >
                    <Grid 
                        item xs={12} sm={12} 
                        md={12} lg={12} xl={12}
                        className = {classes.leftGrid}
                    >
                        <FormControl className={classes.phoneInputField}>
                            <Typography variant='subtitle2' 
                                className={classes.label}
                            >
                                Phone Number
                            </Typography>
                            <PhoneInput
                                country={'in'}
                                placeholder={'Phone Number'}
                                inputStyle = {{
                                    height: '40px',
                                    borderColor: error ? 'red' : ''
                                }}
                                countryCodeEditable={false}
                                onChange={ phone => handleChange(phone, 'phone') }
                                inputProps = {{
                                    required: true,
                                    autoFocus: true
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid 
                        item xs={12} sm={12} 
                        md={5} lg={5} xl={5}
                        className = {classes.orGrid}
                    >
                        <small className='red'>
                            {error !== '' && error}
                        </small>
                        <Button 
                            onClick={handleSearch} 
                            loading = {status === 'GET_REQUEST'}
                            text = 'Search' 
                            style ={{ 
                                margin : '10px 0px',
                                width: '300px'
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box className = {classes.userDetailsBox}>                
                    <Paper className = {classes.tableBox}>
                        <Table
                            tableColumns = {tableHeaders}
                            loading = {status === 'GET_REQUEST'}
                            tableData = {getLogsWithTime()}
                            handleChange = { () => null }
                        />
                    </Paper>
            </Box>
        </Navbar>
    );
}