import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ordersConstants as orc } from '../../constants';
import { orderActions } from '../../actions/orders';
import { userActions } from '../../actions/user';
import notyf from '../../utils/notification';
import OrderDetailsLayout from './layout';
import tHeader from './tableHeader.json';

class OrderDetails extends Component{
    constructor(props) {
        super(props);
        this.state = {
            tableHeaders: [ ...tHeader ],
            summary: { },
            pledge_item: {},
            tableData : []
        }
    }

    handleInvalidOrder = () => {
        const { history } = this.props;
        notyf.open({type: 'error' , message: orc.invalidOrder });
        history.push('/orders');
    }

    componentDidMount() {
        const { dispatch, match, orders } = this.props;
        let order_id = parseInt(match.params.id);
        if(order_id){
            dispatch(orderActions.get(order_id));
        }else{
            this.handleInvalidOrder();
        }
    }

    calculateAmount = (g, flag = false) => {
        let is_hallmark = false;
        if(g.is_hallmark === true) is_hallmark = 'Yes';
        else if(g.is_hallmark === false) is_hallmark = 'No';
        else is_hallmark = g.is_hallmark;

        return { ...g, is_hallmark };
    }

    calculateEachItemAmount = (data) => {
        return data.map(e => { return this.calculateAmount(e); });
    }

    componentDidUpdate(prevProps){
        const { history, orders, dispatch } = this.props;
        let orderPropsChanged = orders.status !== prevProps.orders.status;


        if(orderPropsChanged){
            if(orders.status === 'APPROVE_SUCCESS'){
                history.push('/orders');
            }

            if(
                orders.status === 'GET_SUCCESS' &&
                !orders.details?.order_items?.length
            ){
                this.handleInvalidOrder();
            }
            if(
                orders.status === 'GET_SUCCESS' &&
                orders.details?.order_items?.length
            ){
                let odi = this.calculateEachItemAmount(orders.details.order_items);
                let summary = { ...orders.details.order };
                let pledge_item = { ...orders.details.order_pledge?.[0]}
                this.setState({ tableData: odi, summary, pledge_item });
                dispatch(userActions.get({
                    "filter": [{ "field": "id", "op": "eq", "value": summary.customer_id }]
                }));
            }
        }
    }


    render() {
        return (
            <OrderDetailsLayout
                {...this.props}
                details = {this.state.summary}
                tableData = { this.state.tableData}
                userStatus = {this.props.user.status}
                tableHeaders = { this.state.tableHeaders}
                pledge_item = {this.state.pledge_item}
                userDetails = {this.props.user.userDetails}
                materialDetails = {this.props.material.details || []}
                calculateAmount = {this.calculateAmount}
                setTableData = {(e) => this.setState({tableData : e })}
                updateSummary = {(e) => this.setState({ summary: e })}
            />
        );
    }
}

function mapStateToProps(state) {
    const { profile, material, user, orders, notifications } = state;
    
    return {
        profile,
        material,
        user,
        orders,
        notifications
    }
}

export default connect(mapStateToProps)(OrderDetails);