import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    tableBox : {
        margin: '1% 2%',
    },
    mainBox : {
        margin: '2%'
    },
    addMainBox: {
        margin: '2%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    tableTitleBox : {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    inputField: {                                               
        height: theme.spacing(5),
        margin: `0px 0px ${theme.spacer * 0}px 0px`, 
        width: '300px',
        backgroundColor: theme.color.white,
        border: 'none',
        borderRadius: theme.spacer * 0.3,
        fontSize: '14px !important'
    },
    inputFieldDisabled: {
        height: theme.spacing(5),
        margin: `0px 0px ${theme.spacer * 0}px 0px`, 
        width: '300px',
        backgroundColor: theme.color.white,
        opacity: 0.6,
        border: 'none',
        borderRadius: theme.spacer * 0.3,
        fontSize: '14px !important',
        color: `${theme.color.black} !important`,
    },
    formControlInput:{
        marginBottom: theme.spacer
    },
    extraFields : {
        display: 'flex',
        flexDirection: 'row',
    },
    extraFieldsGrid: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // justifyContent: 'center'
    },
    label: {
        marginBottom: '8px'
    },
    placeOrderBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        margin: `${theme.spacer * 5}px 0px`
    }
}));

export default useStyles;