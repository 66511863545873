import { createMuiTheme } from '@material-ui/core/styles';


const grey10 = '#fafafa';
const grey40 = '#e4e4e6';
const grey80 = '#b7b7a4';
const gold =  '#B1740F'; 
const blue = 'blue';
const black = '#000000';
const white = '#ffffff';
const green = '#3bab52';
const red = '#db252e';
const spacer = 8;

const AgcTheme = createMuiTheme({
    palette : {
        background: {
            primary: gold,
            secondary: white
        },
        grey : grey10,
        primary : {
            main: gold,
            // color: grey10,
            contrastText: white,
        },
        secondary: {
            main: white,
            contrastText: gold,
        },
        text: {
            hint: white,
            primary: black,
            secondary: gold,
            disabled: grey10
        },
    },
    color: {
        grey10 : grey10,
        grey40 : grey40,
        grey80 : grey80,
        gold : gold,
        black : black,
        blue : blue,
        white : white,
        green : green,
        red : red
    },
    typography: {
        button: {
            fontSize: '14px',
            backgroundColor: gold,
            color: white,
            fontWeight: 500,
            padding: `${spacer * 2}px 0px`
        },
        h6: {
            fontSize: `${spacer * 2}px`
        }
    },
    spacing : [
        0,
        spacer * 1,
        spacer * 2,
        spacer * 3,
        spacer * 4,
        spacer * 5,
        spacer * 6,
        spacer * 7,
        spacer * 8,
        spacer * 9,
        spacer * 10
    ],
    spacer: spacer,

    overrides: {
        MuiAppBar: {
          root: {
            borderRadius: '0px !important',
            boxShadow: 'none !important',
          },
        },
        MuiButton: {
          root: {
            borderRadius: '2px !important',
            height: '40px',
            paddingLeft: '16px',
            paddingRight: '16px',
            boxShadow: 'none',
          },
          contained: {
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
            marginLeft: spacer + 'px',
          },
          text: {
            fontSize: '14px',
          },
          containedSecondary: {
            '&:hover': {
              backgroundColor: `${white} !important`,
              // color: `${white}`
            },
          },
          containedRed: {
            background: '#DA1E28',
            color: white
          },
        },
        // MuiOutlinedInput: {
        //   root: {
        //     width: '100%',
        //     border: '1px solid #CDD4E4',
        //     borderRadius: '0px',
        //     background: '#FFFF',
        //     height: '40px',
        //     lineHeight: '24px',
        //     paddingBottom: '0px',
        //   },
        //   input: {
        //     padding: '0px !important',
        //     height: '100%',
        //     paddingLeft: '8px !important',
        //     backgroundColor: 'transparent !important',
        //   },
    
        //   text: {
        //     paddingBottom: '0px',
        //   },
        //   notchedOutline: {
        //     border: '0px',
        //   },
        // },
        MuiFilledInput: {
          root: {
            borderRadius: '0px !important',
            height: spacer * 4 + 'px',
            width: '100%',
          },
          input: {
            padding: '0px 0px 0px 8px',
            height: '100%',
            fontSize: '13px',
          },
          text: {
            paddingBottom: '0px',
          },
          multiline: {
            // minHeight: '72px',
            // padding: '0px 0px 0px 8px',
            // height: '100%',
            // fontSize: '13px',
            // paddingTop: spacer + 'px',
            padding: '8px',
            height: '100%',
            fontSize: '13px',
          },
        },
        MuiInputBase: {
          root: {
            height: '32px',
            borderRadius: '0px',
            padding: '0px 0px 0px 0px',
            paddingBottom: '0px',
            background: 'none',
          },
          text: {
            paddingBottom: '0px',
          },
          input: {
            paddingLeft: spacer * 1 + 'px',
            height: '100%',
            background: 'init',
            backgroundColor: 'unset',
          },
        },
        MuiNativeSelect: {
          outlined: {
            paddingRight: '0px !important',
          },
          icon: {
            color: 'black',
            display: 'none !important',
            '& path': {
              d: 'path("M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z")',
            },
          },
          select: {
            '&:focus': {
              backgroundColor: 'transparent !important',
            },
          },
        },
        MuiSelect: {
          select: {
            '&:focus': {
              backgroundColor: 'transparent !important',
            },
          },
        },
        MuiPaper: {
          root: {
            background: '#FFFFFF',
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.24)',
            borderRadius: '4px',
          },
          elevation1: {
            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.24)',
            // boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.24)',
          },
        },
        MuiToolbar: {
          root: {
            boxShadow: 'none',
          },
        },
        MuiMenuItem: {
          root: {
            fontSize: '14px',
            backgroundColor: '#fff',
            '&$selected': {
              backgroundColor: '#EDF4FF !important',
            },
          },
        },
        //calendar style
        MuiPickersModal: {
          dialogRoot: {
            maxHeight: '542px !important',
            maxWidth: '310px',
            height: '542px !important',
            width: '310px',
          },
        },
        MuiDialog: {
          paperWidthMd: {
            height: '696px',
            width: '856px !important',
          },
          //minimal popup style
          paperWidthSm: {
            width: '440px',
            height: 'auto',
            maxWidth: '440px',
            maxHeight: '500px',
            '& .MuiDialogTitle-root': {
              '& .MuiBox-root': {
                marginTop: '-27px',
                textAlign: 'left',
    
                '& .MuiTypography-h2': {
                  fontSize: '18px',
                  fontWeight: '500',
                  lineHeight: '40px',
                },
                '& .MuiTypography-h3': {
                  fontSize: '16px',
                  lineHeight: '24px',
                },
              },
            },
            '& .MuiDialogContent-root': {
              //marginTop: '32px',
              marginLeft: '-12px',
              '& .MuiBox-root': {
                width: '100%',
                //marginTop: `-${spacer*2}px`,
                '& label': {
                  lineHeight: '32px',
                },
                '& .MuiFilledInput-root': {
                  marginTop: 'unset !important',
                },
              },
            },
            '& .MuiDialogActions-root': {
              borderTop: 'unset',
            },
          },
        },
        MuiFormControl: {
          root: {
            marginTop: spacer + 'px',
            marginBottom: spacer + 'px',
          },
        },
        MuiButtonBase: {
          root: {
            fontFamily: 'Saira',
          },
        },
        MuiFormLabel: {
          root: {
            fontSize: '13px',
            lineHeight: '32px',
            fontWeight: 'normal',
            backgroundColor: 'transparent',
            color: black + ' !important',
            paddingBottom: '0px',
          },
        },
        MuiInputLabel: {
          formControl: {
            position: 'relative',
            transform: 'none',
          },
          focused: {
            transform: 'none',
          },
          shrink: {
            transform: 'none',
          },
        },
        MuiContainer: {
          root: {},
        },
        MuiCssBaseline: {
          '@global': {
            html: {
              WebkitFontSmoothing: 'auto',
            },
            '.eBIpie .muicc-colorpicker-button': {
              margin: '6px 8px',
              width: "32px",
              height: "32px",
              boxShadow: 'none',
              border: "1px solid #CDD4E4",
              borderRadius: "0px !important"
            },
            '.MuiInput-underline:after': {
              border: 'none !important'
            },
            '.MuiFilledInput-underline:after': {
              border: 'none !important'
            },
            '.MuiAutocomplete-endAdornment': {
              top: "unset !important"
            },
            'label + .MuiInput-formControl': {
              marginTop: '0px !important',
            },
            'svg image[*|href*="addLane"],[*|href*="more"],[*|href*="dmn"]': {
              cursor: 'pointer'
            },
            'svg rect': {
              cursor: 'pointer'
            },
            'svg image[*|href*="screen-no-preview"],[*|href*="play-start"],[*|href*="play-stop"],[*|href*="Script"],[*|href*="service"]': {
              pointerEvents: 'none',
            },
            'svg foreignObject div div div': {
              cursor: 'text',
              wordBreak: 'break-all',
              width: '120px',
              height: 'auto',
              padding: '5px 0',
            },
            '.form-input': {
              marginTop: '16px',
              '& .MuiInputBase-root': {
                marginTop: '8px',
              },
            },
          },
        },
      }
})

export default AgcTheme;