import React from 'react';
import { Typography, Box, Grid, Avatar } from '@material-ui/core';
import { Button } from '../../../components';
import { utils } from '../../../utils';
import useStyles from '../style';


export default function ProfileDetails(props){

    const classes = useStyles();
    const { details = {} } = props;

    return (
        <Box className={classes.mostOuterFlexBox}>
            <Box className = {classes.outerBox}>
                <Grid
                    xs = {12} sm = {2} md = {2} lg = {2} 
                    xl={2} className = {classes.mainGrid}
                >
                    <Typography variant='h6' 
                        className= {classes.blockTitle}
                    >
                        Profile
                    </Typography>
                </Grid>
                <Grid
                    xs = {12} sm = {5} md = {5} lg = {5} 
                    xl={5} className = {classes.mainTitleGrid}
                >
                    <Box className={classes.details}>
                        <Typography variant='subtitle2' className={classes.subTitleLabel}>
                            <span className={classes.titleLabel}> First Name : </span>
                            {details.first_name || "NA"}
                        </Typography>
                    </Box>
                    <Box className={classes.details}>
                        <Typography variant='subtitle2' className={classes.subTitleLabel}>
                            <span className={classes.titleLabel}> Last Name : </span>
                            {details.last_name || "NA"}
                        </Typography>
                    </Box>
                    <Box className={classes.details}>
                        <Typography variant='subtitle2' className={classes.subTitleLabel}>
                            <span className={classes.titleLabel}> Email : </span>
                            {details.email || "NA"}
                        </Typography>
                    </Box>
                    <Box className={classes.details}>
                        <Typography variant='subtitle2' className={classes.subTitleLabel}>
                            <span className={classes.titleLabel}> Date of Birth :  </span>
                            {details.dob || "NA"}
                        </Typography>
                    </Box>
                    <Box className={classes.details}>
                        <Typography variant='subtitle2' className={classes.subTitleLabel}>
                            <span className={classes.titleLabel}> Gender : </span>
                            {details.gender || "NA"}
                        </Typography>
                    </Box>            
                </Grid>     
                <Grid
                    xs = {12} sm = {5} md = {5} lg = {5} 
                    xl={5} className = {classes.mainGrid}
                >
                    <Avatar
                        src = {utils.getImageUrl(details?.user_image || "")}
                        className = {classes.avatarProfile}
                    />
                </Grid>           
            </Box>
            <Box className={classes.actionButtons}>
                {/* <Button 
                    onClick = {() => null}
                    style={{
                        width: '200px',
                        maxHeight: '32px',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                    text = 'Edit Profile'
                /> */}
            </Box>        
        </Box>
    );
}