import React, { Component } from 'react';
import { attendanceActions } from '../../actions/attendance';
import { branchActions } from '../../actions/branch';
import AttendanceLayout from './layout';
import { connect } from 'react-redux';
import { utils } from '../../utils';

class Attendance extends Component{
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            limit: 10
        }
    }

    componentDidMount(){
        const { profile, dispatch } = this.props;
        dispatch(branchActions.getAll());
        if(profile.basic?.roles?.length){
            this.getDetails();
            this.getUserData();
        }
    }

    getUserData = () => {
        const { profile, dispatch } = this.props;
        let userId = profile?.basic?.id || 0;
        if(userId) {
            dispatch(attendanceActions.isLoggedForDay(userId));
        }
    }

    getDetails = (page = 0, limit = 10, fl = {}) => {
        const { dispatch, profile } = this.props;
        let filter = { ...fl };
        if(utils.getRole()?.includes('EMPLOYEE')){
            let user_id = profile.basic?.id;
            filter = { ...filter, user_id }
        } else if(utils.getRole()?.includes('MANAGER')){
            let branch_id = profile?.basic?.branch_id;
            filter = { ...filter, branch_id }
        }
        let offset = page * limit;
        dispatch(attendanceActions.getAll(offset, limit, filter ));
        dispatch(attendanceActions.getTotalRecords(filter ));
    }

    handleSubmit = () => {
        const { dispatch } = this.props;
        let payload = {
            user_img: "",
            break_hours: 0,
            logout_time: null,
            login_time: utils.getCurrentDateTime()
        };
        dispatch(attendanceActions.logAttendance(payload));
    }

    resetPagination = () => {
        this.setState({ page: 0, limit: 10 });
    }


    componentDidUpdate(prevProps) {
        const { attendance, auth } = this.props;
        let newAuthProps = auth.status !== prevProps.auth.status;
        let isPropsChanged = attendance.status !== prevProps.attendance.status;
        if(isPropsChanged){
            if(attendance.status === 'POST_SUCCESS'){
               this.getDetails();
               this.getUserData();
               this.resetPagination();
            }
        }

        if(newAuthProps) {
            if(auth.status === 'VALIDATE_SUCCESS'){
                this.getDetails();
                this.getUserData();
            }
        }
    }

    render() {
        return (
            <AttendanceLayout
                {...this.props}
                {...this.state}
                getDetails = {this.getDetails}
                handleSubmit = {this.handleSubmit}
                status = {this.props.attendance.status}
                details = {this.props.attendance.details}
                branches = {this.props.branch.branchDetails}
                noOfRecords = {this.props.attendance.noOfRecords}
                setPage = {(val) => this.setState({ page: val})}
                setLimit = {(val) => this.setState({ limit: val})}
                isLoggedToday = {this.props.attendance.isLoggedToday}
            />
        );
    }
}

function mapStateToProps(state) {
    const { profile, notifications, auth, attendance, branch } = state;
    
    return {
        auth,
        branch,
        profile,
        attendance,
        notifications
    }
}

export default connect(mapStateToProps)(Attendance);