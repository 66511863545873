import authConstants from '../constants/authentication';
import { authService } from '../services';
import notyf from '../utils/notification';

export const authActions = {
    login,
    validateToken,
    verifyOtp,
    logout
}


function  login(payload){
    return dispatch =>{
        dispatch(request());
        authService.login(payload).then(
            user =>{ dispatch(success(user)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: authConstants.LOGIN_REQUEST };
    }
    function success(user){
        return {
            type: authConstants.LOGIN_SUCCESS,
            payload: user
        };
    }
    function failure(error){
        return {
            type: authConstants.LOGIN_ERROR,
            payload : error
        };
    }
}

function validateToken(){
    return dispatch =>{
        dispatch(request());
        authService.validateToken().then(
            user =>{ dispatch(success(user)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: authConstants.VALIDATE_REQUEST };
    }
    function success(user){
        return {
            type: authConstants.VALIDATE_SUCCESS,
            payload: user
        };
    }
    function failure(error){
        return {
            type: authConstants.VALIDATE_ERROR,
            payload : error
        };
    }
}

function  verifyOtp(payload){
    return dispatch =>{
        dispatch(request());
        authService.verifyOtp(payload).then(
            user =>{ dispatch(success(user)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: authConstants.VERIFICATION_REQUEST };
    }
    function success(user){
        return {
            type: authConstants.VERIFICATION_SUCCESS,
            payload: user
        };
    }
    function failure(error){
        return {
            type: authConstants.VERIFICATION_ERROR,
            payload : error
        };
    }
}


function logout(triggerServer = true) {
    return dispatch => {
        if(triggerServer)
            authService.logout().then(
                user =>{ dispatch(success()) },
                error =>{ dispatch(success()) }
            );
        else
            dispatch(success());
    };

    function success(){
        return { type: authConstants.LOGOUT };
    }
  }