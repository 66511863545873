import React, { useEffect, useState } from 'react';
import { Button, ModalDialog, } from "../../../components";
import { Grid, Typography, Box } from '@material-ui/core';
import useStyles from '../style';


let payl = {
    "user_id": 0,
    "mobile_number": ""
}

export default function ResetConfirmation(props) {
    const classes = useStyles();
    const [payload, setPayload] = useState(payl);
    const {
        show = false,
        setShow = () => null,
        details = {},
        status = '',
    } = props;

    useEffect(() => {
        setPayload({ 
            user_id: details.user_id,  
            mobile_number: details.mobile_number
        });
    },[]);


    const handleSubmit = () => {
        props.handleSubmit(payload);
    }


    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography 
                        variant = 'h5' 
                        className={classes.title}
                    >
                        Are you sure to Reset Password ?
                    </Typography>
                </Box>
                <Grid 
                    container 
                    className = {classes.mainGrid}
                >
                    <Grid 
                        item xs={12} sm={6} 
                        md={6} lg={6} xl={6}
                        className = {classes.indGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            First Name
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.first_name || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Mobile Number
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.mobile_number || 'NA'}
                        </Typography>
                    </Grid>
                    <Grid 
                        item xs={12} sm={6} 
                        md={6} lg={6} xl={6}
                        className = {classes.indGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Last Name
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.last_name || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Current Branch
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.branch_name || 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    const footer = () => {
        return (
            <>
                <Button 
                    style = {{ padding: '8px 32px' }}
                    variant={"secondary"}
                    onClick={setShow} 
                    text='No'
                />
                <Button 
                    loading = {status === 'RESET_REQUEST'}
                    style = {{ padding: '8px 32px' }}
                    onClick={handleSubmit} 
                    text='Yes'
                />
            </>
        );
    }

    return (
        <ModalDialog
            open = {show}
            maxWidth = {'sm'}
            dialogTitle = ''
            onClose = {setShow}
            dialogFooter = {footer()}
            dialogContent = {dialogContent()}
            classes = {{ paper: classes.resetDialogPaper}}
        />
    )

}