import React from 'react';
import { 
    Typography, Grid, FormControl
} from '@material-ui/core';
import useStyles from '../style';
import { utils } from '../../../utils';


export default function OrderTotalCalc(props) {
    const classes = useStyles();
    const { details = {} } = props;
    return (
        <Grid xs={12} sm={4} md={4}
            className={classes.extraFieldsGrid}
        >
            <FormControl className={classes.formControlText}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Service Charge Type
                </Typography>
                <Typography
                    variant = "p"
                    className = {classes.textField}
                >
                    {details.service_charge_type}
                </Typography>
            </FormControl>
            <FormControl className={classes.formControlText}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Service Charge Percent (%)
                </Typography>
                <Typography
                    variant = "p"
                    className = {classes.textField}
                >
                    {details.service_charge_percent}
                </Typography>
            </FormControl>
            <FormControl className={classes.formControlText}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Service Charge Amount (INR)
                </Typography>
                <Typography
                    variant = "p"
                    className = {classes.textField}
                >
                    {utils.getFormattedAmount(details?.service_charge_amount || 0)}
                </Typography>
            </FormControl>
            <FormControl className={classes.formControlText}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Requested Amount (INR)
                </Typography>
                <Typography
                    variant = "p"
                    className = {classes.textField}
                >
                    {
                        details.requested_amount ? 
                        utils.getFormattedAmount(details?.requested_amount || 0) : "NA"
                    }
                </Typography>
            </FormControl>
            <FormControl className={classes.formControlText}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Approved Amount (INR)
                </Typography>
                <Typography
                    variant = "p"
                    className = {classes.textField}
                >
                    {
                        details.approved_amount ? 
                        utils.getFormattedAmount(details?.approved_amount || 0) : "NA"
                    }
                </Typography>
            </FormControl>
            <FormControl className={classes.formControlText}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Transaction Type
                </Typography>
                <Typography
                    variant = "p"
                    className = {classes.textField}
                >
                    {details.payout_transaction_type || "NA"}
                </Typography>
            </FormControl>
        </Grid>
    )
}