import React from 'react';
import { Typography, Grid, FormControl } from '@material-ui/core';
import { utils } from '../../../utils';
import useStyles from '../style';

export default function MaterialRelease (props) {
    const classes = useStyles();
    const {
        details = {}, 
        pledge_item = {} ,
        handleImageView = () => null
    } = props;

    return (
        <Grid xs={12} sm={4} md={4}
            className={classes.extraFieldsGrid}
        >
            <FormControl className={classes.formControlText}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Type
                </Typography>
                <Typography
                    variant = "p"
                    className = {classes.textField}
                >
                    {details.order_type}
                </Typography>
            </FormControl>
            {
                details.order_type === 'RELEASE' &&
                <FormControl className={classes.formControlText}>
                    <Typography variant='subtitle2'
                        className={classes.label}
                    >
                        Pledge Doc Name
                    </Typography>
                    <Typography
                        variant = "p"
                        className = {classes.textField}
                    >
                        {pledge_item.pledge_doc_name || 'NA'}
                    </Typography>
                </FormControl>
            }
            {
                details.order_type === 'RELEASE' &&
                <FormControl className={classes.formControlText}>
                    <Typography variant='subtitle2'
                        className={classes.label}
                    >
                        Pledge Doc
                    </Typography>
                    <Typography
                        variant = "p"
                        className = {classes.textField}
                        onClick = {() => handleImageView({
                            item_image: utils.getImageUrl(pledge_item.pledge_doc_image),
                            name: 'Pledge Document '
                        })}
                    >
                        {
                            pledge_item.pledge_doc_image &&
                            <span style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                color: '#0062FF',
                            }}>
                                View Image
                            </span> 
                        }
                        {  !pledge_item.pledge_doc_image && 'NA' }
                    </Typography>
                </FormControl>
            }
            {
                details.order_type === 'RELEASE' &&
                <FormControl className={classes.formControlText}>
                    <Typography variant='subtitle2'
                        className={classes.label}
                    >
                        Bank / Shop
                    </Typography>
                    <Typography
                        variant = "p"
                        className = {classes.textField}
                    >
                        {pledge_item.pledge_at || 'NA'}
                    </Typography>
                </FormControl>
            }
            {
                details.order_type === 'RELEASE' &&
                <FormControl className={classes.formControlText}>
                    <Typography variant='subtitle2'
                        className={classes.label}
                    >
                        Pledge Amount (INR)
                    </Typography>
                    <Typography
                        variant = "p"
                        className = {classes.textField}
                    >
                        {
                            pledge_item.pledge_amount ? 
                            utils.getFormattedAmount(pledge_item.pledge_amount || 0) : "NA"
                        }
                    </Typography>
                </FormControl>
            }
                        {
                details.order_type === 'RELEASE' &&
                <FormControl className={classes.formControlText}>
                    <Typography variant='subtitle2'
                        className={classes.label}
                    >
                        Pledge Date
                    </Typography>
                    <Typography
                        variant = "p"
                        className = {classes.textField}
                    >
                        {utils.getDate(pledge_item.pledge_date || "") || 'NA'}
                    </Typography>
                </FormControl>
            }
        </Grid>
    )
}