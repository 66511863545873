import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, TablePagination } from '@material-ui/core';
import { Button, Navbar, Table, ViewImage } from "../../components";
import { assetRequestsconstants as arc } from '../../constants';
import notyf from '../../utils/notification';
import { utils } from '../../utils';
import useStyles from './style';
import { 
    KeyboardArrowDown, 
    AutorenewOutlined, 
    ClearAllOutlined 
} from '@material-ui/icons';


let tableHeaders = [
    {
        id: "id",
        label : "Id",
        type: 'text_wo_input',
        minWidth: '50px',
        maxWidth: '50px',
        style : {}
    },
    {
        id: "name",
        label : "Name",
        type: 'text_wo_input',
        minWidth: '100px',
        maxWidth: '100px',
        style : {}
    },
    {
        id: "image",
        label : "Image",
        type: 'popup_action',
        minWidth: '50px',
        maxWidth: '50px',
        style : { }
    },
    {
        id: "requested_branch_name",
        label : "From Branch",
        type: 'dropdown',
        placeHolder: "From Branch",
        dropdownItems: [],
        minWidth: '100px',
        maxWidth: '100px',
        style : {}
    },
    {
        id: "approved_branch_name",
        label : "To Branch",
        type: 'dropdown',
        placeHolder: "To Branch",
        dropdownItems: [],
        minWidth: '100px',
        maxWidth: '100px',
        style : {}
    },
    {
        id: "request_on",
        label : "Requested at",
        type: 'text',
        inputType: 'date',
        minWidth: '130px',
        maxWidth: '130px',
        style : {}
    },
    {
        id: "approved_on",
        label : "Approved at",
        type: 'text',
        inputType: 'date',
        minWidth: '130px',
        maxWidth: '130px',
        style : {}
    },
    {
        id: 'status',
        label: 'Status',
        type: 'dropdown',
        placeHolder: "Status",
        dropdownItems: [
            {label: "Pending", value: "PENDING"},
            {label: "Approved", value: "APPROVED"},
            {label: "Rejected", value: "REJECTED"},
            {label: "Need Confirmation", value: "NEED_CONFIRMATION"},
            {label: "Completed", value: "COMPLETED"},
            {label: "Cancelled", value: "CANCELLED"},
        ],
        minWidth: '120px',
        maxWidth: '120px',
    },
    {
        id: 'status',
        label: '',
        type: 'icon',
        minWidth: '30px',
        maxWidth: '30px'
    }
];

let paginationDropDownList = [ 10, 20, 40 ];


let defaultQuery = {
    approved_branch_name : 0,
    requested_branch_name : 0,
    approved_on: '',
    request_on: '',
    status: ''
}

export default function AssetsRequestAllLayout(props){
    const classes = useStyles();
    const [showImage, setShowImage] = useState({flag: false, details: {}});
    const [filterQuery, setFilterQuery] = useState({ ...defaultQuery });
    const [clearInputFields, setClearInputFields] = useState(false);
    const [tableColumns, setTableColumns] = useState(tableHeaders);
    const [selectedRow, setSelectedRow] = useState([]);
    const [tableData, setTableData] = useState([]);
    const {
        setClearInputs = () => null,
        getDetails = () => null,
        setLimit = () => null,
        setPage = () => null,
        requestDetails = [],
        clearInputs = false,
        branchDetails = [],
        noOfRecords = 0,
        status = '',
        limit = 10,
        page = 0
    } = props;

    let role = utils.getRole() || ""

    const dataFormatter = (e) => {
        return {
            ...e,
            request_on: utils.getLocalTime(e.request_on || ""),
            approved_on: utils.getLocalTime(e.approved_on || ""),
            image: utils.getImageUrl(e.image || ""),
        }
    }

    useEffect(() => {
        if(clearInputFields){
            setClearInputFields(false);
            setFilterQuery(defaultQuery);
        }
    }, [clearInputFields]);

    useEffect(() => {
        if(status === 'GET_REQ_SUCCESS'){
            let td = [];
            if(requestDetails.length > 0){
                td = requestDetails.map(e => {
                    return dataFormatter(e);
                });
            }
            setTableData([ ...td ]);
            setSelectedRow([]);
            setClearInputs(true);
        }
    },[status]);

    const getBranchDropdown = (branches) => {
        return branches.map(
            f => { return { 
                label: f.branch_name, 
                value: f.id 
            } }
        )
    }

    useEffect(() => {
        let thc = tableColumns;
        thc = thc.map(e => {
            if( e.id.includes('branch_name') && branchDetails.length > 0 )
                return { ...e, dropdownItems: getBranchDropdown(branchDetails) }
            else return e;
        })
        setTableColumns(thc);
    }, [role, branchDetails]);

    const handleImageViewAction = (r, c, i) => {
        setShowImage({flag: true, details: r});
    }

    const onChangeRowsPerPage = (e) => {
        setPage(0);
        setLimit(e.target.value);
        getDetails(0, e.target.value, filterQuery);
    }
    
    const onChangePage = (e, val) => {
        setPage(val);
        getDetails(val, limit, filterQuery);
    }

    const handleFilterChange = (val, col) => {
        let fq = { ...filterQuery }
        fq = { ...fq, [col.id] : val };
        setPage(0);
        setFilterQuery(fq);
        getDetails(0, limit, fq);
    }

    const resetFilter = () => {
        setPage(0);
        setClearInputFields(true);
        getDetails(0, limit, defaultQuery);
    }

    return (
        <Navbar 
            { ...props }
        >
            <Box className={classes.mainBox}>
                <Box className = {classes.tableTitleBox}>
                    <Typography variant = "h5">
                        {arc.tableTitle3}
                    </Typography>
                    <Box className={classes.actionButtons}>
                        <Button
                            text = "Clear Filter"
                            variant = "secondary"
                            Icon = {ClearAllOutlined}
                            style = {{
                                padding: '8px 16px', 
                                marginRight: '16px' 
                            }}
                            onClick = {resetFilter}
                        />
                        <Button
                            text = "Refresh"
                            variant = "secondary"
                            Icon = {AutorenewOutlined}
                            style = {{
                                padding: '8px 16px', 
                                marginRight: '16px' 
                            }}
                            onClick = {() => getDetails(page, limit, filterQuery)}
                        />
                    </Box>
                </Box>
            </Box>
            <Paper className = {classes.tableBox}>
                <Table
                    loading = {status === 'GET_REQ_REQUEST'}
                    handlePopupAction={handleImageViewAction}
                    handleChange = {handleFilterChange}
                    handleMenuDropdown = {() => null}
                    onTableRowClick = {() => null}
                    tableColumns = {tableColumns}
                    handleCheckBox = {() => null}
                    clearInputs = {clearInputs}
                    headerMenuOptions = {[]}
                    tableData = {tableData}
                    rowMenuOptions = {[]}
                />
                 <TablePagination
                    rowsPerPageOptions={paginationDropDownList}
                    component='div'
                    count={noOfRecords}
                    rowsPerPage={limit}
                    page={page}
                    SelectProps={{
                        IconComponent: KeyboardArrowDown,
                        classes: { icon: classes.selectIcon },
                    }}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                />  
            </Paper>
            {
                showImage.flag &&
                <ViewImage
                    show = {showImage.flag}
                    setShow = {setShowImage}
                    image = {showImage.details?.image}
                    imageTitle = {showImage.details?.name + " IMAGE "}
                />
            }
        </Navbar>
    );
}