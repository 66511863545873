import React from 'react';
import { ExitToAppOutlined, PrintOutlined } from '@material-ui/icons';
import { Box, Paper, Typography } from '@material-ui/core';
import { Table, Loader, Button } from "../../components";
import { reportConstants as rc} from '../../constants';
import Logo from '../../assets/invoice-logo.png';
import th from './table-headers.json';
import { utils } from '../../utils';
import useStyles from './style';

export default function ReportsByAdminLayout(props){

    const classes = useStyles();
    const {
        history,
        profile = {},
        status = '',
        details = [],
        to_date = '',
        from_date = '',
    } = props;

    const { basic = {}} = profile;

    const getTotalAmount = (
        data = [], 
        key = '',
        filterStatus = '',
    ) => {
        let dt = data;
        if(filterStatus)
            dt = dt.filter(e => filterStatus.includes(e.status));

        let amt = 0;
        dt = dt.map(f => amt += parseInt(f[key]));

        return amt;
    }

    const formattedData = (
        data = [], 
        timeKey = '', 
        filterStatus = '',
        amtKey = []
    ) => {
        let dt = data;
        console.log(data, " :: data");
        if(filterStatus)
            dt = dt.filter(e => filterStatus.includes(e.status));

        if(timeKey)
            dt = dt.map(f => {
                return { ...f, [timeKey] : utils.getLocalTime(f[timeKey]) }
            });
    
        if(amtKey.length > 0)
            dt = dt.map(f => {
                let j = { ...f };
                amtKey.map(h => {
                    j =  { ...j, [h] : utils.getFormattedAmount(j[h] || 0) || "NA" }
                })
                return j;
            });

        dt = dt.map(e => {
            let br_dt = details?.branch_details?.filter(f => f.id === e.branch_id) || [];
            if(br_dt.length > 0) {
                return { ...e, branch_name: br_dt[0]?.branch_name || "" }
            }else return e;
        })

        return dt;
    }

    const getFormattedTransfer = (data = []) => {
        let transfer = [];
        if(data?.branch_details?.length > 0){
            transfer = data?.branch_details?.map(e => {
                let ci = [];
                if(data?.cash_in?.length > 0)
                    ci = data?.cash_in?.filter(f => e.id === f.branch_id);
                
                let co = []
                if(data?.cash_out?.length > 0)
                    co = data?.cash_out?.filter(f => e.id === f.branch_id);
                
                return {
                    branch_id: e.id,
                    branch_name: e.branch_name,
                    cash_in: ci?.[0]?.transferred_amount || 0,
                    cash_out: co?.[0]?.transferred_amount || 0
                }
            })
        }
        return transfer;
    }

    const getTitleForTable = (title = '') => {
        if(title)
            return (
                <Typography variant = "h6" 
                    style = {{
                        margin: '20px 0px',
                        fontWeight: 500
                    }}
                >
                    {title}
                </Typography>
            )
        else return null;
    }

    const getTable = (
        title = '',
        columns = [],
        data = [],
        timeKey = '',
        filterStatus = '',
        amtKey = [],
        styleName = 'splitTable',
    ) => {
        return (
            <Box className = {classes[styleName]}>
                {getTitleForTable(title)}
                <Table
                    tableColumns = {columns}
                    tableData = {formattedData(
                        data, timeKey, filterStatus, amtKey
                    )}
                />
            </Box>
        );
    }

    const formatMoney = (amt = 0, negative = false) => {
        if(!amt) return amt;
        return `${negative ? '-' : '+'}` + utils.getFormattedAmount(amt || 0);
    }

    const getDataForTotals = () => {
        let data = [];
        let inv_d_amt = getTotalAmount(details.invoice_details, 'approved_amount' );
        data = [ ...data, { type: 'Purchases', amount: formatMoney(inv_d_amt, true) }]
        let ci_amt = getTotalAmount( details.cash_in, 'transferred_amount' );
        data = [ ...data, { type: 'Cash-In (Fund Transfer)', amount: formatMoney(ci_amt) } ]
        let co_amt = getTotalAmount( details.cash_out, 'transferred_amount' );
        data = [ ...data, { type: 'Cash-Out (Fund Transfer)', amount: formatMoney(co_amt, true) } ]
        let br_amt = getTotalAmount( details.branch_expense, 'expense_amount' );
        data = [ ...data, { type: 'Expense', amount:  formatMoney(br_amt, true) } ]

        return data;
    }

    const formatted = (date) => {
        let st = '';
        if(date){
            let d = date.split('-').reverse();
            d.map((e, i) => {
                if(i === 0) st = e;
                else st = `${st} / ${e}`;
            });
        }
        return st;
    }

    if(status === 'GET_ALL_REQUEST')
        return (
            <Box>
                <Box style={{height: '200px'}}>
                    <Loader />
                </Box>
                <Typography variant = "h5"
                    style = {{textAlign: 'center'}}
                > {rc.loadingText} </Typography>
            </Box>
        );
    else if(status === 'GET_ALL_SUCCESS'){
        return (
            <Box className={classes.outerBox}>
                <Box className={classes.tableTitleBox}>
                    <Box className = {classes.mainBoxLeft}>
                        <Typography variant = "h6">Transaction Report</Typography>
                        <Typography variant = "h6">Branch: All</Typography>
                    </Box>
                    <img 
                        className={classes.logoStyle}
                        src={Logo} 
                        alt={"Abaya Gold buyers logo"} 
                        width={"100px"}
                        height = {'120px'}
                    />
                    <Box className = {classes.mainBox}>
                        <Typography variant = "h6">
                            From: {formatted(from_date)} 
                        </Typography>
                        <Typography variant = "h6">
                            To: {formatted(to_date)} 
                        </Typography>
                    </Box>
                </Box>
                <Paper className = {classes.tableBox}>
                    {getTable(
                        'Orders',
                        th.order_details,
                        details.invoice_details,
                        '', '',
                        ['requested_amount', 'approved_amount', 'service_charge_amount'],
                        'orderTable'
                    )}
                </Paper>
                <Paper className = {classes.tableBox}>
                    {getTable(
                        'Balance Sheet',
                        th.balance_sheet,
                        details.closing_balance,
                        'last_updated_at',
                        '',
                        ['amount']
                    )}
                    {getTable(
                        'Fund Transfers',
                        th.fund_transfer,
                        getFormattedTransfer(details),
                        '' , '',
                        ['cash_in', 'cash_out']
                    )}
                </Paper>
                <Paper className = {classes.tableBox}>        
                    {getTable(
                        'Branch Expenses',
                        th.branch_expense,
                        details.branch_expense,
                        '', '',
                        ['expense_amount']
                    )}
                    <Box className = {classes.splitTable}>
                        {getTitleForTable('Totals')}
                        <Table
                            tableColumns = {th.totals}
                            tableData = {getDataForTotals()}
                        />
                    </Box>
                </Paper>
                <Box className = {classes.FooterBox}>
                    <Typography variant = "subtitle2">
                        {'Generated By :  '}
                        {basic?.first_name || 'NA'}
                        {" " + basic?.last_name}
                    </Typography>
                    <Typography variant = "subtitle2">
                        Generated From : {basic?.branch_details?.branch_name}
                    </Typography>
                    <Typography variant = "subtitle2">
                        Generated At : {new Date().toLocaleString()}
                    </Typography>
                </Box>
                <Box 
                    className={'row col-xs-12 center'}
                    style={{margin: '40px 0px'}}
                >
                    <Button
                        text = "Close"
                        variant = "secondary"
                        Icon = {ExitToAppOutlined}
                        style = {{
                            padding: '8px 16px',
                            marginRight: '16px'
                        }}
                        onClick = {() => history.goBack()}
                    />
                    <Button
                        text = "Print"
                        variant = "primary"
                        Icon = {PrintOutlined}
                        style = {{
                            padding: '8px 16px',
                        }}
                        onClick = {() => window.print()}
                    />
                </Box>
            </Box>
        );
    }else
        return <div></div>
}