import React from 'react';
import { ReactComponent as PaymentReqIcon } from '../../assets/icons/payment-request.svg';
import { ReactComponent as SellAssetsIcon } from '../../assets/icons/sell-assets.svg';
import { ReactComponent as AttendanceIcon } from '../../assets/icons/attendance.svg';
import { ReactComponent as AGCMaterialIcon } from '../../assets/icons/material.svg';
import { ReactComponent as MoneyOutIcon } from '../../assets/icons/money-out.svg';
import { ReactComponent as MoneyInIcon } from '../../assets/icons/money-in.svg';
import { ReactComponent as AssetsIcon } from '../../assets/icons/assets.svg';
import { ReactComponent as LeaveIcon } from '../../assets/icons/leave.svg';
import { ReactComponent as MoneyIcon } from '../../assets/icons/money.svg';
import { 
    PowerSettingsNew, 
    SettingsOutlined,
    CompareArrows,
    Search, SmsOutlined,
    PersonAddOutlined,
    BusinessOutlined,
    StoreOutlined,
    ShoppingCartOutlined,
    GroupOutlined,
    GroupAddOutlined,
    ShoppingBasketOutlined,
    LoyaltyOutlined,
    AssessmentOutlined,
} from '@material-ui/icons';

const menuList = {
    dynamic: [
        {
            label: 'Search Customer',
            key: 'customer-search',
            Icon: Search,
            path: '/search',
            access : [],
            childComponent: []
        },
        {
            label: 'Onboard Customer',
            key: 'add-customer',
            Icon: PersonAddOutlined,
            path: '/onboard/customer',
            access : [],
            childComponent: []
        },
        {
            label: 'Funds Available',
            key: 'fund-available',
            Icon: MoneyIcon,
            path: '/funds/available',
            access : [],
            childComponent: []
        },
        {
            label: 'Funds Requests',
            key: 'funds-requests',
            Icon: PaymentReqIcon,
            path: '',
            access : [],
            childComponent: [
                {
                    label: 'Fund Request - In',
                    key: 'fund-request-in',
                    Icon: MoneyInIcon,
                    path: '/funds/request/in',
                    access : [],
                },
                {
                    label: 'Fund Request - Out',
                    key: 'fund-request-out',
                    Icon: MoneyOutIcon,
                    path: '/funds/request/out',
                    access : [],
                },
                {
                    label: 'Fund Request - All',
                    key: 'fund-request-all',
                    Icon: PaymentReqIcon,
                    path: '/funds/request/all',
                    access : [
                        'SYSTEM_ADMIN',
                        'SUPER_ADMIN',
                        'HQ_ADMIN'
                    ],
                }
            ]
        },
        {
            label: 'Orders',
            key: 'order',
            Icon: ShoppingCartOutlined,
            path: '/orders',
            access : [],
            childComponent: []
        },
        {
            label: 'Assets',
            key: 'asset',
            Icon: ShoppingBasketOutlined,
            path: '',
            access : [],
            childComponent: [
                {
                    label: 'Branch Assets',
                    key: 'branch-assets',
                    Icon: AGCMaterialIcon,
                    path: '/assets',
                    access : [],
                },
                {
                    label: 'Sell Assets',
                    key: 'sell-assets',
                    Icon: SellAssetsIcon,
                    path: '/assets/admin',
                    access : [
                        'SYSTEM_ADMIN',
                        'SUPER_ADMIN',
                        'HQ_ADMIN'
                    ],
                },
                {
                    label: 'Asset Requests - In',
                    key: 'assets-requests-in',
                    Icon: ShoppingBasketOutlined,
                    path: '/assets/request/in',
                    access : [
                        'SYSTEM_ADMIN',
                        'SUPER_ADMIN',
                        'HQ_ADMIN',
                        'BRANCH_MANAGER'
                    ],
                },
                {
                    label: 'Asset Requests - Out',
                    key: 'assets-requests-out',
                    Icon: ShoppingBasketOutlined,
                    path: '/assets/request/out',
                    access : [
                        'SYSTEM_ADMIN',
                        'SUPER_ADMIN',
                        'HQ_ADMIN',
                        'BRANCH_MANAGER'
                    ],
                },
                {
                    label: 'Asset Requests - All',
                    key: 'assets-requests-all',
                    Icon: ShoppingBasketOutlined,
                    path: '/assets/request/all',
                    access : [
                        'SYSTEM_ADMIN',
                        'SUPER_ADMIN',
                        'HQ_ADMIN'
                    ],
                },
            ]
        },
        {
            label: 'Materials',
            key: 'materials',
            Icon: AssetsIcon,
            path: '/materials',
            access : [],
            childComponent: []
        },
        {
            label: 'Expenses',
            key: 'expenses',
            Icon: LoyaltyOutlined,
            path: '/expenses',
            access : [],
            childComponent: []
        },
        {
            label: 'Reports',
            key: 'reports',
            Icon: AssessmentOutlined,
            path: '/reports',
            access : [],
            childComponent: [ ]
        },
        {
            label: 'Internal',
            key: 'internal',
            Icon: BusinessOutlined,
            path: '',
            access : [],
            childComponent: [
                {
                    label: 'Attendance',
                    key: 'attendance',
                    Icon: AttendanceIcon,
                    path: '/attendance',
                    access : [],
                },
                {
                    label: 'Leave Requests',
                    key: 'leave-requests',
                    Icon: LeaveIcon,
                    path: '/leave/request',
                    access : [],
                },
                {
                    label: 'Employee',
                    key: 'employee',
                    Icon: GroupOutlined,
                    path: '/employee',
                    access : [
                        'SYSTEM_ADMIN', 
                        'SUPER_ADMIN' , 
                        'HQ_ADMIN'
                    ],
                },
                {
                    label: 'Onboard Employee',
                    key: 'add-employee',
                    Icon: GroupAddOutlined,
                    path: '/onboard/employee',
                    access : [
                        'SYSTEM_ADMIN', 
                        'SUPER_ADMIN' , 
                        'HQ_ADMIN'
                    ],
                },
                {
                    label: 'Branch',
                    key: 'branch',
                    Icon: StoreOutlined,
                    path: '/branch',
                    access : [
                        'SYSTEM_ADMIN', 
                        'SUPER_ADMIN' , 
                        'HQ_ADMIN'
                    ],
                }
            ]
        },
        {
            label: 'Sms Logs',
            key: 'logs-sms',
            Icon: SmsOutlined,
            path: '/smslogs',
            childComponent: [],
            access : [
                'SYSTEM_ADMIN', 
                'SUPER_ADMIN' , 
                'HQ_ADMIN'
            ]
        }
    ],
    static: [
        {
            label: 'Settings',
            key: 'settings',
            Icon: SettingsOutlined,
            path: '/settings',
            access : [],
            childComponent: []
        },
        {
            label: 'Logout',
            key: 'logout',
            Icon: PowerSettingsNew,
            path: '',
            access : [],
            childComponent: []
        },
        {
            label: 'Collapse',
            key: 'collapse',
            Icon: CompareArrows,
            path: '',
            access : [],
            childComponent: []
        }
    ],
    stars : {
        'BRANCH_EMPLOYEE' : 1,
        'BRANCH_MANAGER' : 2,
        'HQ_ADMIN' : 3,
        'SUPER_ADMIN' : 4,
        'SYSTEM_ADMIN' : 5
    }
};


export default menuList;