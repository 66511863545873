import {httpClient} from "../utils";

export const leaveService = {
    cancelLeaveAction,
    pendingLeaveAction,
    getTotalRecords,
    getAll,
    raise,
}

function getFilter(filter = {}){
    return `&from_date=${filter.from_date || ''}` +
            `&to_date=${filter.to_date || ''}` +
            `&status=${filter.status || '' }`+
            `&request_by=${filter.request_by || 0}`+
            `&approved_by=${filter.approved_by || 0}`+
            `&leave_type=${filter.leave_type || ''}`
}

function getAll(type, offset, limit, filter){
    return httpClient.callApi(
        "GET",
        `/leave/getLeaveRequest?`+ 
            getFilter(filter) +
            `&offset=${offset}`+ 
            `&limit=${limit}`
    );
}

function getTotalRecords(type, filter){
    return httpClient.callApi(
        "GET", `/leave/getTotalLeaveRequest?`+ getFilter(filter)
    );
}

function raise(payload){
    return httpClient.callApi("POST",`/leave/placeLeaveRequest`, payload);
}

function pendingLeaveAction(payload){
    return httpClient.callApi("POST",`/leave/approveLeaveRequest`, payload);   
}

function cancelLeaveAction(payload){
    return httpClient.callApi("POST",`/leave/cancelLeaveRequest`, payload);
}