import React from 'react';
import { Button, ModalDialog, } from "../../components";
import { materialsConstants as mc } from '../../constants';
import useStyles from './style';
import {
    Grid, Box,
    FormControl,
    OutlinedInput,
    Typography,
} from '@material-ui/core';

export default function UpdateMaterial(props) {
    const classes = useStyles();
    const {
        show = false,
        setShow = () => null,
        handleChange = () => null,
        handleSubmit = () => null,
        payload = {},
        status = '',
        error = false
    } = props;

    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        { mc.updateTitle.replaceAll('${material}', show.id.name) }
                    </Typography>
                </Box>
                <Grid 
                    container 
                    direction='row' 
                    className = {classes.mainGrid}
                >
                    <Grid 
                        item xs={12} sm={12} 
                        md={12} lg={12} xl={12}
                        className = {classes.indGrid}
                    >
                        <FormControl className={classes.inputField}>
                            <Typography variant='subtitle2' className={classes.label}>
                                Material Price
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleChange('material_price', e.target.value )}
                                className={classes.inputField}
                                value = {payload['material_price']}
                                error= {error && !payload['material_price']}
                                placeholder={'Material Price'}
                                type = {'number'}
                                id='material_price'
                                inputProps = {{
                                    required: true,
                                    maxLength : 45,
                                    min : 0
                                }}
                            />
                        </FormControl>
                        <small className="red" style={{marginTop: 24}}>
                                {error && !payload['material_price'] && mc.materialPriceInvalid}
                        </small>
                    </Grid>
                    <Grid 
                        item xs={12} sm={12} 
                        md={12} lg={12} xl={12}
                        className = {classes.indGrid}
                    >
                        <FormControl className={classes.inputField}>
                            <Typography variant='subtitle2' className={classes.label}>
                                CGST
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleChange('cgst', e.target.value )}
                                className={classes.inputField}
                                value = {payload['cgst']}
                                error= {error && payload['cgst'] === ''}
                                placeholder={'CGST'}
                                type = {'number'}
                                id='cgst'
                                inputProps = {{
                                    required: true,
                                    maxLength : 45,
                                    min : 0
                                }}
                            />
                        </FormControl>
                        <small className="red" style={{marginTop: 24}}>
                                {error && payload['cgst'] === '' &&  mc.updateValueInvalid}
                        </small>
                    </Grid>
                    <Grid 
                        item xs={12} sm={12} 
                        md={12} lg={12} xl={12}
                        className = {classes.indGrid}
                    >
                        <FormControl className={classes.inputField}>
                            <Typography variant='subtitle2' className={classes.label}>
                                SGST
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleChange('sgst', e.target.value )}
                                className={classes.inputField}
                                value = {payload['sgst']}
                                error= {error && payload['sgst'] === ''}
                                placeholder={'SGST'}
                                type = {'number'}
                                id='sgst'
                                inputProps = {{
                                    required: true,
                                    maxLength : 45,
                                    min : 0
                                }}
                            />
                        </FormControl>
                        <small className="red" style={{marginTop: 24}}>
                                {error && payload['sgst'] === '' && mc.updateValueInvalid}
                        </small>
                    </Grid>
                </Grid>
                <Button 
                    onClick={handleSubmit} 
                    loading = {status === 'UDPATE_REQUEST'}
                    text='Submit' 
                    style ={{ 
                        margin : '40px 0px',
                        width: '280px'
                    }}
                />
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {show.flag}
            onClose = {setShow}
            dialogTitle = ''
            dialogContent = {dialogContent()}
            maxWidth = {'sm'}
            classes = {{ paper: classes.dialogPaper}}
            dialogFooter = {[]}
        />
    )

}