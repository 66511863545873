import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { Button, ModalDialog } from "../../components";
import { globalRegexp as gr } from '../../constants'; 
import UserForm from '../forms/user';
import useStyles from './style';

let err = {
    first_name: false,
    last_name: false,
    dob: false,
    gender : false,
    email : false,
    file_name: false
};


export default function UserUpdateModal(props) {
    const classes = useStyles();
    const [payl, setPayl] = useState({});
    const [error, setError] = useState({ show: false, keys: err });
    const {
        show = false,
        setShow = () => null,
        handleSubmit = () => null,
        handleFileChange = () => null,
        fileuploads = {},
        payload = {},
        status = '',
    } = props;

    useEffect(() => {
        resetPayl();
    },[]);

    useEffect(() => {
        let f = { ...fileuploads };
        if(f?.user_image?.[0]?.status === 'S'){
            console.log('inside :: success ');
            setPayl({
                ...payl,
                file_name: f.user_image?.["0"]?.name
            })
        }
    }, [fileuploads]);

    const resetPayl = () => {
        setPayl({
            id : payload.id,
            first_name: payload.first_name || "",
            last_name: payload.last_name || "",
            dob: payload.dob || "",
            gender : payload.gender || "",
            email : payload.email || "",
            file_name: payload.user_image || ""
        });
    }

    const resetForm = () => {
        resetPayl();
        setError({ show: false, keys: err });
    }

    const isError = () => {
        let flag = false;
        let errorrr = { ...err };

        if(!payl.first_name){
            flag = true;
            errorrr = { ...errorrr, first_name: true };
        }
        if( !payl.dob === '' || !payl.dob.match(gr.date)){
            flag = true;
            errorrr = { ...errorrr, dob: true };
        }
        if(!payl.gender){
            flag = true;
            errorrr = { ...errorrr, gender: true };
        }
        if(
            payl.email !== '' &&
            !payl.email.match(gr.email)
        ){
            flag = true;
            errorrr = { ...errorrr, email: true };
        }
        if(!payl.file_name){
            flag = true;
            errorrr = { ...errorrr, file_name: true };
        }
        
        setError({ show: flag, keys : errorrr });
        return flag;
    }

    const resetError = () => {
        setError({show: false, keys: err });
    }

    const handleChange = (name = '', value, type = 'text') => {
        if(type === 'text'){
            setPayl({ ...payl, [name] : value });
        }else {
            handleFileChange(name, value, 0);
        }
        resetError();
    }

    const handleValidateSubmit = () => {
        if(!isError()){
            handleSubmit(payl);
        }
    }

    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        Edit User
                    </Typography>
                </Box>
                <Grid 
                    container 
                    direction='row' 
                    className = {classes.mainGrid}
                    style = {{ marginTop: '40px' }}
                >                    
                        <UserForm
                            handleChange = {handleChange}
                            fileuploads = {fileuploads}
                            payload = {payl}
                            error = {error}
                        />
                </Grid>               
            </Box>
        );
    }

    const footer = () => {
        return (
            <>
                <Button 
                    style ={{ padding: '8px 32px' }}
                    onClick = {() => setShow(false)}
                    variant = {'secondary'} 
                    text = { 'Cancel' } 
                />
                <Button 
                    style ={{ padding: '8px 32px' }}
                    onClick = {resetForm}
                    variant = {'secondary'} 
                    text = { 'Reset' } 
                />
                <Button 
                    loading = {status === 'UPDATE_REQUEST'}
                    style = {{ padding: '8px 32px' }}
                    onClick = {handleValidateSubmit} 
                    text = { 'Update' }
                />
            </>
        );
    }

    return (
        <ModalDialog
            dialogContent = {dialogContent()}
            onClose = {() => setShow(false)}
            dialogFooter = {footer()}
            dialogTitle = { '' }
            maxWidth = {'md'}
            open = {show}
        />
    )

}