import settingsConstants from '../constants/settings';
import { settingsService } from '../services';
import notyf from '../utils/notification';

export const settingsActions = {
    updatePassword,
    enableSafeMode,
    disableSafeMode,
}

function  updatePassword(payload){
    return dispatch =>{
        dispatch(request());
        settingsService.updatePassword(payload).then(
            payl =>{ dispatch(success(payl)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: settingsConstants.UPDATE_PASS_REQUEST };
    }
    function success(payl){
        notyf.open ({ type: 'success', message: payl.message });
        return {
            type: settingsConstants.UPDATE_PASS_SUCCESS,
            payload: payl
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: settingsConstants.UPDATE_PASS_ERROR,
            payload : error
        };
    }
}

function enableSafeMode(){
    return dispatch =>{
        dispatch(request());
        settingsService.enableSafeMode().then(
            payl =>{ dispatch(success(payl)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: settingsConstants.ENABLE_SM_REQUEST };
    }
    function success(payl){
        notyf.open ({ 
            type: 'success', 
            message: payl.message
        });
        return {
            type: settingsConstants.ENABLE_SM_SUCCESS,
            payload: payl
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: settingsConstants.ENABLE_SM_ERROR,
            payload : error
        };
    }
}

function disableSafeMode(payload){
    return dispatch =>{
        dispatch(request());
        settingsService.disableSafeMode(payload).then(
            payl =>{ dispatch(success(payl)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: settingsConstants.DISABLE_SM_REQUEST };
    }
    function success(payl){
        notyf.open ({ 
            type: 'success', 
            message: payl.message
        });
        return {
            type: settingsConstants.DISABLE_SM_SUCCESS,
            payload: payl
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: settingsConstants.DISABLE_SM_ERROR,
            payload : error
        };
    }
}