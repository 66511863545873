import React from 'react';
import useStyles from './style';
import { 
    Select, MenuItem,
    Typography,
    FormControl,
    OutlinedInput,
    InputAdornment,
    CircularProgress
} from '@material-ui/core';
import { 
    ExpandMore, 
    AttachFileOutlined,
    DescriptionOutlined,
    CheckCircleOutlineRounded, 
    ErrorOutlineRounded
} from '@material-ui/icons';
import {
    onboardCustomerConstants as oc
} from '../../constants';

export default function Kyc(props){

    const classes = useStyles();
    const {
        kycIndex = 0,
        uploadStatus = {},
        handleChange = () => null,
        handleFileChange = () => null,
        errorValue = {show: false, keys: {}}
    } = props;

    return (
        <>
                <FormControl className={classes.formControlInput}>
                    <Typography variant='subtitle2'
                        className={classes.label}
                    >
                        KYC Doc Type *
                    </Typography>
                    <Select
                        disableUnderline
                        displayEmpty
                        variant = 'outlined'
                        className={classes.inputField}
                        IconComponent = {ExpandMore}
                        defaultValue = {'Aadhaar'}
                        onChange={(e) => handleChange(e.target.value, 'kyc', 'doc_type', kycIndex)}
                        inputProps={{
                            name: 'doc_type' + kycIndex ,
                        }}
                    >
                        <MenuItem value={'Aadhaar'}>Aadhaar (UIDAI)</MenuItem>
                        <MenuItem value={'Driving license'}>Driving License</MenuItem>
                        <MenuItem value={'PAN'}>PAN (INCOME TAX)</MenuItem>
                        <MenuItem value={'Voter\'s Id'}>Voter's Identity (EPIC)</MenuItem>
                        <MenuItem value={'Passport'}>Passport</MenuItem>
                    </Select>
                </FormControl>
                <FormControl className={classes.formControlInput}>
                    <Typography variant='subtitle2'
                        className={classes.label}
                    >
                        KYC Doc Number *
                    </Typography>
                    <OutlinedInput
                        onChange={(e) => handleChange(e.target.value, 'kyc', 'kyc_number', kycIndex)}
                        className={classes.inputField}
                        error = { errorValue.show && errorValue.keys?.['kyc_number']  }
                        placeholder={'123456'}
                        type = {'text'}
                        id= { 'kyc_number' + kycIndex }
                        startAdornment = {
                            <InputAdornment position='start'>
                                <DescriptionOutlined edge='start' color='primary'/>
                            </InputAdornment>
                        }
                        inputProps = {{
                            required: true,
                            maxLength : 45
                        }}
                    />
                    <small className={'red'}>
                        {
                            errorValue.show && 
                            errorValue.keys?.['kyc_number'] &&
                            oc.invalid
                        }
                    </small>
                </FormControl>
                <FormControl style={{marginBottom: 16}} 
                    className={classes.formControlInput}
                >
                    <Typography variant='subtitle2'
                        className={classes.label}
                    >
                        Upload KYC Doc *
                    </Typography>
                    <OutlinedInput
                        onChange={(e) => handleFileChange(e.target.files, 'kyc', 'file_name', kycIndex)}
                        className={classes.inputField}
                        error = { errorValue.show && errorValue.keys?.['file_name']  }
                        placeholder={'123456'}
                        type = {'file'}
                        id= { 'kyc_file'+ kycIndex }
                        startAdornment = {
                            <InputAdornment position='start'>
                                {
                                    uploadStatus?.[kycIndex + ""]?.status === 'R' &&
                                    <CircularProgress size={16} color="primary"/> 
                                }
                                {
                                    uploadStatus?.[kycIndex + ""]?.status === 'S' &&
                                    <CheckCircleOutlineRounded size = {24} style={{color: 'green'}}/>
                                }
                                {
                                    uploadStatus?.[kycIndex + ""]?.status === 'E' &&
                                    <ErrorOutlineRounded size={24} color='error' />
                                }
                                {
                                    !uploadStatus?.[kycIndex + ""]?.status &&
                                    <AttachFileOutlined edge='start' color='primary'/>
                                }                              
                            </InputAdornment>
                        }
                        inputProps = {{
                            accept: "image/*",
                            required: true,
                            maxLength : 45
                        }}
                    />
                    <small className={'red'}>
                        {
                            errorValue.show && 
                            errorValue.keys?.['file_name'] &&
                            oc.invalidFile
                        }
                    </small>
                </FormControl>
        </>
    );
}