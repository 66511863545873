import React, { useState } from 'react';
import { assetRequestsconstants as arc } from '../../../constants';
import { ModalDialog, Button } from "../../../components";
import useStyles from '../style';
import {
    Box, Typography,
    FormControl, Grid,
    OutlinedInput,
} from '@material-ui/core';

let payl = {
    "asset_request_id": [],
    "status": "",
    "description": ""
};


export default function PendingAction(props) {
    const classes = useStyles();
    const [payload, setPayload] = useState(payl);
    const {
        status = '',
        details = [],
        show = false,
        setShow = () => null
    } = props;


    const handleChange = (name, value) => {;
        setPayload({ ...payload, [name]: value });
    }


    const handleSubmit = (status) => {
        let ids = details.map(e => { return e.id });
        props.handleSubmit({
            ...payload,
            status,
            asset_request_id: [ ...ids ],
        });
    }

    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        {arc.pendingActionTitle}
                    </Typography>
                    <Typography variant = 'p' className = {`${classes.subTitle}`}>
                        {arc.actionAssetsPrefix} { " " + details.length } 
                    </Typography>
                </Box>
                <Grid 
                    container direction='row' 
                    xs={12} sm={12} md={12} 
                    lg={12} xl={12}
                    className = {classes.fundDetailsMainGrid}
                    style = {{marginTop: '16px'}}
                >
                        <FormControl variant='outlined' 
                            className={classes.inputField}
                        >
                            <Typography variant='subtitle2'
                                className={classes.labelTitle}
                            >
                                Description (optional)
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleChange('description' , e.target.value)}
                                value = {payload['description']}
                                className={classes.inputField}
                                placeholder={'Description'}
                                type = {'text'}
                                id='description'
                                multiline
                                inputProps = {{
                                    required: true,
                                    maxLength : 200
                                }}
                            />
                        </FormControl>
                </Grid>
                <Grid 
                    container direction='row' 
                    xs={12} sm={12} md={12} 
                    lg={12} xl={12}
                    className = {classes.fundDetailsMainGrid}
                    style = {{marginTop: '16px'}}
                >
                    <Button
                        text = "Reject"
                        variant = "primary"
                        loading = {status === 'P_ACTION_REQUEST'}
                        style = {{
                            padding: '8px 16px',
                            margin: '40px 20px 0px 0px', 
                            backgroundColor: '#db252e',
                            width: '200px' 
                        }}
                        onClick = {() => handleSubmit('REJECTED') }
                    />
                    <Button
                        text = "Approve"
                        variant = "primary"
                        style = {{
                            padding: '8px 16px',
                            marginTop: '40px', 
                            width: '200px' 
                        }}
                        loading = {status === 'P_ACTION_REQUEST'}
                        onClick = {() => handleSubmit('APPROVED') }
                    />          
                </Grid>
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {show}
            onClose = {() => setShow(false)}
            dialogTitle = ''
            dialogContent = {dialogContent()}
            maxWidth = {'md'}
            classes = {{ paper: classes.dialogPaper}}
            dialogFooter = {[]}
        />
    )

}