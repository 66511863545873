import React, { useEffect, useState } from 'react';
import { ModalDialog } from "../../../components";
import { utils } from '../../../utils';
import useStyles from '../style';
import {
    Grid, Box,
    Typography
} from '@material-ui/core';

export default function FundDetails(props) {
    const classes = useStyles();
    const [dnm, setDnm] = useState({});
    const {
        detail = {},
        showDetails = false,
        setDetails = () => null
    } = props;

    useEffect(() => {
        if(detail.cash_denomination){
            let d = JSON.parse(detail.cash_denomination);
            setDnm(d);
        }else
            setDnm({});

        return () => setDnm({})
    },[detail]);

    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        Fund Request Details
                    </Typography>
                    <Typography variant = 'p' className = {`${classes.subTitle}`}>
                        status : {' '}
                        <span className={classes[detail?.status?.toLowerCase()]}>
                            {detail.status}
                        </span>
                    </Typography>
                </Box>
                <Grid 
                    container 
                    direction='row' 
                    className = {classes.fundDetailsMainGrid}
                >
                    <Grid 
                        item xs={12} sm={5} 
                        md={5} lg={5} xl={5}
                        className = {classes.indGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Requested from Branch :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {detail.requested_branch_name || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Requested Amount :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {detail.request_amount || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Requested type :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {detail.request_transaction_type || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Requested At :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {detail.request_on || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Requested By :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {detail.requested_by || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Comment :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {detail.request_description || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Cancelled By :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {detail.cancellation_by || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Cancelled On:
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {utils.getLocalTime(detail.cancelled_on) || 'NA'}
                        </Typography>
                    </Grid>
                    <Grid 
                        item xs={12} sm={5} 
                        md={5} lg={5} xl={5}
                        className = {classes.indGrid}
                    >

                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Approved / Rejected from Branch :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {detail.approved_branch_name || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Approved Amount :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {detail.approved_amount || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Approved / Rejected type :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {detail.approved_transaction_type || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Approved / Rejected At :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {detail.approved_on || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Approved / Rejected By :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {detail.approved_by || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Comment :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {detail.approval_description || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Confirmed By :
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {detail.confirmation_by || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Confirmed On:
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {utils.getLocalTime(detail.confirmed_on) || 'NA'}
                        </Typography>
                    </Grid>
                    {
                        Object.keys(dnm).length > 0 &&
                        <Grid 
                            item xs={12} sm={2} 
                            md={2} lg={2} xl={2}
                            className = {classes.indGrid}
                        >
                            <Typography variant='subtitle2' className={classes.labelTitle}>
                                Denominations
                            </Typography>
                            {
                                Object.keys(dnm).map(e => {
                                    return (
                                        <Typography variant='subtitle2' className={classes.label}>
                                            <span className={classes.labelTitle}>{e} : </span>
                                            {dnm[e] || '0'}
                                        </Typography>
                                    )
                                })
                            }
                        </Grid>
                    }
                </Grid>
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {showDetails}
            onClose = {() => setDetails({flag: false, value: {}})}
            dialogTitle = ''
            dialogContent = {dialogContent()}
            maxWidth = {'md'}
            classes = {{ paper: classes.dialogPaper}}
            dialogFooter = {[]}
        />
    )

}