const branchConstants = {
    ADD_REQUEST: 'BRANCH_ADD_REQUEST',
    ADD_ERROR: 'BRANCH_ADD_ERROR',
    ADD_SUCCESS: 'BRANCH_ADD_SUCCESS',

    GET_REQUEST : 'USER_GET_REQUEST',
    GET_SUCCESS : 'USER_GET_SUCCESS',
    GET_ERROR : 'USER_GET_ERROR',

    UPDATE_REQUEST: 'BRANCH_UPDATE_REQUEST',
    UPDATE_ERROR: 'BRANCH_UPDATE_ERROR',
    UPDATE_SUCCESS: 'BRANCH_UPDATE_SUCCESS',

    GET_ALL_REQUEST : 'BRANCH_GET_ALL_REQUEST',
    GET_ALL_SUCCESS : 'BRANCH_GET_ALL_SUCCESS',
    GET_ALL_ERROR : 'BRANCH_GET_ALL_ERROR'
}

export default branchConstants;