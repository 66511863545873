import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { authActions } from "../../actions/authentication";
import ProtectedRoute from "./protectedroute";
import { connect } from 'react-redux';
import store from "../../reducers";

/*********  Screens to route **************/

import AssetsRequestOut from '../assets-request-out';
import AssetsRequestAll from '../assets-request-all';
import FundRequestAll from '../fund-request-admin';
import OnboardCustomer from '../onboard-customer';
import OnboardEmployee from '../onboard-employee';
import FundRequestOut from '../fund-request-out';
import FundsAvailable from "../funds-available";
import SearchCustomer from '../search-customer';
import AssetsRequestIn from '../assets-request';
import FundRequestIn from '../fund-request-in';
import ReportsByBranch from '../report-branch';
import OrdersHistory from '../orders-history';
import OrderApproval from '../order-approval';
import LeaveRequests from "../leave-requests";
import ReportsByAdmin from '../report-admin';
import OrderDetails from '../order-details';
import EmployeeList from '../employee-list';
import AdminAssets from '../assets-admin';
import PlaceOrder from '../place-order';
import Attendance from "../attendance";
import BrokenPage from "../brokenpage";
import Login from "../authentication";
import Dashboard from "../dashboard";
import Settings from "../settings";
import Material from '../material';
import Expense from '../expenses';
import Invoice from '../invoice';
import SmsLogs from '../smslogs';
import Reports from '../reports';
import Assets from '../assets';
import Branch from "../branch";

class RootLoader extends Component{
    constructor(props){
        super(props);
        this.validateToken();
    }

    validateToken = () => {
        const { getState, dispatch } = store;
        if( getState().auth.accessToken ){
            dispatch(authActions.validateToken());
        }
    }

    componentDidMount(){
    }

    render() {
        const { } = this.props;

        return(
            <BrowserRouter>
                <Switch> 
                    <Route exact path="/" component={Login} />
                    <ProtectedRoute exact path="/assets/request/all"component={AssetsRequestAll} />
                    <ProtectedRoute exact path="/assets/request/out"component={AssetsRequestOut} />
                    <ProtectedRoute exact path="/assets/request/in" component={AssetsRequestIn} />
                    <ProtectedRoute exact path="/onboard/customer"  component={OnboardCustomer} />
                    <ProtectedRoute exact path="/onboard/employee"  component={OnboardEmployee} />
                    <ProtectedRoute exact path="/reports/branch"    component={ReportsByBranch} />
                    <ProtectedRoute exact path="/reports/admin"     component={ReportsByAdmin} />
                    <ProtectedRoute exact path="/search"            component={SearchCustomer} />
                    <ProtectedRoute exact path="/funds/available"   component={FundsAvailable} />
                    <ProtectedRoute exact path="/funds/request/all" component={FundRequestAll} />
                    <ProtectedRoute exact path="/funds/request/out" component={FundRequestOut} />
                    <ProtectedRoute exact path="/leave/request"     component={LeaveRequests} />
                    <ProtectedRoute exact path="/funds/request/in"  component={FundRequestIn} />
                    <ProtectedRoute exact path="/order/approve/:id" component={OrderApproval} />
                    <ProtectedRoute exact path="/orders"            component={OrdersHistory} />
                    <ProtectedRoute exact path="/order/:id"         component={OrderDetails} />
                    <ProtectedRoute exact path="/employee"          component={EmployeeList} /> 
                    <ProtectedRoute exact path="/assets/admin"      component={AdminAssets} />                   
                    <ProtectedRoute exact path="/placeorder/:phone" component={PlaceOrder} />
                    <ProtectedRoute exact path="/attendance"        component={Attendance} />
                    <ProtectedRoute exact path="/dashboard"         component={Dashboard} />
                    <ProtectedRoute exact path="/settings"          component={Settings}  />
                    <ProtectedRoute exact path="/profile"           component={Dashboard} />
                    <ProtectedRoute exact path="/materials"         component={Material} />
                    <ProtectedRoute exact path="/expenses"          component={Expense} />
                    <ProtectedRoute exact path="/smslogs"           component={SmsLogs} />
                    <ProtectedRoute exact path="/invoice/:id"       component={Invoice} />
                    <ProtectedRoute exact path="/reports"           component={Reports} />
                    <ProtectedRoute exact path="/branch"            component={Branch} />
                    <ProtectedRoute exact path="/assets"            component={Assets} />
                    <Route component={BrokenPage} />
                </Switch>
            </BrowserRouter>
        );
    }
}

function mapStateToProps(state) {
    const { auth } = state;

    return {
        auth
    }
}

export default connect(mapStateToProps)(RootLoader);