import materialConstants from '../constants/material';

const initialState = {
    status: '',
    details: []
}

function materials(state = initialState, action) {
    switch(action.type) {
        case materialConstants.ADD_SUCCESS : 
            return {
                ...state,   
                status: 'ADD_SUCCESS',
            }
        case materialConstants.ADD_ERROR:
            return {
                ...state,
                status: 'ADD_ERROR'
            }
        case materialConstants.ADD_REQUEST:
            return {
                ...state,
                status: 'ADD_REQUEST'
            }
        case materialConstants.GET_ALL_SUCCESS : 
            return {
                ...state,   
                status: 'GET_ALL_SUCCESS',
                details: action.payload.data
            }
        case materialConstants.GET_ALL_ERROR:
            return {
                ...state,
                status: 'GET_ALL_ERROR'
            }
        case materialConstants.GET_ALL_REQUEST:
            return {
                ...state,
                status: 'GET_ALL_REQUEST'
            }
        case materialConstants.UPDATE_SUCCESS :
            return {
                ...state,   
                status: 'UPDATE_SUCCESS',
            }
        case materialConstants.UPDATE_ERROR:
            return {
                ...state,
                status: 'UPDATE_ERROR'
            }
        case materialConstants.UPDATE_REQUEST:
            return {
                ...state,
                status: 'UPDATE_REQUEST'
            }
        default: return state;
    }
}

export default materials;