import React, { Component } from 'react';
import { materialActions } from '../../actions/material';
import { assetActions } from '../../actions/assets';
import { connect } from 'react-redux';
import AssetsLayout from './layout';
import { utils } from '../../utils';
import Forbidden from '../forbidden';


class AdminAssets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showConfirm : false,
            filterQuery: 0,
            error: false,
            limit: 10,
            page: 0
        }
    }

    componentDidMount(){
        const { dispatch } = this.props;
        dispatch(materialActions.getMaterialWithPrice());
        this.getDetails();
    }

    getDetails = (page = 0, limit = 10, filter = 0) => {
        const { dispatch, profile } = this.props;
        if(profile.basic?.branch_id){
            let offset = page * limit;
            let br_id = profile.basic?.branch_id;
            dispatch(assetActions.getAssets(br_id, offset, limit, filter));
            dispatch(assetActions.getTotalRecords(br_id, filter));
        }
        else
            this.setState({ error : true });
    }

    resetPagination = () => {
        this.setState({ page: 0, limit: 10 });
    }


    handleSubmitRequest = (payload) => {
        const { dispatch } = this.props;
        dispatch(assetActions.markSold(payload));
    }

    componentDidUpdate(prevProps) {
        const { assets, profile } = this.props;
        let isPropsChanged = assets.status !== prevProps.assets.status;
        let profilePropsChanged = profile.basic !== prevProps.profile.basic;

        if(isPropsChanged){
            if(assets.status === 'SOLD_SUCCESS'){
                this.setState({ showConfirm :false, filterQuery: 0 });
                this.resetPagination();
                this.getDetails();
            }
        }
        if(profilePropsChanged){
            if(profile.basic?.branch_id && !assets.status.includes('GET_')){
                this.resetPagination();
                this.getDetails();
            }
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(assetActions.clear());
    }


    render() {
        if(utils.getRole().includes('ADMIN'))
            return (
                <AssetsLayout 
                    {...this.props}
                    page = {this.state.page}
                    limit = {this.state.limit}
                    error = {this.state.error}
                    getDetails = {this.getDetails}
                    handleSubmit = {this.handleSubmit}
                    status = {this.props.assets.status}
                    showConfirm = {this.state.showConfirm}
                    resetPagination = {this.resetPagination}
                    materials = {this.props.material.details}
                    assetDetails = {this.props.assets.details}
                    noOfRecords = {this.props.assets.noOfRecords}
                    handleSubmitRequest = {this.handleSubmitRequest}
                    setPage = {(val) => this.setState({ page: val})}
                    setLimit = {(val) => this.setState({ limit: val})}
                    setShowConfirm = {(e) => this.setState({ showConfirm: e})}
                    setFilterQuery = {(val) => this.setState({filterQuery : val})}
                />
            );
        else
            return ( 
                <Forbidden 
                    profile={this.props.profile}
                    dispatch = {this.props.dispatch}
                    notifications = {this.props.notifications}
                />
            );
    }
}

function mapStateToProps(state) {
    const { profile, assets, notifications, material }  = state;

    return {
        assets,
        profile,
        material,
        notifications
    };
}

export default connect(mapStateToProps)(AdminAssets);