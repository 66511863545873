import {httpClient} from "../utils";

export const fundService ={
    raise,
    getAll,
    addFunds,
    getTotalRecords,
    getFundsInBranch,
    pendingFundAction,
    cancelFundsAction,
    confirmFundsAction
    // update
}


function raise(payload){
    return httpClient.callApi("POST",`/funds/raiseFundRequest`, payload);
}

function getFilter(filter = {}){
    return `&approved_branch_id=${filter.approved_branch_id || 0}` +
            `&requested_branch_id=${filter.requested_branch_id || 0}` +
            `&status=${filter.status || '' }`+
            `&request_transaction_type=${filter.request_transaction_type || ''}`+
            `&approved_transaction_type=${filter.approved_transaction_type || ''}`+
            `&request_on=${filter.request_on || ''}`
}

function getAll(type, offset, limit, filter){
    return httpClient.callApi(
        "GET",
        `/funds/getFundRequestForBranch`+
            `?request_type=${type}`+
            getFilter(filter) +
            `&offset=${offset}`+
            `&limit=${limit}`
    );
}

function getTotalRecords(type, filter){
    return httpClient.callApi(
        "GET",
        `/funds/getTotalRecord`+
            `?request_type=${type}`+
            getFilter(filter)
    );
}

function addFunds(payload){
    return httpClient.callApi("POST",`/funds/addFund`, payload);
}

function getFundsInBranch(branch_id) {
    return httpClient.callApi("GET",`/funds/getFundsInBranch?branch_id=${branch_id}`);
}

function pendingFundAction(payload){
    return httpClient.callApi("POST",`/funds/approveFundRequest`, payload);   
}

function confirmFundsAction(payload){
    return httpClient.callApi("POST",`/funds/confirmApproval`, payload);
}

function cancelFundsAction(payload){
    return httpClient.callApi("POST",`/funds/cancelFundRequest`, payload);
}