import uploadConstants from '../constants/fileupload';

const initialState = {
    status: '',
    uploads: {}
}

function fileupload(state = initialState, action) {
    switch(action.type) {
        case uploadConstants.UPLOAD_REQUEST : 
            return {
                ...state,   
                status: 'UPLOAD_REQUEST',
                uploads : {
                    ...state.uploads,
                    [action.payload.type] : {
                        ...state.uploads[action.payload.type],
                        [action.payload.index] : {
                            name: '',
                            status: 'R'
                        }
                    }
                }
            }
        case uploadConstants.UPLOAD_SUCCESS : 
            return {
                ...state,   
                status: 'UPLOAD_SUCCESS',
                uploads : {
                    ...state.uploads,
                    [action.payload.type] : {
                        ...state.uploads[action.payload.type],
                        [action.payload.index] : {
                            name : action.payload.data,
                            status: 'S'
                        }
                    }
                }
            }
        case uploadConstants.UPLOAD_ERROR : 
            return {
                ...state,   
                status: 'UPLOAD_ERROR',
                uploads : {
                    ...state.uploads,
                    [action.payload.type] : {
                        ...state.uploads[action.payload.type],
                        [action.payload.index] : {
                            name : '',
                            status: 'E'
                        }
                    }
                }
            }
        case uploadConstants.CLEAR_UPLOADS : 
            return {
                ...state,   
                status: 'CLEAR_SUCCESS',
                uploads : {}
            }
        default: return state;
    }
}

export default fileupload;