import React, { useState } from 'react';
import { Lock, VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import { ModalDialog, Button } from "../../../components";
import useStyles from '../style';
import { 
    Grid, Box, FormControl, OutlinedInput, 
    Typography, InputAdornment, IconButton 
} from '@material-ui/core';


export default function PromptPassword(props) {
    const classes = useStyles();
    const [showPass, setShowPass] = useState(false);
    const {
        error = false,
        password = '',
        onSubmit = () => null,
        setDetails = () => null,
        handleChange = () => null,
    } = props;

    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                <Box className={classes.titleSection}>
                    <Typography variant = 'h6' 
                        style={{textAlign: 'center'}}
                    >
                        Please type your password for security reason.
                    </Typography>
                </Box>
                <Grid 
                    container 
                    direction='row' 
                    style = {{marginTop: '24px'}}
                >
                    <Grid 
                        item xs={12} xl={12}
                        sm={12} md={12} lg={12} 
                        className = {classes.actionGrid}
                    >
                        <FormControl className={classes.formControlInput}>
                            <OutlinedInput
                                onChange={(e) => handleChange(e.target.value, 'password')}
                                type = {showPass ? 'text' : 'password'}
                                className={classes.inputField}
                                placeholder = {'Password'}
                                error = { error }
                                value = {password}
                                id= { 'password' }
                                startAdornment = {
                                    <InputAdornment position='start'>
                                        <Lock edge='start' color='primary'/>
                                    </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPass((prev) => (!prev))}
                                            edge="end"
                                        >
                                            { 
                                                showPass ? 
                                                    <VisibilityOutlined /> : 
                                                    <VisibilityOffOutlined />
                                            }
                                        </IconButton>
                                    </InputAdornment>
                                }
                                inputProps = {{
                                    required: true,
                                    maxLength : 20
                                }}
                            />
                            <small className={'red'}>
                                { error && 'Invalid password' }
                            </small>
                        </FormControl>
       
                        <Button
                            text = {`Submit`}
                            variant = "primary"
                            style = {{
                                padding: '8px 16px',
                                margin: '16px 0px 24px', 
                                width: '220px',
                            }}
                            onClick = {() => onSubmit() }
                        />
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {true}
            onClose = {setDetails}
            dialogTitle = ''
            dialogContent = {dialogContent()}
            maxWidth = {'sm'}
            dialogFooter = {[]}
        />
    )

}