const assetConstants = {
    GET_REQUEST : 'GET_ASSETS_BY_BRANCH_REQUEST',
    GET_SUCCESS : 'GET_ASSETS_BY_BRANCH_SUCCESS',
    GET_ERROR : 'GET_ASSETS_BY_BRANCH_ERROR',

    GET_REQ_REQUEST : 'GET_ASSET_REQUEST_BY_BRANCH_REQUEST',
    GET_REQ_SUCCESS : 'GET_ASSET_REQUEST_BY_BRANCH_SUCCESS',
    GET_REQ_ERROR : 'GET_ASSET_REQUEST_BY_BRANCH_ERROR',

    POST_REQUEST: 'ASSET_REQ_POST_REQUEST',
    POST_ERROR: 'ASSET_REQ_POST_ERROR',
    POST_SUCCESS: 'ASSET_REQ_POST_SUCCESS',

    P_ACTION_REQUEST: 'ASSET_REQUEST_PENDING_ACTION_REQUEST',
    P_ACTION_ERROR: 'ASSET_REQUEST_PENDING_ACTION_ERROR',
    P_ACTION_SUCCESS: 'ASSET_REQUEST_PENDING_ACTION_SUCCESS',

    C_ACTION_REQUEST: 'ASSET_REQUEST_CONFIRM_ACTION_REQUEST',
    C_ACTION_ERROR: 'ASSET_REQUEST_CONFIRM_ACTION_ERROR',
    C_ACTION_SUCCESS: 'ASSET_REQUEST_CONFIRM_ACTION_SUCCESS',

    SOLD_REQUEST: 'ASSET_SOLD_FROM_HQ_REQUEST',
    SOLD_ERROR: 'ASSET_SOLD_FROM_HQ_ERROR',
    SOLD_SUCCESS: 'ASSET_SOLD_FROM_HQ_SUCCESS',

    RECORDS_REQUEST : 'ASSETS_TOTAL_RECORDS_REQUEST',
    RECORDS_SUCCESS : 'ASSETS_TOTAL_RECORDS_SUCCESS',
    RECORDS_ERROR : 'ASSETS_TOTAL_RECORDS_ERROR',

    REQ_RECORDS_REQUEST : 'ASSETS_REQUEST_TOTAL_RECORDS_REQUEST',
    REQ_RECORDS_SUCCESS : 'ASSETS_REQUEST_TOTAL_RECORDS_SUCCESS',
    REQ_RECORDS_ERROR : 'ASSETS_REQUEST_TOTAL_RECORDS_ERROR',

    CLEAR: 'CLEAR_ASSET_DATA'
}

export default assetConstants;