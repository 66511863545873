import React, { useEffect } from 'react';
import { Button, Navbar, Table, UserDetailsCard } from "../../components";
import { Box, Paper, Typography } from '@material-ui/core';
import { Add, DeleteOutline } from '@material-ui/icons';
import MaterialRelease from './components/release';
import OrderTotalCalc from './components/totalcalc';
import payload from './payload.json';
import { utils } from '../../utils';
import useStyles from './style';


export default function PlaceOrderLayout(props){
    const classes = useStyles();
    const {
        handleUploadFile = () => null,
        updateSummary = () => null,
        updatePledge = () => null,
        setTableData = () => null,
        handleSubmit = () => null,
        materialDetails = [],
        tableHeaders = [],
        userDetails = [],
        tableData = [],
        details = {},
        pledge = {},
        status = '',
    } = props;

    useEffect(() => {
        setTableData([{ ...payload.order_line }]);
    },[]);

    const getMaterialPrice = (id) => {
        let m = [];
        m = materialDetails.filter(e => e.material_id === id);
        return m[0]?.material_price || 0;
    }

    const handleNewRow = (flag) => {
        if(flag) {
            setTableData([ 
                ...tableData, 
                payload.order_line
            ]);
        } else {
            let t = tableData;
            t.splice(t.length - 1, 1);
            setTableData([ ...t ]);
        }
    }

    const calculateAmount = (g, flag = false) => {
        let net_weight = (g.item_weight - (g.stone_wastage + g.other_wastage));
        net_weight = (net_weight * 100) / 100 ;
        let item_price = net_weight * g.material_price * ( g.item_purity / 100 );
        item_price =  item_price + (g.stone_wastage * g.stone_price);
        if(!flag)
            item_price =  item_price - ( (g.margin * item_price ) / 100) ;

        item_price = utils.roundValue(item_price);
        return { ...g, item_price, net_weight };
    }

    const calculateMargin = (g) => {
        let grossAmount = calculateAmount(g , true)?.item_price;
        let margin_amount = grossAmount - g.item_price;
        let margin = (margin_amount / (margin_amount + g.item_price) ) * 100;
        margin = utils.roundFixed(margin);
        return { ...g, margin_amount, margin };
    }

    const handleTotalChange = (value, name, detail = details) => {
        if(['service_charge_percent', 'actual_amount'].includes(name)){
            let service_charge_percent = name === 'service_charge_percent' ? 
                                            parseFloat(value) : 
                                            detail.service_charge_percent;
            let actual_amount = name === 'actual_amount' ? 
                                            parseFloat(value) :
                                            detail.actual_amount 
            let netAmt = actual_amount || 0;
            actual_amount = utils.roundValue(actual_amount);
            let service_charge_amount = netAmt * ( service_charge_percent / 100 );
            service_charge_amount = utils.roundValue(service_charge_amount);
            let requested_amount = netAmt - service_charge_amount;
            requested_amount = utils.roundValue(requested_amount);
            updateSummary({
                ...detail,
                service_charge_amount,
                service_charge_percent,
                requested_amount,
                actual_amount
            })
        }else if(name === 'order_type'){
            updateSummary({ ...detail, [name]: value });
            updatePledge({ ...payload.pledge_items });
        }else
            updateSummary({ ...detail, [name] : value });
    }

    const calculateNetAmount = (t) => {
        let amt = 0;
        t.map(e => amt = amt + ( parseFloat(e.item_price || 0)) );
        handleTotalChange(amt, 'actual_amount');
    }

    const handleRowInputChange = (v, row, index, column) => {
        let t = [ ...tableData ];
        if(column.inputType === 'number'){
            let val = parseFloat(v.target.value) ?
                           ( parseFloat(v.target.value) >= 0 ? 
                                parseFloat(v.target.value) : 0 ) : 0
            t = t.map((e, i) => {
                if(i === index) {
                    let g = { ...e, [column.id] : val };
                    let final = column.id === 'item_price' ? 
                                    calculateMargin(g) : 
                                    calculateAmount(g);
                    return final;
                }else 
                    return { ...e };
            });
            calculateNetAmount(t);
            setTableData(t);
        }else if(column.inputType === 'file'){
            t = t.map((e, i) => {
                if(i === index) return { ...e, "item_image" : "uploading" }
                else return { ...e };
            });
            handleUploadFile(v.target.files, index);
            setTableData(t);
        }
    }

    const handleRowDropdown = (v, column, index) => {

        let t = [ ...tableData ];
        let val = { [column.id] : v.target.value };

        if(column.id === 'material_id'){
            val = { ...val, material_price : getMaterialPrice(v.target.value) };
        }

        t = t.map((e, i) => {
            if(i === index) 
                return calculateAmount({ ...e, ...val });
            else 
                return { ...e };
        });
        setTableData(t);
    }

    const handlePledgeChange = (value, name, type = 'text') => {
        if(type === 'text')
            updatePledge({ ...pledge, [name] : value });
        else
            handleUploadFile(value, 0, 'pledge_items');
    }

    return (
        <Navbar 
            { ...props }
        >
            {
                userDetails.length >= 1 && 
                userDetails?.[0]?.users &&
                <Box style={{ margin: '2% 10%'}}>
                    <UserDetailsCard
                        userDetails = {userDetails[0]}
                    />
                </Box>
            }
            <Box className={classes.mainBox}>
                <Box className = {classes.tableTitleBox}>
                    <Typography variant = "h5">
                        Buy
                    </Typography>
                </Box>
            </Box>
            <Paper className = {classes.tableBox}>
                <Table
                    tableColumns = {tableHeaders}
                    rowMenuOptions = {[]}
                    handleMenuDropdown = {() => null}
                    tableData = {tableData}
                    handleChange = { () => null }
                    handleRowInputChange = {handleRowInputChange}
                    handleRowDropdown = {handleRowDropdown}
                />
            </Paper>
            <Box className={classes.addMainBox}>
                {
                    tableData.length > 1 &&
                    <Button
                        text = "Remove last line item"
                        variant = "secondary"
                        Icon = {DeleteOutline}
                        style = {{
                            padding: '8px 16px', 
                            marginRight: '8px', 
                            maxWidth: '240px'
                        }}
                        onClick = {() => handleNewRow(false)}
                    />
                }
                <Button
                    text = "Add more line item"
                    variant = "primary"
                    Icon = {Add}
                    style = {{
                        padding: '8px 16px', 
                        maxWidth: '240px'
                    }}
                    onClick = {() => handleNewRow(true)}
                />
            </Box>
            <Box className={classes.extraFields}>
                <MaterialRelease
                    {...props}
                    summary = {details}
                    details = {pledge}
                    handleChange = {handlePledgeChange}
                    handleTotalChange = {handleTotalChange}
                />
                <OrderTotalCalc
                    {...props}
                    handleTotalChange = {handleTotalChange}
                />
            </Box>
            <Box className={classes.placeOrderBtn}>
                <Button
                    text = "Place order"
                    variant = "primary"
                    style = {{
                        padding: '8px 16px', 
                        width: '360px'
                    }}
                    onClick = {handleSubmit}
                />        
            </Box>
        </Navbar>
    );
}
