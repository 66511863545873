import React, { useState } from 'react';
import { ModalDialog, Button } from "../../../components";
import { ExpandMore } from '@material-ui/icons';
import useStyles from '../style';
import {
    Grid, Box,
    Typography,
    FormControl,
    OutlinedInput,
    Select,
    MenuItem
} from '@material-ui/core';
import {
    fundRequestConstants as frc
} from '../../../constants';

let payl = {
    "fund_request_id": 0,
    "approved_amount": 0,
    "transaction_type": "RTGS",
    "status": "",
    "denomination": null,
    "description": ""
};

let dnm = {
    "2000" : 0,
    "500" : 0,
    "200" : 0,
    "100" : 0,
    "50" : 0,
    "20" : 0,
    "10" : 0,
    "5" : 0,
    "2" : 0,
    "1" : 0
};

let dnmKeys = [
    "2000" , "500" , "200", "100",
    "50", "20", "10", "5", "2", "1" 
]

export default function ApproveReject(props) {
    const classes = useStyles();
    const [payload, setPayload] = useState(payl);
    const [denomination, setDenomination] = useState(dnm);
    const [error, setError] = useState(false);
    const {
        status = '',
        details = {},
        showDetails = '',
        setDetails = () => null
    } = props;

    let isDnmRequired = showDetails === 'APPROVED' && 
                        payload.transaction_type === 'CASH';

    const handleChange = (name, value, type = 'text') => {
        setError(false);
        setPayload({
            ...payload, 
            [name]: type === 'number' ? value || 0 : value
        });
    }

    const handleDenominationChange = (name, value) => {
        setDenomination({ ...denomination, [name] : value || 0});
    }

    const handleSubmit = (status) => {
        if(
            status === 'APPROVED' && 
            !payload.approved_amount
        ){
            setError(true);
        }else{
            let dnmStr = status === 'APPROVED' &&
                         payload.transaction_type === 'CASH' ?
                            JSON.stringify(denomination) : null;
            let pay = {
                ...payload,
                status,
                denomination: dnmStr,          
                fund_request_id: details.id,
                description : payload.description,
                approved_amount: status === 'APPROVED' ? 
                                    payload.approved_amount : 0,
                transaction_type: status === 'APPROVED' ? 
                                    payload.transaction_type : 
                                    details.request_transaction_type,
            };
            props.handleSubmit(pay);
        }
    }

    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        Fill Details to {showDetails}
                    </Typography>
                </Box>
                <Grid 
                    container 
                    direction='row' 
                    className = {classes.fundDetailsMainGrid}
                >
                    <Grid 
                        item xs={12} xl={6}
                        sm={6} md={6} lg={6} 
                        className = {classes.actionGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Requested Amount
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.request_amount || 'NA'}
                        </Typography>
                    </Grid>
                    <Grid 
                        item xs={12} xl={6}
                        sm={6} md={6} lg={6} 
                        className = {classes.actionGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Requested type
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.request_transaction_type || 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid 
                    container 
                    direction='row' 
                    className = {classes.fundDetailsMainGrid}
                    style = {{marginTop: '16px'}}
                >
                    <Grid 
                        item 
                        xs={12} 
                        sm={isDnmRequired ? 6 : 12} 
                        md={isDnmRequired ? 6 : 12} 
                        lg={isDnmRequired ? 6 : 12} 
                        xl={isDnmRequired ? 6 : 12}
                        className = {classes.actionGrid}
                    >
                        {
                            showDetails === 'APPROVED' &&
                            <FormControl className={classes.inputField}>
                                <Typography variant='subtitle2' className={classes.label}>
                                    Amount to Approve
                                </Typography>
                                <OutlinedInput
                                    onChange={(e) => handleChange(
                                        'approved_amount', 
                                        parseInt(e.target.value), 
                                        'number'
                                    )}
                                    className={classes.inputField}
                                    value = {payload['approved_amount']}
                                    error= {error}
                                    placeholder={'Amount'}
                                    type = {'number'}
                                    id='approved_amount'
                                    inputProps = {{
                                        required: true,
                                        maxLength : 45,
                                        min : 0
                                    }}
                                />
                            </FormControl>
                        }
                        <small className="red" style={{marginTop: 4}}>
                                {error && frc.invalidApproveamount}
                        </small>
                        {
                            showDetails === 'APPROVED' &&
                            <FormControl variant='outlined' className={classes.inputField}>
                                <Typography variant='subtitle2'
                                    className={classes.label}
                                >
                                    Transaction type
                                </Typography>
                                <Select
                                    className={classes.inputField}
                                    IconComponent = {ExpandMore}
                                    value = {payload['transaction_type']}
                                    inputProps={{name: 'transactiontype'}}
                                    onChange={(e) => handleChange(
                                        'transaction_type', e.target.value
                                    )}
                                >
                                    <MenuItem value={'CASH'}>Cash</MenuItem>
                                    <MenuItem value={'RTGS'}>RTGS</MenuItem>
                                </Select>
                            </FormControl>
                        }
                        <FormControl variant='outlined' className={classes.inputField}>
                            <Typography variant='subtitle2'
                                className={classes.label}
                            >
                                Description (optional)
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleChange('description' , e.target.value)}
                                value = {payload['description']}
                                className={classes.inputField}
                                placeholder={'Description'}
                                type = {'text'}
                                id='description'
                                multiline
                                inputProps = {{
                                    required: true,
                                    maxLength : 200
                                }}
                            />
                        </FormControl>
                    </Grid>
                    {
                    isDnmRequired &&
                    <Grid 
                        item
                        sm={isDnmRequired ? 6 : 12} 
                        md={isDnmRequired ? 6 : 12} 
                        lg={isDnmRequired ? 6 : 12} 
                        xl={isDnmRequired ? 6 : 12}
                        className = {classes.actionGrid}
                    >
                        {
                            dnmKeys.map(dnms => {
                                return (
                                    <FormControl className={classes.inputField}>
                                        <Typography variant='subtitle2' className={classes.label}>
                                            {dnms} 's
                                        </Typography>
                                        <OutlinedInput
                                            onChange={(e) => handleDenominationChange(
                                                dnms, parseInt(e.target.value)
                                            )}
                                            className={classes.inputField}
                                            value = {denomination[dnms]}
                                            type = {'number'}
                                            id={"denom_" + dnms}
                                            inputProps = {{
                                                required: true,
                                                maxLength : 45,
                                                min : 0
                                            }}
                                        />
                                    </FormControl>
                            )})
                        }
                        
                    </Grid>
                    }
                </Grid>
                {
                    showDetails === 'REJECTED' &&
                    <Button
                        text = "Reject"
                        variant = "primary"
                        style = {{
                            padding: '8px 16px',
                            marginTop: '40px', 
                            backgroundColor: '#db252e',
                            width: '280px' 
                        }}
                        loading = {status === 'P_ACTION_REQUEST'}
                        onClick = {() => handleSubmit('REJECTED') }
                    />
                }
                {
                    showDetails === 'APPROVED' &&
                    <Button
                        text = "Approve"
                        variant = "primary"
                        style = {{
                            padding: '8px 16px',
                            marginTop: '40px', 
                            width: '280px' 
                        }}
                        loading = {status === 'P_ACTION_REQUEST'}
                        onClick = {() => handleSubmit('APPROVED') }
                    />
                }
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {showDetails}
            onClose = {() => setDetails('', details)}
            dialogTitle = ''
            dialogContent = {dialogContent()}
            maxWidth = {'md'}
            classes = {{ paper: 
                showDetails === 'APPROVED' ?
                    classes.dialogPaper :
                    classes.pendingDialogPaper
            }}
            dialogFooter = {[]}
        />
    )

}