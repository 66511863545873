const leaveConstants = {
    POST_REQUEST: 'LEAVE_POST_REQUEST',
    POST_ERROR: 'LEAVE_POST_ERROR',
    POST_SUCCESS: 'LEAVE_POST_SUCCESS',

    GET_ALL_REQUEST : 'LEAVE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS : 'LEAVE_GET_ALL_SUCCESS',
    GET_ALL_ERROR : 'LEAVE_GET_ALL_ERROR',

    RECORDS_REQUEST : 'LEAVE_TOTAL_RECORDS_REQUEST',
    RECORDS_SUCCESS : 'LEAVE_TOTAL_RECORDS_SUCCESS',
    RECORDS_ERROR : 'LEAVE_TOTAL_RECORDS_ERROR',

    P_ACTION_REQUEST: 'PENDING_LEAVE_ACTION_REQUEST',
    P_ACTION_SUCCESS: 'PENDING_LEAVE_ACTION_SUCCESS',
    P_ACTION_ERROR: 'PENDING_LEAVE_ACTION_ERROR',

    CANCEL_LR_REQUEST: 'CANCEL_LEAVE_ACTION_REQUEST',
    CANCEL_LR_SUCCESS: 'CANCEL_LEAVE_ACTION_SUCCESS',
    CANCEL_LR_ERROR: 'CANCEL_LEAVE_ACTION_ERROR'
}

export default leaveConstants;