import leaveConstants from '../constants/leave-request';

const initialState = {
    status: '',
    noOfRecords: 0,
    leaveDetails: [],
}

function leave(state = initialState, action) {
    switch(action.type) {
        case leaveConstants.POST_SUCCESS : 
            return {
                ...state,   
                status: 'POST_SUCCESS',
            }
        case leaveConstants.POST_ERROR:
            return {
                ...state,
                status: 'POST_ERROR'
            }
        case leaveConstants.POST_REQUEST:
            return {
                ...state,
                status: 'POST_REQUEST'
            }
        case leaveConstants.GET_ALL_SUCCESS : 
            return {
                ...state,   
                status: 'GETALL_SUCCESS',
                leaveDetails: action.payload.data
            }
        case leaveConstants.GET_ALL_ERROR:
            return {
                ...state,
                status: 'GETALL_ERROR'
            }
        case leaveConstants.GET_ALL_REQUEST:
            return {
                ...state,
                status: 'GETALL_REQUEST'
            }
        case leaveConstants.RECORDS_SUCCESS : 
            return {
                ...state,   
                status: 'RECORDS_SUCCESS',
                noOfRecords: action.payload.data.total
            }
        case leaveConstants.RECORDS_ERROR:
            return {
                ...state,
                status: 'RECORDS_ERROR'
            }
        case leaveConstants.RECORDS_REQUEST:
            return {
                ...state,
                status: 'RECORDS_REQUEST'
            }
        case leaveConstants.P_ACTION_REQUEST : 
            return {
                ...state,   
                status: 'P_ACTION_REQUEST',
            }
        case leaveConstants.P_ACTION_SUCCESS:
            return {
                ...state,
                status: 'P_ACTION_SUCCESS'
            }
        case leaveConstants.P_ACTION_ERROR:
            return {
                ...state,
                status: 'P_ACTION_ERROR'
            }
        case leaveConstants.CANCEL_LR_REQUEST : 
            return {
                ...state,   
                status: 'CANCEL_LR_REQUEST',
            }
        case leaveConstants.CANCEL_LR_SUCCESS:
            return {
                ...state,
                status: 'CANCEL_LR_SUCCESS'
            }
        case leaveConstants.CANCEL_LR_ERROR:
            return {
                ...state,
                status: 'CANCEL_LR_ERROR'
            }
        default: return state;
    }
}

export default leave;