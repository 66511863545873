import React from 'react';
import useStyles from './style';
import ChangePassword from './components/change-password';
import ProfileDetails from './components/profile';
import SafeMode from './components/safe-mode';
import { Navbar } from '../../components';

export default function SettingsLayout(props){
    const classes = useStyles();
    const {
        status = '',
        profile = {},
        notifications = {}
    } = props;

    return (
        <Navbar 
            { ...props }
        >
            <ProfileDetails 
                details = {profile?.basic}
                handleEdit = {() => null}
            />
            <ChangePassword 
                status = {props.status || ''}
                handleSubmit = {props.handleChangePassword}
            />
            <SafeMode 
                status = {status || ''}
                notifications = {notifications || {}}
                handleSubmit = {props.handleSafeMode}
            />
        </Navbar>
    )
}