import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, TablePagination } from '@material-ui/core';
import { KeyboardArrowDown, AutorenewOutlined } from '@material-ui/icons';
import { Button, Navbar, Table, ViewImage } from "../../components";
import { assetListconstants as alc } from '../../constants';
import ConfirmSold from './components/confirm-sold';
import { utils } from '../../utils';
import useStyles from './style';

let tableHeaders = [
    {
        id: "id",
        label : "Asset Id",
        type: 'text_wo_input',
        minWidth: '60px',
        maxWidth: '60px',
        style : {}
    },
    {
        id: "name",
        label : "Name",
        type: 'text_wo_input',
        minWidth: '160px',
        maxWidth: '160px',
        style : {}
    },
    {
        id: "material_type",
        placeHolder: "Material",
        label : "Material",
        type: 'dropdown',
        minWidth: '100px',
        maxWidth: '100px',
        dropdownItems: [],
        style: {}
    },
    {
        id: "image",
        label : "Image",
        type: 'popup_action',
        minWidth: '80px',
        maxWidth: '80px',
        style : { }
    },
    {
        id: "gross_weight",
        label : "Gross weight (g)",
        type: 'text_wo_input',
        minWidth: '100px',
        maxWidth: '100px',
        style : {}
    },
    {
        id: "created_time",
        label : "Bought at",
        type: 'text_wo_input',
        minWidth: '150px',
        maxWidth: '150px',
        style : {}
    }
];

let paginationDropDownList = [
    10, 20, 40
]


let headerMenuOptions = [
    {key: 'sold' , title: 'Change to Sold'}
]

export default function AdminAssetsLayout(props){
    const classes = useStyles();
    const [showImage, setShowImage] = useState({flag: false, details: {}});
    const [tableColumns, setTableColumns] = useState([ ...tableHeaders ]);
    const [clearInputFields, setClearInputFields] = useState(false);
    const [clearInputs, setClearInputs] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [tableData, setTableData] = useState([]);
    const {
        handleSubmitRequest = () => null,
        resetPagination = () => null,
        setFilterQuery = () => null,
        setShowConfirm = () => null,
        getDetails = () => null,
        setLimit = () => null,
        setPage = () => null,
        showConfirm = false,
        assetDetails = [],
        noOfRecords = 0,
        filterQuery = 0,
        materials = [],
        status = '',
        limit = 10,
        page = 0,
    } = props;

    let role = utils.getRole();

    useEffect(() => {
        if(role.includes('ADMIN')){
            setTableColumns([
                ...tableHeaders,
                {
                    id: 'more',
                    label: '',
                    type: 'checkbox',
                    minWidth: '50px',
                    maxWidth: '50px'
                }
            ])
        }
    },[role]);


    useEffect(() => {
        if(clearInputFields){
            setClearInputFields(false);
            setFilterQuery(0);
        }
    }, [clearInputFields]);


    const dataFormatter = (e) => {
        return {
            ...e,
            created_time: utils.getLocalTime(e.created_time || ""),
            image: utils.getImageUrl(e.order_item_details?.item_image || ""),
            gross_weight: e.order_item_details?.item_weight
        }
    }


    useEffect(() => {
        if(status === 'GET_SUCCESS'){
            let td = [];
            if(assetDetails.length > 0){
                td = assetDetails.map(e => {
                    return dataFormatter(e);
                });
            }
            setTableData([ ...td ]);
            setSelectedRow([]);
            setClearInputs(true);
        }
        if(status === 'P_ACTION_SUCCESS'){

        }
    },[status]);


    const handleMenuDropdown = (e, k, i, r) => {
        if(k.key === 'details'){

        }else if(k.key === 'sold'){
            setShowConfirm(true);
        }
    }

    const handleImageViewAction = (r, c, i) => {
        setShowImage({flag: true, details: r});
    }

    const onRowClick = (e, r, i) => {
        
    }

    const handleAllcheckBoxSelect = (e, r, i, all = false) => {
        if(all){
            if(e.target.checked){
                let arr = [];
                tableData.map(f => {arr.push(f.id)});
                setSelectedRow(arr);
            }else
                setSelectedRow([]);
        }else {
            if(e.target.checked){
                let arr = [ ...selectedRow, r.id ];
                setSelectedRow(arr);
            }else {
                let arr = [ ...selectedRow ];
                arr = arr.filter(f => f !== r.id );
                setSelectedRow(arr);
            }
        }
        setClearInputs(false);
    }


    const handleHeaderMenu = () => {
        if( 
            role.includes('ADMIN')
            && selectedRow.length
        )  
            return headerMenuOptions;
        else return [];
    }

    const onChangeRowsPerPage = (e) => {
        setLimit(e.target.value);
        setPage(0);
        getDetails(0, e.target.value, filterQuery);
    }

    const onChangePage = (e, val) => {
        setPage(val);
        getDetails(val, limit, filterQuery);
    }

    const handleFilterChange = (val, col) => {
        setPage(0);
        setFilterQuery(val);
        getDetails(0, limit, val);
    }

    const getFormattedColumns = () => {
        let thc = [ ...tableColumns ];
        if(materials.length > 0){
            thc = thc.map(e => {
                if(e.id === 'material_type') {
                    return {
                        ...e,
                        dropdownItems: materials.map(
                            f => { return { 
                                label: f.name, 
                                value: f.material_id 
                            } }
                        )
                    }
                }else return e;
            });
        }
        return thc;
    }


    return (
        <Navbar 
            { ...props }
        >
            <Box className={classes.mainBox}>
                <Box className = {classes.tableTitleBox}>
                    <Typography variant = "h5">
                        {alc.adminTableTitle}
                    </Typography>
                    <Box className={classes.actionButtons}>
                        <Button
                            text = "Refresh"
                            variant = "secondary"
                            Icon = {AutorenewOutlined}
                            style = {{
                                padding: '8px 16px', 
                                marginRight: '16px' 
                            }}
                            onClick = {() => getDetails(page, limit, filterQuery)}
                        />
                    </Box>
                </Box>
            </Box>
            <Paper className = {classes.tableBox}>
                <Table                 
                    handleMenuDropdown = {handleMenuDropdown}
                    handlePopupAction={handleImageViewAction}
                    handleCheckBox = {handleAllcheckBoxSelect}
                    headerMenuOptions = {handleHeaderMenu()}
                    tableColumns = {getFormattedColumns()}
                    clearInputFields = {clearInputFields}
                    loading = {status === 'GET_REQUEST'}
                    handleChange = {handleFilterChange}
                    onTableRowClick = {onRowClick}
                    clearInputs = {clearInputs}
                    tableData = {tableData}
                    rowMenuOptions = {[]}
                />
                <TablePagination
                    rowsPerPageOptions={paginationDropDownList}
                    component='div'
                    count={noOfRecords}
                    rowsPerPage={limit}
                    page={page}
                    SelectProps={{
                        IconComponent: KeyboardArrowDown,
                        classes: { icon: classes.selectIcon },
                    }}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                />  
            </Paper>
            {
                showImage.flag &&
                <ViewImage
                    show = {showImage.flag}
                    setShow = {setShowImage}
                    image = {showImage.details?.image}
                    imageTitle = {showImage.details?.name + " IMAGE "}
                />
            }
            {
                showConfirm &&
                <ConfirmSold
                    status = {status}
                    show = {showConfirm}
                    details = {selectedRow}
                    setShow = {setShowConfirm}
                    handleSubmit = {handleSubmitRequest}
                />
            }
        </Navbar>
    );
}