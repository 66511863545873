import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    containerBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    mainGrid : {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '80%'
    },
    titleSection: {
        width: '40vw', 
        marginTop: theme.spacer * 3,
        marginBottom: theme.spacer * 3,
        textAlign: 'center'
    },
    indGrid : {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    dialogPaper : {
        maxHeight: '80vh',
        width: '100vw',
        // maxWidth: '50vw'
    },
    mainBox : {
        margin: '5% 5% 2% 5%'
    },
}));

export default useStyles;