import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  selectSearch: {
    width: '100%',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04)',
    borderRadius: '2px !important',
    '& svg': {
      fontSize: theme.spacing(2) + 'px',
      top: theme.spacing(1) + 'px',
      right: theme.spacing(1) + 'px',
    },
    '& .MuiSelect-selectMenu': {
      color: theme.color.black,
      fontSize: '13px'
    }
  },
  rowSelect: {
    backgroundColor: 'rgba(0,0,0,0.09)'
  },
  columnSelect: {
    backgroundColor: theme.color.white,
  },
  dmnSelect :{
    width: '100%',
    '& svg': {
      fontSize: theme.spacing(2) + 'px',
      top: theme.spacing(1) + 'px',
      right: theme.spacing(1) + 'px'
    }
  },
  placeHolder: {
    color: theme.color.notsocoolgray,
    fontSize: 13,
  },
  chips: {
    display: 'flex',
  },
  chip: {
    margin: 2,
  }
}));
