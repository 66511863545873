import React, { useState } from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import ActionDetails from './components/action-details';
import OrderTotalCalc from './components/totalcalc';
import MaterialRelease from './components/release';
import { utils } from '../../utils';
import useStyles from './style';
import { 
    Navbar, ViewImage,
    UserDetailsCard, Table 
} from "../../components";


export default function OrderDetailsLayout(props){
    const classes = useStyles();
    const [showImage, setShowImage] = useState({flag:false, details: {}});
    const {
        tableHeaders = [],
        pledge_item = {},
        userDetails = {},
        tableData = [],
        details = {},
    } = props;

    const handleImageViewAction = (r, c, i) => {
        setShowImage({flag: true, details: r});
    }

    const getTableDataWithImage = () => {
        if(tableData.length)
            return tableData.map(e => {
                    return { 
                        ...e, 
                        item_image: utils.getImageUrl(e.item_image || ""),
                        item_actual_price: utils.getFormattedAmount(e.item_actual_price || 0),
                    }
            })
        return [];
    }

    return (
        <Navbar 
            { ...props }
        >
            <Box style={{ margin: '2% 10%'}}>
                {
                    userDetails?.[0] &&
                    userDetails?.[0].users &&
                    <UserDetailsCard
                        overRideKey = {'Branch'}
                        overRideKeyValue = { userDetails[0]?.branch?.branch_name || "" }
                        userDetails = { userDetails[0] || {}}
                    />
                }
            </Box>
            <Box className={classes.mainBox}>
                <Box className = {classes.tableTitleBox}>
                    <Typography variant = "h5">
                        Order Details 
                    </Typography>
                    <Typography variant = "h5">
                        <span className = {classes.orderStatus}>status : </span> 
                        <span className = {
                                `${classes[details?.order_status?.toLowerCase()]} ` + 
                                `${classes.orderStatus}`
                        }>
                            {" " + ( details.order_status || "NA" ) }
                        </span>
                    </Typography>
                </Box>
            </Box>
            <Paper className = {classes.tableBox}>
                <Table
                    tableColumns = {tableHeaders}
                    rowMenuOptions = {[]}
                    handleMenuDropdown = {() => null}
                    handlePopupAction={handleImageViewAction}
                    tableData = {getTableDataWithImage()}
                    handleChange = { () => null }
                />
            </Paper>
            <Box className={classes.extraFields}>
                <MaterialRelease 
                    {...props} 
                    pledge_item={ pledge_item }
                    handleImageView = {handleImageViewAction}
                />
                <ActionDetails {...props}/>
                <OrderTotalCalc {...props} />
            </Box>
            {
                showImage.flag &&
                <ViewImage
                    show = {showImage.flag}
                    setShow = {setShowImage}
                    image = {showImage.details?.item_image}
                    imageTitle = {showImage.details?.name + " Image "}
                />
            }
        </Navbar>
    );
}
