import React from 'react';
import { ModalDialog, Button } from "../../../components";
import { Grid, Box, Typography } from '@material-ui/core';
import { utils } from '../../../utils';
import useStyles from '../style';


export default function PendingAction(props) {
    const classes = useStyles();
    const {
        status = '',
        details = {},
        setDetails = () => null
    } = props;


    const handleSubmit = (status = '') => {
        let pay = { 
            request_id: details.id,
            status
        };
        props.handleSubmit(pay);
    }

    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        Leave Request Action
                    </Typography>
                </Box>
                <Grid 
                    container 
                    direction='row' 
                    className = {classes.fundDetailsMainGrid}
                >
                    <Grid 
                        item xs={12} xl={6}
                        sm={6} md={6} lg={6} 
                        className = {classes.actionGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Name
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.requested_by || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Leave type
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.leave_type || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Reason
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.reason || 'NA'}
                        </Typography>
                    </Grid>
                    <Grid 
                        item xs={12} xl={6}
                        sm={6} md={6} lg={6} 
                        className = {classes.actionGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Leave From
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.from_date || "NA" }
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Leave Until
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.to_date || "NA" }
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            No of Days 
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.no_of_days || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Requested On
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {utils.getLocalTime(details.request_on || "") || 'NA'}
                        </Typography>
                    </Grid>
                </Grid>         
            </Box>
        );
    }

    const footer = () => {
        return (
            <>
                <Button
                    text = "Close"
                    variant = "secondary"
                    onClick = {setDetails}
                    style = {{ padding: '8px 32px' }}
                />
                <Button
                    text = "Reject"
                    variant = "error"
                    style = {{ padding: '8px 32px' }}
                    loading = {status === 'P_ACTION_REQUEST'}
                    onClick = {() => handleSubmit('REJECTED') }
                />
                <Button
                    text = "Approve"
                    variant = "primary"
                    style = {{ padding: '8px 32px' }}
                    loading = {status === 'P_ACTION_REQUEST'}
                    onClick = {() => handleSubmit('APPROVED') }
                />     
            </>
        )
    }

    return (
        <ModalDialog
            open = {true}
            dialogTitle = ''
            maxWidth = {'md'}
            onClose = {setDetails}
            dialogFooter = {footer()}
            dialogContent = {dialogContent()}
            classes = {{ paper: classes.pendingDialogPaper }}
        />
    )

}