import React, { useEffect, useState } from 'react';
import useStyles from './style';
import { 
    Typography,
    FormControl,
    OutlinedInput,
} from '@material-ui/core';
import { 
    onboardCustomerConstants as oc 
} from '../../constants';

export default function Address(props){

    const classes = useStyles();
    const [data, setData] = useState({});
    const {
        errorValue = {show: false, keys: {}},
        // handleChange = () => null,
        parentKey = 'address',
        addressIndex = 0,
        disabled = false,
        required = true,
        details = {},
        title = '',
    } = props;

    useEffect(() => {
        setData(details);
    },[]);

    const handleChange = (value, parentKey, key, index) => {
        setData((prev) => ({ ...prev, [key] : value }));
        props.handleChange(value, parentKey, key, index);
    }

    return (
        <>
            { 
                title &&
                <Typography variant='subtitle2' className= {classes.addressTitle}>
                    { title }
                </Typography>
            }
            <FormControl className={classes.formControlInput}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Door No. { required && '*'} 
                </Typography>
                <OutlinedInput
                    onChange={(e) => handleChange(e.target.value, parentKey, 'door_no', addressIndex)}
                    error = { errorValue.show && errorValue.keys?.['door_no'] }
                    id={ parentKey + '_door_no_' + addressIndex }
                    className={classes.inputField}
                    value = {data.door_no || ""}
                    placeholder={'Door No.'}
                    disabled = {disabled}
                    type = {'text'}
                    inputProps = {{
                        required: true,
                        maxLength : 45
                    }}
                />
                <small className={'red'}>
                    {
                        errorValue.show && 
                        errorValue.keys?.['door_no'] &&
                        oc.invalid
                    }
                </small>
            </FormControl>
            <FormControl className={classes.formControlInput}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Street { required && '*'} 
                </Typography>
                <OutlinedInput
                    onChange={(e) => handleChange(e.target.value, parentKey, 'street', addressIndex)}
                    error = { errorValue.show &&  errorValue.keys?.['street'] }
                    id={ parentKey + '_street_' + addressIndex }
                    className={classes.inputField}
                    value = {data.street || ""}
                    placeholder={'Street'}
                    type = {'text'}
                    disabled = {disabled}
                    inputProps = {{
                        required: true,
                        maxLength : 45
                    }}
                />
                <small className={'red'}>
                    {
                        errorValue.show && 
                        errorValue.keys?.['street'] &&
                        oc.invalid
                    }
                </small>
            </FormControl>
            <FormControl className={classes.formControlInput}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Area { required && '*'} 
                </Typography>
                <OutlinedInput
                    onChange={(e) => handleChange(e.target.value, parentKey, 'area', addressIndex)}
                    error = { errorValue.show && errorValue.keys?.['area'] }
                    id= { parentKey + '_area_' + addressIndex }
                    className={classes.inputField}
                    value = {data.area || ""}
                    placeholder={'Area'}
                    type = {'text'}
                    disabled = {disabled}
                    inputProps = {{
                        required: true,
                        maxLength : 45
                    }}
                />
                <small className={'red'}>
                    {
                        errorValue.show && 
                        errorValue.keys?.['area'] &&
                        oc.invalid
                    }
                </small>
            </FormControl>
            <FormControl className={classes.formControlInput}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    City { required && '*'} 
                </Typography>
                <OutlinedInput
                    onChange={(e) => handleChange(e.target.value, parentKey, 'city', addressIndex)}
                    error = { errorValue.show &&  errorValue.keys?.['city'] }
                    id= { parentKey + '_city_' + addressIndex }
                    className={classes.inputField}
                    value = {data.city || ""}
                    placeholder={'City'}
                    disabled = {disabled}
                    type = {'text'}
                    inputProps = {{
                        required: true,
                        maxLength : 45
                    }}
                />
                <small className={'red'}>
                    {
                        errorValue.show && 
                        errorValue.keys?.['city'] &&
                        oc.invalid
                    }
                </small>
            </FormControl>
            <FormControl className={classes.formControlInput}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    State { required && '*'} 
                </Typography>
                <OutlinedInput
                    onChange={(e) => handleChange(e.target.value, parentKey, 'state', addressIndex)}
                    error = { errorValue.show &&  errorValue.keys?.['state']  }
                    id= { parentKey + '_state_' + addressIndex }
                    className={classes.inputField}
                    value = {data.state || ""}
                    placeholder={'State'}
                    disabled = {disabled}
                    type = {'text'}
                    inputProps = {{
                        required: true,
                        maxLength : 45
                    }}
                />
                <small className={'red'}>
                    {
                        errorValue.show && 
                        errorValue.keys?.['state'] &&
                        oc.invalid
                    }
                </small>
            </FormControl>
            <FormControl className={classes.formControlInput}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Country { required && '*'} 
                </Typography>
                <OutlinedInput
                    onChange={(e) => handleChange(e.target.value, parentKey, 'country', addressIndex)}
                    error = { errorValue.show &&   errorValue.keys?.['country']  }
                    id= { parentKey + '_country' + addressIndex }
                    className={classes.inputField}
                    value = {data.country || ""}
                    placeholder={'Country'}
                    disabled = {disabled}
                    type = {'text'}
                    inputProps = {{
                        required: true,
                        maxLength : 45
                    }}
                />
                <small className={'red'}>
                    {
                        errorValue.show && 
                        errorValue.keys?.['country'] &&
                        oc.invalid
                    }
                </small>
            </FormControl>
            <FormControl className={classes.formControlInput}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Pincode { required && '*'} 
                </Typography>
                <OutlinedInput
                    onChange={(e) => handleChange(e.target.value, parentKey, 'pincode', addressIndex)}
                    error = {  errorValue.show &&  errorValue.keys?.['pincode']  }
                    id={ parentKey + '_pincode_' + addressIndex }
                    className={classes.inputField}
                    value = {data.pincode || ""}
                    placeholder={'Pincode'}
                    disabled = {disabled}
                    type = {'number'}
                    inputProps = {{
                        required: true,
                        maxLength : 6
                    }}
                />
                <small className={'red'}>
                    {
                        errorValue.show && 
                        errorValue.keys?.['pincode'] &&
                        oc.invalid
                    }
                </small>
            </FormControl>
        </>
    )
}