import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invoiceActions } from '../../actions/invoice';
import notyf from '../../utils/notification';
import InvoiceLayout from './layout';
import { utils } from '../../utils';

class Invoice extends Component{
    constructor(props) {
        super(props);
        this.state = {
            summary: { },
            tableData : [],
            isEstimate: false
        }
    }

    handleInvalidInvoice = () => {
        const { history } = this.props;
        notyf.open({type: 'error' , message: 'Invalid Invoice Detail' });
        history.push('/orders');
    }

    componentDidMount() {
        const { dispatch, match, location } = this.props;
        let order_id = parseInt(match.params.id);
        if(order_id){
            dispatch(invoiceActions.generate({order_id}));
            let qs = location.search;
            if( qs && utils.hasParamKey(qs, 'est')){
                let isEstimate = utils.getDataFromUrl(qs)?.est;
                this.setState({ isEstimate: parseInt(isEstimate) === 1 });
            }
        }else{
            this.handleInvalidInvoice();
        }
    }

    componentDidUpdate(prevProps){
        const { invoice } = this.props;
        let invoicePropsChanged = invoice.status !== prevProps.invoice.status;

        if(invoicePropsChanged){
            if(invoice.status === 'GET_ERROR'){
                this.handleInvalidInvoice();
            }
        }
    }


    render() {
        return (
            <InvoiceLayout
                details = {this.props.invoice.details}
                status = {this.props.invoice.status}
                isEstimate = {this.state.isEstimate}
            />
        );
    }
}

function mapStateToProps(state) {
    const { profile, notifications, invoice } = state;
    
    return {
        profile,
        invoice,
        notifications
    }
}

export default connect(mapStateToProps)(Invoice);