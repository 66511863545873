export const globalRegexp= {
    nonNumeric: /[^0-9]+/g,
    email : /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+/,
    date: /^\d{4}[\-](0?[1-9]|1[012])[\-](0?[1-9]|[12][0-9]|3[01])$/,
}

export const globalConstants = {
    sessionExpired : 'Session Expired!! Login again'
}

export const loginConstants = {
    invalidPin : 'Invalid Pin',
    invalidPhone: 'Invalid Phone Number',
    invalidEmail : 'Invalid Email Id',
    invalidCredentials: 'Invalid Username or Password',
    invalidPassword : 'Password should be minimum 4 characters'
}

export const searchCustomerConstants = {
    title: 'Search Customer',
    subTitle: 'Use Mobile Number or Kyc Number of the customer to search'
}

export const onboardCustomerConstants = {
    title: 'Onboard Customer',
    invalid: 'Invalid Value',
    invalidOtp: 'Invalid Otp',
    subTitle: 'Onboard KYC verified customer',
    invalidForm: 'Please Fill the Required Fields !' ,
    invalidFile: 'Invalid File., if uploaded, plz wait till upload successfull..',
}

export const onboardEmployeeConstants = {
    title: 'Onboard Employee',
    invalid: 'Invalid Value',
    invalidOtp: 'Invalid Otp',
    subTitle: 'Onboard KYC verified employee',
    invalidForm: 'Please Fill the Required Fields !',
    invalidFile: 'Invalid File., if uploaded, plz wait till upload successfull..',
}

export const onboardBranchConstants = {
    title: 'Onboard Branch',
    subTitle: '',
    invalid: 'Invalid Value',
}

export const fundRequestConstants = {
    status : {
        1 : "Pending",
        2 : "Approved",
        3 : "Rejected" 
    },
    title: 'Fund Request ',
    invalidAction: 'Action not allowed !!!',
    postSuccess: 'Fund request posted successfully',
    invalidBranch : 'Please select branch to request',
    addInvalidamount: 'Please enter valid amount to add',
    invalidamount : 'Please enter valid amount to request',
    subTitle: 'Cash required from other branch for Business.',
    invalidApproveamount: 'Please enter valid amount to approve',
}

export const ordersConstants = {
    invalidOrder : 'Invalid Order !'
}

export const smsLogsConstants = {
    title: 'Search Sms Logs',
    subTitle: 'Use Mobile Number of the customer to search for sms logs'
}

export const assetListconstants = {
    title: 'Assets',
    tableTitle: 'Assets List',
    adminTableTitle: 'Sell Assets',
    subTitle: 'Get assets list based on branch.',
    branchErr: 'Select branch name to get asset list',
    successWithExist: 'Success ! Some of asset are already requested .'
}

export const assetRequestsconstants = {
    tableTitle: 'Assets Requests - In',
    tableTitle2: 'Assets Requests - Out',
    tableTitle3: 'Assets Requests - All',
    invalidAction: 'Actions not allowed !',
    pendingActionTitle: 'Pending Action',
    confirmActionTitle: 'Confirm Action',
    actionAssetsPrefix : 'No. of Assets selected for action : ',
    successWithExist: 'Success ! Some of asset are already requested .',
    multipleNotallowed : 'Different status / Branch Actions not allowed !',
}

export const materialsConstants = {
    addTitle: 'Add Material',
    nameInvalid: 'Invalid Name',
    updateTitle: "Update \'${material}\'",
    updateValueInvalid : 'Invalid Value',
    materialPriceInvalid: 'Invalid Price',
}

export const expenseConstants = {
    addTitle: 'Add Expense',
    invalidValue: 'Invalid Value',
    updateTitle: 'Expense Pending Action',
    cancelTitle: 'Expense Cancel Action',
    invaildImage: 'Invalid ! if uploading, plz wait till success. '
}

export const reportConstants = {
    title: 'Reports',
    subTitle: 'on Orders, Cash In/Out.',
    loadingText: 'Generating Report...',
    invalidBranch: 'Invalid Branch ! Please Select Branch',
    invalidDateLimit: '"From Date" should lesser than "To Date"',
    invaildDate: 'Invalid Date! Please Select valid Date (YYYY-MM-DD)',
}

export const forbiddenConstants = {
    title: '403 Forbidden',
    brokenPageBtnText: 'Home',
    buttonTitle: 'Return to Home',
    brokenPageTitle: 'Oops ! Seems you landed on the Planet X',
    subTitle: 'It seems ! You have landed inside the Nuclear Weapon Container.',
}

export const leaveRequestConstants = {
    title: 'New Requests',
    subTitle: '',
    invalidValue: 'Invalid Value',
    invalidAction: 'Action Not Allowed !',
    cancelText: "This action is Irreversible if executed. Are you sure to Cancel your request ?"
}

export const attendanceConstants = {
    postLogText: 'Attendance will be logged with current Date and Time. Do you wish to continue ?',
    invalidAction : 'Action Not Allowed',
    invalidDate: 'Invalid Date.'
}