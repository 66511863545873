import React from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import { ArrowBackOutlined } from '@material-ui/icons';
import { utils } from '../../utils/utils';
import useStyles from './style';

export default function LevelTwoMenu(props){
    const classes = useStyles();
    const { 
        mainMenuExpanded = false,
        setShow = () => null,
        show = false,
        menuKey = {},
        history
    } = props;

    let role = utils.getRole() || '';

    const handleOnClick = (row) => {
        if(row.path) {
            setShow({ show: false, key: {} });
            history.push(row.path);
        }
    }

    return (
        <Box 
            className = {`${classes.sideBarTwo} no-scrollbar`} 
            style={{ 
                display: show ? 'flex' : 'none',
                marginLeft: mainMenuExpanded ? '180px' : '80px' 
            }}
        >
            <Box>
                <Box className={classes.secondMenuTitle}>
                    <Typography 
                        variant = 'p' 
                        className={classes.secondMenuDesc}
                    >
                            {menuKey.label}
                    </Typography>
                </Box>
                <Box className={classes.dynamicMenu}>
                    {
                        show && 
                        menuKey.childComponent.map(row => {
                            let Icon = row.Icon;
                            if(
                                row.access.length === 0 ||
                                row.access.includes(role)
                            ){
                                return (
                                    <IconButton
                                        color='primary'
                                        className={classes.typographyMenuTwo}
                                        onClick = {() => handleOnClick(row)}
                                    >
                                        {   row.Icon && 
                                            <Icon style={{
                                                    width: '24px',
                                                    height: '24px'
                                                }}
                                            /> 
                                        }
                                        <Typography variant = 'p' 
                                            className={classes.menuDesc}
                                        >
                                            { row.label }
                                        </Typography>
                                        
                                    </IconButton>
                                )
                            }else
                                return <div></div>;
                        })
                    }
                </Box>
            </Box>
            <Box className={classes.staticMenuTwo}>
                <IconButton 
                    color='primary'
                    className={classes.typographyMenuTwo}
                    onClick = {() => setShow({ 
                        show: false, key: {} 
                    })}
                >
                    <ArrowBackOutlined />
                    <Typography variant = 'p' 
                        className={classes.menuDesc}
                    >
                        Collapse SubMenu
                    </Typography>
                </IconButton>
            </Box>
        </Box>
    )
}