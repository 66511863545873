import { 
    makeStyles 
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({


    userDetailsGrid : {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        // flexWrap: 'wrap',
        '& .MuiAccordionSummary-content' : {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-around',
            alignItems: 'center'
        },
    },
    userDetailsBriefGrid : {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        '& .MuiAccordionSummary-content' : {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'space-around',
            alignItems: 'center'
        },
    },
    userDetailsBrief : {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        margin: `${theme.spacer * 2}px 0px`,
        '& .MuiAccordionSummary-content' : {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-around',
            alignItems: 'center'
        },
    },
    avatarProfile : {
        height: '100px',
        width: '100px',
        color: theme.palette.primary.main,
        // backgroundColor: theme.color.grey40,
        borderRadius: '10px'
    },
    detailwithlabel : {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
    },
    accordionShadow : {
        boxShadow: '0px 1px 1px 0px rgb(0 0 0 / 14%)',
        borderRadius: `${theme.spacer * 0.5}px`
    },
    AddressBriefs: {
        paddingTop: `${theme.spacer * 0}px`
    }
}));

export default useStyles;