const settingsConstants = {
    UPDATE_PASS_REQUEST: 'UPDATE_MASTER_PASSWORD_REQUEST',
    UPDATE_PASS_ERROR: 'UPDATE_MASTER_PASSWORD_ERROR',
    UPDATE_PASS_SUCCESS: 'UPDATE_MASTER_PASSWORD_SUCCESS',

    ENABLE_SM_REQUEST : 'ENABLE_SAFE_MODE_REQUEST',
    ENABLE_SM_SUCCESS : 'ENABLE_SAFE_MODE_SUCCESS',
    ENABLE_SM_ERROR : 'ENABLE_SAFE_MODE_ERROR',

    DISABLE_SM_REQUEST : 'DISABLE_SAFE_MODE_REQUEST',
    DISABLE_SM_SUCCESS : 'DISABLE_SAFE_MODE_SUCCESS',
    DISABLE_SM_ERROR : 'DISABLE_SAFE_MODE_ERROR',
}

export default settingsConstants;