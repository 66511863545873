import React, { useState } from 'react';
import { ModalDialog, Button } from "../../../components";
import useStyles from '../style';
import {
    Box, Typography,
    FormControl, Grid,
    OutlinedInput,
} from '@material-ui/core';

let payl = {
    "asset_id": [],
    "branch_id": '',
    "description": ""
};


export default function ConfirmRequest(props) {
    const classes = useStyles();
    const [payload, setPayload] = useState(payl);
    const {
        status = '',
        details = [],
        show = false,
        setShow = () => null
    } = props;


    const handleChange = (name, value) => {;
        setPayload({ ...payload, [name]: value });
    }


    const handleSubmit = () => {
        props.handleSubmit({
            ...payload,
            asset_id: [ ...details ]
        });
    }

    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        Confirm Request
                    </Typography>
                    <Typography variant = 'p' className = {`${classes.subTitle}`}>
                        Assets to Request : { " " + details.length } 
                    </Typography>
                </Box>
                <Grid 
                    container direction='row' 
                    xs={12} sm={12} md={12} 
                    lg={12} xl={12}
                    className = {classes.fundDetailsMainGrid}
                    style = {{marginTop: '16px'}}
                >
                        <FormControl variant='outlined' 
                            className={classes.inputField}
                        >
                            <Typography variant='subtitle2'
                                className={classes.labelTitle}
                            >
                                Description (optional)
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleChange('description' , e.target.value)}
                                value = {payload['description']}
                                className={classes.inputField}
                                placeholder={'Description'}
                                type = {'text'}
                                id='description'
                                multiline
                                inputProps = {{
                                    required: true,
                                    maxLength : 200
                                }}
                            />
                        </FormControl>
                </Grid>
                {
                    <Button
                        text = "Request"
                        variant = "primary"
                        style = {{
                            padding: '8px 16px',
                            marginTop: '40px', 
                            width: '280px' 
                        }}
                        loading = {status === 'POST_REQUEST'}
                        onClick = {() => handleSubmit() }
                    />
                }
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {show}
            onClose = {() => setShow(false)}
            dialogTitle = ''
            dialogContent = {dialogContent()}
            maxWidth = {'md'}
            classes = {{ paper: classes.dialogPaper}}
            dialogFooter = {[]}
        />
    )

}