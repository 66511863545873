import React, { useState } from 'react';
import { leaveRequestConstants as lrc } from '../../../constants';
import { Button, ModalDialog, } from "../../../components";
import { ExpandMore } from '@material-ui/icons';
import { utils } from '../../../utils';
import useStyles from '../style';
import {
    Grid, Box,
    FormControl,
    OutlinedInput,
    Typography,
    Select,
    MenuItem
} from '@material-ui/core';


let defaultPayload = {
    "from_date": "",
    "to_date": "",
    "no_of_days": "",
    "leave_type": "Full Day",
    "reason": ""
}

export default function NewRequest(props) {
    const classes = useStyles();
    const [error, setError] = useState([]);
    const [payload, setPayload] = useState({ ...defaultPayload });
    const {
        status = '',
        showNewRequest = false,
        setNewRequest = () => null,
        handleSubmit = () => null,
    } = props;

    const validatePayload = (payl = payload) => {
        let err = [];
        if(!utils.isValidDate(payl.from_date))
            err.push('from_date')
        if(!utils.isValidDate(payl.to_date))
            err.push('to_date')
        if(!payl.reason)
            err.push('reason')

        return err;
    }

    const handleChange = (key, value) => {
        let payl = { ...payload, [key]: value };
        let err = validatePayload(payl);
        setPayload(payl);
        setError(err);
    }

    const submitPayload = () => {
        let err = validatePayload();
        if(err.length) setError(err);
        else {
            let no_of_days = 
                (utils.getDateDifference(
                    payload.from_date,
                    payload.to_date
                ) + 1) + ""
            let finalPayl = {
                ...payload,
                no_of_days
            }
            handleSubmit(finalPayl);
        }
    }

    const footer = () => {
        return (
            <>
                <Button
                    text = {'Cancel'}
                    variant = { "Secondary" }
                    style = {{ padding: '8px 32px' }}
                    onClick = {() => setNewRequest(false)}
                />
                <Button
                    text = {'Submit'}
                    variant = "Primary"
                    onClick={submitPayload} 
                    style = {{ padding: '8px 32px' }}
                    loading = {status === 'POST_REQUEST'}
                />
            </>
        )
    }

    const dialogTitle = () => {
        return (
            <Box className={classes.titleSection} >
                <Typography variant = 'h5' >
                    {lrc.title}
                </Typography>
            </Box>
        )
    }

    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                <Grid 
                    container 
                    direction='row' 
                    className = {classes.mainGrid}
                >
                    <Grid 
                        item xs={12} sm={12} 
                        md={12} lg={12} xl={12}
                        className = {classes.indGrid}
                    >
                        <FormControl className={classes.inputField}>
                            <Typography variant='subtitle2' className={classes.label}>
                                From Date
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleChange(
                                    'from_date', e.target.value
                                )}
                                error= {
                                    error.length > 0 && 
                                    error.includes('from_date')
                                }
                                className={classes.inputField}
                                value = {payload['from_date']}
                                placeholder={'From Date'}
                                type = {'date'}
                                id='from_date'
                                inputProps = {{
                                    required: true,
                                    maxLength : 45,
                                    min : 0
                                }}
                            />
                        </FormControl>
                        <small className="red" style={{marginTop: 4}}>
                                {
                                    error.length > 0 && 
                                    error.includes('from_date') && 
                                    lrc.invalidValue
                                }
                        </small>
                        <FormControl className={classes.inputField}>
                            <Typography variant='subtitle2' className={classes.label}>
                                To Date
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleChange(
                                    'to_date', e.target.value
                                )}
                                error= {
                                    error.length > 0 && 
                                    error.includes('to_date')
                                }
                                className={classes.inputField}
                                value = {payload['to_date']}
                                placeholder={'To Date'}
                                type = {'date'}
                                id='to_date'
                                inputProps = {{
                                    required: true,
                                    maxLength : 45,
                                    min : 0
                                }}
                            />
                        </FormControl>
                        <small className="red" style={{marginTop: 4}}>
                                {
                                    error.length > 0 && 
                                    error.includes('to_date') && 
                                    lrc.invalidValue
                                }
                        </small>
                        <FormControl variant='outlined' className={classes.inputField}>
                            <Typography variant='subtitle2'
                                className={classes.label}
                            >
                                Leave Type
                            </Typography>
                            <Select
                                className={classes.inputField}
                                IconComponent = {ExpandMore}
                                value = {payload['leave_type']}
                                onChange={(e) => handleChange('leave_type', e.target.value)}
                                inputProps={{ name: 'leave_type' }}
                            >
                                
                                <MenuItem value={'Full Day'}>Full Day</MenuItem>
                                <MenuItem value={'First Half'}>{'First Half'}</MenuItem>
                                <MenuItem value={'Second Half'}>{'Second Half'}</MenuItem>                             
                            </Select>
                        </FormControl>

                        <FormControl variant='outlined' className={classes.inputField}>
                            <Typography variant='subtitle2'
                                className={classes.label}
                            >
                                Reason
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleChange('reason' , e.target.value)}
                                error = {
                                    error.length > 0 && 
                                    error.includes('reason')
                                }
                                className={classes.inputField}
                                placeholder={'Reason'}
                                type = {'text'}
                                id='reason'
                                multiline
                                inputProps = {{
                                    required: true,
                                    maxLength : 200
                                }}
                            />
                        </FormControl>
                        <small className="red" style={{marginTop: 4}}>
                                {
                                    error.length > 0 && 
                                    error.includes('reason') && 
                                    lrc.invalidValue
                                }
                        </small>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <ModalDialog
            maxWidth = {'md'}
            open = {showNewRequest}
            dialogFooter = {footer()}
            dialogTitle = {dialogTitle()}
            dialogContent = {dialogContent()}
            onClose = {() => setNewRequest(false)}
            classes = {{ paper: classes.dialogPaperNewRequest}}
        />
    )

}