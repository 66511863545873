import userConstants from '../constants/user';

const initialState = {
    status: '',
    userDetails: [],
    empDetails: []
}

function user(state = initialState, action) {
    switch(action.type) {
        case userConstants.ADD_SUCCESS : 
            return {
                ...state,   
                status: 'ADD_USER_SUCCESS',
            }
        case userConstants.ADD_ERROR:
            return {
                ...state,
                status: 'ADD_USER_ERROR'
            }
        case userConstants.ADD_REQUEST:
            return {
                ...state,
                status: 'ADD_USER_REQUEST'
            }
        case userConstants.UPDATE_SUCCESS : 
            return {
                ...state,   
                status: 'UPDATE_SUCCESS',
            }
        case userConstants.UPDATE_ERROR:
            return {
                ...state,
                status: 'UPDATE_ERROR'
            }
        case userConstants.UPDATE_REQUEST:
            return {
                ...state,
                status: 'UPDATE_REQUEST'
            }
        case userConstants.RESET_REQUEST : 
            return {
                ...state,   
                status: 'RESET_REQUEST',
            }
        case userConstants.RESET_SUCCESS:
            return {
                ...state,
                status: 'RESET_SUCCESS'
            }
        case userConstants.RESET_ERROR:
            return {
                ...state,
                status: 'RESET_ERROR'
            }
        case userConstants.ACTIVATE_REQUEST : 
            return {
                ...state,   
                status: 'ACTIVATE_REQUEST',
            }
        case userConstants.ACTIVATE_SUCCESS:
            return {
                ...state,
                status: 'ACTIVATE_SUCCESS'
            }
        case userConstants.ACTIVATE_ERROR:
            return {
                ...state,
                status: 'ACTIVATE_ERROR'
            }
        case userConstants.DEACTIVATE_REQUEST : 
            return {
                ...state,   
                status: 'DEACTIVATE_REQUEST',
            }
        case userConstants.DEACTIVATE_SUCCESS:
            return {
                ...state,
                status: 'DEACTIVATE_SUCCESS'
            }
        case userConstants.DEACTIVATE_ERROR:
            return {
                ...state,
                status: 'DEACTIVATE_ERROR'
            }
        case userConstants.TRANS_BRANCH_REQUEST : 
            return {
                ...state,   
                status: 'TRANS_BRANCH_REQUEST',
            }
        case userConstants.TRANS_BRANCH_SUCCESS:
            return {
                ...state,
                status: 'TRANS_BRANCH_SUCCESS'
            }
        case userConstants.TRANS_BRANCH_ERROR:
            return {
                ...state,
                status: 'TRANS_BRANCH_ERROR'
            }
        case userConstants.GET_SUCCESS : 
            return {
                ...state,   
                status: 'GET_USER_SUCCESS',
                userDetails: action.payload.data
            }
        case userConstants.GET_ERROR:
            return {
                ...state,
                status: 'GET_USER_ERROR'
            }
        case userConstants.GET_REQUEST:
            return {
                ...state,
                status: 'GET_USER_REQUEST'
            }
        case userConstants.GET_EMP_SUCCESS : 
            return {
                ...state,   
                status: 'GET_EMP_SUCCESS',
                empDetails: action.payload.data
            }
        case userConstants.GET_EMP_ERROR:
            return {
                ...state,
                status: 'GET_EMP_ERROR'
            }
        case userConstants.GET_EMP_REQUEST:
            return {
                ...state,
                status: 'GET_EMP_REQUEST'
            }
        default: return state;
    }
}

export default user;