import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    mainBox: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff'
    },
    forbiddenTitle: {
        margin: `${theme.spacer * 2}px 0px`
    },
    forbiddenSubTitle: {
        margin: `${theme.spacer * 0}px 0px ${theme.spacer * 4}px 0px`
    }
}));

export default useStyles;