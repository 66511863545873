import React, { Component } from 'react';
import { connect } from 'react-redux';
import { assetActions } from '../../actions/assets';
import { branchActions } from '../../actions/branch';
import AssetsRequestOutLayout from './layout';


class AssetsRequestOut extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPending: false,
            showConfirm : false,
            clearInputs: false
        }
    }

    componentDidMount(){
        const { profile, dispatch } = this.props;
        dispatch(branchActions.getAll());
        if(profile.basic?.branch_id){
            this.getDetails();
        }
    }

    getDetails = (page = 0, limit = 10, fl = {}) => {
        const { dispatch, profile } = this.props;
        let br_id = profile.basic?.branch_id ;
        let offset = page * limit;
        let filter = { requested_branch_name : br_id, ...fl };   
        dispatch(assetActions.getAssetReq('INTERNAL', offset, limit, filter));
        dispatch(assetActions.getTotalReqRecords('INTERNAL', filter));
    }

    resetPagination = () => {
        this.setState({
            page: 0,
            limit: 10
        })
    }


    componentDidUpdate(prevProps) {
        const { assets, auth, history } = this.props;
        let isPropsChanged = assets.status !== prevProps.assets.status;
        let newAuthProps = auth.status !== prevProps.auth.status;

        if(isPropsChanged){
            if(assets.status === 'P_ACTION_SUCCESS'){
                this.setState({
                    showPending: false,
                    clearInputs: true
                });
                this.getDetails();
                this.resetPagination();
            }
            if(assets.status === 'C_ACTION_SUCCESS'){
                this.setState({
                    showConfirm: false,
                    clearInputs: true
                });
                this.getDetails();
                this.resetPagination();
            }
        }

        if(newAuthProps) {
            if(auth.status === 'VALIDATE_SUCCESS'){
                this.getDetails();
            }
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(assetActions.clear());
    }

    handleSubmitRequest = (payload) => {
        const { dispatch } = this.props;
        this.setState({ clearInputs: false });
        dispatch(assetActions.pendingAction(payload));
    }

    handleConfirmSubmit = (payload) => {
        const { dispatch } = this.props;
        this.setState({ clearInputs: false });
        dispatch(assetActions.confirmAction(payload));
    }

    render() {
        return (
            <AssetsRequestOutLayout 
                {...this.props}
                page = {this.state.page}
                limit = {this.state.limit}
                getDetails = {this.getDetails}
                status = {this.props.assets.status}
                showPending = {this.state.showPending}
                showConfirm = {this.state.showConfirm}
                noOfRecords = {this.props.assets.noOfReq}
                requestDetails = {this.props.assets.requests}
                branchDetails ={this.props.branch.branchDetails}
                handleSubmitRequest = {this.handleSubmitRequest}
                handleConfirmSubmit = {this.handleConfirmSubmit}
                setPage = {(val) => this.setState({ page: val})}
                setLimit = {(val) => this.setState({ limit: val})}
                setShowPending = {(e) => this.setState({ showPending: e })}
                setShowConfirm = {(e) => this.setState({ showConfirm: e })}
                setClearInputs = {(e) => this.setState({ clearInputs: e })}
            />
        );
    }
}

function mapStateToProps(state) {
    const { auth, profile, assets, notifications, branch }  = state;

    return {
        auth,
        branch,
        assets,
        profile,
        notifications
    };
}

export default connect(mapStateToProps)(AssetsRequestOut);