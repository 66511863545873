import {httpClient} from "../utils";

export const roleService ={
    getAll,
    updateRoleForUser
}

function getAll(){
    return  httpClient.callApi("GET",`/role/getAllRoles`);
}

function updateRoleForUser(payload){
    return  httpClient.callApi("PUT",`/role/rolesForUser`, payload);
}