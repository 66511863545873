import React, { Component } from 'react';
import { connect } from 'react-redux';
import { uploadActions } from '../../actions/fileupload';
import { orderActions } from '../../actions/orders';
import { userActions } from '../../actions/user';
import SearchCustomerLayout from './layout';

class SearchCustomer extends Component {

    constructor(props){
        super(props);
        this.state = {
            showUpdate : {
                flag: false,
                payload: {}
            }
        }
    }

    handleSearch = (payload) => {
        const { dispatch } =  this.props;
        dispatch(userActions.get(payload));
    }

    componentDidUpdate(prevProps){
        const { dispatch, user } = this.props;
        let isUserPropsChanged = user.status !== prevProps.user.status;

        if(isUserPropsChanged){
            let ud = user.userDetails;       
            if(
                user.status === 'GET_USER_SUCCESS' &&
                ud.length === 1 && ud?.[0]?.users
            ){
                let id = ud?.[0]?.users?.id;
                dispatch(orderActions.getTransactions(id));
            }
        }
    }

    handleUserUpdateSubmit = (payload = {}) => {
        const { dispatch } = this.props;
        dispatch(userActions.update(payload));
    }

    setShowUpdate = (flag = false, payload = {}) => {
        this.setState({ showUpdate: { flag, payload } });
    }

    handleFileChange = (name, files, index) => {
        const { dispatch } = this.props;
        if(files){
            dispatch(uploadActions.upload(files, name, index));
        }
    }

    clearUploads = () => {
        const { dispatch } = this.props;
        dispatch(uploadActions.clearState());
    }

    render() {
        return (
            <SearchCustomerLayout 
                {...this.props}
                handleSearch = {this.handleSearch}
                status = {this.props.user.status}
                clearUploads = {this.clearUploads}
                setShowUpdate = {this.setShowUpdate}
                showUpdate = {this.state.showUpdate}
                handleFileChange = {this.handleFileChange}
                userDetails = {this.props.user.userDetails}
                fileuploads = {this.props.fileupload.uploads}
                transactions = {this.props.orders.transactions}
                handleUserUpdateSubmit = {this.handleUserUpdateSubmit}
            />
        );
    }
}

function mapStateToProps(state) {
    const { profile, user, orders, fileupload, notifications }  = state;

    return {
        profile,
        fileupload,
        user, 
        orders,
        notifications
    };
}

export default connect(mapStateToProps)(SearchCustomer);