import React, { Component } from 'react';
import { materialActions } from '../../actions/material';
import { branchActions } from '../../actions/branch';
import { assetActions } from '../../actions/assets';
import { connect } from 'react-redux';
import AssetsLayout from './layout';


class Assets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showConfirm : false,
            filterQuery: 0,
            branch_id: '',
            error: false,
            limit : 10,
            page : 0,
        }
    }

    componentDidMount(){
        const { dispatch } = this.props;
        dispatch(branchActions.getAll());
        dispatch(materialActions.getMaterialWithPrice());
    }

    getDetails = (page = 0, limit = 10, filter = 0) => {
        const { dispatch } = this.props;
        const { branch_id } = this.state;
        if(branch_id){
            let offset = page * limit;
            dispatch(assetActions.getAssets(branch_id, offset, limit, filter));
            dispatch(assetActions.getTotalRecords(branch_id, filter));
        }
        else
            this.setState({ error : true });
    }

    resetPagination = () => {
        this.setState({ page: 0, limit: 10 });
    }


    handleSubmitRequest = (payload) => {
        const { dispatch } = this.props;
        const { branch_id } = this.state;
        let payl = { ...payload, branch_id };
        dispatch(assetActions.raiseReq(payl));
    }

    componentDidUpdate(prevProps) {
        const { assets, history } = this.props;
        let isPropsChanged = assets.status !== prevProps.assets.status;

        if(isPropsChanged){
            if(assets.status === 'POST_SUCCESS'){
                this.setState({ showConfirm :false });
                history.push('/assets/request/out');
            }
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(assetActions.clear());
    }

    setBranchId = (branch_id) => {
        this.setState({
            error: false,
            branch_id
        })
    }

    render() {
        return (
            <AssetsLayout 
                {...this.props}
                page = {this.state.page}
                limit = {this.state.limit}
                error = {this.state.error}
                getDetails = {this.getDetails}
                setBranchId = {this.setBranchId}
                handleSubmit = {this.handleSubmit}
                status = {this.props.assets.status}
                showConfirm = {this.state.showConfirm}
                resetPagination = {this.resetPagination}
                materials = {this.props.material.details}
                assetDetails = {this.props.assets.details}
                noOfRecords = {this.props.assets.noOfRecords}
                branchDetails = {this.props.branch.branchDetails}
                handleSubmitRequest = {this.handleSubmitRequest}
                setPage = {(val) => this.setState({ page: val})}
                setLimit = {(val) => this.setState({ limit: val})}
                setShowConfirm = {(e) => this.setState({ showConfirm: e})}
                setFilterQuery = {(val) => this.setState({filterQuery : val})}
            />
        );
    }
}

function mapStateToProps(state) {
    const { 
        assets, 
        branch,
        profile, 
        material,
        notifications 
    }  = state;

    return {
        branch,
        assets,
        profile,
        material,
        notifications
    };
}

export default connect(mapStateToProps)(Assets);