import React from 'react';
import { 
    Typography, Grid, MenuItem,
    FormControl, Select, OutlinedInput,
    InputAdornment, CircularProgress
} from '@material-ui/core';
import { 
    CheckCircleOutlineRounded,
    ErrorOutlineRounded,
    AttachFileOutlined,
    ExpandMore 
} from '@material-ui/icons';
import useStyles from '../style';

export default function MaterialRelease (props) {
    const classes = useStyles();
    const { 
        summary = {} ,
        details = {},
        handleChange = () => null,
        handleTotalChange = () => null
    } = props;
    return (
        <Grid xs={12} sm={6} md={6}
            className={classes.extraFieldsGrid}
        >
            <FormControl className={classes.formControlInput}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Type
                </Typography>
                <Select
                    inputProps={{ name: 'order_type' }}
                    className = {classes.inputField}
                    IconComponent = {ExpandMore}
                    value = {summary.order_type}
                    onChange={(e) => handleTotalChange(
                        e.target.value, 
                        'order_type'
                    )}
                    variant = 'outlined'
                    disableUnderline
                >
                    <MenuItem value={'PHYSICAL'}>PHYSICAL</MenuItem>
                    <MenuItem value={'RELEASE'}>RELEASE</MenuItem>
                </Select>
            </FormControl>
            {
                summary.order_type === 'RELEASE' &&
                <FormControl className={classes.formControlInput}>
                    <Typography variant='subtitle2'
                        className={classes.label}
                    >
                        Pledge Doc Name
                    </Typography>
                    <OutlinedInput
                        onChange={(e) => handleChange(
                            e.target.value,
                            'pledge_doc_name'
                        )}
                        value = {details.pledge_doc_name}
                        className={classes.inputField}
                        id='pledge_doc_name'
                        error = {false}
                        type = {'text'}
                        inputProps = {{
                            required: true,
                            maxLength : 45,
                        }}
                    />
                </FormControl>
            }
            {
                summary.order_type === 'RELEASE' &&
                <FormControl style={{marginBottom: 16}} 
                    className={classes.formControlInput}
                >
                    <Typography variant='subtitle2'
                        className={classes.label}
                    >
                        Upload Pledge Doc
                    </Typography>
                    <OutlinedInput
                        onChange={(e) => handleChange(
                            e.target.files,
                            'pledge_doc_image',
                            'file'
                        )}
                        // value = {details.pledge_doc_image}
                        className={classes.inputField}
                        id= { 'pledge_doc_image' }
                        error = { false }
                        type = {'file'}
                        startAdornment = {
                            <InputAdornment position='start'>
                                {
                                    details?.pledge_doc_image === 'uploading' &&
                                    <CircularProgress size={16} color="primary"/> 
                                }
                                {
                                    !['', 'uploading', 'failed'].includes(details?.pledge_doc_image) &&
                                    <CheckCircleOutlineRounded size = {24} style={{color: 'green'}}/>
                                }
                                {
                                    details?.pledge_doc_image === 'failed' &&
                                    <ErrorOutlineRounded size={24} color='error' />
                                }
                                {
                                    details?.pledge_doc_image === '' &&
                                    <AttachFileOutlined edge='start' color='primary'/>
                                }                              
                            </InputAdornment>
                        }
                        inputProps = {{
                            required: true,
                            maxLength : 45
                        }}
                    />
                </FormControl>
            }
            {
                summary.order_type === 'RELEASE' &&
                <FormControl className={classes.formControlInput}>
                    <Typography variant='subtitle2'
                        className={classes.label}
                    >
                        Bank / Shop
                    </Typography>
                    <OutlinedInput
                        onChange={(e) => handleChange(
                            e.target.value,
                            'pledge_at'
                        )}
                        className={classes.inputField}
                        value = {details.pledge_at}
                        type = {'text'}
                        id='pledge_at'
                        error = {false}
                        inputProps = {{
                            required: true,
                            maxLength : 45,
                        }}
                    />
                </FormControl>
            }
            {
                summary.order_type === 'RELEASE' &&
                <FormControl className={classes.formControlInput}>
                    <Typography variant='subtitle2'
                        className={classes.label}
                    >
                        Pledge Amount (INR)
                    </Typography>
                    <OutlinedInput
                        onChange={(e) => handleChange(
                            e.target.value,
                            'pledge_amount'
                        )}
                        className={classes.inputField}
                        value = {details.pledge_amount}
                        id='pledge_amount'
                        type = {'number'}
                        error = {false}
                        inputProps = {{
                            required: true,
                            maxLength : 45,
                        }}
                    />
                </FormControl>
            }
            {
                summary.order_type === 'RELEASE' &&
                <FormControl className={classes.formControlInput}>
                    <Typography variant='subtitle2'
                        className={classes.label}
                    >
                        Pledge Date
                    </Typography>
                    <OutlinedInput
                        onChange={(e) => handleChange(
                            e.target.value,
                            'pledge_date'
                        )}
                        className={classes.inputField}
                        value = {details.pledge_date}
                        type = {'date'}
                        id='pledge_date'
                        error = {false}
                        inputProps = {{
                            required: true,
                            maxLength : 45,
                        }}
                    />
                </FormControl>
            }
        </Grid>
    )
}