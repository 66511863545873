import React, { useEffect, useState } from 'react';
import { AutorenewOutlined, Add } from '@material-ui/icons';
import { Box, Paper, Typography } from '@material-ui/core';
import { Button, Navbar, Table } from "../../components";
import { utils } from '../../utils';
import AddFunds from './add-funds';
import useStyles from './style';

let tableHeaders = [
    {
        id: "branch_name",
        label : "Branch",
        type: 'text',
        minWidth: '150px',
        maxWidth: '150px',
        style : {}
    },
    {
        id: "amount",
        label : "Amount (INR)",
        type: 'text_wo_input',
        minWidth: '150px',
        maxWidth: '150px',
        style : {}
    },
    {
        id: "last_updated_at",
        label : "Updated at",
        type: 'text_wo_input',
        minWidth: '150px',
        maxWidth: '150px',
        style : {}
    }
];

export default function FundsAvailableLayout(props){

    const classes = useStyles();
    const [filterQuery, setFilterQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [showAddFunds, setShowAddFunds]= useState(false);
    const [clearInputFields, setClearInputFields] = useState(false);
    const {
        handleAddFundsSubmit = () => null,
        getDetails = () => null,
        fundDetails = [],
        basic = {},
        status = ''
    } = props;

    useEffect(() => {
        if(status === 'ADD_FUND_SUCCESS') {
            setShowAddFunds(false);
        }
    },[status]);

    useEffect(() => {
        setFilterQuery('');
        setFilteredData([]);
        setClearInputFields(true);
    }, [fundDetails]);

    useEffect(() => {
        if(clearInputFields){
            setClearInputFields(false);
        }
    }, [clearInputFields]);

    const getFormattedData = (data = []) => {
        if(data.length > 0){
            return data.map(e => {
                return {
                    ...e,
                    last_updated_at: utils.getLocalTime(e.last_updated_at),
                    amount : utils.getFormattedAmount(e.amount) || 0,
                }
            })
        }else
            return data;
    }

    const handleFilterChange = (val, col) => {
        if(val){
            let fd = [ ...fundDetails ];
            if(fundDetails.length > 0){
                fd = fd.filter((f) => 
                    f.branch_name.toLowerCase().includes(val.toLowerCase())
                );
            }
            setFilterQuery(val);
            setFilteredData(fd);
        }else {
            setFilterQuery('');
            setFilteredData([]);
        }
    }

    return (
        <Navbar 
            { ...props }
        >
            <Box className={classes.mainBox}>
                <Box className = {classes.tableTitleBox}>
                    <Typography variant = "h5">
                        Funds Available
                    </Typography>
                    <Box className={classes.actionButtons}>
                        <Button
                            text = "Refresh"
                            variant = "secondary"
                            Icon = {AutorenewOutlined}
                            style = {{
                                padding: '8px 16px', 
                                marginRight: '16px' 
                            }}
                            onClick = {() => getDetails(basic)}
                        />
                        {
                            // basic.roles?.includes('SUPER_ADMIN') &&
                            ['SUPER_ADMIN', 'SYSTEM_ADMIN']?.includes(utils.getRole()) &&
                            <Button
                                text = "Add Funds"
                                variant = "primary"
                                Icon = {Add}
                                style = {{padding: '8px 16px'}}
                                onClick = {() => setShowAddFunds(true)}
                            />
                        }
                    </Box>
                </Box>
            </Box>
            <Paper className = {classes.tableBox}>
                <Table
                    loading = {status === 'BRANCH_FUND_REQUEST'}
                    tableColumns = {tableHeaders}
                    tableData = { filterQuery ? 
                        getFormattedData(filteredData) : 
                        getFormattedData(fundDetails) 
                    }
                    handleChange = {handleFilterChange}
                    handleMenuDropdown = {() => null}
                    onTableRowClick = {() => null}
                    rowMenuOptions = {[]}                    
                />
            </Paper>
            {
                showAddFunds &&
                <AddFunds
                    show = {showAddFunds}
                    setShow = {setShowAddFunds}
                    handleSubmit = {handleAddFundsSubmit}
                    basic = {basic}
                    status = {status}
                />
            }
        </Navbar>
    );
}