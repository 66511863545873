import { 
    makeStyles 
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    mainBox : {
        margin: '5% 5% 2% 5%'
    },
    tableTitleBox : {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    footerButtons : {
        display: 'flex',
        flexDirection: 'row',
    },
    tableBox : {
        margin: '1% 5%',
    },
    actionButtons: {
        display: 'flex',
        flexDirection: 'row',
    },

    containerBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    mainGrid : {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '80%'
    },
    titleSection: {
        width: '40vw', 
        marginTop: theme.spacer * 3,
        marginBottom: theme.spacer * 3,
        textAlign: 'center'
    },
    indGrid : {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    label: {
        lineHeight: theme.spacer * 0.35,
    },
    inputField: {                                                
        margin: `0px 0px ${theme.spacer * 0}px 0px`, 
        width: '280px',
        backgroundColor: theme.color.white,
        border: 'none',
        borderRadius: theme.spacer * 0.3,
        '& .MuiSelect-select:focus' : {
            border: 'none',
            borderWidth : 0,
            backgroundColor: 'transparent'
        },
        '& .MuiOutlinedInput-root' : {
            height: theme.spacing(5)
        },
        '& ..MuiInputBase-input' : {
            height: theme.spacing(5)
        }
    },
}));

export default useStyles;