import React, { Component } from 'react';
import { connect } from 'react-redux';
import FundsAvailableLayout from './layout';
import { fundActions } from '../../actions/fund-request';
import { authActions } from '../../actions/authentication';

class FundsAvailable extends Component {

    componentDidMount(){
        const { profile } = this.props;
        if(profile.basic?.branch_id){
            this.getDetails(profile.basic);
        }
    }

    getDetails = (basic = {}) => {
        const { dispatch } = this.props;
        let br_id = basic.roles?.includes('SUPER_ADMIN') ? 0 : basic?.branch_id;
        dispatch(fundActions.getFundsInBranch(br_id));
    }

    handleAddFundsSubmit = (payload) => {
        const { dispatch } = this.props;
        dispatch(fundActions.addFunds(payload));
    }

    componentDidUpdate(prevProps) {
        const { auth, profile, funds } = this.props;
        let newAuthProps = auth.status !== prevProps.auth.status;
        let newFundsProps = funds.status !== prevProps.funds.status;

        if(newAuthProps) {
            if(auth.status === 'VALIDATE_SUCCESS'){
                this.getDetails(profile.basic);
            }
        }

        if(newFundsProps) {
            if(funds.status === 'ADD_FUND_SUCCESS') {
                this.getDetails(profile.basic);
            }
        }
    }

    render() {
        return (
            <FundsAvailableLayout
                getDetails = {this.getDetails}
                status = {this.props.funds.status}
                basic = {this.props.profile.basic}
                fundDetails = {this.props.funds.fundsInBranch}
                handleAddFundsSubmit = {this.handleAddFundsSubmit}
                {...this.props}
            />
        );
    }
}

function mapStateToProps(state) {
    const { auth, profile, funds, notifications }  = state;

    return {
        auth,
        profile,
        funds,
        notifications
    };
}

export default connect(mapStateToProps)(FundsAvailable);