import React from 'react';
import { ExitToAppOutlined, PrintOutlined } from '@material-ui/icons';
import { Box, Paper, Typography } from '@material-ui/core';
import { Table, Loader, Button } from "../../components";
import { reportConstants as rc} from '../../constants';
import Logo from '../../assets/invoice-logo.png';
import th from './table-headers.json';
import { utils } from '../../utils';
import useStyles from './style';

export default function ReportsByBranchLayout(props){

    const classes = useStyles();
    const {
        history,
        profile = {},
        status = '',
        details = [],
        to_date = '',
        branch_id = 0,
        from_date = '',
    } = props;

    const { basic = {}} = profile;

    const getTotalAmount = (
        data = [], 
        filterStatus = '',
        key = ''
    ) => {
        let dt = data;
        if(filterStatus)
            dt = dt.filter(e => filterStatus.includes(e.status));

        let amt = 0;
        dt = dt.map(f => amt += parseInt(f[key]));

        return amt;
    }

    const formattedData = (
        data = [], 
        timeKey = '', 
        filterStatus = '',
        amtKey = [],
    ) => {
        let dt = data;
        if(filterStatus)
            dt = dt.filter(e => filterStatus.includes(e.status));

        if(timeKey)
            dt = dt.map(f => {
                return {
                    ...f,
                    [timeKey] : utils.getLocalTime(f[timeKey])
                }
            });
        
        if(amtKey.length > 0)
            dt = dt.map(f => {
                let j = { ...f };
                amtKey.map(h => {
                    j =  { ...j, [h] : utils.getFormattedAmount(j[h] || 0) || "NA" }
                })
                return j;
            });

        return dt;
    }

    const getTitleForTable = (title = '') => {
        if(title)
            return (
                <Typography variant = "h6" 
                    style = {{
                        margin: '20px 0px',
                        fontWeight: 500
                    }}
                >
                    {title}
                </Typography>
            )
        else return null;
    }

    const getTable = (
        title = '',
        columns = [],
        data = [],
        timeKey = '',
        filterStatus = '',
        amtKey = [],
        styleName = 'splitTable'
    ) => {
        return (
            <Box className = {classes[styleName]}>
                {getTitleForTable(title)}
                <Table
                    tableColumns = {columns}
                    tableData = {formattedData(
                        data, timeKey, filterStatus, amtKey
                    )}
                />
            </Box>
        );
    }

    const formatMoney = (amt = 0, negative = false) => {
        if(!amt) return amt;
        return `${negative ? '-' : '+'}` + utils.getFormattedAmount(amt || 0);
    }

    const getDataForTotals = () => {
        let data = [];

        let ci_amt = getTotalAmount( details.cash_in, 'CONFIRMED', 'approved_amount' );
        data = [ ...data, { type: 'Cash-In (Fund Transfer)', amount: formatMoney(ci_amt) } ]
        let ord_amt = getTotalAmount(details.order_details, '', 'approved_amount' )
        data = [ ...data, { type: 'Purchases', amount: formatMoney(ord_amt, true) }]
        let co_amt = getTotalAmount( details.cash_out, ['CONFIRMED', 'NEED_CONFIRMATION'], 'approved_amount' );
        data = [ ...data, { type: 'Cash-Out (Fund Transfer)', amount: formatMoney(co_amt, true) } ]
        let br_amt = getTotalAmount( details.branch_expense, '', 'expense_amount' );
        data = [ ...data, { type: 'Expense', amount:  formatMoney(br_amt, true) } ]
    
        return data;
    }

    const getFormattedBalanceSheet = (data = []) => {
        let balance_sheet = [];
        if(data?.opening_balance?.length > 0){
            balance_sheet = data?.opening_balance?.map(e => {
                let cb = [];
                if(data?.closing_balance?.length > 0)
                    cb = data?.closing_balance?.filter(f => e.date === f.date);
                
                return {
                    date: e.date,
                    opening_balance : e.amount,
                    closing_balance: cb.length > 0 ? ( cb?.[0]?.amount || 0 ) : 'NA'
                }
            })
        }
        return balance_sheet;
    }

    const formatted = (date) => {
        let st = '';
        if(date){
            let d = date.split('-').reverse();
            d.map((e, i) => {
                if(i === 0) st = e;
                else st = `${st} / ${e}`;
            });
        }
        return st;
    }

    if(status === 'GET_BRANCH_REQUEST')
        return (
            <Box>
                <Box style={{height: '200px'}}>
                    <Loader />
                </Box>
                <Typography variant = "h5"
                    style = {{textAlign: 'center'}}
                > {rc.loadingText} </Typography>
            </Box>
        );
    else if(status === 'GET_BRANCH_SUCCESS'){
        return (
            <Box className={classes.outerBox}>
                <Box className={classes.tableTitleBox}>
                    <Box className = {classes.mainBoxLeft}>
                        <Typography variant = "h5">
                                {details?.branch_details?.branch_name || 'Branch'}
                        </Typography>
                        <Typography variant = "h6">
                            ({' AGB'}
                            {(branch_id).toLocaleString('en-US', {
                                minimumIntegerDigits: 3,
                                useGrouping: false
                            })} )
                        </Typography>
                    </Box>
                    <img 
                        className={classes.logoStyle}
                        src={Logo} 
                        alt={"Abaya Gold buyers logo"} 
                        width={"100px"}
                        height = {'120px'}
                    />
                    <Box className = {classes.mainBox}>
                        <Typography variant = "h6">
                            From: {formatted(from_date)} 
                        </Typography>
                        <Typography variant = "h6">
                            To: {formatted(to_date)} 
                        </Typography>
                    </Box>
                </Box>
                <Paper className = {classes.tableBox}>
                    {getTable(
                        'Orders',
                        th.order_details,
                        details.order_details,
                        '', '',
                        ['requested_amount', 'approved_amount'],
                        'orderTable'
                    )}
                </Paper>
                <Paper className = {classes.tableBox}>
                    {getTable(
                        'Cash In (Fund Transfer)',
                        th.cash_in,
                        details.cash_in,
                        'confirmed_on',
                        'CONFIRMED',
                        ['approved_amount']
                    )}
                    {getTable(
                        'Cash Out (Fund Transfer)',
                        th.cash_out,
                        details.cash_out,
                        'approved_on',
                        ['CONFIRMED', 'NEED_CONFIRMATION'],
                        ['approved_amount']
                    )}
                </Paper>
                <Paper className = {classes.tableBox}>
                    {getTable(
                        'Balance Sheet',
                        th.balance_sheet,
                        getFormattedBalanceSheet(details),
                        'date',
                        '',
                        ['opening_balance', 'closing_balance']
                    )}
                    <Box className = {classes.splitTable}>
                        {getTable(
                            'Branch Expenses',
                            th.branch_expense,
                            details.branch_expense,
                            'expense_on',
                            '',
                            ['expense_amount']
                        )}
                        {getTitleForTable('Totals')}
                        <Table
                            tableColumns = {th.totals}
                            tableData = {getDataForTotals()}
                        />
                    </Box>
                </Paper>
                <Box className = {classes.FooterBox}>
                    <Typography variant = "subtitle2">
                        {'Generated By :  '}
                        {basic?.first_name || 'NA'}
                        {" " + basic?.last_name}
                    </Typography>
                    <Typography variant = "subtitle2">
                        Generated From : {basic?.branch_details?.branch_name}
                    </Typography>
                    <Typography variant = "subtitle2">
                        Generated At : {new Date().toLocaleString()}
                    </Typography>
                </Box>
                <Box 
                    className={'row col-xs-12 center'}
                    style={{margin: '40px 0px'}}
                >
                    <Button
                        text = "Close"
                        variant = "secondary"
                        Icon = {ExitToAppOutlined}
                        style = {{
                            padding: '8px 16px',
                            marginRight: '16px'
                        }}
                        onClick = {() => history.goBack()}
                    />
                    <Button
                        text = "Print"
                        variant = "primary"
                        Icon = {PrintOutlined}
                        style = {{
                            padding: '8px 16px',
                        }}
                        onClick = {() => window.print()}
                    />
                </Box>
            </Box>
        );
    }else
        return <div></div>
}