import React, { Component } from 'react';
import { connect } from 'react-redux';
import DashboardLayout from './layout';

class Dashboard extends Component {
    render() {
        return (
            <DashboardLayout 
                {...this.props}
            />
        );
    }
}

function mapStateToProps(state) {
    const { profile, notifications }  = state;

    return {
        profile,
        notifications
    };
}

export default connect(mapStateToProps)(Dashboard);