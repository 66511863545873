import React from 'react';
import DropDownMenu from '../dropdown-menu';
import { utils } from '../../utils/utils';
import Logo from '../../assets/agc.png';
import appInfo from '../../app.json';
import useStyles from './style';
import { 
    AccountBalanceWalletOutlined,
    ShoppingCartOutlined,
    StarRateOutlined
} from '@material-ui/icons';
import { 
    Avatar, Box, 
    Typography, 
    IconButton, 
    Badge,
    OutlinedInput
} from '@material-ui/core';


export default function Header(props){
    const classes = useStyles();
    const {
        badgeValue = {},
        profileDetails = {},
        dispatch,
        history
    } = props;

    let role = utils.getRole() || '';
    let starArr = [ ...Array(appInfo.stars[role] || 0) ];

    const handleCompanyClick = (value) => {
        history.push('/search');
    }

    return (
        <Box className = {classes.headerNav}>
            <DropDownMenu
                handleClick = {handleCompanyClick}
            >
                <Box className = {classes.companyDetails}
                onClick = {() => history.push('/search')}
                >
                    <Avatar src={Logo}/>
                    <Typography 
                        variant='subtitle2' 
                        className = {classes.companyName}
                    >
                        Abaya Gold Buyers  
                        { 
                            profileDetails?.branch_details?.branch_name &&
                            (" - " + profileDetails?.branch_details?.branch_name)
                        }
                    </Typography>
                </Box>
            </DropDownMenu>
            <Box>
                <IconButton 
                    color='primary'
                    className={classes.HeaderMenus}
                    onClick = {() => history.push('/funds/request/in')}
                >
                    {
                        badgeValue.pending_funds ?
                        <Badge 
                            badgeContent={badgeValue.pending_funds} 
                            color = {'error'}
                        >
                            <AccountBalanceWalletOutlined />
                        </Badge> : ''
                    }
                    {
                        !badgeValue.pending_funds &&
                        <AccountBalanceWalletOutlined />
                    }
                </IconButton>
                <IconButton 
                    color='primary'
                    className={classes.HeaderMenus}
                    onClick = {() => history.push('/orders')}
                >
                    {
                        badgeValue.pending_orders ?
                        <Badge 
                            badgeContent={badgeValue.pending_orders} 
                            color = {'error'}
                        >
                            <ShoppingCartOutlined />
                        </Badge> : ''
                    }
                    {
                        !badgeValue.pending_orders &&
                        <ShoppingCartOutlined />
                    }
                </IconButton>
                <IconButton 
                    color='primary'
                    className={classes.HeaderMenus}
                    onClick={() => history.push('/settings')}
                >
                    <Avatar 
                        className  = {classes.avatarProfile}
                        src = {utils.getImageUrl(profileDetails?.user_image || '')}
                    />
                    <Typography variant = 'p' 
                        className={classes.menuDesc}
                    >
                            {profileDetails?.first_name || ''}
                            {profileDetails?.last_name && ' '}
                            {profileDetails?.last_name || ''}
                            {/* <span className={classes.role}>
                                {`|  `}
                            </span> */}
                            {/* <span className={classes.role}>
                                {`  ${utils.getRole()?.replace('_', ' ')} `}
                            </span> */}
                    </Typography>
                </IconButton>
                <IconButton 
                    color='primary'
                    className={`${classes.HeaderMenus} ${classes.noDisabledColor}`}
                    disabled
                >
                    { starArr.map(e => { return <StarRateOutlined /> }) }
                </IconButton>
            </Box>
        </Box>
    )
}