import React, { Component } from 'react';
import { connect } from 'react-redux';
import OnboardEmployeeLayout from './layout';
import { userActions } from '../../actions/user';
import { branchActions } from '../../actions/branch';
import { roleActions } from '../../actions/role';
import { uploadActions } from '../../actions/fileupload';
import { verificationActions } from '../../actions/verification';
import notyf from '../../utils/notification';
import values from './onboard.json';
import { utils } from '../../utils';
import Forbidden from '../forbidden';
import { 
    globalRegexp as gr, 
    onboardEmployeeConstants as oec 
} from '../../constants'; 

let kyc = {
    "doc_type": "Aadhaar Card",
    "kyc_number": "",
    "file_name": ""
};

let kycError = {
    "doc_type": false,
    "kyc_number": false,
    "file_name": false
}

class OnboardEmployee extends Component {

    constructor() {
        super();
        this.state = {
            details : { ...values.payload},
            valid: false,
            error : { show: false, keys: {}},
            kycCount: 1,
            address : false
        }
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(branchActions.getAll());
        dispatch(roleActions.getAll());
        this.setState({ 
            error: {
                show: false, 
                keys: { ...values.error }
            }
        });
    }

    validate = (detail = {}, show = false) => {
        const { details, error, address } = this.state;
        let err = { ...error.keys };
        let valid = true;
        let d = detail !== {} ? { ...detail} : { ...details };
        if(d['first_name'] === ''){
            valid = false;
            err['first_name'] = true;
        }else{
            err['first_name'] = false;
        }
        if(
            d.email !== '' &&
            !d.email.match(gr.email)
        ){
            valid = false;
            err['email'] = true;
        }else {
            err['email'] = false;
        }
        if(d["mobile_number"].length <= 9){
            valid = false;
            err['mobile_number'] = true;
        }else {
            err['mobile_number'] = false;
        }

        if(d["branch_id"] === 0){
            valid = false;
            err['branch_id'] = true;
        }else {
            err['branch_id'] = false;
        }

        if(d["role_id"].length === 0){
            valid = false;
            err['role_id'] = true;
        }else {
            err['role_id'] = false;
        }

        if(d.dob === '' || d.dob.length !== 10){
            valid = false;
            err['dob'] = true;
        }else {
            err['dob'] = false;
        }
        if(d.file_name === ''){
            valid = false;
            err['file_name'] = true;
        }else {
            err['file_name'] = false;
        }

        let ad = d.address;
        for(let i=0; i < (address ? ad.length : 1); i++){
            if(ad[i+""].door_no === ''){
                valid = false;
                err['address'][i+""] = { 
                    ...err['address'][i+""] , 
                    door_no : true 
                };
            }else {
                err['address'][i+""] = { 
                    ...err['address'][i+""] , 
                    door_no : false 
                };
            }
            if(ad[i+""].street === ''){
                valid = false;
                err['address'][i+""] = { 
                    ...err['address'][i+""] , 
                    street : true 
                };
            }else {
                err['address'][i+""] = { 
                    ...err['address'][i+""] , 
                    street : false 
                };
            }
            if(ad[i+""].area === ''){
                valid = false;
                err['address'][i+""]['area'] = true;
            }else {
                err['address'][i+""]['area'] = false;
            }
            if(ad[i+""].city === ''){
                valid = false;
                err['address'][i+""]['city'] = true;
            }else {
                err['address'][i+""]['city'] = false;
            }
            if(ad[i+""].state === ''){
                valid = false;
                err['address'][i+""]['state'] = true;
            }else {
                err['address'][i+""]['state'] = false;
            }
            if(ad[i+""].country === ''){
                valid = false;
                err['address'][i+""]['country'] = true;
            }else {
                err['address'][i+""]['country'] = false;
            }
            if(ad[i+""].pincode === 0 || ad[i+""].pincode === ''){
                valid = false;
                err['address'][i+""]['pincode'] = true;
            }else {
                err['address'][i+""]['pincode'] = false;
            }
        }
        let kc = d.kyc;
        for(let i = 0; i < kc.length; i++){
            if(kc[i]['doc_type'] === ''){
                valid = false;
                err['kyc'][i+""]['doc_type'] = true;
            }else {
                err['kyc'][i+""]['doc_type'] = false;
            }
            if(kc[i]['kyc_number'] === ''){
                valid = false;
                err['kyc'][i+""]['kyc_number'] = true;
            }else {
                err['kyc'][i+""]['kyc_number'] = false;
            }
            if(kc[i]['file_name'] === ''){
                valid = false;
                err['kyc'][i+""]['file_name'] = true;
            }else {
                err['kyc'][i+""]['file_name'] = false;
            }
        }
        // if(d["otp"].length <= 3){
        //     valid = false;
        //     err['otp'] = true;
        // }else {
        //     err['otp'] = false;
        // }


        this.setState((prev) => ({
            valid,
            error: { show: show || prev.error.show, keys: err }
        }));

        return valid;
    }

    handleChange = (
        value = '', 
        parentKey = '', 
        childKey = '', 
        index = 0
    ) => {
        let d = this.state.details;

        if(childKey){
            d = {
                ...d,
                [parentKey] : d[parentKey].map(
                    (e,i) => {
                        if(i === index) return { ...e, [childKey] : value} ;
                        else    return e;
                    }
                )
            }
        }else {
            d = { ...d, [parentKey] : value }
        }

        this.setState({ details: d });
        this.validate(d);
    }

    removeKycCount = () => {
        let kyc = this.state.error.keys.kyc;
        delete kyc[""+ (Object.keys(kyc).length - 1)];
        this.setState((prev) => ({
            kycCount: prev.kycCount - 1,
            details: { 
                ...prev.details, 
                kyc : prev.details.kyc.filter(
                    (e, index) => index !== prev.details.kyc.length - 1
                )
            },
            error: {
                show: prev.error.show,
                keys: {
                    ...prev.error.keys,
                    kyc
                }
            }
        }))
    }

    addKycCount = () => {
        this.setState((prev) => ({
            kycCount: prev.kycCount + 1,
            details: { 
                ...prev.details, 
                kyc : [
                    ...prev.details.kyc,
                    kyc
                ]
            },
            error: {
                show: prev.error.show,
                keys: {
                    ...prev.error.keys,
                    kyc : {
                        ...prev.error.keys.kyc,
                        [prev.kycCount + ""] : kycError
                    }
                }
            }
        }))
    }

    handleSubmit = () => {
        const { dispatch } = this.props;
        const { details, valid, address } = this.state;

        if(valid && this.validate(details)){
            // if(details.otp.length > 3){
                let p = { ...details }
                if(!address){
                    p = {
                        ...p,
                        address : [
                            { ...p.address[0] }
                        ]
                    }
                }
                dispatch(userActions.add(p));
            // }
        }else {
            this.validate(details, true);
            notyf.open({ 
                type: 'error', 
                message: oec.invalidForm 
            });
        }
    }

    handleOtpTrigger = () => {
        const { details } = this.state;
        const { dispatch } = this.props;
        
        if(details.mobile_number.length > 8){
            let payload = { mobile_number : details.mobile_number }; 
            dispatch(verificationActions.sendOtp(payload));
        }else {
            this.validate(details, true);
            window.scrollTo(0,0);
        }
    }

    handleFileChange = (file, parentKey, childKey, index) => {
        const { dispatch } = this.props;

        if(file){
            dispatch(uploadActions.upload(file, parentKey, index));
        }
    }

    componentDidUpdate(prevProps) {
        const { fileupload, user, history } = this.props;
        const { details } = this.state;

        if(fileupload.uploads !== prevProps.fileupload.uploads){
            let dd = {...details};
            let d = [ ...details.kyc ];
            let f = { ...fileupload.uploads };

            if(d.length > 0){
                d = d.map( 
                    (e, index) => {
                        if(f.kyc?.[index + ""]?.status === 'S') {
                            return { ...e, file_name : f.kyc?.[index].name }
                        }else {
                            return e;
                        }
                    }
                )
            }

            if(f.file_name?.["0"]?.status === 'S'){
                dd = {
                    ...dd,
                    file_name: f?.file_name?.["0"]?.name
                }
            }

            let detail = {
                ...dd,
                kyc : [ ...d  ], 
            }

            this.setState({
                details : { ...detail   }
            })
            this.validate(detail);
        }

        if(
            user.status !== prevProps.user.status && 
            user.status ===  'ADD_USER_SUCCESS'
        ){
            history.push('/employee');
        }

    }

    handleCheckBox = (value, key) => {
        let e = { ...this.state.error.keys };
        if(key === 'address' && !value){
            e = { 
                ...e,
                "address" : {
                    ...e.address,
                    "1" : {
                        "door_no": false,
                        "street": false,
                        "area": false,
                        "city": false,
                        "state": false,
                        "country": false,
                        "pincode": false
                    }
                }
            }
        }
        this.setState( prev =>({
            error : { 
                show: prev.error.show, 
                keys: { ...e }
            },
            [key] : value,
        }))
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(uploadActions.clearState());
    }

    render() {
        if(utils.getRole().includes('ADMIN'))
            return (
                <OnboardEmployeeLayout 
                    kycCount = { this.state.kycCount }
                    addKycCount = {this.addKycCount}
                    removeKycCount = {this.removeKycCount}
                    handleFileChange = {this.handleFileChange}
                    handleOtpTrigger = {this.handleOtpTrigger}
                    handleChange = {this.handleChange}
                    handleSubmit = {this.handleSubmit}
                    handleCheckBox ={this.handleCheckBox}
                    branches = {this.props.branch.branchDetails}
                    roles = {this.props.role.roles}
                    valid = {this.state.valid}
                    errorValue = {this.state.error}
                    fileuploads = {this.props.fileupload.uploads}
                    verifyStatus = {this.props.verification.status || ''}
                    {...this.props}
                />
            );
        else
            return ( 
                <Forbidden 
                    profile={this.props.profile}
                    dispatch = {this.props.dispatch}
                    notifications = {this.props.notifications}
                />
            );
    }
}

function mapStateToProps(state) {
    const { 
            profile, 
            branch,
            fileupload, 
            user, role,
            verification,
            notifications 
    }  = state;

    return {
        profile,
        branch,
        fileupload,
        user,
        role,
        verification,
        notifications
    };
}

export default connect(mapStateToProps)(OnboardEmployee);