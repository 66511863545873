import roleConstants from '../constants/role';
import { roleService } from '../services';
import notyf from '../utils/notification';

export const roleActions = {
    getAll,
    updateRoleForUser
}

function  getAll(payload){
    return dispatch =>{
        dispatch(request());
        roleService.getAll(payload).then(
            roles =>{ dispatch(success(roles)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: roleConstants.GET_ALL_REQUEST };
    }
    function success(roles){
        return {
            type: roleConstants.GET_ALL_SUCCESS,
            payload: roles
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: roleConstants.GET_ALL_ERROR,
            payload : error
        };
    }
}

function updateRoleForUser(payload){
    return dispatch =>{
        dispatch(request());
        roleService.updateRoleForUser(payload).then(
            roles =>{ dispatch(success(roles)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: roleConstants.U_ROLE_EMP_REQUEST };
    }
    function success(roles){
        notyf.open({
            type: 'success',
            message: 'Role updated Succssfully'
        })
        return {
            type: roleConstants.U_ROLE_EMP_SUCCESS,
            payload: roles
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: roleConstants.U_ROLE_EMP_ERROR,
            payload : error
        };
    }
}