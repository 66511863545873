import React from 'react';
import { forbiddenConstants as fc } from '../../constants';
import forbiddenImg from '../../assets/forbidden-error.gif';
import { Typography, Box } from '@material-ui/core';
import { HomeOutlined } from '@material-ui/icons';
import { Button, Navbar } from '../../components';
import useStyles from './style.js';
import { useHistory } from 'react-router-dom';


export default function ForbiddenLayout(props) {

    const classes = useStyles();
    let history = useHistory();

    return (
        <Navbar 
            {...props} 
        >
            <Box className = {classes.mainBox}>
                <img src={forbiddenImg} width="300" height="300"/>
                <Typography
                    variant = "h2"
                    className={classes.forbiddenTitle}
                >
                    {fc.title}
                </Typography>
                <Typography
                    variant = "h6"
                    className={classes.forbiddenSubTitle}
                >
                    {fc.subTitle}
                </Typography>
                <Button 
                    text = {fc.buttonTitle}
                    variant = "primary"
                    Icon = {HomeOutlined}
                    style = {{
                        padding: '8px 16px', 
                        marginRight: '16px' ,
                        width: '300px'
                    }}
                    onClick = {() => history.push('/search')}
                />
            </Box>
        </Navbar>       
    );
}