import React from 'react';
import {
    Box,
    CircularProgress
} from '@material-ui/core';
import { 
    makeStyles 
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    box: {
        height: '100vh',
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    }
}));

export default function Loader(props) {

    const defaultClasses = useStyles();

    const { 
        style = {},
        classes = {},
        size = 40,
        color = 'primary',
        disableShrink = false,
        thickness = 3.6,
        value = 0,
        variant = 'indeterminate'
    } = props;

    return (
        <Box className={defaultClasses.box}>
            <CircularProgress 
                style = {style}
                classes = {classes}
                size = {size}
                color = {color}
                disableShrink = {disableShrink}
                thickness = { thickness }
                value = { value }
                variant = { variant }
            />
        </Box>
    );
}