import fundConstants from '../constants/fund-request';

const initialState = {
    status: '',
    noOfRecords: 0,
    fundDetails: [],
    fundsInBranch: []
}

function funds(state = initialState, action) {
    switch(action.type) {
        case fundConstants.POST_SUCCESS : 
            return {
                ...state,   
                status: 'POST_FUND_SUCCESS',
            }
        case fundConstants.POST_ERROR:
            return {
                ...state,
                status: 'POST_FUND_ERROR'
            }
        case fundConstants.POST_REQUEST:
            return {
                ...state,
                status: 'POST_FUND_REQUEST'
            }
        case fundConstants.ADD_SUCCESS : 
            return {
                ...state,   
                status: 'ADD_FUND_SUCCESS',
            }
        case fundConstants.ADD_ERROR:
            return {
                ...state,
                status: 'ADD_FUND_ERROR'
            }
        case fundConstants.ADD_REQUEST:
            return {
                ...state,
                status: 'ADD_FUND_REQUEST'
            }
        case fundConstants.GET_ALL_SUCCESS : 
            return {
                ...state,   
                status: 'GETALL_SUCCESS',
                fundDetails: action.payload.data
            }
        case fundConstants.GET_ALL_ERROR:
            return {
                ...state,
                status: 'GETALL_ERROR'
            }
        case fundConstants.GET_ALL_REQUEST:
            return {
                ...state,
                status: 'GETALL_REQUEST'
            }
        case fundConstants.BRANCH_FUND_SUCCESS : 
            return {
                ...state,   
                status: 'BRANCH_FUND_SUCCESS',
                fundsInBranch: action.payload.data
            }
        case fundConstants.BRANCH_FUND_ERROR:
            return {
                ...state,
                status: 'BRANCH_FUND_ERROR'
            }
        case fundConstants.BRANCH_FUND_REQUEST:
            return {
                ...state,
                status: 'BRANCH_FUND_REQUEST'
            }
        case fundConstants.P_ACTION_REQUEST : 
            return {
                ...state,   
                status: 'P_ACTION_REQUEST',
            }
        case fundConstants.P_ACTION_SUCCESS:
            return {
                ...state,
                status: 'P_ACTION_SUCCESS'
            }
        case fundConstants.P_ACTION_ERROR:
            return {
                ...state,
                status: 'P_ACTION_ERROR'
            }
        case fundConstants.C_ACTION_REQUEST : 
            return {
                ...state,   
                status: 'C_ACTION_REQUEST',
            }
        case fundConstants.C_ACTION_SUCCESS:
            return {
                ...state,
                status: 'C_ACTION_SUCCESS'
            }
        case fundConstants.C_ACTION_ERROR:
            return {
                ...state,
                status: 'C_ACTION_ERROR'
            }
        case fundConstants.CANCEL_FR_REQUEST : 
            return {
                ...state,   
                status: 'CANCEL_FR_REQUEST',
            }
        case fundConstants.CANCEL_FR_SUCCESS:
            return {
                ...state,
                status: 'CANCEL_FR_SUCCESS'
            }
        case fundConstants.CANCEL_FR_ERROR:
            return {
                ...state,
                status: 'CANCEL_FR_ERROR'
            }
        case fundConstants.RECORDS_SUCCESS : 
            return {
                ...state,   
                status: 'RECORDS_SUCCESS',
                noOfRecords: action.payload.data.total
            }
        case fundConstants.RECORDS_ERROR:
            return {
                ...state,
                status: 'RECORDS_ERROR'
            }
        case fundConstants.RECORDS_REQUEST:
            return {
                ...state,
                status: 'RECORDS_REQUEST'
            }
        default: return state;
    }
}

export default funds;