import reportConstants from '../constants/report';

const initialState = {
    status: '',
    details: []
}

function reports(state = initialState, action) {
    switch(action.type) {
        case reportConstants.GET_BRANCH_SUCCESS : 
            return {
                ...state,   
                status: 'GET_BRANCH_SUCCESS',
                details: action.payload.data
            }
        case reportConstants.GET_BRANCH_ERROR:
            return {
                ...state,
                status: 'GET_BRANCH_ERROR'
            }
        case reportConstants.GET_BRANCH_REQUEST:
            return {
                ...state,
                status: 'GET_BRANCH_REQUEST'
            }
        case reportConstants.GET_ALL_SUCCESS : 
            return {
                ...state,   
                status: 'GET_ALL_SUCCESS',
                details: action.payload.data
            }
        case reportConstants.GET_ALL_ERROR:
            return {
                ...state,
                status: 'GET_ALL_ERROR'
            }
        case reportConstants.GET_ALL_REQUEST:
            return {
                ...state,
                status: 'GET_ALL_REQUEST'
            }
        case reportConstants.CLEAR : 
            return {
                ...state,   
                status: '',
                details: []
            }
        default: return state;
    }
}

export default reports;