import {httpClient} from "../utils";

export const uploadService ={
    upload
}


function upload(file){

    const data = new FormData()
    data.append('file', file[0]);

    return  httpClient.callApi( "POST",`/third-party/uploadFile`, data, false, true );
}