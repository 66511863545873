import {httpClient} from "../utils";

export const orderService ={
    get,
    drop,
    place,
    cancel,
    getAll,
    approve,
    confirm,
    release,
    getOrderItems,
    getTransactions,
    getTotalRecords
}


function getFilter(filter = {}){
    return `branch_id=${filter.requested_branch || 0}` +
            `&status=${filter.order_status || '' }`+
            `&order_date=${filter.order_date || ''}`
}

function get(id){
    return httpClient.callApi("GET",`/order/getOrderDetails?order_id=${id}`);
}

function place(payload){
    return httpClient.callApi("POST",`/order/placeOrder`, payload);
}

function cancel(payload){
    return httpClient.callApi("POST",`/order/cancelOrder`, payload);
}

function release(payload){
    return httpClient.callApi("POST",`/order/markPledgeAsReceived`, payload);
}

function drop(payload){
    return httpClient.callApi("POST",`/order/dropOrder`, payload);
}

function getAll(offset, limit, filter = {}){
    return  httpClient.callApi(
                "GET",
                `/order/getOrders?` +
                getFilter(filter) +
                `&offset=${offset}` +
                `&limit=${limit}`
            );
}

function approve(payload) {
    return httpClient.callApi("POST",`/order/approveOrder`, payload);
}

function confirm(payload) {
    return httpClient.callApi("POST",`/order/payout`, payload);
}

function getOrderItems(id){
    return httpClient.callApi("GET",`/order/getOrderItems?order_id=${id}`);
}

function getTransactions(id){
    return httpClient.callApi("GET",`/order/getTransaction?customer_id=${id}`);
}

function getTotalRecords(filter = {}){
    return httpClient.callApi(
                "GET", `/order/getTotalRecord?` + getFilter(filter)
        );
}