import React, { useState } from 'react';
import { 
    Address, Button, 
    Kyc, Navbar 
} from "../../components";
import { onboardEmployeeConstants as oc } from '../../constants';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import useStyles from './style';
import { 
    Grid, Box, Select,
    FormControlLabel,
    Checkbox,
    Typography, 
    FormControl,
    OutlinedInput,
    InputAdornment,
    MenuItem,
    CircularProgress
} from '@material-ui/core';
import { 
    Cake, ExpandMore, 
    MailOutlined,
    PersonOutlined,
    CheckCircleOutlineRounded,
    ErrorOutlineRounded,
    AttachFileOutlined,
    WcOutlined
} from '@material-ui/icons';

export default function OnboardEmployeeLayout(props){

    const classes = useStyles();
    const [havePermAddress, setHavePermAddress] = useState(false);
    const {
        kycCount = 1,
        errorValue = {show: false, keys: {}},
        fileuploads = {},
        branches = [],
        roles = [],
        valid = true,
        addKycCount = () => null,
        removeKycCount = () => null,
        handleChange = () => null,
        handleFileChange = () => null,
        handleSubmit = () => null,
        handleOtpTrigger = () => null,
        handleCheckBox = () => null,
        verifyStatus = ''
    } = props;

    const handleToggleCheckBox = (e, key) => {
        handleCheckBox(e, key);
        setHavePermAddress(e);
    }

    return (
        <Navbar 
            { ...props }
        >
            <Box className={classes.titleSection}>
                <Typography variant = 'h5' className={classes.title}>
                    {oc.title}
                </Typography>
                <Typography variant = 'p' className={classes.subTitle}>
                    {oc.subTitle}
                </Typography>
            </Box>
            <Box className = {classes.outerBox}>
                <Typography variant='h6' className= {classes.blockTitle}>
                    Personal Details
                </Typography>
                <Grid
                    xs = {12} sm = {6} md = {6} lg = {6} xl = {6}
                    className = {classes.mainGrid}
                >
                            <FormControl className={classes.phoneInputField}>
                                <Typography variant='subtitle2' className={classes.label}>
                                    Phone Number *
                                </Typography>
                                <PhoneInput
                                    country={'in'}
                                    placeholder={'Phone Number'}
                                    inputStyle = {{
                                        height: '40px', 
                                        borderColor: errorValue.show && 
                                        errorValue.keys['mobile_number'] ? 'red' : ''
                                    }}
                                    countryCodeEditable={false}
                                    onChange={ phone => handleChange("+" + phone, 'mobile_number') }
                                    inputProps = {{
                                        required: true,
                                        autoFocus: true
                                    }}
                                />
                                <small className={'red'}>
                                    {
                                        errorValue.show && 
                                        errorValue.keys['mobile_number'] &&
                                        oc.invalid
                                    }
                                </small>
                            </FormControl>
                            <FormControl className={classes.formControlInput}>
                                <Typography variant='subtitle2'
                                    className={classes.label}
                                >
                                    First Name *
                                </Typography>
                                <OutlinedInput
                                    onChange={(e) => handleChange(e.target.value, 'first_name')}
                                    className={classes.inputField}
                                    placeholder={'First Name'}
                                    type = {'text'}
                                    id='first_name'
                                    error = {
                                        errorValue.show && 
                                        errorValue.keys['first_name']
                                    }
                                    startAdornment = {
                                        <InputAdornment position='start'>
                                            <PersonOutlined edge='start' color='primary'/>
                                        </InputAdornment>
                                    }
                                    inputProps = {{
                                        required: true,
                                        maxLength : 45
                                    }}
                                />
                                <small className={'red'}>
                                    {
                                        errorValue.show && 
                                        errorValue.keys['first_name'] &&
                                        oc.invalid
                                    }
                                </small>
                            </FormControl>
                            <FormControl className={classes.formControlInput}>
                                <Typography variant='subtitle2'
                                    className={classes.label}
                                >
                                    Last Name (optional)
                                </Typography>
                                <OutlinedInput
                                    onChange={(e) => handleChange(e.target.value, 'last_name')}
                                    className={classes.inputField}
                                    placeholder={'Last Name'}
                                    type = {'text'}
                                    id='last_name'
                                    startAdornment = {
                                        <InputAdornment position='start'>
                                            <PersonOutlined edge='start' color='primary'/>
                                        </InputAdornment>
                                    }
                                    inputProps = {{
                                        required: true,
                                        maxLength : 45
                                    }}
                                />
                                {<small></small>}
                            </FormControl>
                            <FormControl className={classes.formControlInput}>
                                <Typography variant='subtitle2'
                                    className={classes.label}
                                >
                                    Employed under Branch *
                                </Typography>
                                <Select
                                    disableUnderline
                                    variant = 'outlined'
                                    className={classes.inputField}
                                    IconComponent = {ExpandMore}
                                    error = {
                                        errorValue.show && 
                                        errorValue.keys['branch_id']
                                    }
                                    onChange={(e) => handleChange(e.target.value, 'branch_id')}
                                    inputProps={{
                                        name: 'branch_id',
                                    }}
                                >
                                    {
                                        branches.length > 0 &&
                                        branches.map(
                                            e => <MenuItem value={e.id}>
                                                    {e.branch_name}
                                                 </MenuItem>
                                        )
                                    }
                                </Select>
                                <small className={'red'}>
                                    {
                                        errorValue.show && 
                                        errorValue.keys['branch_id'] &&
                                        oc.invalid
                                    }
                                </small>
                            </FormControl>
                            <FormControl style={{marginBottom: 16}} 
                                className={classes.formControlInput}
                            >
                                <Typography variant='subtitle2'
                                    className={classes.label}
                                >
                                    Upload Image
                                </Typography>
                                <OutlinedInput
                                    onChange={(e) => handleFileChange(e.target.files, 'file_name', '' , 0)}
                                    className={classes.inputField}
                                    error = { errorValue.show && errorValue.keys?.['file_name']  }
                                    placeholder={''}
                                    type = {'file'}
                                    id= { 'image_0' }
                                    startAdornment = {
                                        <InputAdornment position='start'>
                                            {
                                                fileuploads?.file_name?.["0"]?.status === 'R' &&
                                                <CircularProgress size={16} color="primary"/> 
                                            }
                                            {
                                                fileuploads?.file_name?.["0"]?.status === 'S' &&
                                                <CheckCircleOutlineRounded size = {24} style={{color: 'green'}}/>
                                            }
                                            {
                                                fileuploads?.file_name?.["0"]?.status === 'E' &&
                                                <ErrorOutlineRounded size={24} color='error' />
                                            }
                                            {
                                                !fileuploads?.file_name?.["0"]?.status &&
                                                <AttachFileOutlined edge='start' color='primary'/>
                                            }                              
                                        </InputAdornment>
                                    }
                                    inputProps = {{
                                        accept: "image/*",
                                        required: true,
                                        maxLength : 45
                                    }}
                                />
                                <small className={'red'}>
                                    {
                                        errorValue.show && 
                                        errorValue.keys['file_name'] &&
                                        oc.invalidFile
                                    }
                                </small>
                            </FormControl>
                </Grid>
                <Grid
                    xs = {12} sm = {6} md = {6} lg = {6} xl = {6}
                    className = {classes.mainGrid}
                >
                        <FormControl className={classes.formControlInput}>
                            <Typography variant='subtitle2'
                                className={classes.label}
                            >
                                Email (optional)
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleChange(e.target.value, 'email')}
                                className={classes.inputField}
                                placeholder={'abc@example.com'}
                                type = {'text'}
                                id='email'
                                error = {
                                    errorValue.show && 
                                    errorValue.keys['email']
                                }
                                startAdornment = {
                                    <InputAdornment position='start'>
                                        <MailOutlined edge='start' color='primary'/>
                                    </InputAdornment>
                                }
                                inputProps = {{
                                    required: true,
                                    maxLength : 45
                                }}
                            />
                            <small className={'red'}>
                                    {
                                        errorValue.show && 
                                        errorValue.keys['email'] &&
                                        oc.invalid
                                    }
                            </small>
                        </FormControl>
                        <FormControl className={classes.formControlInput}>
                            <Typography variant='subtitle2'
                                className={classes.label}
                            >
                                Gender *
                            </Typography>
                            <Select
                                disableUnderline
                                variant = 'outlined'
                                className={classes.inputField}
                                IconComponent = {ExpandMore}
                                onChange={(e) => handleChange(e.target.value, 'gender')}
                                inputProps={{ name: 'gender' }}
                                startAdornment = {
                                    <InputAdornment position='start'>
                                        <WcOutlined edge='start' color='primary'/>
                                    </InputAdornment>
                                }
                            >
                                <MenuItem value={'MALE'}>Male</MenuItem>
                                <MenuItem value={'FEMALE'}>Female</MenuItem>
                                <MenuItem value={'OTHERS'}>Others</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <Typography variant='subtitle2'
                                className={classes.label}
                            >
                                Date of Birth *
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleChange(e.target.value, 'dob')}
                                startAdornment = {
                                    <InputAdornment position='start'>
                                        <Cake edge='start' color='primary'/>
                                    </InputAdornment>
                                }
                                error = {
                                    errorValue.show && 
                                    errorValue.keys['dob']
                                }
                                color = {"primary"}
                                className={classes.inputField}
                                placeholder={'Date of Birth'}
                                type = {'date'}
                                id='dob'
                                inputProps = {{
                                    required: true,
                                    maxLength : 45
                                }}
                            />
                            <small className={'red'}>
                                    {
                                        errorValue.show && 
                                        errorValue.keys['dob'] &&
                                        oc.invalid
                                    }
                            </small>
                        </FormControl>
                        <FormControl className={classes.formControlInput}>
                            <Typography variant='subtitle2'
                                className={classes.label}
                            >
                                Employed under Role *
                            </Typography>
                            <Select
                                disableUnderline
                                variant = 'outlined'
                                className={classes.inputField}
                                IconComponent = {ExpandMore}
                                error = {
                                    errorValue.show && 
                                    errorValue.keys['role_id']
                                }
                                onChange={(e) => handleChange([e.target.value], 'role_id')}
                                inputProps={{
                                    name: 'role_id',
                                }}
                            >
                                {
                                    roles.length > 0 &&
                                    roles.map(
                                        e => <MenuItem value={e.id}>
                                                {e.role_name}
                                                </MenuItem>
                                    )
                                }
                            </Select>
                            <small className={'red'}>
                                {
                                    errorValue.show && 
                                    errorValue.keys['role_id'] &&
                                    oc.invalid
                                }
                            </small>
                        </FormControl>
                </Grid>
            </Box>
            <Box className = {classes.kycOuterBox}>
                <Box className = {classes.kycBox}>
                    <Typography variant='h6' className= {classes.blockTitle}>
                        KYC details
                    </Typography>
                        <Grid
                            xs = {12} sm = {6} md = {6} lg = {6} xl = {6}
                            className = {classes.mainKycGrid}
                        >
                            {   [ ...Array(Math.round(kycCount / 2))].map(
                                    (i, index) => { 
                                        return <Kyc 
                                                    uploadStatus = {fileuploads.kyc}
                                                    kycIndex={index * 2} 
                                                    handleFileChange = {handleFileChange}
                                                    handleChange = {handleChange}
                                                    errorValue = {{
                                                        show: errorValue.show, 
                                                        keys: errorValue.keys.kyc?.[`${index * 2}`]
                                                    }}
                                                />;
                                    } 
                                )
                            }
                        </Grid>
                        <Grid
                            xs = {12} sm = {6} md = {6} lg = {6} xl = {6}
                            className = {classes.mainKycGrid}
                        >
                            {   [...Array(Math.floor(kycCount / 2))].map(
                                    (i, index) => { 
                                        return  <Kyc 
                                                    uploadStatus = {fileuploads.kyc}
                                                    kycIndex={(index * 2) + 1} 
                                                    handleFileChange = {handleFileChange}
                                                    handleChange = {handleChange}
                                                    errorValue = {{
                                                        show: errorValue.show, 
                                                        keys: errorValue.keys.kyc?.[`${(index * 2)+1}`]
                                                    }}
                                                />;
                                    } 
                                )
                            }
                        </Grid>
                    </Box>
                    <Box style = {{
                        display: 'flex', 
                        flexDirection: 'row', 
                        marginBottom: '16px'
                    }}>
                        <Button 
                            variant='primary'
                            onClick = {addKycCount} 
                            style={{
                                width: '200px',
                                marginLeft: 'auto',
                                marginRight: kycCount === 1 ? 'auto' :'16px' 
                            }}
                            text = 'Add KYC Doc'
                        />
                        <Button 
                            variant='secondary'
                            onClick = {removeKycCount} 
                            style={{
                                width: '200px',
                                display: kycCount === 1 ? 'none' : 'flex',
                                marginLeft: '16px',
                                marginRight: 'auto' 
                            }}
                            text = 'Remove Last KYC Doc'
                        />
                    </Box>
                    
            </Box>
            <Box className = {classes.outerBox}>
                <Typography variant='h6' className= {classes.blockTitle}>
                    Employee Address
                </Typography>
                <Grid
                    xs = {12} sm = {6} md = {6} lg = {6} xl = {6}
                    className = {classes.mainGrid}
                >
                    <Address 
                        errorValue = {{
                            show: errorValue.show, 
                            keys: errorValue.keys.address?.["0"]
                        }}
                        title = {'Communication Address'}
                        disabled = {false}
                        addressIndex = {0}
                        handleChange = {handleChange}
                    />
                    <FormControlLabel
                        control={
                        <Checkbox
                            onChange={(e) => handleToggleCheckBox(
                                e.target.checked,
                                'address'
                            )}
                            name="address_same"
                            color="primary"
                        />
                        }
                        label = {"Have Permanent Address ?"}
                    />
                </Grid>
                <Grid
                    xs = {12} sm = {6} md = {6} lg = {6} xl = {6}
                    className = {classes.mainGrid}
                >
                    {
                        havePermAddress &&
                        <Address 
                            errorValue = {{
                                show: errorValue.show, 
                                keys: errorValue.keys.address?.["1"]
                            }}
                            title = {'Permanent Address'}
                            disabled = {!havePermAddress}
                            addressIndex = {1}
                            handleChange = {handleChange}
                        />
                    }
                </Grid>
            </Box>
            <Box className = {classes.kycOuterBox}>
                {/* <Box className = {classes.kycBox}>
                    <Typography variant='h6' className= {classes.blockTitle}>
                        One Time Password
                    </Typography>
                    <Grid
                        xs = {12} sm = {6} md = {6} lg = {6} xl = {6}
                        className = {classes.mainGrid}
                    >
                                <FormControl className={classes.formControlInput}>
                                    <OutlinedInput
                                        onChange={(e) => handleChange(e.target.value, 'otp')}
                                        error = {errorValue.show && errorValue.keys?.['otp']}
                                        className={classes.inputField}
                                        placeholder={'OTP'}
                                        type = {'text'}
                                        id='otp'
                                        startAdornment = {
                                            <InputAdornment position='start'>
                                                <LockOutlined edge='start' color='primary'/>
                                            </InputAdornment>
                                        }
                                        inputProps = {{
                                            required: true,
                                            maxLength : 10
                                        }}
                                    />
                                    <small className={'red'}>
                                        {
                                            errorValue.show && 
                                            errorValue.keys?.['otp'] &&
                                            oc.invalidOtp
                                        }
                                    </small>
                                </FormControl>
                    </Grid>
                    <Grid
                        xs = {12} sm = {6} md = {6} lg = {6} xl = {6}
                        className = {classes.mainGrid}
                    >
                        <Button 
                            variant='secondary'
                            onClick = {handleOtpTrigger} 
                            loading = {verifyStatus.includes('REQUEST')}
                            style={{
                                width: '250px',
                                marginLeft: 'auto',
                                marginRight: 'auto' 
                            }}
                            text = 'Get One Time Password'
                        />
                    </Grid>
                </Box> */}
                {/* {
                    verifyStatus === 'SEND_OTP_SUCCESS' && */}
                        <Box style = {{
                            display: 'flex', 
                            flexDirection: 'row', 
                            marginBottom: '16px'
                        }}>
                            <Button 
                                variant='primary'
                                onClick = {handleSubmit}
                                style={{
                                    width: '300px',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    marginTop: '40px'
                                }}
                                text = 'On Board'
                            />
                    </Box>
            </Box>
        </Navbar>
    );
}