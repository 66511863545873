import React, { useEffect, useState } from 'react';
import { Button, ModalDialog, } from "../../../components";
import { branchActions } from '../../../actions/branch';
import { ExpandMore } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import useStyles from '../style';
import { 
    Grid, Typography, Select,
    FormControl, Box, MenuItem 
} from '@material-ui/core';


let payl = {
    "user_id": 0,
    "from_branch": 0,
    "to_branch": 0
}

export default function UpdateBranch(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [payload, setPayload] = useState(payl);
    const [error, setError] = useState(false);
    const {
        show = false,
        setShow = () => null,
        details = {},
        status = '',
        branches = []
    } = props;

    useEffect(() => {
        setPayload({ 
            ...payl, 
            user_id: details?.id || 0,
            from_branch: details?.branch_id || 0 
        });
        dispatch(branchActions.getAll());
    },[]);

    const handleChange = (key = '', value  = 0) => {
        setError(false);
        setPayload({
            ...payload,
            [key]: value
        });
    }

    const handleSubmit = () => {
        if(payload.to_branch)
            props.handleSubmit(payload);
        else
            setError(true);
    }


    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography 
                        variant = 'h5' 
                        className={classes.title}
                    >
                        Branch Transfer
                    </Typography>
                    <Typography 
                        variant = 'h6' 
                        className={classes.title}
                    >
                        Transfer Employee to new branch
                    </Typography>
                </Box>
                <Grid 
                    container 
                    className = {classes.mainGrid}
                >
                    <Grid 
                        item xs={12} sm={6} 
                        md={6} lg={6} xl={6}
                        className = {classes.indGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            First Name
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.first_name || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Current Role
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.role_name || 'NA'}
                        </Typography>
                    </Grid>
                    <Grid 
                        item xs={12} sm={6} 
                        md={6} lg={6} xl={6}
                        className = {classes.indGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Last Name
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.last_name || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Current Branch
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.branch_name || 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
                <FormControl 
                    className={classes.inputField} 
                    style={{marginTop: '48px'}}
                >
                    <Typography variant='subtitle2'
                        className={classes.label}
                        style = {{ marginBottom: '8px'}}
                    >
                        Select branch to update :
                    </Typography>
                    <Select
                        error = { error }
                        disableUnderline
                        variant = 'outlined'
                        className={classes.inputField}
                        IconComponent = {ExpandMore}
                        onChange={(e) => handleChange('to_branch', e.target.value)}
                        inputProps={{ name: 'to_branch' }}
                    >
                        {
                            branches.length > 0 &&
                            branches.map(
                                e => {
                                    if(e.id !== payload.from_branch){
                                        return (
                                            <MenuItem value={e.id}>
                                                {e.branch_name}
                                            </MenuItem>)
                                    }else return null;
                                }
                            )
                        }
                    </Select>
                    <small className={'red'}>
                        { error &&  'Invalid Branch. Please select a valid branch' }
                    </small>
                </FormControl>               
            </Box>
        );
    }

    const footer = () => {
        return (
            <>
                <Button 
                    style = {{ padding: '8px 32px' }}
                    variant={"secondary"}
                    onClick={setShow} 
                    text={'Cancel'}
                />
                <Button 
                    loading = {status === 'TRANS_BRANCH_REQUEST'}
                    style = {{ padding : '8px 32px' }}
                    onClick={handleSubmit}
                    text = {'Submit'}

                />
            </>
        );
    }

    return (
        <ModalDialog
            open = {show}
            dialogTitle = ''
            maxWidth = {'md'}
            onClose = {setShow}
            dialogFooter = {footer()}
            dialogContent = {dialogContent()}
            classes = {{ paper: classes.dialogPaper}}
        />
    )

}