import React from 'react';
import { 
    Typography, Grid, 
    FormControl, OutlinedInput,
    Select, MenuItem 
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import useStyles from '../style';


export default function OrderTotalCalc(props) {
    const classes = useStyles();
    const { 
        details = {} ,
        handleTotalChange = () => null
    } = props;
    return (
        <Grid xs={12} sm={6} md={6}
            className={classes.extraFieldsGrid}
        >
            <FormControl className={classes.formControlInput}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Service Charge Type
                </Typography>
                <Select
                    disableUnderline
                    variant = 'outlined'
                    className={classes.inputField}
                    IconComponent = {ExpandMore}
                    value = {details.service_charge_type}
                    value = {'Billing'}
                    onChange={(e) => handleTotalChange(
                        e.target.value, 
                        'service_charge_type'
                    )}
                    inputProps={{
                        name: 'service_charge_type',
                    }}
                >
                    <MenuItem value={'Billing'}>BILLING</MenuItem>
                </Select>
            </FormControl>
            <FormControl className={classes.formControlInput}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Net Amount (INR)
                </Typography>
                <OutlinedInput
                    className={classes.inputFieldDisabled}
                    value = {details.actual_amount || 0}
                    onChange={(e) => handleTotalChange(
                        e.target.value, 
                        'actual_amount'
                    )}
                    type = {'number'}
                    id='actual_amount'
                    error = {false}
                    disabled = {true}
                    inputProps = {{
                        required: true,
                        maxLength : 20,
                        min : 0
                    }}
                />
            </FormControl>
            <FormControl className={classes.formControlInput}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Service Charge Percent (%)
                </Typography>
                <OutlinedInput
                    className={classes.inputField}
                    value = {details.service_charge_percent}
                    onChange={(e) => handleTotalChange(
                        e.target.value, 
                        'service_charge_percent'
                    )}
                    type = {'number'}
                    id='service_charge_percent'
                    error = {false}
                    inputProps = {{
                        required: true,
                        maxLength : 20,
                        min : 0
                    }}
                />
            </FormControl>
            <FormControl className={classes.formControlInput}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Service Charge Amount (INR)
                </Typography>
                <OutlinedInput
                    className={classes.inputFieldDisabled}
                    value = {details.service_charge_amount}
                    onChange={(e) => handleTotalChange(
                        e.target.value, 
                        'service_charge_amount'
                    )}
                    type = {'number'}
                    id='service_charge_amount'
                    error = {false}
                    disabled = {true}
                    inputProps = {{
                        required: true,
                        maxLength : 20,
                        min : 0
                    }}
                />
            </FormControl>
            <FormControl className={classes.formControlInput}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Amount to request (INR)
                </Typography>
                <OutlinedInput
                    // className={classes.inputField}
                    className={classes.inputFieldDisabled}
                    value = {details.requested_amount}
                    onChange={(e) => handleTotalChange(
                        e.target.value, 
                        'requested_amount'
                    )}
                    type = {'number'}
                    disabled
                    id='requested_amount'
                    error = {false}
                    inputProps = {{
                        required: true,
                        maxLength : 20,
                        min : 0
                    }}
                />
            </FormControl>
            <FormControl className={classes.formControlInput}>
                <Typography variant='subtitle2'
                    className={classes.label}
                >
                    Description
                </Typography>
                <OutlinedInput
                    className={classes.inputField}
                    value = {details.description}
                    onChange={(e) => handleTotalChange(
                        e.target.value, 
                        'description'
                    )}
                    type = {'text'}
                    id='description'
                    error = {false}
                    inputProps = {{
                        required: true,
                        maxLength : 45,
                    }}
                />
            </FormControl>
        </Grid>
    )
}