import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    containerBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '80%',
        padding: `${theme.spacer * 3}px`
    },
    titleSection: {
        width: '80%', 
        textAlign: 'left'
    },
    icon : {
        fontSize: '28px',
        margin: `0px ${theme.spacer * 1}px 0px 0px`,
    },
    modalContent : {
        fontSize: `16px`
    },
    modalTitleContent : {
        display: 'flex',
        flexDirection: 'row',
        marginTop: `-${theme.spacer * 3}px`
    },
    dialogPaper : {
        height: '40vh',
        width: '40vw'
    },
    actionButtons: {
        display: 'flex',
        flexDirection: 'row',
    },
    warning: {
        color: theme.palette.primary.main
    },
    error: {
        color: theme.color.red
    }
}));

export default useStyles;