import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import { authActions } from '../../actions/authentication';
import { Confirmation } from '../';
import { utils } from '../../utils';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import useStyles from './style';
import menuList from './menu';

export default function SideBar(props){
    const classes = useStyles();
    const [confirm, setConfirm] = useState(false);
    const [selected, setSelected] = useState('');
    const {
        setSecondMenu = () => null,
        setExpand = () => null,
        expand = false,
        dispatch,
        history
    } = props;

    let menuClass = expand ? classes.typographyMenu : classes.iconMenus;
    let path = window.location.pathname;
    let role = utils.getRole() || '';

    useEffect(() => {
        let slct = '';
        menuList.dynamic.map(e => {
            if(e.path === path)
                slct = e.key;
            else if(e.childComponent.length > 0)
                e.childComponent.map(f => { 
                    if(f.path === path) slct = e.key; 
                    else return null; 
                })    
        })
        setSelected(slct);
    },[path]);

    const handleOnClick = (row) => {
        if(row.key === 'logout'){
            setConfirm(true);
        }else if(row.key === 'collapse'){
            if(!expand) {
                setSecondMenu({ show: false, key: {} });
            }
            setExpand(expand ? false : true);
        }else {
            if(row.childComponent.length > 0){
                setSelected(row.key);
                setSecondMenu({ show: true, key: row });
                setExpand(false);
            }else if(row.path){
                setSelected(row.key);
                setSecondMenu({show: false, key: {}});
                history.push(row.path);
            }
        }
    }

    const logout = () => {
        dispatch(authActions.logout());
    }

    return (
        <Box 
            className = {`${classes.sideBar} no-scrollbar`} 
            style = {{width: expand ? 184 : 80}}
        >
            <Box className={classes.dynamicMenu}>
                {
                    menuList.dynamic.map((row, index) => {
                        let Icon = row.Icon;
                        if(
                            row.access.length === 0 ||
                            row.access.includes(role)
                        ){
                            if(!expand)
                                return (
                                    <Tippy 
                                        content = {row.label} 
                                        placement = "right"
                                    >
                                        <IconButton 
                                            color='primary'
                                            className={
                                                row.key !== selected ? menuClass : 
                                                `${menuClass} ${classes.selectedIconMenus}`
                                            }
                                            onClick = {() => handleOnClick(row)}
                                        >
                                            {   row.Icon && 
                                                <Icon style={{
                                                        width: '24px',
                                                        height: '24px'
                                                    }}
                                                /> 
                                            }
                                        </IconButton>
                                    </Tippy>
                                );
                            else
                                return (  
                                    <IconButton 
                                        color='primary'
                                        className={
                                            row.key !== selected ? menuClass : 
                                            `${menuClass} ${classes.selectedIconMenus}`
                                        }
                                        onClick = {() => handleOnClick(row)}
                                    >
                                        {   row.Icon && 
                                            <Icon style={{
                                                    width: '24px',
                                                    height: '24px'
                                                }}
                                            /> 
                                        }
                                        <Typography variant = 'p' 
                                            className={classes.menuDesc}
                                        >
                                            { row.label }
                                        </Typography>
                                    </IconButton>
                                )
                        }else
                            return <div></div>;
                    })
                }
            </Box>  
            <Box className={classes.staticMenu}>
            {
                    menuList.static.map(row => {
                        let Icon = row.Icon;
                        if(!expand)
                            return (
                                <Tippy 
                                    content = {row.label} 
                                    placement = "right"
                                >
                                    <IconButton 
                                        color='primary'
                                        className={menuClass}
                                        onClick = {() => handleOnClick(row)}
                                    >
                                        { row.Icon && <Icon /> }
                                    </IconButton>
                                </Tippy>
                            );
                        else
                            return (
                                <IconButton 
                                    color='primary'
                                    className={menuClass}
                                    onClick = {() => handleOnClick(row)}
                                >
                                    { row.Icon && <Icon /> }
                                    <Typography variant = 'p' 
                                        className={classes.menuDesc}
                                    >
                                        { row.label }
                                    </Typography>
                                </IconButton>
                            );
                    })
                }
            </Box>
            {
                confirm &&
                <Confirmation
                    onClose = {() => setConfirm(false)}
                    onSubmit = {logout}
                />
            }
        </Box>
    )
}