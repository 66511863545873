import attendanceConstants from '../constants/attendance';

const initialState = {
    status: '',
    noOfRecords: 0,
    details: [],
    isLoggedToday: false,
}

function attendance(state = initialState, action) {
    switch(action.type) {
        case attendanceConstants.POST_SUCCESS : 
            return {
                ...state,   
                status: 'POST_SUCCESS',
            }
        case attendanceConstants.POST_ERROR:
            return {
                ...state,
                status: 'POST_ERROR'
            }
        case attendanceConstants.POST_REQUEST:
            return {
                ...state,
                status: 'POST_REQUEST'
            }
        case attendanceConstants.GET_ALL_SUCCESS : 
            return {
                ...state,   
                status: 'GETALL_SUCCESS',
                details: action.payload.data
            }
        case attendanceConstants.GET_ALL_ERROR:
            return {
                ...state,
                status: 'GETALL_ERROR'
            }
        case attendanceConstants.GET_ALL_REQUEST:
            return {
                ...state,
                status: 'GETALL_REQUEST'
            }
        case attendanceConstants.GET_SUCCESS : 
            return {
                ...state,   
                status: 'GET_SUCCESS',
                isLoggedToday: action.payload.data?.is_logged || false
            }
        case attendanceConstants.GET_ERROR:
            return {
                ...state,
                status: 'GET_ERROR'
            }
        case attendanceConstants.GET_REQUEST:
            return {
                ...state,
                status: 'GET_REQUEST'
            }
        case attendanceConstants.RECORDS_SUCCESS : 
            return {
                ...state,   
                status: 'RECORDS_SUCCESS',
                noOfRecords: action.payload.data.total
            }
        case attendanceConstants.RECORDS_ERROR:
            return {
                ...state,
                status: 'RECORDS_ERROR'
            }
        case attendanceConstants.RECORDS_REQUEST:
            return {
                ...state,
                status: 'RECORDS_REQUEST'
            }
        default: return state;
    }
}

export default attendance;