const userConstants = {
    ADD_REQUEST: 'USER_ADD_REQUEST',
    ADD_ERROR: 'USER_ADD_ERROR',
    ADD_SUCCESS: 'USER_ADD_SUCCESS',

    GET_REQUEST : 'USER_GET_REQUEST',
    GET_SUCCESS : 'USER_GET_SUCCESS',
    GET_ERROR : 'USER_GET_ERROR',

    GET_EMP_REQUEST : 'USER_GET_EMPLOYEE_REQUEST',
    GET_EMP_SUCCESS : 'USER_GET_EMPLOYEE_SUCCESS',
    GET_EMP_ERROR : 'USER_GET_EMPLOYEE_ERROR',

    UPDATE_REQUEST : 'USER_UPDATE_REQUEST',
    UPDATE_SUCCESS : 'USER_UPDATE_SUCCESS',
    UPDATE_ERROR : 'USER_UPDATE_ERROR',

    ACTIVATE_REQUEST : 'USER_ACTIVATION_REQUEST',
    ACTIVATE_SUCCESS : 'USER_ACTIVATION_SUCCESS',
    ACTIVATE_ERROR : 'USER_ACTIVATION_ERROR',

    DEACTIVATE_REQUEST : 'USER_DEACTIVATION_REQUEST',
    DEACTIVATE_SUCCESS : 'USER_DEACTIVATION_SUCCESS',
    DEACTIVATE_ERROR : 'USER_DEACTIVATION_ERROR',

    RESET_REQUEST: 'USER_RESET_PASSWORD_REQUEST',
    RESET_ERROR: 'USER_RESET_PASSWORD_ERROR',
    RESET_SUCCESS: 'USER_RESET_PASSWORD_SUCCESS',

    TRANS_BRANCH_REQUEST: 'USER_TRANSFER_BRANCH_REQUEST',
    TRANS_BRANCH_ERROR: 'USER_TRANSFER_BRANCH_ERROR',
    TRANS_BRANCH_SUCCESS: 'USER_TRANSFER_BRANCH_SUCCESS',
}

export default userConstants;