import React from 'react';
import { ModalDialog } from "..";
import useStyles from './style';
import {
    Grid, Box,
    Typography
} from '@material-ui/core';

export default function ViewImage(props) {
    const classes = useStyles();
    const {
        show = false,
        setShow = () => null,
        image = "",
        imageTitle = "",
        subTitle = ""
    } = props;


    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        { imageTitle || 'Image' }
                    </Typography>
                    { 
                        subTitle &&
                        <Typography 
                            variant = 'p' 
                            className = {`${classes.subTitle}`}
                        >
                            { subTitle || "" }
                        </Typography>
                    }
                </Box>
                <Grid 
                    container 
                    direction='row' 
                    className = {classes.mainGrid}
                >
                    <Grid 
                        item xs={12} sm={8} 
                        md={8} lg={8} xl={8}
                        className = {classes.indGrid}
                    >
                        <img
                            src = {image || ""}
                            alt = {imageTitle || "Image"}
                            height = "100%"
                            wdith = "100%"
                        />
                    </Grid>       
                </Grid>
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {show}
            onClose = {() => setShow({flag: false, value: {}})}
            dialogTitle = ''
            dialogContent = {dialogContent()}
            maxWidth = {'md'}
            classes = {{ paper: classes.dialogPaper}}
            dialogFooter = {[]}
        />
    )

}