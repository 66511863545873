import authConstants from '../constants/authentication';

const initialState = {
    status: '',
    error: '',
    accessToken : '',
    loggedIn: false
}

function auth(state = initialState, action) {
    switch(action.type) {
        case authConstants.LOGIN_SUCCESS : 
            return {
                ...state,   
                accessToken: action.payload.data.token,
                error: '',
                status: 'LOGIN_SUCCESS',
                loggedIn: true
            }
        case authConstants.LOGIN_REQUEST: 
            return {
                ...state,   
                status: 'LOGIN_REQUEST'
            }
        case authConstants.LOGIN_ERROR: 
            return {
                ...state,   
                status: 'LOGIN_ERROR',
                error : action.payload.message
            }
        case authConstants.VERIFICATION_SUCCESS : 
            return {
                ...state,   
                accessToken: action.payload.data.token,
                error: '',
                status : 'VERIFICATION_SUCCESS',
                loggedIn: true
            }
        case authConstants.VERIFICATION_REQUEST: 
            return {
                ...state,   
                status: 'VERIFICATION_REQUEST'
            }
        case authConstants.VERIFICATION_ERROR: 
            return {
                ...state,   
                status: 'VERIFICATION_ERROR',
                error : action.payload.message
            }
        case authConstants.LOGOUT:
            return {
                ...state,
                accessToken: '',
                status: '',
                loggedIn: false,
                error: '',
            }
        case authConstants.VALIDATE_REQUEST:
            return {
                ...state,
                status: "VALIDATE_REQUEST"
            }
        case authConstants.VALIDATE_SUCCESS:
            return {
                ...state,
                status: "VALIDATE_SUCCESS"
            }
        case authConstants.VALIDATE_ERROR:
            return {
                ...state,
                status: "VALIDATE_ERROR"
            }
        default: return state;
    }
}

export default auth;