import React, { Component } from 'react';
import { connect } from 'react-redux';
import { materialActions } from '../../actions/material';
import { uploadActions } from '../../actions/fileupload';
import { orderActions } from '../../actions/orders';
import { userActions } from '../../actions/user';
import notyf from '../../utils/notification';
import orderPayload from './payload.json';
import tHeader from './tableHeader.json';
import PlaceOrderLayout from './layout';

class PlaceOrder extends Component{
    constructor(props) {
        super(props);
        this.state = {
            tableHeaders: [ ...tHeader ],
            summary: { ...orderPayload.payload },
            pledge: { ...orderPayload.pledge_items },
            tableData : []
        }
    }

    componentDidMount() {
        const { dispatch, user, match } = this.props;
        dispatch(materialActions.getMaterialWithPrice());
        dispatch(uploadActions.clearState());
        let u = '';
        if(user.userDetails?.length > 0)
            u = user.userDetails?.[0]?.users?.mobile_number;

        if( u !== "+" + match.params?.phone){
            let payl = { mobile_number: `+${match.params?.phone}` }
            dispatch(userActions.get(payl));
        }
    }

    componentDidUpdate(prevProps){
        const { material, fileupload, user, history, orders } = this.props;
        const { tableHeaders, tableData, pledge } = this.state;
        let propsChanged = material.status !== prevProps.material.status;
        let uploadPropsChanged = fileupload.status !== prevProps.fileupload.status;
        let userPropsChanged = user.status !== prevProps.user.status;
        let orderPropsChanged = orders.status !== prevProps.orders.status;

        if(propsChanged){
            if(
                material.status === 'GET_ALL_SUCCESS' && 
                material.details.length > 0
            ){
                let t = tableHeaders.map(e => {
                    if(e.id === 'material_id'){ 
                        let dropDownItems = material.details.map(
                            f => { return {label: f.name, value: f.material_id} }
                        );
                        return { ...e, options: dropDownItems};
                    }else 
                        return { ...e };
                });
                this.setState({ tableHeaders : t });
            }
        }

        if(uploadPropsChanged){
            let u = fileupload.uploads?.["item_image"] || {};
            let uKeys = Object.keys(u);
            let t  = [ ...tableData ];
            uKeys.length > 0 && uKeys.map( e => {
                let st = '';
                if(u[e].status === 'R') st = 'uploading';
                else if(u[e].status === 'S') st = u[e].name;
                else if(u[e].status === 'E') st = 'failed';

                t[e]["item_image"] = st;
            })
            this.setState({ tableData: t });

            let p_i = fileupload.uploads?.['pledge_items'] || {};
            if(p_i?.["0"]){
                let st = '';
                if(p_i["0"].status === 'R') st = 'uploading';
                else if(p_i["0"].status === 'S') st = p_i["0"].name;
                else if(p_i['0'].status === 'E') st = 'failed';

                this.setState({ 
                    pledge: { 
                        ...pledge, 
                        pledge_doc_image: st
                    }
                });
            }
        }

        if(userPropsChanged){
            if(
                user.status === 'GET_USER_SUCCESS' && 
                user.userDetails?.length > 0 &&
                !user.userDetails?.[0].users
            ){
                notyf.open({ 
                    type: 'error', 
                    message: 'Customer detail not found'
                });
                history.push('/search');
            }
        }

        if(orderPropsChanged){
            if(orders.status === 'ADD_SUCCESS'){
                history.push('/orders');
            }
        }
    }

    isPayloadValid = () => {
        return false;
    }

    handleUploadFile = (files, index, target = 'item_image') => {
        const { dispatch } = this.props;
        dispatch(uploadActions.upload(files, target , index));
    }

    handleSubmit = () => {
        const { dispatch, profile, user } = this.props;
        const { summary, tableData, pledge } = this.state;
        // if(isPayloadValid()){
            let payl = {
                ...summary,
                customer_id: user.userDetails?.[0]?.users?.id,
                order_items: [ ...tableData ],
                pledge_items: summary.order_type === 'RELEASE' ? pledge : null
            }
        // }
        dispatch(orderActions.place(payl));
    }

    render() {
        return (
            <PlaceOrderLayout
                {...this.props}
                userStatus = {this.props.user.status}
                userDetails = {this.props.user.userDetails}
                tableData = { this.state.tableData}
                details = {this.state.summary}
                pledge = {this.state.pledge}
                handleSubmit = {this.handleSubmit}
                updatePledge = {(e) => this.setState({ pledge: e })}
                setTableData = {(e) => this.setState({tableData : e })}
                updateSummary = {(e) => this.setState({ summary: e })}
                materialDetails = {this.props.material.details || []}
                tableHeaders = { this.state.tableHeaders}
                handleUploadFile = {this.handleUploadFile}
            />
        );
    }
}

function mapStateToProps(state) {
    const { profile, material, fileupload, user, orders, notifications } = state;
    
    return {
        profile,
        material,
        fileupload, 
        user,
        orders,
        notifications
    }
}

export default connect(mapStateToProps)(PlaceOrder);