import React, { Component } from 'react';
import { connect } from 'react-redux';
import { materialActions } from '../../actions/material';
import MaterialLayout from './layout';

class Material extends Component{
    constructor(props) {
        super(props);
        this.state = {
            showUpdate : { flag : false, id: {} },
            addPayload : { name: ''},
            updatePayload : {},
            error : false,
            showAdd : false,
        }
    }

    componentDidMount() {
       this.getDetails();
    }

    getDetails = () => {
        const { dispatch } = this.props;
        dispatch(materialActions.getMaterialWithPrice());
    }

    componentDidUpdate(prevProps){

        const { material, dispatch } = this.props;
        let propsChanged = material.status !== prevProps.material.status;
        if(propsChanged){
            if(material.status === 'ADD_SUCCESS'){
                this.setState({
                    addPayload: { name: ''},
                    showAdd: false
                });
                this.getDetails();
            }
            if(material.status === 'UPDATE_SUCCESS'){
                this.setState({
                    showUpdate: { flag: false, id: {}},
                    updatePayload: {}
                });
                this.getDetails();
            }
        }
    }

    handleAddSubmit = () => {
        const { dispatch } = this.props;
        const { addPayload } = this.state;

        if(!addPayload.name){
            this.setState({ error: true });
        }else {
            let payl = { ...addPayload };
            dispatch(materialActions.add(payl));
        }
    }

    handleUpdateSubmit = () => {
        const { dispatch } = this.props;
        const { updatePayload } = this.state;


        if(
            !updatePayload.material_price || 
            updatePayload.cgst === '' ||
            updatePayload.sgst === ''
        )
            this.setState({ error : true });
        else 
            dispatch(materialActions.update(updatePayload));

    }

    render() {
        return (
            <MaterialLayout
                {...this.props}
                error = {this.state.error}
                showAdd = {this.state.showAdd}
                getDetails = {this.getDetails}
                addPayload = {this.state.addPayload}
                showUpdate = {this.state.showUpdate}
                details = {this.props.material.details}
                handleAddSubmit = {this.handleAddSubmit}
                updatePayload = {this.state.updatePayload}
                handleUpdateSubmit = {this.handleUpdateSubmit}
                status = {this.props.material.status || ''}
                updateState = {(e) => this.setState(e)}
            />
        );
    }
}

function mapStateToProps(state) {
    const { profile, material, notifications } = state;
    
    return {
        profile,
        material,
        notifications
    }
}

export default connect(mapStateToProps)(Material);