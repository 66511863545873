import React, { Component } from 'react';
import { orderActions } from '../../actions/orders';
import { branchActions } from '../../actions/branch';
import OrdersHistoryLayout from './layout';
import { connect } from 'react-redux';

class OrdersHistory extends Component{
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            limit: 10
        }
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(branchActions.getAll());
        this.getDetails();
    }

    getDetails = (page = 0, limit = 10, filter = {}) => {
        const { dispatch } = this.props;
        let offset = page * limit;
        dispatch(orderActions.getAll(offset, limit, filter));
        dispatch(orderActions.getTotalRecords(filter));
    }

    resetPagination = () => {
        this.setState({ page: 0, limit: 10 });
    }

    handleCancelOrderSubmit = (payload) => {
        const { dispatch } = this.props;
        dispatch(orderActions.cancel(payload));
    }

    handleConfirmOrderSubmit = (payload) => {
        const { dispatch } = this.props;
        dispatch(orderActions.confirm(payload));
    }
    handleDropOrderSubmit = (payload) => {
        const { dispatch } = this.props;
        dispatch(orderActions.drop(payload));
    }

    handleReleaseActionSubmit = (payload) => {
        const { dispatch } = this.props;
        dispatch(orderActions.release(payload));
    }

    componentDidUpdate(prevProps){
        const { orders } = this.props;
        let isOrderPropsChanged = orders.status !== prevProps.orders.status;

        if(isOrderPropsChanged){
            if(orders.status === 'CONFIRM_SUCCESS'){
                this.getDetails();
                this.resetPagination();
            }
            if(orders.status === 'CANCEL_SUCCESS'){
                this.getDetails();
                this.resetPagination();
            }
            if(orders.status === 'DROP_SUCCESS'){
                this.getDetails();
                this.resetPagination();
            }
            if(orders.status === 'RELEASE_SUCCESS'){
                this.getDetails();
                this.resetPagination();
            }
        }
    }

    render() {
        return (
            <OrdersHistoryLayout
                {...this.props}
                {...this.state}
                getDetails = {this.getDetails}
                status = {this.props.orders.status}
                branches = {this.props.branch.branchDetails}
                noOfRecords = {this.props.orders.noOfRecords}
                orderDetails = {this.props.orders.orderDetails}
                setPage = {(val) => this.setState({ page: val})}
                setLimit = {(val) => this.setState({ limit: val})}
                handleDropOrderSubmit = {this.handleDropOrderSubmit}
                handleConfirmOrderSubmit = {this.handleConfirmOrderSubmit}
                handleCancelOrderSubmit = {this.handleCancelOrderSubmit}
                handleReleaseActionSubmit = {this.handleReleaseActionSubmit}
            />
        );
    }
}

function mapStateToProps(state) {
    const { profile, orders, notifications, branch } = state;
    
    return {
        profile,
        orders,
        branch,
        notifications
    }
}

export default connect(mapStateToProps)(OrdersHistory);