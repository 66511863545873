import React from 'react';
import { ModalDialog, Button } from "../../../components";
import useStyles from '../style';
import {
    Grid, Box,
    Typography
} from '@material-ui/core';

export default function PendingAction(props) {
    const classes = useStyles();
    const {
        details = {},
        showDetails = false,
        setDetails = () => null
    } = props;

    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        Choose Action
                    </Typography>
                </Box>
                <Grid 
                    container 
                    direction='row' 
                    className = {classes.fundDetailsMainGrid}
                >
                    <Grid 
                        item xs={12} sm={6} 
                        md={6} lg={6} xl={6}
                        className = {classes.actionGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Requested from Branch
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.requested_branch_name || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Requested type
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.request_transaction_type || 'NA'}
                        </Typography>

                        <Button
                            text = "Reject"
                            variant = "primary"
                            style = {{
                                padding: '8px 16px',
                                marginTop: '60px', 
                                backgroundColor: '#db252e',
                                width: '200px' 
                            }}
                            onClick = {() => setDetails('REJECTED', details) }
                        />

                    </Grid>
                    <Grid 
                        item xs={12} sm={6} 
                        md={6} lg={6} xl={6}
                        className = {classes.actionGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Requested Amount
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.request_amount || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Comment
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.request_description || 'NA'}
                        </Typography>

                        <Button
                            text = "Approve"
                            variant = "primary"
                            style = {{
                                padding: '8px 16px',
                                marginTop: '60px', 
                                width: '200px' 
                            }}
                            onClick = {() => setDetails('APPROVED', details) }
                        />
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {showDetails}
            onClose = {() => setDetails('', details)}
            dialogTitle = ''
            dialogContent = {dialogContent()}
            maxWidth = {'md'}
            classes = {{ paper: classes.pendingDialogPaper}}
            dialogFooter = {[]}
        />
    )

}