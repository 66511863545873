import React from 'react';
import { Button, ModalDialog, } from "../../../components";
import useStyles from '../style';
import {
    fundRequestConstants as frc
} from '../../../constants';
import {
    Grid, Box,
    FormControl,
    OutlinedInput,
    Typography,
    Select,
    MenuItem
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

export default function NewRequest(props) {
    const classes = useStyles();
    const {
        showNewRequest = false,
        setNewRequest = () => null,
        handleChange = () => null,
        handleSubmit = () => null,
        branches = [],
        current_branch = 0,
        payload = {},
        status = '',
        error = false
    } = props;

    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        {frc.title}
                    </Typography>
                    <Typography variant = 'p' className={classes.subTitle}>
                        {frc.subTitle}
                    </Typography>
                </Box>
                <Grid 
                    container 
                    direction='row' 
                    className = {classes.mainGrid}
                >
                    <Grid 
                        item xs={12} sm={12} 
                        md={12} lg={12} xl={12}
                        className = {classes.indGrid}
                    >
                        <FormControl className={classes.inputField}>
                            <Typography variant='subtitle2' className={classes.label}>
                                Amount
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleChange(
                                    'requested_amount', parseFloat(e.target.value)
                                )}
                                className={classes.inputField}
                                value = {payload['requested_amount']}
                                error= {error}
                                placeholder={'Amount'}
                                type = {'number'}
                                id='amount'
                                inputProps = {{
                                    required: true,
                                    maxLength : 45,
                                    min : 0
                                }}
                            />
                        </FormControl>
                        <small className="red" style={{marginTop: 4}}>
                                {error && !payload['requested_amount'] && frc.invalidamount}
                        </small>
                    {/* </Grid>
                    <Grid 
                        item xs={12} sm={12} 
                        md={12} lg={12} xl={12}
                        className = {classes.indGrid}
                    > */}
                        <FormControl variant='outlined' className={classes.inputField}>
                            <Typography variant='subtitle2'
                                className={classes.label}
                            >
                                From Branch
                            </Typography>
                            <Select
                                className={classes.inputField}
                                IconComponent = {ExpandMore}
                                value = {payload['branch_id']}
                                onChange={(e) => handleChange('branch_id', e.target.value)}
                                inputProps={{
                                    name: 'branch_id',
                                }}
                            >
                                {
                                    branches &&
                                    branches.length &&
                                    branches.map(e => {
                                        if(e.id !== current_branch)
                                            return(
                                                <MenuItem value={e.id}>
                                                    {e.branch_name}
                                                </MenuItem>
                                            );
                                        else
                                            return <div></div>
                                    })
                                }
                            </Select>
                        </FormControl>
                        <small className="red" style={{marginTop: 4}}>
                                {error && !payload['branch_id'] && frc.invalidBranch}
                        </small>
                        <FormControl variant='outlined' className={classes.inputField}>
                            <Typography variant='subtitle2'
                                className={classes.label}
                            >
                                Transaction type
                            </Typography>
                            <Select
                                className={classes.inputField}
                                IconComponent = {ExpandMore}
                                value = {payload['transaction_type']}
                                onChange={(e) => handleChange('transaction_type', e.target.value)}
                                inputProps={{
                                    name: 'transactiontype',
                                }}
                            >
                                <MenuItem value={'CASH'}>Cash</MenuItem>
                                <MenuItem value={'RTGS'}>RTGS</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl variant='outlined' className={classes.inputField}>
                            <Typography variant='subtitle2'
                                className={classes.label}
                            >
                                Description (optional)
                            </Typography>
                            <OutlinedInput
                                onChange={(e) => handleChange('description' , e.target.value)}
                                className={classes.inputField}
                                placeholder={'Description'}
                                type = {'text'}
                                id='description'
                                multiline
                                inputProps = {{
                                    required: true,
                                    maxLength : 200
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Button 
                    onClick={handleSubmit} 
                    loading = {status === 'POST_FUND_REQUEST'}
                    text='Submit' 
                    style ={{ 
                        margin : '40px 0px',
                        width: '280px'
                    }}
                />
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {showNewRequest}
            onClose = {() => setNewRequest(false)}
            dialogTitle = ''
            dialogContent = {dialogContent()}
            maxWidth = {'sm'}
            classes = {{ paper: classes.dialogPaperNewRequest}}
            dialogFooter = {[]}
        />
    )

}