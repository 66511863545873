import invoiceConstants from '../constants/invoice';

const initialState = {
    status: '',
    details: {}
}

function invoice(state = initialState, action) {
    switch(action.type) {
        case invoiceConstants.GET_REQUEST:
            return {
                ...state,
                status: 'GET_REQUEST'
            }
        case invoiceConstants.GET_SUCCESS : 
            return {
                ...state,   
                status: 'GET_SUCCESS',
                details: action.payload.data
            }
        case invoiceConstants.GET_ERROR:
            return {
                ...state,
                status: 'GET_ERROR'
            }
        default: return state;
    }
}

export default invoice;