import {httpClient} from "../utils";

export const materialService ={
    add,
    getMaterialWithPrice,
    update
}


function add(payload){
    return httpClient.callApi("POST",`/material/all/addMaterial`, payload);
}

function getMaterialWithPrice(){
    return httpClient.callApi("GET",`/material/getMaterialPrice`);
}

function update(payload){
    return httpClient.callApi("POST",`/material/all/updateMaterial`, payload);
}