import branchConstants from '../constants/branch';
import { branchService } from '../services';
import notyf from '../utils/notification';

export const branchActions = {
    // get,
    getAll,
    update,
    add,
}


function  add(payload){
    return dispatch =>{
        dispatch(request());
        branchService.add(payload).then(
            branch =>{ dispatch(success(branch)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: branchConstants.ADD_REQUEST };
    }
    function success(branch){
        notyf.open({
            type: 'success',
            message: 'Added Successfully.'
        })
        return {
            type: branchConstants.ADD_SUCCESS,
            payload: branch
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: branchConstants.ADD_ERROR,
            payload : error
        };
    }
}

function update(payload){
    return dispatch =>{
        dispatch(request());
        branchService.update(payload).then(
            branch =>{ dispatch(success(branch)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: branchConstants.UPDATE_REQUEST };
    }
    function success(branch){
        notyf.open({
            type: 'success',
            message: 'Updated Successfully.'
        })
        return {
            type: branchConstants.UPDATE_SUCCESS,
            payload: branch
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: branchConstants.UPDATE_ERROR,
            payload : error
        };
    }
}

function  getAll(){
    return dispatch =>{
        dispatch(request());
        branchService.getAll().then(
            branch =>{ dispatch(success(branch)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: branchConstants.GET_ALL_REQUEST };
    }
    function success(branch){
        return {
            type: branchConstants.GET_ALL_SUCCESS,
            payload: branch
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: branchConstants.GET_ALL_ERROR,
            payload : error
        };
    }
}