import React, { useEffect } from 'react';
import { Button, ModalDialog, } from "../../../components";
import { expenseConstants as ec } from '../../../constants';
import useStyles from '../style';
import {
    Grid, Box, FormControl, 
    OutlinedInput, Typography,
} from '@material-ui/core';


export default function CancelExpense(props) {
    const classes = useStyles();
    const {
        show = false,
        setShow = () => null,
        details = {},
        payload = {},
        status = ''
    } = props;

    useEffect(() => {
        props.handleChange( 'request_id', details.id );
    },[]);


    const handleChange = (name, value) => {
        props.handleChange(name, value);
    }

    const handleSubmit = () => {
        props.handleSubmit(payload);
    }


    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        {ec.cancelTitle}
                    </Typography>
                </Box>
                <Grid 
                    container 
                    className = {classes.mainGrid}
                >
                    <Grid 
                        item xs={12} sm={6} 
                        md={6} lg={6} xl={6}
                        className = {classes.indGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Requested from Branch
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.requested_branch_name || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Expense Amount
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.expense_amount || 'NA'}
                        </Typography>
                    </Grid>
                    <Grid 
                        item xs={12} sm={6} 
                        md={6} lg={6} xl={6}
                        className = {classes.indGrid}
                    >
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Expense type
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.expense_type || 'NA'}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.labelTitle}>
                            Description
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subTitle}>
                            {details.expense_description || 'NA'}
                        </Typography>
                    </Grid>
                </Grid>
                <FormControl className={classes.inputField} style={{marginTop: 16}}>
                    <Typography variant='subtitle2' className={classes.label}>
                        comment
                    </Typography>
                    <OutlinedInput
                        onChange={(e) => handleChange('remarks', e.target.value )}
                        className={classes.inputField}
                        value = {payload['remarks']}
                        placeholder={'comment'}
                        type = {'text'}
                        id='remarks'
                        inputProps = {{
                            required: true,
                            maxLength : 50,
                            min : 0
                        }}
                    />
                </FormControl>
                <Button 
                    onClick={handleSubmit} 
                    loading = {status === 'CANCEL_REQUEST'}
                    text='Cancel Request'
                    style ={{ 
                        margin : '40px 0px',
                        width: '280px'
                    }}
                />
                
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {show}
            onClose = {setShow}
            dialogTitle = ''
            dialogContent = {dialogContent()}
            maxWidth = {'md'}
            classes = {{ paper: classes.dialogPaper}}
            dialogFooter = {[]}
        />
    )

}