import authConstants from '../constants/authentication';

const initialState = {
    basic : {}
}

function profile(state = initialState, action) {
    switch(action.type) {
        case authConstants.VERIFICATION_SUCCESS : 
            return {
                ...state,   
                basic: action.payload.data
            }
        case authConstants.VALIDATE_SUCCESS:
            return {
                ...state,
                basic: action.payload.data
            }
        default: 
            return state
    }
}

export default profile;