import React from 'react';
import Logo from '../../assets/invoice-logo.png';
import { utils } from '../../utils'; 
import './style.css';

export default function InvoicePage(props) {
    const {
        details = {},
        isEstimate = false,
    } = props;

    let branch = details.branch_details;
    let user = details.customer;
    let address = details.customer_address?.[0];
    let employee_details = details.employee;
    let invoice = details.invoice;
    let order = details.order;
    let order_items = details.order_items;
    let pledge = details.order_pledge?.["0"] || {};

    const calcNetWeight = (item) => {
        return item.item_weight - (item.stone_wastage + item.other_wastage);
    }

    const calcActualAmount = (item) => {
        let net_weight = calcNetWeight(item);
        let actual_amount  = net_weight * item.material_price * ( item.item_purity / 100 );
        actual_amount = actual_amount + ( (item.stone_wastage * item.stone_price) || 0 )
        return  actual_amount;
    }

    const calcTotalAmount = (items) => {
        let amount = 0;
        items.map(f => {
            amount += calcActualAmount(f)
        });
        return amount;
    }

    const calcItemMargin = (item) => {
        return (item.margin * calcActualAmount(item)) / 100;
    }

    const calcTotalMarginAmount = (items, order = {}) => {
        let margin_amount = 0;
        items.map(f => {
            margin_amount += calcItemMargin(f)
        });
        if(margin_amount !== 0) {
            let tot_amt = calcTotalAmount(items);
            let serv_amt = order?.service_charge_amount || 0;
            let apprv_amt = order?.approved_amount || 0;

            margin_amount = tot_amt - ( apprv_amt + serv_amt );
        }
        return margin_amount;
    }

    const roundValue = (amount) => {
        return utils.roundValue(amount || 0);
    }

    const roundFixed = (amount) => {
        return utils.roundFixed(amount || 0);
    }

    const calculateAmountPayable = () => {
        let a_p = ( order?.approved_amount || 0 ) - ( pledge.pledge_amount || 0 );
        return roundValue(a_p);
    }

    return (
        <div className={'conatiner-div'}>
        <div className ={"container"}>
            <div className={"row"}>
                <div className={"col-xs-12 table-space"}>
                    <div className={"invoice-title header"}>

                        {/*
                            Here comes the Logo of the B2B seller 
                            To change the logo just use source file path in the "src" tag
                                and Name of the Seller in the "alt" tag
                        */}
                            <img 
                                className={"img-responsive pull-right image-style"} 
                                src={Logo} 
                                alt={"Abaya Gold buyers logo"} 
                                width={"120px"}
                            /> 
                    </div>
                    <div className={"row"}>
                        <div className={"col-xs-6"}>

                            {/*
                                Here comes the address of the WholeSale Dealer who sells the product.
                            */}
                            <div>
                                <strong className={"address-font"}>
                                    Abaya Gold Buyers pvt ltd.
                                </strong><br />
                                {branch?.door_no ? branch?.door_no + ',' : ""}
                                {branch?.street || ""} 
                                { ( branch?.door_no || branch?.street ) && <br /> }

                                {branch?.area || ""}{ branch?.area && <br /> }
                                {branch?.landmark || ""}{branch?.landmark && <br />} 

                                {branch?.city ? branch.city + ", " : ""}
                                {branch?.state ? branch?.state + ', ' : ""} 
                                {branch?.pincode ? branch?.pincode : ""}
                                { ( branch?.city || branch?.state || branch.pincode ) && <br /> }

                                E-Mail : reachus@abayagoldbuyers.com<br />
                                Customer Care : 8861 600 600<br />
                                GSTIN : {'29AAVCA0393A1ZL' || ''}
                            </div>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-xs-12"}>
                            <h2 className={"invoice text-center"}>
                                <strong>
                                    {isEstimate ? 'ESTIMATE' : 'INVOICE'}
                                </strong>
                            </h2>
                            <br />
                        </div>
                    </div>
                    <div className={"row invoice-details"}>
                        <div className={"col-xs-6"}>

                    {/*

                        Address of the customer who buys a product from the dealer comes here
                        * Address should place here by steps such as name, center name, locality,state, etc...
                        Every line of the address should end with the  " <br /> " as shown in the example below.

                    */}
                            <div>
                                    <strong>
                                        {user?.first_name || ''}{" "}{user?.last_name || ""}
                                    </strong><br />
                                    {address?.door_no  ? address?.door_no + ', ' : ''}
                                    {address?.street ? address?.street+',' : ''}
                                    {(address?.door_no || address?.street ) && <br />}
                                    {address?.area || ''}{ address?.area && <br /> }
                                    {address?.city ? address?.city + ', ' : ''}
                                    {address?.state ? address?.state + ', ' : ''}
                                    {(address?.city || address?.state) && <br />}
                                    {address?.country ? address?.country + ', ' : ''}
                                    {address?.pincode || ''}
                                    {(address?.country || address?.pincode) && <br />}
                                    Mobile: {user?.mobile_number || 'NA'}<br />
                            </div>
                        </div>
                        <div className={"col-xs-6 text-right"}>
                            <p>
                                <strong>INVOICE NO</strong><br /> 
                                {invoice?.invoice_id || ''}
                            </p>
                            <p>
                                <strong>INVOICE DATE-TIME</strong><br />
                                {utils.getLocalTime(invoice?.invoice_date || "")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"row table-space"}>
                <div className={"col-md-12"}>
                    <div className={"panel panel-default"}>
                        <div className={"panel-body"}>
                            <div className={"table-responsive"}>
                                <table className={"table"}>
                                    <thead>
                                        <tr className={"success table-font"}>
                                            <td className={"td-cnt text-center"}><strong>Item /</strong></td>
                                            <td className={"td-cnt text-center"}><strong>Gross Weight</strong></td>
                                            <td className={"td-cnt text-center"}><strong>Stone Weight</strong></td>
                                            <td className={"td-cnt text-center"}><strong>Stone Price</strong></td>
                                            <td className={"td-cnt text-center"}><strong>Other Weight</strong></td>
                                            <td className={"td-cnt text-center"}><strong>Net Weight</strong></td>
                                            <td className={"td-cnt text-center"}><strong>Price</strong></td>
                                            <td className={"td-cnt text-center"}><strong>Purity</strong></td>
                                            <td className={"td-cnt text-center"}><strong>Amount</strong></td>
                                        </tr>
                                        <tr className={"success table-font"}>
                                            <td className={"td-cnt text-center"}><strong>Article</strong></td>
                                            <td className={"td-cnt text-center"}><strong>(g)</strong></td>
                                            <td className={"td-cnt text-center"}><strong>(g)</strong></td>
                                            <td className={"td-cnt text-center"}><strong>(per g)</strong></td>
                                            <td className={"td-cnt text-center"}><strong>(g)</strong></td>
                                            <td className={"td-cnt text-center"}><strong>(g)</strong></td>
                                            <td className={"td-cnt text-center"}><strong>(per g)</strong></td>
                                            <td className={"td-cnt text-center"}><strong>(%)</strong></td>
                                            <td className={"td-cnt text-center"}><strong>(INR)</strong></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            order_items && 
                                            order_items.length &&
                                            order_items.map(e => {
                                                return (
                                                    <tr className={"table-font"}>
                                                        <td className={"td-cnt text-left"}>{e?.name?.toLowerCase() || 'NA'}</td>
                                                        <td className={"td-cnt text-center"}>{e?.item_weight || '0'}</td>
                                                        <td className={"td-cnt text-center"}>{e?.stone_wastage || '0'}</td>
                                                        <td className={"td-cnt text-center"}>{e?.stone_price || '0'}</td>
                                                        <td className={"td-cnt text-center"}>{e?.other_wastage || '0'}</td>
                                                        <td className={"td-cnt text-center"}>{roundFixed(calcNetWeight(e)) || '0'}</td>
                                                        <td className={"td-cnt text-center"}>{e?.material_price || '0'}</td>
                                                        <td className={"td-cnt text-center"}>{e?.item_purity || '0'}</td>
                                                        <td className={"td-cnt text-right"}>{roundFixed(calcActualAmount(e)) || '0.00'}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"column col-xs-12"}>
                   Note: The Totals given below are Round Off values
                   <br />
                   <br />
            </div>
            <br />
            <div className={"row main-amount-content"}>
                    <div className={"column col-xs-12"}>
                        <div className={"col-xs-9 text-right"}><strong>Gross Amount (INR):</strong></div>
                        <div className={"col-xs-3 text-right no-right-padding"}>
                            {roundValue(calcTotalAmount(order_items)) || '0.00'}
                        </div>
                    </div>
                    <div className={"column col-xs-12"}>
                        <div className={"col-xs-9 text-right"}><strong>Margin (INR):</strong></div>
                        <div className={"col-xs-3 text-right no-right-padding"}> 
                            {roundValue(calcTotalMarginAmount(order_items, order)) || '0.00'} 
                        </div>
                    </div>
                    <div className={"column col-xs-12"}>
                        <div className={"col-xs-9 text-right"}><strong>Service Charges (INR):</strong></div>
                        <div className={"col-xs-3 text-right no-right-padding"}> 
                            {roundValue(order?.service_charge_amount) || '0.00'} 
                        </div>
                    </div>
                    <div className={"column col-xs-12"}>
                        <div className={"col-xs-9 text-right"}><strong>Net Amount (INR):</strong></div>
                        <div className={"col-xs-3 text-right no-right-padding"}>
                            {roundValue(order?.approved_amount)  || '0.00'}</div>
                    </div>
                    {
                        order?.order_type === 'RELEASE' &&
                        <div className={"column col-xs-12"}>
                            <div className={"col-xs-9 text-right"}><strong>Amount Paid (Release) :</strong></div>
                            <div className={"col-xs-3 text-right no-right-padding"}>
                                {roundValue(pledge.pledge_amount) || 'NA'}
                            </div>
                        </div>
                    }
                    <div className={"column col-xs-12"}>
                        <div className={"col-xs-9 text-right"}><strong>Amount Payable (INR):</strong></div>
                        <div className={"col-xs-3 text-right no-right-padding"}>
                            {calculateAmountPayable() || '0.00'}
                            {/* {roundValue(order?.approved_amount) || '0.00'} */}
                        </div>
                    </div>
                    <div className={"column col-xs-12"}>
                        <div className={"col-xs-9 text-right"}><strong>Payment Type :</strong></div>
                        <div className={"col-xs-3 text-right no-right-padding"}>
                            {order?.payout_transaction_type || 'NA'}
                        </div>
                    </div>
            </div><br /><br />
            <div className={"column col-xs-12"}>
                <div>
                    <br />
                    <br />
                    <strong>TERMS &amp; CONDITIONS</strong>
                </div>
                <div>
                    I accept the terms and conditions that are mentioned in the attached application form.
                </div>
            </div>
            <br />
            <br />
            <div className={"column col-xs-12 signature-area"}>
                <div className={"column col-xs-6 text-center"}>
                    <strong>Authorized Signature</strong><br />
                    ( For Abaya Gold Buyers Pvt Ltd. )
                </div>
                <div className={"column col-xs-6 text-center"}>
                    <strong>Customer Signature</strong><br />
                    ( {user?.first_name || ""}{" "}{user?.last_name || ""} )
                </div>
            </div>
        </div>
    </div>
    );
}