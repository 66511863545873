import React, { useEffect, useState } from 'react';
import { Add, AutorenewOutlined, ClearAllOutlined } from '@material-ui/icons';
import UserUpdateModal from '../../components/userUpdateModal';
import { Box, Paper, Typography } from '@material-ui/core';
import ResetConfirmation from './components/reset-confirm';
import { Button, Navbar, Table } from "../../components";
import UpdateBranch from './components/update-branch';
import UserActivation from './components/activation';
import UpdateRole from './components/update-role';
import notyf from '../../utils/notification';
import appInfo from '../../app.json';
import { utils } from '../../utils';
import useStyles from './style';

let tableHeaders = [
    {
        id: "first_name",
        label : "Name",
        type: 'text',
        minWidth: '120px',
        style : {}
    },
    {
        id: "branch_name",
        label : "Branch",
        type: 'text',
        minWidth: '120px',
        style : {}
    },
    {
        id: "mobile_number",
        label : "Mobile",
        type: 'text',
        minWidth: '120px',
        style : {}
    },
    {
        id: "email",
        label : "Email",
        type: 'text',
        minWidth: '120px',
        style : {}
    },
    {
        id: "role_name",
        label : "Role",
        type: 'dropdown',
        placeHolder: "Role",
        dropdownItems: [
            {"label": "SUPER ADMIN", "value": "SUPER_ADMIN"},
            {"label": "HQ ADMIN", "value": "HQ_ADMIN"},
            {"label": "BRANCH MANAGER", "value": "BRANCH_MANAGER"},
            {"label": "BRANCH EMPLOYEE", "value": "BRANCH_EMPLOYEE"}
        ],
        minWidth: '120px',
        style : {}
    },
    {
        id: "gender",
        label : "Gender",
        type: 'dropdown',
        placeHolder: "Gender",
        dropdownItems: [
            {"label": "Male", "value": "male"},
            {"label": "Female", "value": "female"},
            {"label": "Others", "value": "others"},
        ],
        minWidth: '120px',
        style : {}
    }
];

let rowMenuOptions = [
    {key: 'edit-user' , title: 'Edit'},
    {key: 'update-role' , title: 'Update Role'},
    {key: 'update-branch' , title: 'Transfer Branch'},
    {key: 'reset-password' , title: 'Reset Password'},
    {key: 'activation' , title: 'Enable / Disable'},
];

let defaultQuery = {
    gender: '',
    role_name: '',
    first_name: '',
    email : '',
    mobile_number: '',
    branch_name: ''
}

export default function EmployeeListLayout(props){
    const classes = useStyles();
    const [filteredData, setFilteredData] = useState([]);
    const [tableColumns, setTableColumns] = useState(tableHeaders);
    const [clearInputFields, setClearInputFields] = useState(false);
    const [filterQuery, setFilterQuery] = useState({ ...defaultQuery });
    const [showConfirmReset, setShowConfirmReset] = useState(
        {flag: false, details: {}});
    const [showUpdateBranch, setShowUpdateBranch] = useState(
        {flag: false, details: {}});
    const [showUpdateRole, setShowUpdateRole] = useState(
        {flag: false, details: {}});
    const [showActivation, setShowActivation] = useState(
        {flag: false, details: {}});
    const {
        handleUserUpdateSubmit = () => null,
        handleUpdateEmpBranch = () => null,
        handleResetPassword = () => null,
        handleActivation = () => null,
        handleFileChange = () => null,
        handleUpdateRole = () => null,
        setShowUpdate = () => null,
        clearUploads = () => null,
        getDetails = () => null,
        showUpdate = {
            flag: false,
            payload: {}
        },
        fileuploads = {},
        roleStatus = '',
        branches = [],
        profile = {},
        details = [],
        status = '',
        roles = [],
        history,
    } = props;

    let currentUserRole = utils.getRole();

    useEffect(() => {
        if(roleStatus === 'U_ROLE_EMP_SUCCESS'){
            setShowUpdateRole({flag: false, details: {}});
        }
    },[roleStatus]);

    useEffect(() => {
        if(status === 'UPDATE_SUCCESS'){
            getDetails();
            clearUploads();
            setShowUpdate(false, {});
        } else if(status === 'RESET_SUCCESS') {
            setShowConfirmReset({flag: false, details: {}});
        } else if(
            status === 'ACTIVATE_SUCCESS' || 
            status === 'DEACTIVATE_SUCCESS'
        ) {
            setShowActivation({flag: false, details: {}});
        } else if(status === 'TRANS_BRANCH_SUCCESS') {
            setShowUpdateBranch({flag: false, details: {}})
        }
    }, [status]);

    useEffect(() => {
        setFilterQuery({ ...defaultQuery });
        setFilteredData([]);
        setClearInputFields(true);
    }, [details]);

    useEffect(() => {
        if(clearInputFields){
            setClearInputFields(false);
            setFilterQuery(defaultQuery);
        }
    }, [clearInputFields]);

    useEffect(() => {
        if(currentUserRole.includes('ADMIN')){
            setTableColumns([
                ...tableHeaders,
                {
                    id: "",
                    label : "",
                    type: 'more',
                    minWidth: '50px',
                    style : {}
                }
            ]);
        }
    }, [currentUserRole])


    const handleMenuDropdown = (a, k, c, row) => {
        let stars = appInfo.stars;
        let currentRole = profile.basic?.roles?.[0] || "";
        if(stars[currentRole] >= stars[row.role_name]){
            if(k.key === 'update-role') {
                setShowUpdateRole({flag: true, details: row});
            }else if(k.key === 'edit-user'){
                setShowUpdate(true, row);
            } else if(k.key === 'reset-password') {
                setShowConfirmReset({flag: true, details: row});
            } else if(k.key === 'activation') {
                setShowActivation({flag: true, details: row});
            } else if(k.key === 'update-branch') {
                setShowUpdateBranch({flag: true, details: row});
            }
        }else {
            notyf.open({
                type: 'error', 
                message: '403: Access Denied !'
            });
        }
    }

    const checkIfExist = (source, query) => {
        return source?.toLowerCase().includes(query?.toLowerCase());
    }

    const getFilteredData = (fq = {}) => {
        let fd = [ ...details ];
        let fql = Object.keys(fq);
        if(fql.length > 0){
            fql.map(j => {
                if(fq[j]){
                    fd = fd.filter(e => 
                        checkIfExist(e[j], fq[j])
                    );
                }
            })
        }

        setFilteredData(fd);
    }

    const handleFilterChange = (val, col) => {
        let fq = { 
            ...filterQuery,
            [col.id] : val
        }
        setFilterQuery(fq);
        getFilteredData(fq);
    }

    const isFilterQueryExist = () => {
        let f = filterQuery;
        return ( 
            f.gender || f.role_name || f.first_name ||
            f.email || f.mobile_number || f.branch_name 
        );
    }

    return (
        <Navbar 
            { ...props }
        >
            <Box className={classes.mainBox}>
                <Box className = {classes.tableTitleBox}>
                    <Typography variant = "h5">
                        Employee List
                    </Typography>
                    <Box className={classes.actionButtons}>
                        <Button
                            text = "Clear Filter"
                            variant = "secondary"
                            Icon = {ClearAllOutlined}
                            style = {{
                                padding: '8px 16px', 
                                marginRight: '16px' 
                            }}
                            onClick = {() => setClearInputFields(true)}
                        />
                        <Button
                            text = "Refresh"
                            variant = "secondary"
                            Icon = {AutorenewOutlined}
                            style = {{
                                padding: '8px 16px', 
                                marginRight: '16px' 
                            }}
                            onClick = {() => getDetails()}
                        />
                        <Button
                            text = "Add new"
                            variant = "primary"
                            Icon = {Add}
                            style = {{padding: '8px 16px'}}
                            onClick = {() => history.push('/onboard/employee')}
                        />
                    </Box>
                </Box>
            </Box>
            <Paper className = {classes.tableBox}>
                <Table
                    tableData = {isFilterQueryExist() ? filteredData : details}
                    handleMenuDropdown = {handleMenuDropdown}
                    loading = {status === 'GET_EMP_REQUEST'}
                    clearInputFields = {clearInputFields}
                    handleChange = { handleFilterChange }
                    rowMenuOptions = {rowMenuOptions}
                    backgroundColorKey ={'is_active'}
                    backgroundColorValue = {false}
                    tableColumns = {tableColumns}
                    onTableRowClick = {() => null}
                />
            </Paper>
            {
                showUpdateRole.flag &&
                <UpdateRole 
                    setShow = {() => setShowUpdateRole({
                        flag: false, details: {}
                    })}
                    details = {showUpdateRole.details}
                    handleSubmit = {handleUpdateRole}
                    show = {showUpdateRole.flag}
                    status = {roleStatus}
                    roles = {roles}
                />
            }
            {
                showUpdate.flag &&
                <UserUpdateModal
                    status = {status}
                    show = {showUpdate.flag}
                    fileuploads = {fileuploads}
                    payload = {showUpdate.payload}
                    setShow = {() => {
                        setShowUpdate();
                        clearUploads();
                    }}
                    handleSubmit = {handleUserUpdateSubmit}
                    handleFileChange = {handleFileChange}
                />
            }
            {
                showConfirmReset.flag &&
                <ResetConfirmation 
                    setShow = {() => setShowConfirmReset({
                        flag: false, details: {}
                    })}
                    details = {showConfirmReset.details}
                    handleSubmit = {handleResetPassword}
                    show = {showConfirmReset.flag}
                    status = {status}
                />
                            
            }
            {
                showActivation.flag &&
                <UserActivation 
                    setShow = {() => setShowActivation({
                        flag: false, details: {}
                    })}
                    details = {showActivation.details}
                    handleSubmit = {handleActivation}
                    show = {showActivation.flag}
                    status = {status}
                />
                            
            }
            {
                showUpdateBranch.flag &&
                <UpdateBranch
                    setShow = {() => setShowUpdateBranch({
                        flag: false, details: {}
                    })}
                    details = {showUpdateBranch.details}
                    handleSubmit = {handleUpdateEmpBranch}
                    show = {showUpdateBranch.flag}
                    branches = {branches}
                    status = {status}
                />
            }
        </Navbar>
    );
}