const materialConstants = {
    ADD_REQUEST: 'MATERIAL_ADD_REQUEST',
    ADD_ERROR: 'MATERIAL_ADD_ERROR',
    ADD_SUCCESS: 'MATERIAL_ADD_SUCCESS',

    UPDATE_REQUEST : 'MATERIAL_UPDATE_REQUEST',
    UPDATE_SUCCESS : 'MATERIAL_UPDATE_SUCCESS',
    UPDATE_ERROR : 'MATERIAL_UPDATE_ERROR',

    GET_ALL_REQUEST : 'MATERIAL_GET_ALL_REQUEST',
    GET_ALL_SUCCESS : 'MATERIAL_GET_ALL_SUCCESS',
    GET_ALL_ERROR : 'MATERIAL_GET_ALL_ERROR'
}

export default materialConstants;