import { assetListconstants as alc } from '../constants';
import assetConstants from '../constants/assets';
import { assetService } from '../services';
import notyf from '../utils/notification';

export const assetActions = {
    getTotalRecords,
    getTotalReqRecords,
    confirmAction,
    pendingAction,
    getAssetReq,
    getAssets,
    markSold,
    raiseReq,
    clear
}

function getAssets(id, offset = 0, limit = 10, material_id = 0){
    return dispatch =>{
        dispatch(request());
        assetService.getAssets(id, offset, limit, material_id).then(
            assets =>{ dispatch(success(assets)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: assetConstants.GET_REQUEST };
    }
    function success(assets){
        return {
            type: assetConstants.GET_SUCCESS,
            payload: assets
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: assetConstants.GET_ERROR,
            payload : error
        };
    }
}

function getTotalRecords(id, material_id = 0){
    return dispatch =>{
        dispatch(request());
        assetService.getTotalRecords(id, material_id).then(
            assets =>{ dispatch(success(assets)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: assetConstants.RECORDS_REQUEST };
    }
    function success(assets){
        return {
            type: assetConstants.RECORDS_SUCCESS,
            payload: assets
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: assetConstants.RECORDS_ERROR,
            payload : error
        };
    }
}

function getTotalReqRecords(type = '', filter = {}){
    return dispatch =>{
        dispatch(request());
        assetService.getTotalReqRecords(type, filter).then(
            assets =>{ dispatch(success(assets)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: assetConstants.REQ_RECORDS_REQUEST };
    }
    function success(assets){
        return {
            type: assetConstants.REQ_RECORDS_SUCCESS,
            payload: assets
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: assetConstants.REQ_RECORDS_ERROR,
            payload : error
        };
    }
}

function getAssetReq(type = '', offset = 0, limit = 10, filter = {}){
    return dispatch =>{
        dispatch(request());
        assetService.getAssetReq(type, offset, limit, filter).then(
            assets =>{ dispatch(success(assets)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: assetConstants.GET_REQ_REQUEST };
    }
    function success(assets){
        return {
            type: assetConstants.GET_REQ_SUCCESS,
            payload: assets
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: assetConstants.GET_REQ_ERROR,
            payload : error
        };
    }
}

function pendingAction(payload){
    return dispatch =>{
        dispatch(request());
        assetService.pendingAction(payload).then(
            assets =>{ dispatch(success(assets)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: assetConstants.P_ACTION_REQUEST };
    }
    function success(assets){
        notyf.open({ type: 'success', message: assets.message })
        return {
            type: assetConstants.P_ACTION_SUCCESS,
            payload: assets
        };
    }
    function failure(error){
        notyf.open({ type: 'error', message: error.message });
        return {
            type: assetConstants.P_ACTION_ERROR,
            payload : error
        };
    }
}

function confirmAction(payload){
    return dispatch =>{
        dispatch(request());
        assetService.confirmAction(payload).then(
            assets =>{ dispatch(success(assets)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: assetConstants.C_ACTION_REQUEST };
    }
    function success(assets){
        notyf.open({ type: 'success', message: assets.message })
        return {
            type: assetConstants.C_ACTION_SUCCESS,
            payload: assets
        };
    }
    function failure(error){
        notyf.open({ type: 'error', message: error.message });
        return {
            type: assetConstants.C_ACTION_ERROR,
            payload : error
        };
    }
}

function markSold(payload){
    return dispatch =>{
        dispatch(request());
        assetService.markSold(payload).then(
            assets =>{ dispatch(success(assets)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: assetConstants.SOLD_REQUEST };
    }
    function success(assets){
        notyf.open({ type: 'success', message: assets.message })
        return {
            type: assetConstants.SOLD_SUCCESS,
            payload: assets
        };
    }
    function failure(error){
        notyf.open({ type: 'error', message: error.message });
        return {
            type: assetConstants.SOLD_ERROR,
            payload : error
        };
    }
}

function raiseReq(payload){
    return dispatch =>{
        dispatch(request());
        assetService.raiseReq(payload).then(
            assets =>{ dispatch(success(assets)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: assetConstants.POST_REQUEST };
    }
    function success(assets){
        let isExist = assets.data?.is_request_exists;
        let msg = isExist ? alc.successWithExist : assets.message;
        notyf.open({ type: 'success', message: msg })
        return {
            type: assetConstants.POST_SUCCESS,
            payload: assets
        };
    }
    function failure(error){
        notyf.open({ type: 'error', message: error.message });
        return {
            type: assetConstants.POST_ERROR,
            payload : error
        };
    }
}

function clear() {
    return dispatch => {
        dispatch(request());
    };

    function request(){
        return { type: assetConstants.CLEAR };
    }
}