import React, { Component } from 'react';
import { branchActions } from '../../actions/branch';
import { connect } from 'react-redux';
import Forbidden from '../forbidden';
import BranchLayout from './layout';
import values from './onboard.json';
import { utils } from '../../utils';


class Branch extends Component {

    constructor() {
        super();
        this.state = {
            details : { ...values.payload},
            valid: false,
            error : { show: false, keys: {}},
            showOnboard : false,
            showUpdate : false
        }
    }

    componentDidMount() {
        this.getList();
        this.setState({ 
            error: {
                show: false, 
                keys: { ...values.error }
            }
        });
    }

    getList = () => {
        const { dispatch } = this.props;
        dispatch(branchActions.getAll()); 
    }

    validate = (detail = {}, show = false) => {
        const { details, error, address } = this.state;
        let err = { ...error.keys };
        let valid = true;
        let d = detail !== {} ? { ...detail} : { ...details };
        if(d['branch_name'] === ''){
            valid = false;
            err['branch_name'] = true;
        }else{
            err['branch_name'] = false;
        }

        if(d.door_no === ''){
            valid = false;
            err['door_no'] = true 
        }else {
            err['door_no'] = false 
        }
        if(d.street === ''){
            valid = false;
            err['street'] = true 
        }else {
            err['street'] = false
        }
        if(d.area === ''){
            valid = false;
            err['area'] = true;
        }else {
            err['area'] = false;
        }
        if(d.city === ''){
            valid = false;
            err['city'] = true;
        }else {
            err['city'] = false;
        }
        if(d.state === ''){
            valid = false;
            err['state'] = true;
        }else {
            err['state'] = false;
        }
        if(d.country === ''){
            valid = false;
            err['country'] = true;
        }else {
            err['country'] = false;
        }
        if(d.pincode === 0 || d.pincode === ''){
            valid = false;
            err['pincode'] = true;
        }else {
            err['pincode'] = false;
        }
        

        this.setState((prev) => ({
            valid,
            error: { show: show || prev.error.show, keys: err }
        }));

        return valid;
    }

    handleChange = (
        value = '', 
        parentKey = '', 
        childKey = ''
    ) => {
        let d = this.state.details;

        d = { ...d, [childKey] : value }

        this.setState({ details: d });
        this.validate(d);
    }


    handleSubmit = (task = '') => {
        const { dispatch } = this.props;
        const { details, valid } = this.state;

        if(valid && this.validate(details)){
            if(task === 'ADD'){
                dispatch(branchActions.add(details));
            }else if(task === 'UPDATE'){
                dispatch(branchActions.update(details));
            }
        }else {
            this.validate(details, true);
        }
    }


    componentDidUpdate(prevProps) {
        const { branch } = this.props;
        let isPropsChanged = branch.status !== prevProps.branch.status;

        if(isPropsChanged && branch.status ===  'ADD_SUCCESS'){
            this.setShowOnBoard(false);
            this.getList();
        }

        if(isPropsChanged && branch.status ===  'UPDATE_SUCCESS'){
            this.setShowUpdate(false);
            this.getList();
        }

    }

    setShowOnBoard = (value) => {
        this.setState({
            details: { ...values.payload },
            error: { show: false, keys: { ...values.error }},
            showOnboard: value
        })
    }

    setShowUpdate = (value, payl = {}) => {
        this.setState({
            details: value ? payl : { ...values.payload },
            error: { show: false, keys: { ...values.error }},
            showUpdate: value
        })
    }

    render() {
        let role = utils.getRole();
        if(role.includes('ADMIN'))
            return (
                <BranchLayout
                    {...this.props}
                    branchDetails = {this.props.branch.branchDetails} 
                    showOnboard = {this.state.showOnboard}
                    setShowOnboard = {this.setShowOnBoard}
                    setShowUpdate = {this.setShowUpdate}
                    showUpdate = {this.state.showUpdate}
                    handleChange = {this.handleChange}
                    handleSubmit = {this.handleSubmit}
                    status ={this.props.branch.status}
                    errorValue = {this.state.error}
                    details = {this.state.details}
                    valid = {this.state.valid}
                />
            );
        else
            return ( 
                <Forbidden 
                    profile={this.props.profile}
                    dispatch = {this.props.dispatch}
                    notifications = {this.props.notifications}
                />
            );
    }
}

function mapStateToProps(state) {
    const { profile, branch, notifications  }  = state;

    return {
        profile,
        branch,
        notifications
    };
}

export default connect(mapStateToProps)(Branch);