import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { AddBoxTwoTone, PinDropSharp } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  menuText : {
    display: 'flex',
    flexDirection: 'row' ,
    alignItems: 'center'
  }
}));

export default function DropDownMenu(props) {
  const classes = useStyles();
  const {
    menuItems = [],
    handleClick = () => null
  } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const prevOpen = React.useRef(open);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
        anchorRef.current && 
        anchorRef.current.contains(event.target)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  React.useEffect(() => {
    if (
        prevOpen.current === true && 
        open === false
    ) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
        <Box
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={classes.menuText}
        >
          {props.children}
        </Box>
        <Popper 
            open={open} 
            anchorEl={anchorRef.current} 
            role={undefined} 
            transition 
            disablePortal
        >
            {
                () => (
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList 
                            autoFocusItem={open} 
                            id="menu-list-grow" 
                            onKeyDown={handleListKeyDown}
                        >
                            {
                                menuItems && menuItems.map(
                                    val => <MenuItem 
                                            onClick={(e) => {
                                                handleClick(val);
                                                handleClose(e);
                                            }}
                                          >
                                              {val}
                                          </MenuItem>
                                )
                            }
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
                )
            }
        </Popper>
    </div>
  );
}








// import React from 'react';
// import { withStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import DraftsIcon from '@material-ui/icons/Drafts';
// import SendIcon from '@material-ui/icons/Send';

// const StyledMenu = withStyles({
//   paper: {
//     border: '1px solid #d3d4d5',
//   },
// })((props) => (
//   <Menu
//     elevation={0}
//     getContentAnchorEl={null}
//     anchorOrigin={{
//       vertical: 'bottom',
//       horizontal: 'center',
//     }}
//     transformOrigin={{
//       vertical: 'top',
//       horizontal: 'center',
//     }}
//     {...props}
//   />
// ));

// const StyledMenuItem = withStyles((theme) => ({
//   root: {
//     '&:focus': {
//       backgroundColor: theme.palette.primary.main,
//       '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
//         color: theme.palette.common.white,
//       },
//     },
//   },
// }))(MenuItem);

// export default function CustomizedMenus() {
//   const [anchorEl, setAnchorEl] = React.useState(null);

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <div>
//       <Button
//         aria-controls="customized-menu"
//         aria-haspopup="true"
//         variant="contained"
//         color="primary"
//         onClick={handleClick}
//       >
//         Open Menu
//       </Button>
//       <StyledMenu
//         id="customized-menu"
//         anchorEl={anchorEl}
//         keepMounted
//         open={Boolean(anchorEl)}
//         onClose={handleClose}
//       >
//         <StyledMenuItem>
//           <ListItemIcon>
//             <SendIcon fontSize="small" />
//           </ListItemIcon>
//           <ListItemText primary="Sent mail" />
//         </StyledMenuItem>
//         <StyledMenuItem>
//           <ListItemIcon>
//             <DraftsIcon fontSize="small" />
//           </ListItemIcon>
//           <ListItemText primary="Drafts" />
//         </StyledMenuItem>
//         <StyledMenuItem>
//           <ListItemIcon>
//             <InboxIcon fontSize="small" />
//           </ListItemIcon>
//           <ListItemText primary="Inbox" />
//         </StyledMenuItem>
//       </StyledMenu>
//     </div>
//   );
// }
