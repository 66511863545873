const roleConstants = {
    GET_ALL_REQUEST: 'GET_ALL_ROLES_REQUEST',
    GET_ALL_ERROR: 'GET_ALL_ROLES_ERROR',
    GET_ALL_SUCCESS: 'GET_ALL_ROLES_SUCCESS',

    U_ROLE_EMP_REQUEST : 'UPDATE_ROLE_FOR_EMPLOYEE_REQUEST',
    U_ROLE_EMP_SUCCESS : 'UPDATE_ROLE_FOR_EMPLOYEE_SUCCESS',
    U_ROLE_EMP_ERROR : 'UPDATE_ROLE_FOR_EMPLOYEE_ERROR',

    CLEAR_LOGS : 'CLEAR_LOGS'
}

export default roleConstants;