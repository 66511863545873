import smsConstants from '../constants/sms';
import { smsService } from '../services';
import notyf from '../utils/notification';

export const smsActions = {
    get
}

function  get(payload){
    return dispatch =>{
        dispatch(request());
        smsService.get(payload).then(
            logs =>{ dispatch(success(logs)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: smsConstants.GET_REQUEST };
    }
    function success(logs){
        return {
            type: smsConstants.GET_SUCCESS,
            payload: logs
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: smsConstants.GET_ERROR,
            payload : error
        };
    }
}