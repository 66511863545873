import React from 'react';
import { assetRequestsconstants as arc } from '../../../constants';
import {Box, Typography, Grid } from '@material-ui/core';
import { ModalDialog, Button } from "../../../components";
import useStyles from '../style';


export default function ConfirmAction(props) {
    const classes = useStyles();
    const {
        status = '',
        details = [],
        show = false,
        setShow = () => null
    } = props;



    const handleSubmit = () => {
        let ids = details.map(e => { return e.id });
        props.handleSubmit({
            asset_request_id: [ ...ids ],
        });
    }

    const dialogContent = () => {
        return (
            <Box className={classes.containerBox}>
                
                <Box className={classes.titleSection}>
                    <Typography variant = 'h5' className={classes.title}>
                        {arc.confirmActionTitle}
                    </Typography>
                    <Typography variant = 'p' className = {`${classes.subTitle}`}>
                        {arc.actionAssetsPrefix} { " " + details.length } 
                    </Typography>
                </Box>
                <Grid 
                    container direction='row' 
                    xs={12} sm={12} md={12} 
                    lg={12} xl={12}
                    className = {classes.fundDetailsMainGrid}
                    style = {{margin: '24px 0px'}}
                >
                    <Button
                        text = "Confirm"
                        variant = "primary"
                        style = {{
                            padding: '8px 16px',
                            margin: '24px 0px', 
                            width: '240px' 
                        }}
                        loading = {status === 'C_ACTION_REQUEST'}
                        onClick = {() => handleSubmit() }
                    />          
                </Grid>
            </Box>
        );
    }

    return (
        <ModalDialog
            open = {show}
            onClose = {() => setShow(false)}
            dialogTitle = ''
            dialogContent = {dialogContent()}
            maxWidth = {'sm'}
            classes = {{ paper: classes.dialogPaper}}
            dialogFooter = {[]}
        />
    )

}