import orderConstants from '../constants/orders';
import { orderService } from '../services';
import notyf from '../utils/notification';

export const orderActions = {
    getTotalRecords,
    getTransactions,
    getOrderItems,
    release,
    cancel,
    confirm,
    approve,
    getAll,
    place,
    drop,
    get,
}


function place(payload){
    return dispatch =>{
        dispatch(request());
        orderService.place(payload).then(
            order =>{ dispatch(success(order)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: orderConstants.ADD_REQUEST };
    }
    function success(order){
        notyf.open({
            type: 'success',
            message: 'Order Placed Successfully.'
        })
        return {
            type: orderConstants.ADD_SUCCESS,
            payload: order
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: orderConstants.ADD_ERROR,
            payload : error
        };
    }
}

function cancel(payload){
    return dispatch =>{
        dispatch(request());
        orderService.cancel(payload).then(
            order =>{ dispatch(success(order)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: orderConstants.CANCEL_REQUEST };
    }
    function success(order){
        notyf.open({
            type: 'success',
            message: 'Order Cancelled Successfully.'
        })
        return {
            type: orderConstants.CANCEL_SUCCESS,
            payload: order
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: orderConstants.CANCEL_ERROR,
            payload : error
        };
    }
}

function release(payload){
    return dispatch =>{
        dispatch(request());
        orderService.release(payload).then(
            order =>{ dispatch(success(order)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: orderConstants.RELEASE_REQUEST };
    }
    function success(order){
        notyf.open({
            type: 'success',
            message: 'Pledge confirmed successfully.'
        })
        return {
            type: orderConstants.RELEASE_SUCCESS,
            payload: order
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: orderConstants.RELEASE_ERROR,
            payload : error
        };
    }
}

function drop(payload){
    return dispatch =>{
        dispatch(request());
        orderService.drop(payload).then(
            order =>{ dispatch(success(order)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: orderConstants.DROP_REQUEST };
    }
    function success(order){
        notyf.open({
            type: 'success',
            message: 'Order Dropped Successfully.'
        })
        return {
            type: orderConstants.DROP_SUCCESS,
            payload: order
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: orderConstants.DROP_ERROR,
            payload : error
        };
    }
}

function approve(payload, approved = true) {
    return dispatch =>{
        dispatch(request());
        orderService.approve(payload).then(
            order =>{ dispatch(success(order)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: orderConstants.APPROVE_REQUEST };
    }
    function success(order){
        notyf.open({
            type: 'success',
            message: `Order ${approved ? 'Approved' : 'Rejected'} Successfully.`
        })
        return {
            type: orderConstants.APPROVE_SUCCESS,
            payload: order
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: orderConstants.APPROVE_ERROR,
            payload : error
        };
    }
}

function confirm(payload) {
    return dispatch =>{
        dispatch(request());
        orderService.confirm(payload).then(
            order =>{ dispatch(success(order)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: orderConstants.CONFIRM_REQUEST };
    }
    function success(order){
        notyf.open({
            type: 'success',
            message: 'Order Confirmed Successfully.'
        })
        return {
            type: orderConstants.CONFIRM_SUCCESS,
            payload: order
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: orderConstants.CONFIRM_ERROR,
            payload : error
        };
    }
}

function getAll(offset = 0, limit = 10, filter = {}){
    return dispatch =>{
        dispatch(request());
        orderService.getAll(offset, limit, filter).then(
            order =>{ dispatch(success(order)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: orderConstants.GETALL_REQUEST };
    }
    function success(order){
        return {
            type: orderConstants.GETALL_SUCCESS,
            payload: order
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: orderConstants.GETALL_ERROR,
            payload : error
        };
    }
}

function get(id){
    return dispatch =>{
        dispatch(request());
        orderService.get(id).then(
            order =>{ dispatch(success(order)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: orderConstants.GET_REQUEST };
    }
    function success(order){
        return {
            type: orderConstants.GET_SUCCESS,
            payload: order
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: orderConstants.GET_ERROR,
            payload : error
        };
    }
}

function getOrderItems(id) {
    return dispatch =>{
        dispatch(request());
        orderService.getOrderItems(id).then(
            order =>{ dispatch(success(order)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: orderConstants.GET_ITEM_REQUEST };
    }
    function success(order){
        return {
            type: orderConstants.GET_ITEM_SUCCESS,
            payload: order
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: orderConstants.GET_ITEM_ERROR,
            payload : error
        };
    }
}

function getTransactions(id) {
    return dispatch =>{
        dispatch(request());
        orderService.getTransactions(id).then(
            order =>{ dispatch(success(order)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: orderConstants.TRANSACTIONS_REQUEST };
    }
    function success(order){
        return {
            type: orderConstants.TRANSACTIONS_SUCCESS,
            payload: order
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: orderConstants.TRANSACTIONS_ERROR,
            payload : error
        };
    }
}

function getTotalRecords(filter = {}){
    return dispatch =>{
        dispatch(request());
        orderService.getTotalRecords(filter).then(
            value =>{ dispatch(success(value)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: orderConstants.RECORDS_REQUEST };
    }
    function success(value){
        return {
            type: orderConstants.RECORDS_SUCCESS,
            payload: value
        };
    }
    function failure(error){
        return {
            type: orderConstants.RECORDS_ERROR,
            payload : error
        };
    }
}