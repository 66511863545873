import React, { Component } from 'react';
import { reportActions } from '../../actions/reports';
import ReportsByBranchLayout from './layout';
import notyf from '../../utils/notification';
import { connect } from 'react-redux';
import { utils } from '../../utils';

class ReportsByBranch extends Component {
    constructor(props){
        super(props);
        this.state = {
            branch_id: 0,
            from_date: '',
            to_date: ''
        }
    }

    componentDidMount() {
        const { dispatch, location } =  this.props;
        let qs = location.search;
        if( qs &&
            utils.hasParamKey(qs, 'branch_id') &&
            utils.hasParamKey(qs, 'from_date') && 
            utils.hasParamKey(qs, 'to_date')
        ){
            let values = utils.getDataFromUrl(qs);
            if( 
                parseInt(values.branch_id) &&
                utils.isValidDate(values.from_date) &&
                utils.isValidDate(values.to_date) &&
                values.from_date <= values.to_date
            ){
                this.setState({ 
                    ...values,
                    branch_id: parseInt(values.branch_id)
                });
                dispatch(reportActions.getByBranch(values));
            }else 
                this.redirectWithError();
        }else
            this.redirectWithError();
        
    }

    redirectWithError = (enableMsg = true) => {
        const { history } = this.props;
        history.push('/reports');
        if(enableMsg)
            notyf.open({ 
                type: 'error', 
                message: 'Invalid Filter ! ' 
            });
    }

    componentWillUnmount() {
        const { dispatch } =  this.props;
        dispatch(reportActions.clear());
    }

    componentDidUpdate(prevProps){
        const { reports } = this.props;
        let isPropsChanged = prevProps.reports.status !== reports.status;

        if(isPropsChanged){
            if(reports.status === 'GET_BRANCH_ERROR'){
                this.redirectWithError(false);
            }
        }
    }


    render() {
        return (
            <ReportsByBranchLayout 
                {...this.props}
                {...this.state}
                status = {this.props.reports.status}
                details = {this.props.reports.details}
            />
        );
    }
}

function mapStateToProps(state) {
    const { profile, reports, branch, notifications }  = state;

    return {
        branch,
        profile,
        reports,
        notifications
    };
}

export default connect(mapStateToProps)(ReportsByBranch);