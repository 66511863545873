import React, { Component } from 'react';
import { smsActions } from '../../actions/sms';
import { connect } from 'react-redux';
import { utils } from '../../utils';
import SmsLogsLayout from './layout';
import Forbidden from '../forbidden';

class SmsLogs extends Component {

    handleSearch = (payload) => {
        const { dispatch } =  this.props;
        dispatch(smsActions.get(payload));
    }

    render() {
        if(utils.getRole().includes('ADMIN'))
            return (
                <SmsLogsLayout 
                    handleSearch = {this.handleSearch}
                    status = {this.props.sms.status}
                    logs = {this.props.sms.logs}
                    {...this.props}
                />
            );
        else 
            return ( 
                <Forbidden 
                    profile = {this.props.profile}
                    dispatch = {this.props.dispatch}
                    notifications = {this.props.notifications}
                />
            );
    }
}

function mapStateToProps(state) {
    const { profile, sms, notifications }  = state;

    return {
        profile,
        sms,
        notifications
    };
}

export default connect(mapStateToProps)(SmsLogs);