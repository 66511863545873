import materialConstants from '../constants/material';
import { materialService } from '../services';
import notyf from '../utils/notification';

export const materialActions = {
    getMaterialWithPrice,
    add,
    update,
}


function  add(payload){
    return dispatch =>{
        dispatch(request());
        materialService.add(payload).then(
            value =>{ dispatch(success(value)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: materialConstants.ADD_REQUEST };
    }
    function success(value){
        notyf.open({
            type: 'success',
            message: 'Material Added Successfully.'
        })
        return {
            type: materialConstants.ADD_SUCCESS,
            payload: value
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: materialConstants.ADD_ERROR,
            payload : error
        };
    }
}

function  getMaterialWithPrice(){
    return dispatch =>{
        dispatch(request());
        materialService.getMaterialWithPrice().then(
            value =>{ dispatch(success(value)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: materialConstants.GET_ALL_REQUEST };
    }
    function success(value){
        return {
            type: materialConstants.GET_ALL_SUCCESS,
            payload: value
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: materialConstants.GET_ALL_ERROR,
            payload : error
        };
    }
}

function update(payload){
    return dispatch =>{
        dispatch(request());
        materialService.update(payload).then(
            value =>{ dispatch(success(value)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: materialConstants.UPDATE_REQUEST };
    }
    function success(value){
        notyf.open({
            type: 'success',
            message: 'Updated Successfully.'
        })
        return {
            type: materialConstants.UPDATE_SUCCESS,
            payload: value
        };
    }
    function failure(error){
        notyf.open({
            type: 'error',
            message: error.message
        })
        return {
            type: materialConstants.UPDATE_ERROR,
            payload : error
        };
    }
}