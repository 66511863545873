import React, { useEffect, useState } from 'react';
import {
    Box, Avatar,
    OutlinedInput,
    FormControl,
    Paper,
    InputAdornment,
    Typography,
    // Button
} from '@material-ui/core';
import { Button } from '../../../components'; 
import Logo from '../../../assets/agc.png';
import { 
    loginConstants as lc,
    globalRegexp as gr 
} from '../../../constants';
import { 
    Brightness2, Mail
} from '@material-ui/icons';
import useStyles from '../style';


export default function ForgotPassword(props) {
    let defaultValue = {
        email : '',
        password: '',
        phone: '+91'
    }
    const classes = useStyles();
    const {
        setPage = () => null,
        status = '',
        errorMessage = ''
    } = props;
    const [values, setValues] = useState(defaultValue);
    const [error, setError] = useState({show: false, keys: []});
    const [loading, setLoading]  = useState(false);

    useEffect(() => {
    }, [status]);

    const errorStyle = (key = '') => {
        return {
            margin: `2px 0px 08px 0px`,
            color: 'red'
        }
    }

    const handleChange = (val, key = '') => {
        setValues(
            (prev) => ({
                ...prev,
                [key] : val
            })
        )
        let errKey  =   error.keys && 
                        error.keys.filter(e => e !== key);

        setError({
            show:   !(errKey.length === 0),
            keys:   [ ...errKey ]
        })
    }

    const isvalid = (value, key) => {
        if(key === 'email'){
            if(value.match(gr.email))   return true;
            else    return false;
        }

        return true;
    }

    const validateForm = () => {
        let err = [];
        
        if(
            values.email === '' || 
            !isvalid(values.email, 'email')
        )
            err.push('email')


        if(err.length === 1){
            setError({
                show: true, 
                keys: [ ...err ]
            })
        }else {
            let payload = {
                email : values.email
            } 
        }
    }

    return (
                <Paper 
                    elevation = {1} 
                    component={'div'} 
                    className={classes.paper}
                >
                    <Box className={classes.company}>
                        <Avatar src={Logo}/>
                        <Typography className={classes.companyName}>
                            Abaya Gold Buyers
                        </Typography>
                    </Box>
                    <FormControl>
                        <OutlinedInput 
                            className={classes.inputField}
                            onChange={(e) => 
                                handleChange(e.target.value, 'email')
                            }
                            placeholder={'Email'}
                            type = {'email'}
                            id='email'
                            startAdornment = {
                                <InputAdornment position='start'>
                                    <Mail edge='start' color='primary'/>
                                </InputAdornment>
                            }
                            error = {
                                error.show && 
                                error.keys.includes('email')
                            }
                            inputProps = {{
                                required: true,
                                maxLength : 45
                            }}
                        />
                        {   
                            error.show && 
                            error.keys.includes('email') &&
                            <small style = {errorStyle()}>
                                {lc.invalidEmail}
                            </small>
                        }
                    </FormControl>
                    <Button 
                        onClick={validateForm} 
                        loading = {loading}
                        text='Submit' 
                        style ={{ 
                            margin : '20px 0px',
                            width: '300px'
                        }}
                    />
                    <Typography 
                        className={classes.forgot}
                        variant = 'p'
                        onClick = {() => setPage(1)}
                    >
                        Have Credentials ? Login 
                    </Typography>
                    { status === 'LOGIN_ERROR' &&
                        <small style={{
                            color: 'red', 
                            margin: '10px 0px',
                            fontSize: '12px'
                        }}>
                            {  errorMessage }
                        </small>
                    }
                </Paper>

    );
}