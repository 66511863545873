import expenseConstants from '../constants/expense';

const initialState = {
    status: '',
    details: [],
    noOfRecords: 0
}

function expense(state = initialState, action) {
    switch(action.type) {
        case expenseConstants.ADD_SUCCESS : 
            return {
                ...state,   
                status: 'ADD_SUCCESS',
            }
        case expenseConstants.ADD_ERROR:
            return {
                ...state,
                status: 'ADD_ERROR'
            }
        case expenseConstants.ADD_REQUEST:
            return {
                ...state,
                status: 'ADD_REQUEST'
            }
        case expenseConstants.GET_ALL_SUCCESS : 
            return {
                ...state,   
                status: 'GET_ALL_SUCCESS',
                details: action.payload.data
            }
        case expenseConstants.GET_ALL_ERROR:
            return {
                ...state,
                status: 'GET_ALL_ERROR'
            }
        case expenseConstants.GET_ALL_REQUEST:
            return {
                ...state,
                status: 'GET_ALL_REQUEST'
            }
        case expenseConstants.RECORDS_SUCCESS : 
            return {
                ...state,   
                status: 'RECORDS_SUCCESS',
                noOfRecords: action.payload.data.total
            }
        case expenseConstants.RECORDS_ERROR:
            return {
                ...state,
                status: 'RECORDS_ERROR'
            }
        case expenseConstants.RECORDS_REQUEST:
            return {
                ...state,
                status: 'RECORDS_REQUEST'
            }
        case expenseConstants.UPDATE_SUCCESS :
            return {
                ...state,   
                status: 'UPDATE_SUCCESS',
            }
        case expenseConstants.UPDATE_ERROR:
            return {
                ...state,
                status: 'UPDATE_ERROR'
            }
        case expenseConstants.UPDATE_REQUEST:
            return {
                ...state,
                status: 'UPDATE_REQUEST'
            }
        case expenseConstants.CANCEL_REQUEST:
            return {
                ...state,
                status: 'CANCEL_REQUEST'
            }
        case expenseConstants.CANCEL_SUCCESS :
            return {
                ...state,   
                status: 'CANCEL_SUCCESS',
            }
        case expenseConstants.CANCEL_ERROR:
            return {
                ...state,
                status: 'CANCEL_ERROR'
            }
        default: return state;
    }
}

export default expense;