import React, { Component } from 'react';
import { fundActions } from '../../actions/fund-request';
import { branchActions } from '../../actions/branch';
import FundRequestLayout from './layout';
import { connect } from 'react-redux';
import { utils } from '../../utils';
import Forbidden from '../forbidden';

class FundRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            limit: 10
        }
    }

    componentDidMount(){
        const { profile, dispatch } = this.props;
        dispatch(branchActions.getAll());
        if(profile.basic?.branch_id){
            this.getDetails();
        }
    }

    getDetails = (page = 0, limit = 10, filter = {}) => {
        const { dispatch } = this.props;
        let offset = page * limit;
        dispatch(fundActions.getAll( 'ALL', offset, limit, filter ));
        dispatch(fundActions.getTotalRecords( 'ALL', filter ));
    }

    componentDidUpdate(prevProps) {
        const { auth } = this.props;
        let newAuthProps = auth.status !== prevProps.auth.status;
        if(newAuthProps) {
            if(auth.status === 'VALIDATE_SUCCESS'){
                this.getDetails();
            }
        }
    }

    render() {
        if(utils.getRole().includes('ADMIN'))
            return (
                <FundRequestLayout 
                    {...this.props}
                    {...this.state}
                    getDetails = {this.getDetails}
                    status = {this.props.funds.status}
                    noOfRecords = {this.props.funds.noOfRecords}
                    fundDetails = {this.props.funds.fundDetails}
                    branches = {this.props.branch.branchDetails}
                    setPage = {(val) => this.setState({ page: val})}
                    setLimit = {(val) => this.setState({ limit: val})}
                />
            );
        else if(utils.getRole().includes('BRANCH'))
            return ( 
                <Forbidden
                    profile={this.props.profile}
                    dispatch = {this.props.dispatch}
                    notifications = {this.props.notifications}
                />
            );
        else 
            return <div></div>;
    }
}

function mapStateToProps(state) {
    const { profile, funds, notifications, auth, branch }  = state;

    return {
        profile,
        funds,
        auth,
        branch,
        notifications
    };
}

export default connect(mapStateToProps)(FundRequest);