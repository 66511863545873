import {httpClient} from "../utils";

export const assetService = {
    getTotalRecords,
    getTotalReqRecords,
    confirmAction,
    pendingAction,
    getAssetReq,
    getAssets,
    markSold,
    raiseReq
}

function getFilter(type = '', filter = {}){
    return  `request_type=${type}` +
            `&requested_branch_id=${filter.requested_branch_name || 0}` +
            `&approved_branch_id=${filter.approved_branch_name || 0}`+
            `&request_on=${filter.request_on || ''}` +
            `&approved_on=${filter.approved_on || ''}` +
            `&status=${filter.status || '' }`
}

function getAssets(branch_id, offset, limit, material_id){
    return  httpClient.callApi(
        "GET",
        `/assets/getAssets`+
            `?branch_id=${branch_id}`+
                `&material_id=${material_id || 0}` +
                `&offset=${offset}`+
                `&limit=${limit}`
    );
}

function getAssetReq(type = '', offset, limit, filter = {}){
    return  httpClient.callApi(
                "GET",
                `/assets/getAssetsRequests?`+
                    getFilter(type, filter) +
                    `&offset=${offset}`+
                    `&limit=${limit}`
            );
}

function pendingAction(payload) {
    return  httpClient.callApi("POST",`/assets/approveAssetRequest`, payload);
}

function confirmAction(payload) {
    return  httpClient.callApi("POST",`/assets/confirmApproval`, payload);
}

function raiseReq(payload){
    return  httpClient.callApi("POST",`/assets/raiseAssetRequest`, payload);
}

function markSold(payload) {
    return  httpClient.callApi("POST",`/assets/soldAssets`, payload);
}

function getTotalRecords(branch_id, material_id){
    return  httpClient.callApi(
                "GET",
                `/assets/getTotalAssetRecord` +
                    `?branch_id=${branch_id}` + 
                    `&material_id=${material_id || 0}`
            );
}

function getTotalReqRecords(type = '', filter = {}){
    return  httpClient.callApi(
                "GET",
                `/assets/getTotalAssetsRequests?` + getFilter(type, filter)
            );
}