import React, { Component } from 'react';
import { connect } from 'react-redux';
import { uploadActions } from '../../actions/fileupload';
import { userActions } from '../../actions/user';
import { roleActions } from '../../actions/role';
import EmployeeListLayout from './layout';
import { utils } from '../../utils';
import Forbidden from '../forbidden';

class EmployeeList extends Component{
    constructor(props) {
        super(props);
        this.state = {
            details: [],
            showUpdate : {
                flag: false,
                payload: {}
            }
        }
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(roleActions.getAll());
        this.getDetails();
    }

    getDetails = () => {
        const { dispatch } = this.props;
        dispatch(userActions.getAllEmployees());
    }

    handleUpdateRole = (payload) => {
        const { dispatch } = this.props;
        dispatch(roleActions.updateRoleForUser(payload));
    }

    componentDidUpdate(prevProps){
        const { user, role } = this.props;
        let isUserPropsChanged = user.status !== prevProps.user.status;
        let isRolePropsChanged = role.status !== prevProps.role.status;

        if(isUserPropsChanged){
            if(user.status === 'GET_EMP_SUCCESS'){
                let details = user.empDetails.map(e => {
                    return {
                        ...e.branch_details,
                        ...e.roles,
                        ...e.user_details,
                        role_id: e.roles.id,
                        user_id: e.user_details.id
                    }
                });
                this.setState({ details });
            }

            if( user.status === 'ACTIVATE_SUCCESS' || 
                user.status === 'DEACTIVATE_SUCCESS' ||
                user.status === 'TRANS_BRANCH_SUCCESS'
            ) {
                    this.getDetails();
            }
        }

        if(isRolePropsChanged){
            if(role.status === 'U_ROLE_EMP_SUCCESS'){
                this.getDetails();
            }
        }
    }

    handleUserUpdateSubmit = (payload = {}) => {
        const { dispatch } = this.props;
        dispatch(userActions.update(payload));
    }

    handleUpdateEmpBranch = (payload = {}) => {
        const { dispatch } = this.props;
        dispatch(userActions.transferBranch(payload));
    }

    setShowUpdate = (flag = false, payload = {}) => {
        this.setState({ showUpdate: { flag, payload } });
    }

    handleFileChange = (name, files, index) => {
        const { dispatch } = this.props;
        if(files){
            dispatch(uploadActions.upload(files, name, index));
        }
    }

    handleResetPassword = (payload) => {
        const { dispatch } = this.props;
        dispatch(userActions.resetPassword(payload));
    }

    clearUploads = () => {
        const { dispatch } = this.props;
        dispatch(uploadActions.clearState());
    }

    handleActivation = (payload = {}, userState = false) => {
        const { dispatch } = this.props;
        if(userState) {
            dispatch(userActions.activateUser(payload));
        } else {
            dispatch(userActions.deactivateUser(payload));
        }
    }

    render() {
        if(utils.getRole().includes('ADMIN'))
            return (
                <EmployeeListLayout
                    {...this.props}
                    roles = {this.props.role.roles}
                    details = {this.state.details}
                    getDetails = {this.getDetails}
                    status = {this.props.user.status}
                    clearUploads = {this.clearUploads}
                    setShowUpdate = {this.setShowUpdate}
                    showUpdate = {this.state.showUpdate}
                    roleStatus = {this.props.role.status}
                    handleFileChange = {this.handleFileChange}
                    handleUpdateRole = {this.handleUpdateRole}
                    handleActivation = {this.handleActivation}
                    branches = {this.props.branch.branchDetails}
                    fileuploads = {this.props.fileupload.uploads}
                    handleResetPassword = {this.handleResetPassword}
                    handleUpdateEmpBranch = {this.handleUpdateEmpBranch}
                    handleUserUpdateSubmit = {this.handleUserUpdateSubmit}
                />
            );
        else 
            return ( 
                <Forbidden 
                    profile={this.props.profile}
                    dispatch = {this.props.dispatch}
                    notifications = {this.props.notifications}
                />
            );
    }
}

function mapStateToProps(state) {
    const { profile, user, notifications, fileupload, role, branch } = state;
    
    return {
        user, 
        role,
        branch,
        profile,
        fileupload,
        notifications
    }
}

export default connect(mapStateToProps)(EmployeeList);