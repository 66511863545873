import React, { Component } from 'react';
import { uploadActions } from '../../actions/fileupload';
import { expenseActions } from '../../actions/expense';
import { branchActions } from '../../actions/branch';
import payloadJSON from './payload.json';
import { connect } from 'react-redux';
import ExpenseLayout from './layout';


class Expense extends Component{
    constructor(props) {
        super(props);
        this.state = {
            showUpdate : { flag : false, id: {} },
            showCancel : { flag : false, id: {} },
            addPayload : { ...payloadJSON.expensePayload },
            actionPayload : { ...payloadJSON.updPayload },
            cancelPayload : { ...payloadJSON.cancelPayl },
            clearInputFields : false,
            error : false,
            showAdd : false,
            limit : 10,
            page : 0,
        }
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(branchActions.getAll());
       this.getDetails();
    }

    getDetails = (page = 0, limit = 10, filter = {}) => {
        const { dispatch } = this.props;
        let offset = page * limit;
        dispatch(expenseActions.get(offset, limit, filter));
        dispatch(expenseActions.getTotalRecords(filter));
    }

    resetPagination = () => {
        this.setState({
            page: 0,
            limit: 10
        })
    }

    componentDidUpdate(prevProps){

        const { expense, fileupload } = this.props;
        let propsChanged = expense.status !== prevProps.expense.status;
        let uploadPropsChanged = fileupload.status !== prevProps.fileupload.status;
        if(propsChanged){
            if(expense.status === 'ADD_SUCCESS'){
                this.setState({
                    addPayload: { ...payloadJSON.expensePayload },
                    showAdd: false
                });
                this.getDetails();
                this.resetPagination();
                this.setState({ clearInputFields: true });
            }
            if(expense.status === 'UPDATE_SUCCESS'){
                this.setState({
                    showUpdate: { flag: false, id: {}},
                    actionPayload: { ...payloadJSON.updPayload }
                });
                this.getDetails();
                this.resetPagination();
                this.setState({ clearInputFields: true });
            }
            if(expense.status === 'CANCEL_SUCCESS'){
                this.setState({
                    showCancel: { flag: false, id: {}},
                    cancelPayload: { ...payloadJSON.cancelPayl }
                });
                this.getDetails();
                this.resetPagination();
                this.setState({ clearInputFields: true });
            }
        }
        if(uploadPropsChanged){
            let p_i = fileupload.uploads?.['expense_bill'] || {};
            if(p_i?.["0"]){
                let st = '';
                if(p_i["0"].status === 'R') st = 'uploading';
                else if(p_i["0"].status === 'S') st = p_i["0"].name;
                else if(p_i['0'].status === 'E') st = 'failed';

                this.setState((prev) => ({ 
                    addPayload: {  ...prev.addPayload, expense_bill: st }
                }));
            }
        }
    }

    handleAddSubmit = (payload) => {
        const { dispatch } = this.props;
        dispatch(expenseActions.add(payload));
    }

    handleUpdateSubmit = (payload) => {
        const { dispatch } = this.props;
        dispatch(expenseActions.update(payload));
    }

    handleCancelSubmit = (payload) => {
        const { dispatch } = this.props;
        dispatch(expenseActions.cancel(payload));
    }

    handleFileUpload = (files, name) => {
        const { dispatch } = this.props;
        dispatch(uploadActions.upload(files, name , 0));
    }

    render() {
        return (
            <ExpenseLayout
                {...this.props}
                page = {this.state.page}
                limit = {this.state.limit}
                error = {this.state.error}
                showAdd = {this.state.showAdd}
                getDetails = {this.getDetails}
                status = {this.props.expense.status}
                addPayload = {this.state.addPayload}
                showUpdate = {this.state.showUpdate}
                showCancel = {this.state.showCancel}
                details = {this.props.expense.details}
                updateState = {(e) => this.setState(e)}
                handleAddSubmit = {this.handleAddSubmit}
                actionPayload = {this.state.actionPayload}
                cancelPayload = {this.state.cancelPayload}
                handleFileUpload = {this.handleFileUpload}
                branches = {this.props.branch.branchDetails}
                handleUpdateSubmit = {this.handleUpdateSubmit}
                handleCancelSubmit = {this.handleCancelSubmit}
                noOfRecords = {this.props.expense.noOfRecords}
                clearInputFields = {this.state.clearInputFields}
                setPage = {(val) => this.setState({ page: val})}
                setLimit = {(val) => this.setState({ limit: val})}
                setClearInputFields = {(val) => 
                    this.setState({ clearInputFields: val})
                }
            />
        );
    }
}

function mapStateToProps(state) {
    const { 
        branch,
        profile, 
        expense, 
        notifications, 
        fileupload 
    } = state;
    
    return {
        branch,
        profile,
        expense,
        fileupload,
        notifications
    }
}

export default connect(mapStateToProps)(Expense);