import React, { Component } from 'react';
import { reportActions } from '../../actions/reports';
import notyf from '../../utils/notification';
import ReportsByAdminLayout from './layout';
import { connect } from 'react-redux';
import { utils } from '../../utils';
import Forbidden from '../forbidden';

class ReportsByAdmin extends Component {
    constructor(props){
        super(props);
        this.state = {
            from_date: '',
            to_date: ''
        }
    }

    componentDidMount() {
        const { dispatch, location } =  this.props;
        let qs = location.search;
        if( qs &&
            utils.hasParamKey(qs, 'from_date') && 
            utils.hasParamKey(qs, 'to_date')
        ){
            let values = utils.getDataFromUrl(qs);
            if( 
                utils.isValidDate(values.from_date) &&
                utils.isValidDate(values.to_date) &&
                values.from_date <= values.to_date
            ){
                this.setState({ ...values });
                dispatch(reportActions.getAll(values));
            }else 
                this.redirectWithError();
        }else
            this.redirectWithError();
        
    }

    redirectWithError = (enableMsg = true) => {
        const { history } = this.props;
        history.push('/reports');
        if(enableMsg){
            notyf.open({ 
                type: 'error', 
                message: 'Invalid Filter ! ' 
            });
        }
    }

    componentWillUnmount() {
        const { dispatch } =  this.props;
        dispatch(reportActions.clear());
    }

    componentDidUpdate(prevProps){
        const { reports } = this.props;
        let isPropsChanged = prevProps.reports.status !== reports.status;

        if(isPropsChanged){
            if(reports.status === 'GET_ALL_ERROR'){
                this.redirectWithError(false);
            }
        }
    }

    render() {
        if(utils.getRole().includes('ADMIN'))
            return (
                <ReportsByAdminLayout 
                    {...this.props}
                    {...this.state}
                    status = {this.props.reports.status}
                    details = {this.props.reports.details}
                />
            );
        else if(utils.getRole().includes('BRANCH'))
            return ( 
                <Forbidden
                    profile = {this.props.profile}
                    dispatch = {this.props.dispatch}
                    notifications = {this.props.notifications}
                />
            );
        else
            return <div></div>;
    }
}

function mapStateToProps(state) {
    const { profile, reports, branch, notifications }  = state;

    return {
        branch,
        profile,
        reports,
        notifications
    };
}

export default connect(mapStateToProps)(ReportsByAdmin);