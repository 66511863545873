import React, { Component }  from 'react';
import ForbiddenLayout from './layout';

export default class Forbidden extends Component {
    render(){
        return (
            <ForbiddenLayout
                profile = {this.props.profile}
                dispatch = {this.props.dispatch}
                notifications = {this.props.notifications}
            />
        )
    }
}