import React, { useState } from 'react';
import { EditOutlined, ExpandMore } from '@material-ui/icons';
import { ViewImage, Button } from '../';
import { utils } from '../../utils';
import useStyles from './style';
import {
    Accordion, AccordionSummary,
    AccordionDetails, Avatar, Box,
    Typography
} from '@material-ui/core';


export default function UserDetailsCard(props) {

    const classes = useStyles();
    const [isExpanded, setIsExpanded] = useState(false);
    const [showImage, setShowImage] = useState({flag: false, details: {}})
    const {
        onClickEdit = () => null,
        canExpanded = true,
        isEditable = false,
        overRideKey = '',
        userDetails = {},
    } = props;

    return (
        <>
        <Accordion 
            expanded={canExpanded ? isExpanded : false} 
            className = {classes.accordionShadow}
            onChange={() => setIsExpanded((prev) => !prev)}
        >
            <AccordionSummary
                expandIcon={<ExpandMore />}
                className = {classes.userDetailsGrid}
                id="userDetails"
            >
                <Avatar 
                    className  = {classes.avatarProfile}
                    variant = 'square'
                    src = {utils.getImageUrl(userDetails?.users?.user_image)}
                />
                <Box className={classes.detailwithlabel}>
                    <Typography variant = "p">
                            {   !overRideKey && 'First Name' }
                            {   
                                overRideKey && 
                                userDetails?.users?.last_name &&
                                'Name'
                            }
                    </Typography>
                    <Typography variant = "h6">
                            {   !overRideKey && 
                                ( userDetails?.users?.first_name || "NA" )
                            }
                            {   overRideKey && 
                                ( userDetails?.users?.first_name || "NA" )
                            }
                            {   overRideKey && 
                                ( " " + userDetails?.users?.last_name || "" ) 
                            }
                    </Typography>
                </Box>
                {
                    !overRideKey &&
                    <Box className={classes.detailwithlabel}>
                        <Typography variant = "p">
                                Last Name
                        </Typography>
                        <Typography variant = "h6">
                                {userDetails?.users?.last_name || "NA"}
                        </Typography>
                    </Box>
                }
                <Box className={classes.detailwithlabel}>
                    <Typography variant = "p">
                            Phone Number
                    </Typography>
                    <Typography variant = "h6">
                        {userDetails?.users?.mobile_number || "NA"}
                    </Typography>
                </Box>
                {
                    overRideKey &&
                    <Box className={classes.detailwithlabel}>
                        <Typography variant = "p">
                                {overRideKey}
                        </Typography>
                        <Typography variant = "h6">
                                {props?.overRideKeyValue || "NA"}
                        </Typography>
                    </Box>
                }
            </AccordionSummary>
            <AccordionDetails
                className = {classes.userDetailsBriefGrid}
            >
                <Box className={classes.userDetailsBrief}>
                    <Box className={classes.detailwithlabel}>
                        <Typography variant = "p">
                                Date of Birth
                        </Typography>
                        <Typography variant = "h6">
                                {userDetails?.users?.dob || "NA"}
                        </Typography>
                    </Box>
                    <Box className={classes.detailwithlabel}>
                        <Typography variant = "p">
                                Email Id
                        </Typography>
                        <Typography variant = "h6">
                            {userDetails?.users?.email || "NA"}
                        </Typography>
                    </Box>  
                    {
                        isEditable &&
                        <Box className={classes.detailwithlabel}>
                            <Button 
                                text = "Edit Details"
                                variant = 'primary'
                                Icon = {EditOutlined}
                                onClick = {() => onClickEdit(userDetails?.users)}
                            />
                        </Box>  
                    }
                </Box>  
                <Typography variant = "h5">
                        KYC Details
                </Typography>
                {
                    userDetails?.kyc?.length > 0 &&
                    userDetails?.kyc.map(
                        e => {
                            return(
                                <Box className={classes.userDetailsBrief}>
                                    <Box className={classes.detailwithlabel}>
                                        <Typography variant = "p">
                                                Doc Type
                                        </Typography>
                                        <Typography variant = "h6">
                                                {e.doc_type || "NA"}
                                        </Typography>
                                    </Box>
                                    <Box className={classes.detailwithlabel}>
                                        <Typography variant = "p">
                                                Kyc Number
                                        </Typography>
                                        <Typography variant = "h6">
                                                {e.kyc_number || "NA"}
                                        </Typography>
                                    </Box>
                                    <Box className={classes.detailwithlabel}>
                                        <Typography variant = "p">
                                                Kyc Document
                                        </Typography>
                                        <Typography variant = "h6" 
                                            onClick={() => setShowImage({
                                                flag: true,
                                                details: {
                                                    ...e,
                                                    kyc_image: utils.getImageUrl(e.kyc_image)
                                                } 
                                            })}
                                        >
                                            {
                                                e.kyc_image &&
                                                <span style={{
                                                    textDecoration: 'underline',
                                                    color: '#0062FF',
                                                    cursor: 'pointer'
                                                }}>
                                                    View File
                                                </span>
                                            }
                                            {!e.kyc_image && "NA"}
                                        </Typography>
                                    </Box>   
                                </Box>
                            );
                        }
                    )
                } 
                {
                    userDetails?.kyc?.length === 0 &&
                    <Box className={classes.userDetailsBrief}>
                        <Box className={classes.detailwithlabel}>
                            <Typography variant = "p">
                                    Not available
                            </Typography>
                        </Box>
                    </Box>
                }
                <Typography variant = "h5">
                        Address
                </Typography>
                {
                    userDetails?.address?.length === 0 &&
                    <Box className={classes.userDetailsBrief}>
                        <Box className={classes.detailwithlabel}>
                            <Typography variant = "p">
                                    Not available
                            </Typography>
                        </Box>
                    </Box>
                }
                <Box className={classes.userDetailsBrief}>
                {
                    userDetails?.address?.length > 0 &&
                    userDetails?.address.map(
                        e => {
                            return(
                                <address className={classes.AddressBriefs}>
                                    <Typography variant = "h6">
                                        {e['door_no'] || ''}{' '}
                                        {e['street' || '']}
                                    </Typography>
                                    <Typography variant = "h6">
                                        {e['area'] || ''}{' '}
                                    </Typography>
                                    <Typography variant = "h6">
                                        {e['city'] || ''}{' '}
                                        {e['pincode' || '']}
                                    </Typography>
                                    <Typography variant = "h6">
                                        {e['state'] || ''}{' '}
                                    </Typography>
                                    <Typography variant = "h6">
                                        {e['country'] || ''}{' '}
                                    </Typography>                                   
                                </address>
                            );
                        }
                    )
                } 
                </Box>
            </AccordionDetails>
        </Accordion>
        {
            showImage.flag &&
            <ViewImage
                show = {showImage.flag}
                setShow = {setShowImage}
                image = {showImage.details?.kyc_image}
                imageTitle = {showImage.details?.doc_type + " Image "}
            />
        }
        </>
    );
}