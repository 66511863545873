import React, { Component } from 'react';
import { leaveActions } from '../../actions/leave-request';
import LeaveRequestsLayout from './layout';
import { connect } from 'react-redux';
import { utils } from '../../utils';

class LeaveRequests extends Component{
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            limit: 10
        }
    }

    componentDidMount(){
        const { profile } = this.props;
        if(profile.basic?.roles?.length){
            this.getDetails();
        }
    }

    getDetails = (page = 0, limit = 10, fl = {}) => {
        const { dispatch, profile } = this.props;
        let filter = { ...fl };
        if(utils.getRole()?.includes('EMPLOYEE')){
            let user_id = profile.basic?.id ;
            filter = { ...filter, request_by: user_id }
        }
        let offset = page * limit;
        dispatch(leaveActions.getAll( '', offset, limit, filter ));
        dispatch(leaveActions.getTotalRecords( '', filter ));
    }

    handleSubmit = (payload) => {
        const { dispatch } = this.props;
        dispatch(leaveActions.raise(payload));
    }

    handlePendingActionSubmit = (p) => {
        const { dispatch } = this.props;
        dispatch(leaveActions.pendingLeaveAction(p));
    }

    handleCancelActionSubmit = (p) => {
        const { dispatch } = this.props;
        dispatch(leaveActions.cancelLeaveAction(p));
    }

    resetPagination = () => {
        this.setState({ page: 0, limit: 10 });
    }


    componentDidUpdate(prevProps) {
        const { leave, auth } = this.props;
        let newAuthProps = auth.status !== prevProps.auth.status;
        let isPropsChanged = leave.status !== prevProps.leave.status;
        if(isPropsChanged){
            if(
                leave.status === 'POST_SUCCESS' || 
                leave.status === 'P_ACTION_SUCCESS' ||
                leave.status === 'CANCEL_LR_SUCCESS'
            ){
               this.getDetails();
               this.resetPagination();
            }
        }

        if(newAuthProps) {
            if(auth.status === 'VALIDATE_SUCCESS'){
                this.getDetails();
            }
        }
    }

    render() {
        return (
            <LeaveRequestsLayout
                {...this.props}
                {...this.state}
                getDetails = {this.getDetails}
                handleSubmit = {this.handleSubmit}
                status = {this.props.leave.status}
                noOfRecords = {this.props.leave.noOfRecords}
                leaveDetails = {this.props.leave.leaveDetails}
                setPage = {(val) => this.setState({ page: val})}
                setLimit = {(val) => this.setState({ limit: val})}
                handleCancelActionSubmit = {this.handleCancelActionSubmit}
                handlePendingActionSubmit = {this.handlePendingActionSubmit}
            />
        );
    }
}

function mapStateToProps(state) {
    const { profile, notifications, leave, auth } = state;
    
    return {
        auth,
        leave,
        profile,
        notifications
    }
}

export default connect(mapStateToProps)(LeaveRequests);