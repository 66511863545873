import React, { Component } from 'react';
import { connect } from 'react-redux';
import FundRequestLayout from './layout';
import { branchActions } from '../../actions/branch';
import { fundActions } from '../../actions/fund-request';

class FundRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            limit: 10
        }
    }

    componentDidMount(){
        const { dispatch, profile } = this.props;
        dispatch(branchActions.getAll());
        if(profile.basic?.branch_id){
            this.getDetails();
        }
    }

    getDetails = (page = 0, limit = 10, fl = {}) => {
        const { dispatch, profile } = this.props;
        let br_id = profile.basic?.branch_id ;
        let offset = page * limit;
        let filter = { requested_branch_id : br_id, ...fl };
        dispatch(fundActions.getAll( 'INTERNAL', offset, limit, filter ));
        dispatch(fundActions.getTotalRecords( 'INTERNAL', filter ));
    }

    handleSubmit = (payload) => {
        const { dispatch } = this.props;
        dispatch(fundActions.raise(payload));
    }

    handlePendingActionSubmit = (p) => {
        const { dispatch } = this.props;
        dispatch(fundActions.pendingFundAction(p));
    }

    handleConfirmFundsAction = (p) => {
        const { dispatch } = this.props;
        dispatch(fundActions.confirmFundsAction(p));
    }

    handleCancelFundsAction = (p) => {
        const { dispatch } = this.props;
        dispatch(fundActions.cancelFundsAction(p));
    }

    resetPagination = () => {
        this.setState({ page: 0, limit: 10 });
    }

    componentDidUpdate(prevProps) {
        const { funds, auth } = this.props;
        let newAuthProps = auth.status !== prevProps.auth.status;
        let isPropsChanged = funds.status !== prevProps.funds.status;
        if(isPropsChanged){
            if(
                funds.status === 'POST_FUND_SUCCESS' ||
                funds.status === 'C_ACTION_SUCCESS' ||
                funds.status === 'CANCEL_FR_SUCCESS'
            ){
                this.getDetails();
                this.resetPagination();
            }
        }

        if(newAuthProps) {
            if(auth.status === 'VALIDATE_SUCCESS'){
                this.getDetails();
            }
        }
    }

    render() {
        return (
            <FundRequestLayout 
                {...this.state}
                {...this.props}
                getDetails = {this.getDetails}
                handleSubmit = {this.handleSubmit}
                status = {this.props.funds.status}
                basic = {this.props.profile.basic}
                fundDetails = {this.props.funds.fundDetails}
                branches = {this.props.branch.branchDetails}
                noOfRecords = {this.props.funds.noOfRecords}
                setPage = {(val) => this.setState({ page: val})}
                setLimit = {(val) => this.setState({ limit: val})}
                handleCancelFundsAction = {this.handleCancelFundsAction}
                handleConfirmFundsAction = {this.handleConfirmFundsAction}
                handlePendingActionSubmit = {this.handlePendingActionSubmit}
            />
        );
    }
}

function mapStateToProps(state) {
    const { profile, funds, notifications, branch, auth }  = state;

    return {
        profile,
        branch,
        funds,
        auth,
        notifications
    };
}

export default connect(mapStateToProps)(FundRequest);