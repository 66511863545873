import notificationConstants from '../constants/notification';
import { notificationService } from '../services';

export const notificationActions = {
    get
}


function get(payload){
    return dispatch =>{
        dispatch(request());
        notificationService.get(payload).then(
            notification =>{ dispatch(success(notification)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type: notificationConstants.GET_REQUEST };
    }
    function success(notification){
        return {
            type: notificationConstants.GET_SUCCESS,
            payload: notification
        };
    }
    function failure(error){
        return {
            type: notificationConstants.GET_ERROR,
            payload : error
        };
    }
}