import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, TablePagination } from '@material-ui/core';
import { Button, Navbar, Table, ViewImage } from "../../components";
import { assetRequestsconstants as arc } from '../../constants';
import ConfirmAction from './components/complete-action';
import PendingAction from './components/pending-action';
import notyf from '../../utils/notification';
import { utils } from '../../utils';
import useStyles from './style';
import { 
    KeyboardArrowDown, 
    AutorenewOutlined, 
    ClearAllOutlined 
} from '@material-ui/icons';


let tableHeaders = [
    {
        id: "id",
        label : "Id",
        type: 'text_wo_input',
        minWidth: '80px',
        maxWidth: '80px',
        style : {}
    },
    {
        id: "name",
        label : "Name",
        type: 'text_wo_input',
        minWidth: '120px',
        maxWidth: '120px',
        style : {}
    },
    {
        id: "image",
        label : "Image",
        type: 'popup_action',
        minWidth: '50px',
        maxWidth: '50px',
        style : { }
    },
    {
        id: "approved_branch_name",
        label : "To Branch",
        type: 'dropdown',
        placeHolder: "To Branch",
        dropdownItems: [],
        minWidth: '120px',
        maxWidth: '120px',
        style : {}
    },
    {
        id: "request_on",
        label : "Requested at",
        type: 'text',
        inputType: 'date',
        minWidth: '130px',
        maxWidth: '130px',
        style : {}
    },
    {
        id: "approved_on",
        label : "Approved at",
        type: 'text',
        inputType: 'date',
        minWidth: '130px',
        maxWidth: '130px',
        style : {}
    },
    {
        id: 'status',
        label: 'Status',
        type: 'dropdown',
        placeHolder: "Status",
        dropdownItems: [
            {label: "Pending", value: "PENDING"},
            {label: "Approved", value: "APPROVED"},
            {label: "Rejected", value: "REJECTED"},
            {label: "Need Confirmation", value: "NEED_CONFIRMATION"},
            {label: "Completed", value: "COMPLETED"},
            {label: "Cancelled", value: "CANCELLED"},
        ],
        minWidth: '120px',
        maxWidth: '120px',
    },
    {
        id: 'status',
        label: '',
        type: 'icon',
        minWidth: '30px',
        maxWidth: '30px'
    },
    {
        id: 'more',
        label: '',
        type: 'checkbox',
        minWidth: '70px',
        maxWidth: '70px'
    }
];

let paginationDropDownList = [ 10, 20, 40 ];


let headerMenuOptions = [
    {key: 'confirm', title: 'Confirm'}
];

let defaultQuery = {
    approved_branch_name : 0,
    approved_on: '',
    request_on: '',
    status: ''
}

export default function AssetsRequestOutLayout(props){
    const classes = useStyles();
    const [showImage, setShowImage] = useState({flag: false, details: {}});
    const [filterQuery, setFilterQuery] = useState({ ...defaultQuery });
    const [clearInputFields, setClearInputFields] = useState(false);
    const [tableColumns, setTableColumns] = useState(tableHeaders);
    const [selectedRow, setSelectedRow] = useState([]);
    const [tableData, setTableData] = useState([]);
    const {
        handleConfirmSubmit = () => null,
        handleSubmitRequest = () => null,
        setClearInputs = () => null,
        setShowConfirm = () => null,
        setShowPending = () => null,
        getDetails = () => null,
        setLimit = () => null,
        setPage = () => null,
        requestDetails = [],
        clearInputs = false,
        showConfirm = false,
        showPending = false,
        branchDetails = [],
        noOfRecords = 0,
        profile = {},
        status = '',
        limit = 10,
        page = 0
    } = props;

    let role = utils.getRole() || ""

    const dataFormatter = (e) => {
        return {
            ...e,
            request_on: utils.getLocalTime(e.request_on || ""),
            approved_on: utils.getLocalTime(e.approved_on || ""),
            image: utils.getImageUrl(e.image || ""),
        }
    }

    useEffect(() => {
        if(clearInputFields){
            setClearInputFields(false);
            setFilterQuery(defaultQuery);
        }
    }, [clearInputFields]);

    useEffect(() => {
        if(status === 'GET_REQ_SUCCESS'){
            let td = [];
            if(requestDetails.length > 0){
                td = requestDetails.map(e => {
                    return dataFormatter(e);
                });
            }
            setTableData([ ...td ]);
            setSelectedRow([]);
            setClearInputs(true);
        }
        if(['P_ACTION_SUCCESS', 'C_ACTION_SUCCESS'].includes(status)){
            setSelectedRow([]);
            setClearInputFields(true);
        }
    },[status]);

    const getBranchDropdown = (branches) => {
        return branches.map(
            f => { return { 
                label: f.branch_name, 
                value: f.id 
            } }
        )
    }

    useEffect(() => {
        let thc = tableColumns;
        thc = thc.map(e => {
            if( e.id === 'approved_branch_name' && branchDetails.length > 0 )
                return { ...e, dropdownItems: getBranchDropdown(branchDetails) }
            else return e;
        })
        setTableColumns(thc);
    }, [role, branchDetails]);


    const handleMenuDropdown = (e, k, i, r) => {
        if(k.key === 'confirm'){
            let flag = false;
            selectedRow.map((f,index) => {
                if( !['NEED_CONFIRMATION', 'REJECTED'].includes(f.status) )
                    flag = true;
            })
            if( !role.includes('EMPLOYEE') && !flag )
                setShowConfirm(true);
            else if(flag)
                notyf.open({ type: 'error', message: arc.multipleNotallowed });                   
            else
                notyf.open({ type: 'error', message: arc.invalidAction });

        }

    }

    const handleImageViewAction = (r, c, i) => {
        setShowImage({flag: true, details: r});
    }

    const onRowClick = (e, r, i) => {
        
    }

    const handleAllcheckBoxSelect = (e, r, i, all = false) => {
        if(all){
            if(e.target.checked){
                let arr = [];
                tableData.map(f => {arr.push(f)});
                setSelectedRow(arr);
            }else
                setSelectedRow([]);
        }else {
            if(e.target.checked){
                let arr = [ ...selectedRow, r ];
                setSelectedRow(arr);
            }else {
                let arr = [ ...selectedRow ];
                arr = arr.filter(f => f !== r );
                setSelectedRow(arr);
            }
        }
        setClearInputs(false);
    }


    const handleHeaderMenu = () => {
        if(selectedRow.length)  
            return headerMenuOptions;
        else return [];
    }

    const onChangeRowsPerPage = (e) => {
        setPage(0);
        setLimit(e.target.value);
        getDetails(0, e.target.value, filterQuery);
    }
    
    const onChangePage = (e, val) => {
        setPage(val);
        getDetails(val, limit, filterQuery);
    }

    const handleFilterChange = (val, col) => {
        let fq = { ...filterQuery }
        fq = { ...fq, [col.id] : val };
        setPage(0);
        setFilterQuery(fq);
        getDetails(0, limit, fq);
    }

    const resetFilter = () => {
        setPage(0);
        setClearInputFields(true);
        getDetails(0, limit, defaultQuery);
    }


    return (
        <Navbar 
            { ...props }
        >
            <Box className={classes.mainBox}>
                <Box className = {classes.tableTitleBox}>
                    <Typography variant = "h5">
                        {arc.tableTitle2}
                    </Typography>
                    <Box className={classes.actionButtons}>
                        <Button
                            text = "Clear Filter"
                            variant = "secondary"
                            Icon = {ClearAllOutlined}
                            style = {{
                                padding: '8px 16px', 
                                marginRight: '16px' 
                            }}
                            onClick = {resetFilter}
                        />
                        <Button
                            text = "Refresh"
                            variant = "secondary"
                            Icon = {AutorenewOutlined}
                            style = {{
                                padding: '8px 16px', 
                                marginRight: '16px' 
                            }}
                            onClick = {() => getDetails(page, limit, filterQuery)}
                        />
                    </Box>
                </Box>
            </Box>
            <Paper className = {classes.tableBox}>
                <Table
                    loading = {status === 'GET_REQ_REQUEST'}
                    handleMenuDropdown = {handleMenuDropdown}
                    handlePopupAction={handleImageViewAction}
                    handleCheckBox = {handleAllcheckBoxSelect}
                    headerMenuOptions = {handleHeaderMenu()}
                    handleChange = {handleFilterChange}
                    onTableRowClick = {onRowClick}
                    tableColumns = {tableColumns}
                    clearInputs = {clearInputs}
                    tableData = {tableData}
                    rowMenuOptions = {[]}
                />
                 <TablePagination
                    rowsPerPageOptions={paginationDropDownList}
                    component='div'
                    count={noOfRecords}
                    rowsPerPage={limit}
                    page={page}
                    SelectProps={{
                        IconComponent: KeyboardArrowDown,
                        classes: { icon: classes.selectIcon },
                    }}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                />  
            </Paper>
            {
                showImage.flag &&
                <ViewImage
                    show = {showImage.flag}
                    setShow = {setShowImage}
                    image = {showImage.details?.image}
                    imageTitle = {showImage.details?.name + " IMAGE "}
                />
            }
            {
                showPending &&
                <PendingAction
                    status = {status}
                    show = {showPending}
                    details = {selectedRow}
                    setShow = {setShowPending}
                    handleSubmit = {handleSubmitRequest}
                />
            }
            {
                showConfirm &&
                <ConfirmAction
                    status = {status}
                    show = {showConfirm}
                    details = {selectedRow}
                    setShow = {setShowConfirm}
                    handleSubmit = {handleConfirmSubmit}
                />
            }
        </Navbar>
    );
}