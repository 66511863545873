import roleConstants from '../constants/role';

const initialState = {
    status: '',
    roles: []
}

function role(state = initialState, action) {
    switch(action.type) {
        case roleConstants.GET_ALL_SUCCESS : 
            return {
                ...state,   
                status: 'GET_ALL_SUCCESS',
                roles: action.payload.data
            }
        case roleConstants.GET_ALL_ERROR:
            return {
                ...state,
                status: 'GET_ALL_ERROR'
            }
        case roleConstants.GET_ALL_REQUEST:
            return {
                ...state,
                status: 'GET_ALL_REQUEST'
            }
        case roleConstants.U_ROLE_EMP_REQUEST: 
            return {
                ...state,   
                status: 'U_ROLE_EMP_REQUEST'
            }
        case roleConstants.U_ROLE_EMP_SUCCESS:
            return {
                ...state,
                status: 'U_ROLE_EMP_SUCCESS'
            }
        case roleConstants.U_ROLE_EMP_ERROR:
            return {
                ...state,
                status: 'U_ROLE_EMP_ERROR'
            }
        default: return state;
    }
}

export default role;