import { 
    makeStyles 
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    box: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        justifyContent: 'center'
    },
    gridBox: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '-40px'
    },
    gridText : {
        textAlign: 'center'
    },
    homeButton: {
        marginTop: '20px'
    }
}));

export default useStyles;